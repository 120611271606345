/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
export type ProjectMemberRow__ProjectMembershipFragment = { __typename: 'ProjectMembership', id: string, permissionNames: Array<Types.PermissionName>, user: { __typename: 'User', id: string, email: any, active: boolean, organisationId: string, organisationTitle: string, jobTitle: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string, label: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string, label: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }> };

export const ProjectMemberRow__ProjectMembershipFragmentDoc = gql`
    fragment ProjectMemberRow__ProjectMembership on ProjectMembership {
  id
  user {
    id
    email
    active
    roles {
      id
      internalName
      label
    }
    defaultProjectRoles {
      id
      internalName
      label
    }
    organisationId
    organisationTitle
    active
    jobTitle
    homeAddress {
      ...AddressDetails__Address
    }
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
    email
    phones {
      id
      main
      type
      number
    }
    avatar {
      ...AttachmentSnapshot
    }
  }
  roles {
    id
    label
    internalName
  }
  permissionNames
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;