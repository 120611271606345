import { ModalOpenButton } from "@msys/ui";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../auth/useUserData";
import { DecisionWizardButton } from "../doc-items/buttons/DecisionWizardButton";
import { CreateQuoteFromRequirementButton } from "../quotes/buttons/CreateQuoteForRequirementButton";
import { FinalizeDecisionsInBulkButton } from "../quotes/buttons/FinalizeDecisionsInBulkButton";
import { RequestCreateButton } from "../requests/buttons/RequestCreateButton";
import { RequestQuoteForRequirementButton } from "../requests/buttons/RequestQuoteForRequirementButton";
import { RequestViewButton } from "../requests/buttons/RequestViewButton";
import { useShop } from "../shop/hooks/useShopCart";
import {
  RequirementAction_ProjectFragment,
  RequirementAction_RequirementFragment,
} from "./RequirementAction.generated";
import { RequirementSendViaEmailModal } from "./RequirementSendViaEmailModal";

interface Props {
  doc: RequirementAction_RequirementFragment;
  project: RequirementAction_ProjectFragment;
  pathToProject: string;
  startDecisionWizard?: () => unknown;
  startDecisionsInBulkProcess?(): void;
}

export const RequirementAction = ({
  doc: requirement,
  project,
  pathToProject,
  startDecisionWizard,
  startDecisionsInBulkProcess,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["Global"]);
  const viewer = useUserData().currentUser!;
  const isShopEnabled = useFeature("Shop");
  const { setCartId } = useShop();

  const actionButtons: React.ReactNode[] = [
    <RestrictedByProjectPermissionWithDebug
      permission="SHARE_REQUIREMENTS"
      project={project}
      key={"send-requirement-via-email"}
    >
      <ModalOpenButton
        Modal={RequirementSendViaEmailModal}
        modalProps={{
          project,
          requirement,
        }}
      >
        <Button color="primary" variant="contained" endIcon={<SendIcon />}>
          {t("Send", { ns: "Global" })}
        </Button>
      </ModalOpenButton>
    </RestrictedByProjectPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="MANAGE_REQUIREMENTS"
      document={requirement}
      key={"view requirement request"}
    >
      {requirement.owningSystemOrganisationId === viewer.organisation.id &&
        requirement.outgoingRequests.length > 0 && (
          <RequestViewButton projectId={project.id} requirement={requirement} />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByProjectPermissionWithDebug
      permission="SHARE_REQUIREMENTS"
      project={project}
      key={"create requirement request"}
    >
      {requirement.owningSystemOrganisationId === viewer.organisation.id &&
        !requirement.requirementRequestWonId && (
          <RequestQuoteForRequirementButton
            requirementId={requirement.id}
            projectId={project.id}
            refetchQueries={[
              namedOperations.Query.ProjectOverviewIncomingQuotes,
              namedOperations.Query.ProjectQuotes,
              namedOperations.Query.ProjectRequests,
            ]}
            Button={<RequestCreateButton type="button" />}
          />
        )}
    </RestrictedByProjectPermissionWithDebug>,

    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"import requirements to quote"}
    >
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
      >
        <CreateQuoteFromRequirementButton
          docId={requirement.id}
          docProjectId={project.id}
          pathToProject={pathToProject}
        />
      </RestrictedByProjectPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    startDecisionWizard && (
      <DecisionWizardButton
        key={"decision-wizard"}
        start={startDecisionWizard}
      />
    ),
    startDecisionsInBulkProcess && (
      <FinalizeDecisionsInBulkButton
        onClick={startDecisionsInBulkProcess}
        key={"decisions"}
      />
    ),
    isShopEnabled && (
      <RestrictedByCapabilityWithDebug capability="SHOP">
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_REQUIREMENTS"
          document={requirement}
          key={"shop requirement"}
        >
          {requirement.owningSystemOrganisationId ===
            viewer.organisation.id && (
            <Button
              color="secondary"
              onClick={async () => {
                setCartId(requirement.id);
                navigate(`/shop`);
              }}
              variant="outlined"
              key={"shop requirement"}
            >
              Shop
            </Button>
          )}
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
  ].filter(Boolean);

  return actionButtons;
};
