import { ListItem, useFormatting, useScreenWidth } from "@msys/ui";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Divider,
  Icon,
  IconButton,
  Link as MuiLink,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Circle, GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useTranslate } from "@tolgee/react";
import { values } from "lodash";
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { color } from "../../../common/MuiThemeProvider";
import { useGoogleMapsApi } from "../../../common/google-maps/useGoogleMapsApi";
import { ReactComponent as EditCalendarIcon } from "../../assets/icons/edit-calendar.svg";
import companyLocationIconUrl from "../../assets/icons/icon-map-company.svg";
import craftsmanLocationIconUrl from "../../assets/icons/icon-map-craftsman.svg";
import pinIconBlueUrl from "../../assets/icons/icon-map-pin-blue.svg";
import pinIconRedUrl from "../../assets/icons/icon-map-pin-red.svg";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../auth/useUserData";
import { Stack } from "../../commons/layout/Stack";
import { AddressDetails__AddressFragment } from "../addresses/Addresses.generated";
import { getAddressLabel, getAddressSearchUrl } from "../addresses/helpers";
import { usePhoneTypes } from "../phones/usePhoneTypes";
import { ProjectListItem } from "../projects/components/ProjectListItem";
import { MapProjectFragment } from "../schedule/Fragments.generated";
import { useOrganisationRoles } from "../users/useRoles";
import { PlanningResourcesFragment } from "./PlanningSchedule.generated";

const CONTAINER_STYLE = {
  maxWidth: "1280px",
  margin: "0 auto",
  width: "100%",
  height: "100%",
  flexGrow: 1,
  flexShrink: 1,
};

const CIRCLE_OPTIONS = {
  fillColor: color.secondary,
  fillOpacity: 0.2,
  strokeColor: color.secondary,
  strokeOpacity: 0.7,
};

type MapBuilding = MapProjectFragment["buildingInfo"] & {
  projects: MapProjectFragment[];
};
type MapResource = {
  address: AddressDetails__AddressFragment;
  craftsmen: PlanningResourcesFragment[];
};

export interface PlanningMapRef {
  setSelectedBuildingId: (buildingId?: string) => void;
  setSelectedResourceId: (resourceId?: string) => void;
}

interface Props {
  projects: MapProjectFragment[];
  resources: PlanningResourcesFragment[];
  maxWidth?: number;
  fitBounds?: boolean;
  initialZoom?: number;
  initialCenter?: { lat: number; lng: number };
  initialSelectedBuildingId?: string;
  initialSelectedResourceId?: string;
  projectAddress?: AddressDetails__AddressFragment;
  resourceMaxDistance?: number;
  RightButtons?: React.ReactNode;
}

export const PlanningMap = forwardRef<PlanningMapRef, Props>(
  (
    {
      projects,
      resources,
      maxWidth,
      fitBounds = true,
      initialZoom = 4,
      initialCenter,
      initialSelectedBuildingId,
      initialSelectedResourceId,
      projectAddress,
      resourceMaxDistance,
      RightButtons,
    },
    ref
  ) => {
    const { isLoaded } = useGoogleMapsApi();
    const viewer = useUserData().currentUser!;
    const organisationAddress =
      viewer.organisation.branchAddress ?? viewer.organisation.billingAddress;

    const [map, setMap] = React.useState<google.maps.Map | null>(null);

    const [center, setCenter] = useState<
      { lat: number; lng: number } | undefined
    >(
      initialCenter ??
        (organisationAddress
          ? {
              lat: organisationAddress.lat,
              lng: organisationAddress.lng,
            }
          : undefined)
    );
    const [zoom, setZoom] = useState<number | undefined>(initialZoom);

    useUpdateEffect(() => {
      const newCenter =
        initialCenter ??
        (organisationAddress
          ? {
              lat: organisationAddress.lat,
              lng: organisationAddress.lng,
            }
          : undefined);
      if (newCenter) setCenter(newCenter);
    }, [initialCenter, organisationAddress]);

    useUpdateEffect(() => {
      if (initialZoom) setZoom(initialZoom);
    }, [initialZoom]);

    const [selectedBuildingId, setSelectedBuildingId] = useState<
      string | undefined
    >(initialSelectedBuildingId);

    const [selectedResourceId, setSelectedResourceId] = useState<
      string | undefined
    >(initialSelectedResourceId);

    const onLoad = React.useCallback((map: google.maps.Map) => {
      setMap(map);
    }, []);

    React.useImperativeHandle(ref, () => ({
      setSelectedBuildingId,
      setSelectedResourceId,
    }));

    const mapBuildings: MapBuilding[] = useMemo(() => {
      const buildings: Record<string, MapBuilding> = {};

      projects.forEach(project => {
        if (!project?.buildingInfo?.id) return;

        if (!buildings[project.buildingInfo.id]) {
          buildings[project.buildingInfo.id] = {
            ...project.buildingInfo,
            projects: [],
          };
        }

        buildings[project.buildingInfo.id].projects.push(project);
      });

      return values(buildings) as MapBuilding[];
    }, [projects]);

    const mapResources: MapResource[] = useMemo(() => {
      const craftsmen: Record<string, MapResource> = {};

      resources
        .filter(r => r.homeAddress)
        .forEach(resource => {
          const key = `${resource.homeAddress!.lat}-${
            resource.homeAddress!.lng
          }`;
          craftsmen[key] = craftsmen[key] || {
            address: resource.homeAddress,
            craftsmen: [],
          };
          craftsmen[key].craftsmen.push(resource);
        });

      return values(craftsmen) as MapResource[];
    }, [resources]);

    const selectedBuilding: MapBuilding | undefined = useMemo(
      () =>
        selectedBuildingId
          ? mapBuildings.find(b => b.id === selectedBuildingId)
          : undefined,
      [mapBuildings, selectedBuildingId]
    );

    const selectedResource: MapResource | undefined = useMemo(
      () =>
        selectedResourceId
          ? mapResources.find(r =>
              r.craftsmen.some(c => c.id === selectedResourceId)
            )
          : undefined,
      [mapResources, selectedResourceId]
    );

    useEffect(() => {
      if (!map) return;
      if (!fitBounds) return;

      const bounds = new google.maps.LatLngBounds();

      mapBuildings.forEach(building =>
        bounds.extend(building.buildingAddress!)
      );

      if (organisationAddress) {
        bounds.extend(organisationAddress);
      }

      // Don't zoom in too far on only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.1,
          bounds.getNorthEast().lng() + 0.1
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.1,
          bounds.getNorthEast().lng() - 0.1
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }

      map.fitBounds(bounds);

      const zoom = map.getZoom();
      // show only area around organisation if zoomed out to far
      if (zoom && zoom < 6 && organisationAddress) {
        map.setCenter(organisationAddress);
        map.setZoom(6);
      }
    }, [map, mapBuildings, organisationAddress, fitBounds]);

    if (!isLoaded) return null;

    return (
      <Stack
        flexDirection="column"
        flex={1}
        width="100%"
        height="100%"
        spacing={1}
        overflow="hidden"
      >
        {RightButtons && (
          <Stack justifyContent="flex-end" alignItems="center">
            {RightButtons}
          </Stack>
        )}
        {/*@ts-ignore property 'children' does not exist*/}
        <GoogleMap
          options={{ mapTypeControl: false, streetViewControl: false }}
          mapTypeId={google.maps.MapTypeId.ROADMAP}
          mapContainerStyle={{
            ...CONTAINER_STYLE,
            ...(maxWidth ? { maxWidth } : undefined),
          }}
          onLoad={onLoad}
          zoom={zoom}
          center={center}
        >
          <OrganisationMarker />

          {mapResources.map(resource => (
            <Marker
              visible
              key={
                // TODO better use some object hash function
                resource.address.streetLines1 +
                resource.address.postalCode +
                resource.address.city
              }
              label={
                resource.craftsmen.length > 1
                  ? {
                      text: resource.craftsmen.length.toString(),
                      className: "msys-map-pin-label",
                      fontSize: "12px",
                    }
                  : undefined
              }
              position={{
                lat: resource.address.lat,
                lng: resource.address.lng,
              }}
              onClick={() => setSelectedResourceId(resource.craftsmen[0].id)}
              icon={getCraftsmanMarker()}
              zIndex={1}
            />
          ))}
          {mapBuildings.map(building => (
            <Marker
              visible
              key={building.id}
              label={
                building.projects.length > 1
                  ? {
                      text: building.projects.length.toString(),
                      className: "msys-map-pin-label",
                      fontSize: "12px",
                    }
                  : undefined
              }
              position={{
                lat: building.buildingAddress!.lat,
                lng: building.buildingAddress!.lng,
              }}
              onClick={() => setSelectedBuildingId(building.id)}
              icon={
                building.projects.find(project => !project.assigned)
                  ? getPinMarker("red")
                  : getPinMarker("blue")
              }
              zIndex={2}
            />
          ))}
          {selectedResource && (
            // @ts-ignore property 'children' does not exist
            <InfoWindow
              options={{ pixelOffset: new google.maps.Size(0, -8) }}
              position={{
                lat: selectedResource.address.lat,
                lng: selectedResource.address.lng,
              }}
              onCloseClick={() => setSelectedResourceId(undefined)}
              zIndex={998}
            >
              <ResourceInfoWindow resource={selectedResource} />
            </InfoWindow>
          )}
          {selectedBuilding && (
            // @ts-ignore property 'children' does not exist
            <InfoWindow
              options={{ pixelOffset: new google.maps.Size(0, -20) }}
              position={{
                lat: selectedBuilding.buildingAddress!.lat,
                lng: selectedBuilding.buildingAddress!.lng,
              }}
              onCloseClick={() => setSelectedBuildingId(undefined)}
              zIndex={999}
            >
              <BuildingInfoWindow building={selectedBuilding} />
            </InfoWindow>
          )}
          {projectAddress && (resourceMaxDistance ?? 0) > 0 && (
            <Circle
              options={CIRCLE_OPTIONS}
              center={{
                lat: projectAddress.lat,
                lng: projectAddress.lng,
              }}
              radius={resourceMaxDistance! * 1000}
              draggable={false}
              editable={false}
            />
          )}
        </GoogleMap>
      </Stack>
    );
  }
);

function OrganisationMarker() {
  const viewer = useUserData().currentUser!;

  const [isOpen, setIsOpen] = React.useState(false);

  const address =
    viewer.organisation.branchAddress ?? viewer.organisation.billingAddress;
  if (!address) return null;

  return (
    // @ts-ignore property 'children' does not exist
    <Marker
      visible
      position={{
        lat: address.lat,
        lng: address.lng,
      }}
      icon={getCompanyMarker()}
      onClick={() => setIsOpen(true)}
    >
      {isOpen && (
        // @ts-ignore property 'children' does not exist
        <InfoWindow
          options={{ pixelOffset: new google.maps.Size(0, -8) }}
          position={{
            lat: address.lat,
            lng: address.lng,
          }}
          onCloseClick={() => setIsOpen(false)}
          zIndex={997}
        >
          <Stack flexDirection="column" spacing={0}>
            <MuiLink
              href={`/organisation/profile`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Typography variant="h3">{viewer.organisation.title}</Typography>
            </MuiLink>
            {address && (
              <MuiLink
                href={getAddressSearchUrl(address)}
                target="_blank"
                rel="nofollow noreferrer"
              >
                <Typography variant="body2">
                  {getAddressLabel(address, ", ")}
                </Typography>
              </MuiLink>
            )}
          </Stack>
        </InfoWindow>
      )}
    </Marker>
  );
}

const ResourceInfoWindow = ({ resource }: { resource: MapResource }) => {
  const { phoneTypeLabels } = usePhoneTypes();
  const { getOrganisationRoleTitle } = useOrganisationRoles();

  return (
    <Stack flexDirection="column">
      {resource.craftsmen.map(craftsman => (
        <Fragment key={craftsman.id}>
          <Stack flexDirection="column" spacing={0}>
            <MuiLink
              href={`/organisation/users/${craftsman.id}/profile`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Typography variant="h3">{craftsman.fullname}</Typography>
            </MuiLink>

            <Typography variant="caption" color="secondary">
              {getOrganisationRoleTitle(craftsman.roles)}
            </Typography>

            {craftsman.email && (
              <Stack spacing={0.5}>
                <EmailIcon
                  fontSize="small"
                  style={{
                    flexGrow: 0,
                    flexShrink: 0,
                    position: "relative",
                    top: 1,
                  }}
                />
                <Typography variant="body2">
                  <MuiLink href={`mailto:${craftsman.email}`}>
                    {craftsman.email}
                  </MuiLink>
                </Typography>
              </Stack>
            )}

            {craftsman.phones.length > 0 &&
              craftsman.phones.map((phone, index) => {
                if (phone.type === "PRIVATE")
                  throw new Error("Invalid phone type");
                return (
                  <Stack key={phone.id} spacing={0.5}>
                    <PhoneIcon
                      fontSize="small"
                      style={{ flexGrow: 0, flexShrink: 0 }}
                    />
                    <div>
                      <Typography variant="body2" key={index}>
                        {phoneTypeLabels[phone.type]}{" "}
                        <MuiLink href={`tel:${phone.number}`}>
                          {phone.number}
                        </MuiLink>
                      </Typography>
                    </div>
                  </Stack>
                );
              })}
          </Stack>
          <Divider />
        </Fragment>
      ))}

      <MuiLink
        href={getAddressSearchUrl(resource.address)}
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Typography variant="body2">
          {getAddressLabel(resource.address, ", ")}
        </Typography>
      </MuiLink>
    </Stack>
  );
};

const BuildingInfoWindow = ({ building }: { building: MapBuilding }) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Tickets", "PlanningModal"]);
  const { getFormattedDate } = useFormatting();
  const theme = useTheme();
  const { isMaxPhone } = useScreenWidth();

  return (
    <Stack flexDirection="column">
      {building.buildingAddress && (
        <Stack flexDirection="row" alignItems="center" spacing={0.5}>
          <LocationOnIcon
            fontSize="small"
            style={{ flexGrow: 0, flexShrink: 0 }}
          />
          <Typography variant="body2">
            <MuiLink
              href={`/buildings/${building.id}`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {getAddressLabel(building.buildingAddress, ", ")}
            </MuiLink>
          </Typography>
        </Stack>
      )}
      <Stack
        flexDirection="column"
        spacing={0}
        width={isMaxPhone ? "300px" : "360px"}
      >
        <Divider />
        {building.projects.map(project => (
          <ListItem
            key={project.id}
            component={Link}
            // @ts-ignore
            to={`/projects/${project.id}`}
          >
            <ProjectListItem
              project={project}
              Action={
                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_PROJECT"
                  project={project}
                >
                  {viewer.organisation.id ===
                    project.owningSystemOrganisationId && (
                    <Tooltip
                      title={
                        project.assigned
                          ? t("Re-schedule", {
                              ns: "PlanningModal",
                            })
                          : t("Schedule", {
                              ns: "PlanningModal",
                            })
                      }
                    >
                      <IconButton
                        component={Link}
                        size="small"
                        color="primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        to={`/planning/projects/${project.id}`}
                        style={{ flexGrow: 0, flexShrink: 0 }}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                        }}
                      >
                        <Icon fontSize="small">
                          <EditCalendarIcon
                            style={{ height: 18, width: 18, display: "flex" }}
                          />
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </RestrictedByProjectPermissionWithDebug>
              }
            />
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};

function getPinMarker(color: "red" | "blue") {
  return {
    url: color === "red" ? pinIconRedUrl : pinIconBlueUrl,
    labelOrigin: new google.maps.Point(2, 2),
  };
}

function getCompanyMarker() {
  return {
    url: companyLocationIconUrl,
    anchor: new google.maps.Point(16, 16),
  };
}

function getCraftsmanMarker() {
  return {
    url: craftsmanLocationIconUrl,
    anchor: new google.maps.Point(16, 22),
    labelOrigin: new google.maps.Point(2, 2),
  };
}
