import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { Meister1Flow } from "../../../clients/graphqlTypes";
import { useMeister1FlowsQuery } from "./useM1Integration.generated";

export function useM1Integration() {
  const [flows, setFlows] = React.useState<Array<Meister1Flow>>([]);
  const [hasMeister1Integration, setHasMeister1Integration] =
    React.useState(false);

  const client = useApolloClient();
  const flowsQuery = useMeister1FlowsQuery({
    client,
  });

  React.useEffect(() => {
    setFlows(flowsQuery.data?.meister1Flows ?? []);
    setHasMeister1Integration(
      getDataOrNull(flowsQuery.data?.organisationIntegrations)?.edges.length ===
        1
    );
  }, [
    flowsQuery.data?.meister1Flows,
    flowsQuery.data?.organisationIntegrations,
  ]);

  return {
    meister1Loading: flowsQuery.loading,
    meister1Flows: flows,
    hasMeister1Integration,
  };
}
