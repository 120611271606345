import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  ModalOpenProcess,
  ModalOpenProcessRef,
  ellipsisStyle,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton, Stack, StackProps, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash";
import React from "react";
import { ContactLine, LocationLine } from "../../commons/DataItem";
import { highlightWithBold } from "../../utils";
import { getAddressLabel } from "../addresses/helpers";
import { CrmCreateModal } from "../crm/CrmCreateModal";
import { CrmContactType } from "../crm/useCrmContactTypes";
import { CrmTagChips } from "../crm/CrmTagChips";
import { OrganisationAvatar } from "../organisations/OrganisationAvatar";
import { OrganisationCategoryAvatar } from "../organisations/OrganisationCategoryAvatar";
import {
  CrmCompanySelect_CrmCompaniesRecordFragment,
  useCrmCompanySelectLazyQuery,
} from "./CrmCompanySelect.generated";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";

const DEFAULT_ALLOWED_CONTACT_TYPES: CrmContactType[] = [
  "INDIVIDUAL",
  "COMPANY",
];

export type CrmCompanySelectEntry = Omit<
  CrmCompanySelect_CrmCompaniesRecordFragment,
  "systemTags" | "customTags" | "__typename"
>;

interface Props {
  crmCompanyId?: string | undefined | null;
  onChange: (crmCompany: CrmCompanySelectEntry | null) => void;
  filterProjectId?: string | undefined;
  filterTagsAll?: string[] | undefined;
  filterTagsAny?: string[] | undefined;
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  canCreateNew?: boolean;
  createModalTitle?: string;
  createNewLabel?: string;
  allowedContactTypesForCreate?: CrmContactType[];
  createModalPrefill?: React.ComponentProps<typeof CrmCreateModal>["prefill"];
  error?: string;
  disabled?: boolean;
  disableBlockedBusinessPartners?: boolean;
}

export const CrmCompanySelect = ({
  crmCompanyId,
  onChange,
  filterProjectId,
  filterTagsAll,
  filterTagsAny,
  inputLabel,
  placeholder,
  required = false,
  canCreateNew = false,
  createModalTitle,
  allowedContactTypesForCreate = DEFAULT_ALLOWED_CONTACT_TYPES,
  createNewLabel,
  createModalPrefill,
  error,
  disabled,
  disableBlockedBusinessPartners = true,
  ...stackProps
}: Props & Pick<StackProps, "sx">) => {
  const { t } = useTranslate(["Global"]);

  const [inputValue, setInputValue] = React.useState("");

  const { highlightText, crmCompanies, debouncedRefetch, loading, totalCount } =
    useCrmCompanies(
      crmCompanyId,
      inputValue,
      setInputValue,
      {
        filterProjectId,
        filterTagsAll,
        filterTagsAny,
      },
      disableBlockedBusinessPartners
    );

  const processRef = React.useRef<ModalOpenProcessRef>();

  return (
    <>
      <Stack direction="row" spacing={1} {...stackProps}>
        <Autocomplete
          placeholder={placeholder ?? t("Type to search", { ns: "Global" })}
          options={[
            ...crmCompanies,
            ...(canCreateNew && createNewLabel && !crmCompanyId
              ? [
                  {
                    title: createNewLabel,
                    id: "__create_new__" as const,
                    disabled: false,
                  },
                ]
              : []),
            ...(totalCount > crmCompanies.length
              ? [
                  {
                    title: t("Type to see more...", { ns: "Global" }),
                    id: "__type_to_see_more__" as const,
                    disabled: true,
                  },
                ]
              : []),
          ]}
          getOptionDisabled={option => option.disabled}
          getOptionLabel={option => option.title}
          renderOption={(props, option) =>
            "__typename" in option ? (
              <CrmCompanyListItem
                {...props}
                key={option.id}
                option={option}
                highlightText={highlightText}
              />
            ) : option.id === "__create_new__" ? (
              <li
                {...props}
                key={option.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon sx={{ mr: 0.5 }} color="secondary" />
                <Typography fontWeight="500" color="textSecondary">
                  {option.title}
                </Typography>
              </li>
            ) : (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )
          }
          inputLabel={inputLabel}
          inputValue={inputValue}
          onInputChange={(_, value, reason) => {
            if (reason === "input") {
              setInputValue(value);
              debouncedRefetch(value);
              if (crmCompanyId) onChange(null);
            }
          }}
          required={required}
          value={crmCompanies.find(c => c.id === crmCompanyId) ?? null}
          onChange={value => {
            if (value && "__typename" in value) {
              setInputValue(value?.title ?? "");
              onChange(value);
            } else if (value && value.id === "__create_new__") {
              processRef.current?.open();
            } else if (!value) {
              setInputValue("");
              onChange(null);
            }
          }}
          loading={loading}
          error={error}
          disabled={disabled}
        />
        {canCreateNew && !disabled && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
            <IconButton
              color="primary"
              size="large"
              onClick={() => processRef.current?.open()}
              sx={{ alignSelf: "center" }}
            >
              <AddIcon />
            </IconButton>
          </RestrictedByOrganisationPermissionWithDebug>
        )}
      </Stack>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_CRM">
        <ModalOpenProcess
          ref={processRef}
          Modal={CrmCreateModal}
          modalProps={{
            id: "create-crm-modal",
            title: createModalTitle,
            allowedContactTypes: allowedContactTypesForCreate,
            prefill: createModalPrefill,
            handleComplete: async (handleClose, organisation) => {
              setInputValue(organisation.title);
              onChange(organisation);
              handleClose();
            },
          }}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    </>
  );
};

export function CrmCompanyListItem({
  option,
  highlightText,
  showAvatar = false,
  ...props
}: React.HTMLAttributes<HTMLLIElement> & {
  option: CrmCompanySelect_CrmCompaniesRecordFragment;
  highlightText: (text: string | null) => React.ReactNode;
  showAvatar?: boolean;
}) {
  return (
    <li {...props}>
      {showAvatar && (
        <OrganisationAvatar
          size="s"
          organisationAvatar={option}
          style={{ marginRight: "12px" }}
        />
      )}
      <Stack direction="column" spacing={0.5} minWidth={0} flex={1}>
        <div>
          {highlightText(option.title)}
          {option.abcCategory && option.abcCategory !== "none" && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
                height: "20px",
                marginTop: "-8px",
              }}
            >
              <OrganisationCategoryAvatar abcCategory={option.abcCategory} />
            </span>
          )}
        </div>
        {option.contactPerson && (
          <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
            <ContactLine>
              {highlightText(option.contactPerson.fullname)}
            </ContactLine>
          </div>
        )}
        {option.billingAddress && (
          <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
            <LocationLine>
              {highlightText(getAddressLabel(option.billingAddress))}
            </LocationLine>
          </div>
        )}
      </Stack>
      {option.systemTags.length > 0 || option.customTags.length > 0 ? (
        <Box maxWidth="50%" ml={0.5}>
          <CrmTagChips
            tags={[...option.systemTags, ...option.customTags]}
            boxProps={{ justifyContent: "flex-end" }}
          />
        </Box>
      ) : null}
    </li>
  );
}

const LIMIT = 10;

function useCrmCompanies(
  crmCompanyId: string | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void,
  filters: {
    filterProjectId: string | undefined;
    filterTagsAll: string[] | undefined;
    filterTagsAny: string[] | undefined;
  },
  disableBlockedBusinessPartners: boolean
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useCrmCompanySelectLazyQuery({
    client,
    variables: {
      limit: LIMIT,
      filterProjectId: filters.filterProjectId,
      filterTagsAll: filters.filterTagsAll,
      filterTagsAny: filters.filterTagsAny,
    },
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          filterProjectId: filters.filterProjectId,
          filterTagsAll: filters.filterTagsAll,
          filterTagsAny: filters.filterTagsAny,
          searchTerm: value,
          filterIds: null,
        },
      });
    },
    [
      filters.filterProjectId,
      filters.filterTagsAll,
      filters.filterTagsAny,
      fetchQuery,
    ]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (crmCompanyId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            filterProjectId: filters.filterProjectId,
            filterTagsAll: filters.filterTagsAll,
            filterTagsAny: filters.filterTagsAny,
            filterIds: [crmCompanyId],
          },
        });
        setInputValue(
          getDataOrNull(result.data?.crmCompanies)?.edges[0].node.title ?? ""
        );
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            filterProjectId: filters.filterProjectId,
            filterTagsAll: filters.filterTagsAll,
            filterTagsAny: filters.filterTagsAny,
            searchTerm: inputValueRef.current,
            filterIds: null,
          },
        });
      }
    })();
  }, [
    crmCompanyId,
    filters.filterProjectId,
    filters.filterTagsAll,
    filters.filterTagsAny,
    fetchQuery,
    setInputValue,
  ]);

  const crmCompanies =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)?.edges.map(
      edge => ({
        ...edge.node,
        disabled:
          disableBlockedBusinessPartners &&
          (edge.node.businessPartnerStatus === "GLOBAL_BLOCK" ||
            edge.node.businessPartnerStatus === "ORDER_BLOCK"),
      })
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.crmCompanies)
      ?.totalCount ?? 0;

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  return {
    highlightText,
    crmCompanies,
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}
