/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteCreateModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type QuoteCreateModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, state: Types.ProjectStateMachineStatus, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }>, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string } | null } | null } | null }, organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultContractType: Types.ContractType }, quoteTemplates: { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, owningSystemOrganisationId: string } }> } };

export type QuoteCreateModal_RequirementQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  requirementId: Types.Scalars['ID']['input'];
}>;


export type QuoteCreateModal_RequirementQuery = { requirement: { __typename: 'MissingPermissions' } | { __typename: 'RequirementResult', requirement?: { __typename: 'Requirement', id: string, title: string } | null } };

export type CreateQuoteMutationVariables = Types.Exact<{
  input: Types.CreateQuoteInput;
}>;


export type CreateQuoteMutation = { createQuote: { __typename: 'CreateQuoteResult', quote: { __typename: 'Quote', id: string, title: string, description: string }, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } };

export type Meister1PollForQuoteDocOfLeadIdQueryVariables = Types.Exact<{
  input: Types.Meister1PollForDocOfLeadIdInput;
}>;


export type Meister1PollForQuoteDocOfLeadIdQuery = { meister1PollForQuoteDocOfLeadId?: Array<string> | null };


export const QuoteCreateModalDocument = gql`
    query QuoteCreateModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        state
        incomingQuoteRequests {
          id
          title
          status
          wonBySystemOrganisationId
        }
        building {
          id
          buildingAddress {
            ...AddressDetails__Address
          }
        }
        crmOrganisation {
          id
          title
          contactPerson {
            id
            title
            firstname
            familyname
          }
        }
      }
    }
  }
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultContractType
    }
  }
  quoteTemplates(
    limit: 1
    filters: {sources: [available], applicableFor: QUOTE}
    sorting: {column: title, direction: asc}
  ) {
    edges {
      node {
        id
        resolvedAsReadModelVersionNumber
        title
        owningSystemOrganisationId
      }
    }
    totalCount
  }
}
    ${AddressDetails__AddressFragmentDoc}`;

/**
 * __useQuoteCreateModalQuery__
 *
 * To run a query within a React component, call `useQuoteCreateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteCreateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteCreateModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useQuoteCreateModalQuery(baseOptions: Apollo.QueryHookOptions<QuoteCreateModalQuery, QuoteCreateModalQueryVariables> & ({ variables: QuoteCreateModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>(QuoteCreateModalDocument, options);
      }
export function useQuoteCreateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>(QuoteCreateModalDocument, options);
        }
export function useQuoteCreateModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>(QuoteCreateModalDocument, options);
        }
export type QuoteCreateModalQueryHookResult = ReturnType<typeof useQuoteCreateModalQuery>;
export type QuoteCreateModalLazyQueryHookResult = ReturnType<typeof useQuoteCreateModalLazyQuery>;
export type QuoteCreateModalSuspenseQueryHookResult = ReturnType<typeof useQuoteCreateModalSuspenseQuery>;
export type QuoteCreateModalQueryResult = Apollo.QueryResult<QuoteCreateModalQuery, QuoteCreateModalQueryVariables>;
export const QuoteCreateModal_RequirementDocument = gql`
    query QuoteCreateModal_Requirement($projectId: ID!, $requirementId: ID!) {
  requirement(projectId: $projectId, docId: $requirementId) {
    ... on RequirementResult {
      requirement {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useQuoteCreateModal_RequirementQuery__
 *
 * To run a query within a React component, call `useQuoteCreateModal_RequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteCreateModal_RequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteCreateModal_RequirementQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useQuoteCreateModal_RequirementQuery(baseOptions: Apollo.QueryHookOptions<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables> & ({ variables: QuoteCreateModal_RequirementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>(QuoteCreateModal_RequirementDocument, options);
      }
export function useQuoteCreateModal_RequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>(QuoteCreateModal_RequirementDocument, options);
        }
export function useQuoteCreateModal_RequirementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>(QuoteCreateModal_RequirementDocument, options);
        }
export type QuoteCreateModal_RequirementQueryHookResult = ReturnType<typeof useQuoteCreateModal_RequirementQuery>;
export type QuoteCreateModal_RequirementLazyQueryHookResult = ReturnType<typeof useQuoteCreateModal_RequirementLazyQuery>;
export type QuoteCreateModal_RequirementSuspenseQueryHookResult = ReturnType<typeof useQuoteCreateModal_RequirementSuspenseQuery>;
export type QuoteCreateModal_RequirementQueryResult = Apollo.QueryResult<QuoteCreateModal_RequirementQuery, QuoteCreateModal_RequirementQueryVariables>;
export const CreateQuoteDocument = gql`
    mutation CreateQuote($input: CreateQuoteInput!) {
  createQuote(input: $input) {
    quote {
      id
      title
      description
    }
    rootItem {
      id
      originVersionNumber
    }
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const Meister1PollForQuoteDocOfLeadIdDocument = gql`
    query Meister1PollForQuoteDocOfLeadId($input: Meister1PollForDocOfLeadIdInput!) {
  meister1PollForQuoteDocOfLeadId(input: $input)
}
    `;

/**
 * __useMeister1PollForQuoteDocOfLeadIdQuery__
 *
 * To run a query within a React component, call `useMeister1PollForQuoteDocOfLeadIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeister1PollForQuoteDocOfLeadIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeister1PollForQuoteDocOfLeadIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMeister1PollForQuoteDocOfLeadIdQuery(baseOptions: Apollo.QueryHookOptions<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables> & ({ variables: Meister1PollForQuoteDocOfLeadIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>(Meister1PollForQuoteDocOfLeadIdDocument, options);
      }
export function useMeister1PollForQuoteDocOfLeadIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>(Meister1PollForQuoteDocOfLeadIdDocument, options);
        }
export function useMeister1PollForQuoteDocOfLeadIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>(Meister1PollForQuoteDocOfLeadIdDocument, options);
        }
export type Meister1PollForQuoteDocOfLeadIdQueryHookResult = ReturnType<typeof useMeister1PollForQuoteDocOfLeadIdQuery>;
export type Meister1PollForQuoteDocOfLeadIdLazyQueryHookResult = ReturnType<typeof useMeister1PollForQuoteDocOfLeadIdLazyQuery>;
export type Meister1PollForQuoteDocOfLeadIdSuspenseQueryHookResult = ReturnType<typeof useMeister1PollForQuoteDocOfLeadIdSuspenseQuery>;
export type Meister1PollForQuoteDocOfLeadIdQueryResult = Apollo.QueryResult<Meister1PollForQuoteDocOfLeadIdQuery, Meister1PollForQuoteDocOfLeadIdQueryVariables>;