import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Form, Formik } from "formik";
import { isNull, omit, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { Stack } from "../../../commons/layout/Stack";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated";
import {
  useCrmCompanyEditBranchAddressModalQuery,
  useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation,
} from "./CrmCompanyEditBranchAddressModal.generated";
import { useTranslate } from "@tolgee/react";
import { AddressInput } from "../../addresses/AddressInput";

interface FormValues {
  branchAddress: AddressDetails__AddressFragment | null;
}

interface Props {
  title?: string;
  crmOrganisationId: string;
  handleClose: () => void;
  handleComplete?: () => Promise<void> | void;
}

export const CrmCompanyEditBranchAddressModal = ({
  title,
  crmOrganisationId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const query = useCrmCompanyEditBranchAddressModalQuery({
    client,
    variables: { organisationId: crmOrganisationId },
  });

  const crmCompany = getDataOrNull(query?.data?.crmCompany)?.crmCompany;

  const [modifyCrmOrganisation] =
    useCrmCompanyEditBranchAddressModal_ModifyCrmCompanyMutation({
      client,
    });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyCrmOrganisation({
        variables: {
          crmCompanies: [
            {
              id: crmOrganisationId,
              branchAddress: isNull(values.branchAddress)
                ? null
                : values.branchAddress
                  ? omit(values.branchAddress, "__typename", "id")
                  : undefined,
            },
          ],
        },
      });

      await handleComplete?.();
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues: FormValues = {
    branchAddress: crmCompany?.branchAddress ?? null,
  };

  const validationSchema = Yup.object().shape({
    branchAddress: Yup.object()
      .label(t("Branch address", { ns: "OrganisationProfile" }))
      .required(),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={
            title ??
            t("Change branch address", {
              ns: "OrganisationProfile",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled:
                  !formikProps.dirty || !formikProps.isValid || query.loading,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
        >
          <Form id={formId}>
            <Stack flexDirection="column" spacing={1}>
              <AddressInput
                label={t("Branch address", {
                  ns: "OrganisationProfile",
                })}
                value={formikProps.values.branchAddress}
                onChange={value => {
                  formikProps.setFieldValue("branchAddress", value);
                }}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
