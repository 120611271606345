/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
export type EditOrganisationProfileContactModal_OrganisationProfileFragment = { __typename: 'OrganisationProfile', id: string, slug: string, contactEmail?: any | null, contactWebsite?: string | null, openingHours?: any | null, contactAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, contactPhones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }> };

export const EditOrganisationProfileContactModal_OrganisationProfileFragmentDoc = gql`
    fragment EditOrganisationProfileContactModal_OrganisationProfile on OrganisationProfile {
  id
  slug
  contactAddress {
    ...AddressDetails__Address
  }
  contactEmail
  contactPhones {
    id
    number
    type
    main
  }
  contactWebsite
  openingHours
}
    ${AddressDetails__AddressFragmentDoc}`;