import { gql, useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { DataGrid, GridColDef, ModalOpenButton } from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { Link, Stack } from "@mui/material";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { DebugAllPropsButton } from "../../../features/doc-items/debug/PropertiesDebug";
import { AttributeExpressionModal } from "../../../features/doc-items/modals/AttributeExpressionModal";
import { ItemProductSearchFilterExpressionModal } from "../../../features/doc-items/modals/ItemProductSearchFilterExpressionModal";
import { PropertyExpressionModal } from "../../../features/doc-items/modals/PropertyExpressionModal";
import { PropertyMappingExpressionModal } from "../../../features/templates/quote/PropertyMappingExpressionModal";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import {
  TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragment,
  useTemplateQuoteEditTroubleshootQuery,
} from "./TemplateQuoteEditTroubleshoot.generated";
import { buildDocPath } from "../../../utils";

interface Props {
  docId: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  pathToDocList: string;
  pageSubHeader: React.ReactElement | null;
}

export function TemplateQuoteEditTroubleshoot({
  docId,
  submenuItems,
  prefixBreadcrumbs,
  pathToDocList,
  pageSubHeader,
}: Props) {
  const { t } = useTranslate(["Global", "Templates"]);

  const client = useApolloClient();
  const query = useTemplateQuoteEditTroubleshootQuery({
    client,
    variables: { id: docId },
    notifyOnNetworkStatusChange: true,
  });
  const quoteTemplate = query.data?.quoteTemplateLatest;
  const expressionValidationResults =
    query.data?.compileDocIsolatedExpression.results;

  const columns: GridColDef<TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragment>[] =
    [
      {
        field: "title",
        headerName: t("Item", {
          ns: "Global",
        }),
        flex: 1,
        renderCell: ({ row }) => (
          <Link
            href={`${buildDocPath(
              `${pathToDocList}/${row.docId}`,
              row.versionNumber ?? null
            )}${row.versionNumber ? "" : "/edit"}/items/${row.itemId}`}
            target="_blank"
            onClick={event => {
              event.stopPropagation();
            }}
          >{`${row.itemPdfPath} ${row.itemTitle}`}</Link>
        ),
      },
      {
        field: "id",
        headerName: t("Property", {
          ns: "Global",
        }),
        flex: 1,
        valueGetter: ({ row }) => {
          if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticComputedProp"
          ) {
            return row.key;
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticAttribute"
          ) {
            return row.attribute;
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticPropertyMapping"
          ) {
            return row.toKey;
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf"
          ) {
            return "n/a";
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf"
          ) {
            return "n/a";
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression"
          ) {
            return row.propertyKey;
          } else {
            assertNever(row);
          }
        },
      },
      {
        field: "expression",
        headerName: t("Formula", {
          ns: "Global",
        }),
        flex: 2,
        renderCell: ({ row }) => {
          if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticComputedProp"
          ) {
            return (
              <ModalOpenButton
                Modal={PropertyExpressionModal}
                modalProps={{
                  projectId: null,
                  docId: row.docId,
                  itemId: row.itemId,
                  fieldName: row.key,
                  expression: row.expr,
                }}
              >
                <Link>{row.expr}</Link>
              </ModalOpenButton>
            );
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticAttribute"
          ) {
            return (
              <ModalOpenButton
                Modal={AttributeExpressionModal}
                modalProps={{
                  projectId: null,
                  docId: row.docId,
                  itemId: row.itemId,
                  fieldName: row.attribute,
                  expression: row.expr,
                }}
              >
                <Link>{row.expr}</Link>
              </ModalOpenButton>
            );
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf"
          ) {
            return row.expr;
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf"
          ) {
            return row.expr;
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticPropertyMapping"
          ) {
            return (
              <ModalOpenButton
                Modal={PropertyMappingExpressionModal}
                modalProps={{
                  projectId: null,
                  docId: row.docId,
                  itemId: row.itemId,
                  fieldName: row.toKey,
                  expression: row.fromExpr,
                }}
              >
                <Link>{row.fromExpr}</Link>
              </ModalOpenButton>
            );
          } else if (
            row.__typename ===
            "CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression"
          ) {
            return (
              <ModalOpenButton
                Modal={ItemProductSearchFilterExpressionModal}
                modalProps={{
                  projectId: null,
                  docId: row.docId,
                  itemId: row.itemId,
                  filterKind: row.filterKind,
                  filterKey: row.filterKey,
                  propertyKey: row.propertyKey ?? undefined,
                  expression: row.expr,
                  operator: row.operator,
                  refetchQueries: [
                    namedOperations.Query.TemplateQuoteEditTroubleshoot,
                  ],
                }}
              >
                <Link>{row.expr}</Link>
              </ModalOpenButton>
            );
          } else {
            assertNever(row);
          }
        },
      },
      {
        field: "messageText",
        headerName: t("Error", {
          ns: "Global",
        }),
        flex: 2,
        valueGetter: ({ row }) => row.messageText,
      },
    ];

  return (
    <Page
      title={quoteTemplate?.title ?? ""}
      subtitle={t("Document Templates", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      subHeader={pageSubHeader}
      action={
        quoteTemplate && [
          <DebugAllPropsButton
            key="debug-all-props"
            projectId={null}
            docId={docId}
          />,
          <LoadingButton
            key="rerun-checks"
            onClick={() => {
              query.refetch();
            }}
            loading={query.loading}
            variant="contained"
          >
            {t("Re-run checks", {
              ns: "Global",
            })}
          </LoadingButton>,
        ]
      }
    >
      <PageContainer>
        <DataGrid<TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragment>
          columns={columns}
          rows={expressionValidationResults ?? []}
          getRowId={row =>
            `${row.itemId}-${row.__typename}-${JSON.stringify(row)}`
          }
          paginationMode="client"
          loading={query.loading}
        />
      </PageContainer>
    </Page>
  );
}
