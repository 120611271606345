import { useScreenWidth } from "@msys/ui";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ExtendedNodeData,
  getVisibleNodeCount,
  TreeItem,
} from "react-sortable-tree";
import { useLatest, useUpdateEffect } from "react-use";
import { WindowScroller } from "react-virtualized";
import { LocalDndProvider } from "../../common/dnd";
import { useScroll } from "../commons/hooks/useScroll";
import { useScrollZone } from "../commons/hooks/useScrollZone";
import { TREE_SCAFFOLD_PX_WIDTH } from "../constants";
import { browserHasOnlyTouch } from "../featureDetection";
import { getDefaultCreatableSubItemTypes } from "../features/doc-items/constraints";
import { size } from "../../common/MuiThemeProvider";
import { ItemType } from "../../clients/graphqlTypes";
import { transientOptions } from "../styles";
import { InputComponent, ItemComponent } from "../trees/types";
import { VirtualBareTreeStandaloneItem } from "./components/VirtualBareTreeStandaloneItem";
import { VirtualItemNodeRenderer } from "./components/VirtualItemNodeRenderer";
import { StyledSortableTree } from "./components/VirtualItemSortableTree";
import {
  getLastSelectedNode,
  getNonLeafChildrenIdsRecurively,
  getOutOfScreen,
  getSyntheticRootItem,
  getTreeFromItems,
  isNodePlacedAfterInput,
  ROOT_ITEM_ID,
} from "./helpers";
import { ALL_KEY, ExpandedStore } from "./hooks/useExpandedStore";
import { useGoToItem } from "./hooks/useGoToItem";
import { useMoveTreeItem } from "./hooks/useMoveTreeItem";
import { VirtualItem, VirtualTreeItemContext } from "./types";

const DEFAULT_STYLE = { height: "unset" };
const RIGHT_SPACING = "12px";
const LEFT_SPACING = "12px";
const INNER_TABLET_STYLE = {
  paddingRight: RIGHT_SPACING,
  paddingLeft: LEFT_SPACING,
};
const INNER_MOBILE_STYLE = {
  paddingRight: RIGHT_SPACING,
  paddingLeft: LEFT_SPACING,
};
const ITEM_TABLET_STYLE = {
  marginLeft: `-${LEFT_SPACING}`,
};
const ITEM_MOBILE_STYLE = {
  marginLeft: `-${LEFT_SPACING}`,
};

interface Props<T extends VirtualItem, EnableDragging extends boolean> {
  top?: number;

  docId: EnableDragging extends true ? string : null;
  projectId: EnableDragging extends true ? string | null : null;

  items: T[];
  allItems?: T[];
  filterItem?: (item: T) => boolean;

  // virtual synthetic root item or root item id
  rootItem?: T;
  rootItemId?: string;

  rootItemComponent?: ItemComponent<T>;
  itemComponent: ItemComponent<T>;
  inputComponent?: InputComponent<T, T>;

  selectedItemId: string | null;

  enableCreating: boolean;
  enableDragging: EnableDragging;

  allowHaveChildren?: (item: T) => boolean;

  shouldRenderCreateInput?: (item: T) => boolean;

  documentItemTypes: ItemType[];

  container?: HTMLElement | null;

  isVirtualized?: boolean;

  onItemsCountChange?: (itemsCount: number) => void;

  expandedStore: ExpandedStore;
  // isItemExpanded: (itemId: string, defaultValue?: boolean) => boolean;
  // setItemExpanded: (itemId: string, expanded: boolean) => void;
  // setExpandedState: (state: { [key: string]: boolean }) => void;
}

export const VirtualItemTree = React.memo(VirtualItemTreeComponent);

function VirtualItemTreeComponent<
  T extends VirtualItem,
  EnableDragging extends boolean
>({
  docId,
  projectId,
  items,
  allItems,
  filterItem,
  rootItem: syntheticRootItem,
  rootItemId: syntheticRootItemId,
  selectedItemId,
  rootItemComponent,
  itemComponent,
  inputComponent,
  enableCreating,
  enableDragging,
  allowHaveChildren,
  shouldRenderCreateInput,
  documentItemTypes,
  container,
  isVirtualized = true,
  onItemsCountChange,
  expandedStore,
}: // isItemExpanded,
// setItemExpanded,
// setExpandedState,

Props<T, EnableDragging>) {
  const selectedItem = useMemo<T | null>(
    () => items.find(i => i.id === selectedItemId) ?? null,
    [items, selectedItemId]
  );

  const [treeWidth, setTreeWidth] = useState<number | undefined>(undefined);
  const treeWidthLatest = useLatest(treeWidth);

  const [handleMoveTreeItem, moveTreeItemLoading] = useMoveTreeItem({
    projectId,
    docId,
  });

  const [aboveScreen, setAboveScreen] = useState<boolean>(false);
  const [belowScreen, setBelowScreen] = useState<boolean>(false);

  const aboveScreenLatest = useLatest(aboveScreen);
  const belowScreenLatest = useLatest(belowScreen);

  // @ts-ignore synthetic root item support
  const rootItem: T | undefined = useMemo(() => {
    if (syntheticRootItem) return syntheticRootItem;
    if (syntheticRootItemId)
      return getSyntheticRootItem("Item", syntheticRootItemId);
    return items.find(item => item.isRootItem);
  }, [items, syntheticRootItem, syntheticRootItemId]);

  const getTree = () => {
    return getTreeFromItems(
      items,
      rootItem,
      selectedItem,
      filterItem,
      enableCreating,
      expandedStore.isItemExpanded,
      documentItemTypes,
      shouldRenderCreateInput
    );
  };

  const updateTree = () => {
    const treeData = getTree();
    setQuoteItems(treeData);
    return treeData;
  };

  const [quoteItems, setQuoteItems] = useState(getTree);

  const collapseItemAndChildrenRecursively = React.useCallback(
    (node: TreeItem) => {
      const toCollapseIds = getNonLeafChildrenIdsRecurively(node);
      const toExpandItemIds = expandedStore.areAllItemsExpanded
        ? items
            .filter(
              item => item.hasChildren && !toCollapseIds.includes(item.id)
            )
            .map(item => item.id)
        : [];
      expandedStore.setExpandedState(
        Object.fromEntries([
          [ALL_KEY, false],
          ...toExpandItemIds.map(id => [id, true]),
          ...toCollapseIds.map(id => [id, false]),
        ])
      );
    },
    [expandedStore, items]
  );

  useUpdateEffect(() => {
    updateTree();
  }, [
    items,
    filterItem,
    rootItem?.id,
    selectedItem?.id,
    expandedStore.isItemExpanded,
  ]);

  const treeItemsCount = useMemo(
    () => getVisibleNodeCount({ treeData: quoteItems }),
    [quoteItems]
  );
  const [lastSelectedIndex, lastSelectedItemId, selectedPath] = useMemo(
    () => getLastSelectedNode(quoteItems, items, selectedItem),
    [quoteItems, items, selectedItem]
  );

  const onItemsCountChangeLatest = useLatest(onItemsCountChange);

  React.useEffect(() => {
    onItemsCountChangeLatest.current?.(treeItemsCount);
  }, [treeItemsCount, onItemsCountChangeLatest]);

  const windowScrollerRef = useRef<any>(null);

  const generateNodeProps = useCallback(
    ({ node }: ExtendedNodeData): VirtualTreeItemContext<T> => {
      return {
        selectedItemId: selectedItem?.id ?? null,
        lastSelectedItemId,
        rootItem,
        items,
        allItems,
        rootItemComponent,
        itemComponent,
        inputComponent,
      };
    },
    [
      selectedItem?.id,
      lastSelectedItemId,
      rootItem,
      items,
      allItems,
      rootItemComponent,
      itemComponent,
      inputComponent,
    ]
  );

  const { isMaxPhone } = useScreenWidth();

  const goToItem = useGoToItem(
    windowScrollerRef,
    updateTree,
    expandedStore.setExpandedState,
    container
  );

  const onScroll = useCallback(
    ({ y }: { y: number }) => {
      if (lastSelectedIndex === null) {
        if (aboveScreenLatest.current) setAboveScreen(false);
        if (belowScreenLatest.current) setBelowScreen(false);
        return;
      }
      const top: number = windowScrollerRef.current?._positionFromTop ?? 0;
      const scrollTop: number = y - top;
      const windowHeight = container
        ? container.offsetHeight
        : window.innerHeight;
      const [above, below] = getOutOfScreen(
        lastSelectedIndex,
        size.treeItemFullHeight,
        scrollTop,
        windowHeight
      );
      if (above !== aboveScreenLatest.current) setAboveScreen(above);
      if (below !== belowScreenLatest.current) setBelowScreen(below);
    },
    [lastSelectedIndex, aboveScreenLatest, belowScreenLatest, container]
  );

  useScroll(container ?? window, onScroll);

  useUpdateEffect(() => {
    const y = container ? container.scrollTop : window.scrollY;
    onScroll({ y });
  }, [container, lastSelectedIndex]);

  const dragging = useRef<boolean>(false);
  const draggingHeight = useRef<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const virtualizedListRef = useRef<any>(null);

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver | null = null;

    const handleResize = debounce(() => {
      if (!scrollContainerRef.current) return;
      const { width } = scrollContainerRef.current.getBoundingClientRect();
      if (treeWidthLatest.current !== width) setTreeWidth(width);
    });

    if (typeof ResizeObserver !== "undefined" && scrollContainerRef.current) {
      resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(scrollContainerRef.current);
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [treeWidthLatest]);

  const { startDrag, endDrag } = useScrollZone();

  return (
    <LocalDndProvider>
      {/*@ts-ignore render return type is incompatible*/}
      <WindowScroller
        ref={windowScrollerRef}
        scrollElement={isVirtualized ? container ?? undefined : undefined}
      >
        {({ height, isScrolling, onChildScroll, scrollTop }) => {
          return (
            <>
              {aboveScreen && selectedItem && (
                <PageFixedColumn $top={0}>
                  <PageFixedColumnInner $top={0}>
                    <VirtualBareTreeStandaloneItem
                      item={selectedItem!}
                      items={items}
                      depth={selectedPath.length}
                      itemComponent={
                        selectedItem!.isRootItem
                          ? rootItemComponent ?? itemComponent
                          : itemComponent
                      }
                      selected
                      clickable
                      onClick={() => goToItem(selectedItem, items)}
                      to={null}
                    />
                  </PageFixedColumnInner>
                </PageFixedColumn>
              )}
              <ScrollContainer
                ref={scrollContainerRef}
                $itemsCount={treeItemsCount}
                $isVirtualized={isVirtualized}
              >
                <StyledSortableTree
                  isVirtualized={isVirtualized}
                  style={DEFAULT_STYLE}
                  innerStyle={
                    isMaxPhone ? INNER_MOBILE_STYLE : INNER_TABLET_STYLE
                  }
                  generateNodeProps={generateNodeProps}
                  reactVirtualizedListProps={{
                    autoHeight: true,
                    height,
                    width: treeWidth,
                    isScrolling,
                    scrollTop,
                    onScroll: onChildScroll,
                    overscanRowCount: 4,
                    ref: virtualizedListRef,
                  }}
                  scaffoldBlockPxWidth={TREE_SCAFFOLD_PX_WIDTH}
                  rowHeight={size.treeItemFullHeight}
                  canDrag={({ node }) =>
                    !moveTreeItemLoading && enableDragging && !node.isInput
                  }
                  canDrop={({ node, nextParent, nextPath, prevPath }) => {
                    if (moveTreeItemLoading) return false;
                    if (dragging.current && scrollContainerRef.current?.style) {
                      // calculate new height during dragging
                      const rowCount =
                        virtualizedListRef.current?.props?.rowCount;
                      const newHeight = getTreeHeight(rowCount);
                      if (draggingHeight.current !== newHeight) {
                        draggingHeight.current = newHeight;
                        scrollContainerRef.current.style.height = `${newHeight}px`;
                      }
                    }

                    const nextParentItem = (nextParent?.item || rootItem) as T;

                    if (
                      "linkedQuoteTemplate" in nextParentItem &&
                      Boolean(nextParentItem.linkedQuoteTemplate)
                    ) {
                      return false;
                    }

                    if (
                      !getDefaultCreatableSubItemTypes(
                        nextParentItem.type,
                        documentItemTypes
                      ).includes(node.type)
                    ) {
                      return false;
                    }
                    if (nextParent?.isInput) {
                      return false;
                    }
                    if (
                      syntheticRootItemId === ROOT_ITEM_ID &&
                      (nextParent?.id === syntheticRootItemId ||
                        nextParent?.id === syntheticRootItem?.id)
                    ) {
                      return false;
                    }
                    if (
                      isNodePlacedAfterInput(
                        quoteItems,
                        node,
                        nextPath,
                        prevPath
                      )
                    ) {
                      return false;
                    }
                    return true;
                  }}
                  canNodeHaveChildren={node =>
                    !node.isInput &&
                    allowHaveChildren?.(node.item as T) !== false
                  }
                  treeData={quoteItems}
                  onChange={setQuoteItems}
                  onMoveNode={async ({ node, treeData, nextParentNode }) => {
                    const parentId = (nextParentNode?.id ??
                      rootItem?.id) as string;
                    const childrenIds = (
                      (nextParentNode?.children as TreeItem[]) ?? treeData
                    )
                      .filter(node => !node.isInput)
                      .map(node => node.id);
                    const rank =
                      childrenIds.findIndex(id => id === node.id) + 1;
                    await handleMoveTreeItem(node.id, parentId, rank);
                  }}
                  onVisibilityToggle={({ node, expanded }) => {
                    if (expanded === true) {
                      expandedStore.setItemExpanded(node.id, expanded);
                    } else {
                      collapseItemAndChildrenRecursively(node);
                    }
                  }}
                  onDragStateChanged={({ isDragging }) => {
                    if (browserHasOnlyTouch) {
                      if (isDragging) {
                        startDrag();
                      } else {
                        endDrag();
                      }
                    }
                    dragging.current = isDragging;
                    draggingHeight.current = isDragging
                      ? getTreeHeight(treeItemsCount)
                      : 0;
                    if (!isDragging && scrollContainerRef.current?.style)
                      scrollContainerRef.current.style.height = "";
                  }}
                  // @ts-ignore
                  nodeContentRenderer={VirtualItemNodeRenderer}
                />
              </ScrollContainer>
              {belowScreen && selectedItem && (
                <PageFixedColumn $bottom={0}>
                  <PageFixedColumnInner $bottom={0}>
                    <VirtualBareTreeStandaloneItem
                      item={selectedItem!}
                      items={items}
                      depth={selectedPath.length}
                      itemComponent={
                        selectedItem!.isRootItem
                          ? rootItemComponent ?? itemComponent
                          : itemComponent
                      }
                      selected
                      clickable
                      onClick={() => goToItem(selectedItem, items)}
                      to={null}
                    />
                  </PageFixedColumnInner>
                </PageFixedColumn>
              )}
            </>
          );
        }}
      </WindowScroller>
    </LocalDndProvider>
  );
}

const PageFixedColumn = styled("div", transientOptions)<{
  $top?: number;
  $bottom?: number;
}>`
  z-index: 20;
  position: sticky;

  width: 100%;
  height: 0;

  ${({ $top }) =>
    $top !== undefined
      ? `
        top: ${$top}px;
      `
      : ""}

  ${({ $bottom }) =>
    $bottom !== undefined
      ? `
        bottom: ${$bottom}px;
      `
      : ""}
`;

const PageFixedColumnInner = styled("div", transientOptions)<{
  $top?: number;
  $bottom?: number;
}>`
  z-index: 21;
  position: absolute;
  width: 100%;

  ${({ $top }) =>
    $top !== undefined
      ? `
        top: 0;
        padding-top: env(safe-area-inset-top);
      `
      : ""}

  ${({ $bottom }) =>
    $bottom !== undefined
      ? `
        bottom: 0;
        padding-bottom: env(safe-area-inset-bottom);
      `
      : ""}
`;

const ScrollContainer = styled(
  "div",
  transientOptions
)<{
  $itemsCount: number;
  $isVirtualized: boolean;
}>(
  ({ $isVirtualized, $itemsCount }) => `
  ${
    $isVirtualized
      ? `
        margin-right: -${RIGHT_SPACING};
        margin-left: -${LEFT_SPACING};
      `
      : ""
  }
  box-sizing: content-box;
  position: relative;
  height: ${getTreeHeight($itemsCount)}px;
`
);

const getTreeHeight = (itemsCount: number) =>
  size.treeItemFullHeight * itemsCount;
