import { Maybe, Quote } from "../../../clients/graphqlTypes";
import { ItemTreeItem } from "../../trees/types";

export function shouldRenderTemplateCreateInput<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  }
>(item: T): boolean {
  return !item.templateQuote;
}

export function allowHaveTemplateChildren<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  }
>(item: T): boolean {
  return !item.templateQuote;
}
