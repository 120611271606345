import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  LabeledTextValue,
  LabeledValue,
  TagChips,
} from "@msys/ui";
import { Divider, Link as MuiLink, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { isNull, omit } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  CrmOrganisationAbcCategory,
  CrmOrganisationContactType,
  PhoneInput,
  Skill,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { EntityNumber } from "../../../commons/EntityNumber";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { DetachedEmailField } from "../../../commons/form-fields/EmailField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { TagsField } from "../../../commons/form-fields/TagsField";
import {
  PHONE_REG_EXP,
  SITE_REG_EXP,
  prepareUrl,
} from "../../../commons/form-fields/helpers";
import { Stack } from "../../../commons/layout/Stack";
import { AddressComboField } from "../../addresses/AddressComboField";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated";
import { LabeledAddressValue } from "../../addresses/LabeledAddressValue";
import { Attachment } from "../../attachments/helpers";
import { useEmailAddressValidation } from "../../email/validateEmailAddress";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar";
import { useOrganisationTags } from "../../organisations/useOrganisationTags";
import { LabeledPhoneValue } from "../../phones/LabeledPhoneValue";
import { PhonesField } from "../../phones/PhonesField";
import { defaultPhoneType } from "../../phones/usePhoneTypes";
import CategoriesField from "../../skill-categories/CategoriesField";
import { LabeledCategoriesValue } from "../../skill-categories/LabeledCategoriesValue";
import { useCrmOrganisationAbcCategories } from "../hooks/useCrmOrganisationAbcCategories";
import { useCrmOrganisationContactTypes } from "../hooks/useCrmOrganisationContactTypes";
import {
  CrmCompanyDetailsBox_CrmComapnyFragment,
  CrmCompanyDetailsData_CrmCompanyFragment,
  CrmCompanyDetailsForm_CrmCompanyFragment,
  useCrmCompanyDetailsForm_ModifyCrmCompanyMutation,
  useModifyCrmOrganisationAvatarMutation,
} from "./CrmCompanyDetailsBox.generated";

interface Props {
  crmCompany: CrmCompanyDetailsBox_CrmComapnyFragment;
  forceEditable?: boolean;
}

export function CrmCompanyDetailsBox({ crmCompany, forceEditable }: Props) {
  const client = useApolloClient();

  const [modifyCrmOrganisation, { loading: modifyCrmOrganisationLoading }] =
    useModifyCrmOrganisationAvatarMutation({
      client,
    });

  async function onAttachment(attachment: Attachment | null) {
    await modifyCrmOrganisation({
      variables: {
        organisations: [
          {
            id: crmCompany.id,
            logo: attachment
              ? {
                  url: attachment.url,
                  title: attachment.title,
                  mimeType: attachment.mimeType,
                }
              : null,
          },
        ],
      },
    });
  }

  return (
    <CardContainer>
      <Stack flexDirection="column" p={1.5}>
        <Stack justifyContent="center" mb={1}>
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_CRM"
            otherwise={
              <OrganisationAvatar organisationAvatar={crmCompany} size="l" />
            }
          >
            <OrganisationAvatar
              organisationAvatar={crmCompany}
              size="l"
              disabled={modifyCrmOrganisationLoading}
              onAttachment={onAttachment}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        </Stack>
        <Stack flexDirection="column" spacing={0.5}>
          <Typography variant="h2" align="center">
            {crmCompany.title}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="primary"
            gutterBottom
          >
            <EntityNumber number={crmCompany.number} />
          </Typography>
        </Stack>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_CRM"
          otherwise={<CrmCompanyDetailsData crmCompany={crmCompany} />}
        >
          <CrmCompanyDetailsForm crmCompany={crmCompany} />
        </RestrictedByOrganisationPermissionWithDebug>
      </Stack>
    </CardContainer>
  );
}

function CrmCompanyDetailsData({
  crmCompany,
}: {
  crmCompany: CrmCompanyDetailsData_CrmCompanyFragment;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);
  const { abcCategoryLabels } = useCrmOrganisationAbcCategories();
  const { contactTypeLabels } = useCrmOrganisationContactTypes();
  const isCraftsman = crmCompany.systemTags.includes("CRAFTSMAN");
  const isSupplier = crmCompany.systemTags.includes("SUPPLIER");

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Account Name", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.title ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Description", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.description ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <Divider />
      <LabeledValue
        label={t("Website", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.website ? (
          <MuiLink
            href={prepareUrl(crmCompany.website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {crmCompany.website}
          </MuiLink>
        ) : (
          t("Not set", {
            ns: "Global",
          })
        )}
      </LabeledValue>
      <LabeledValue
        label={t("Email", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.email ? (
          <MuiLink href={`mailto:${crmCompany.email}`}>
            {crmCompany.email}
          </MuiLink>
        ) : (
          t("Not set", {
            ns: "Global",
          })
        )}
      </LabeledValue>
      <LabeledPhoneValue
        label={t("Phone", {
          ns: "Global",
        })}
        phones={crmCompany.phones}
      />
      {isCraftsman && (
        <>
          <Divider />
          <LabeledCategoriesValue
            value={crmCompany.skillset}
            label={t("Services", {
              ns: "OrganisationProfile",
            })}
          />
        </>
      )}
      <Divider />
      {!isSupplier && (
        <>
          <LabeledValue
            label={t("Contact type", {
              ns: "OrganisationProfile",
            })}
          >
            {contactTypeLabels[crmCompany.contactType]}
          </LabeledValue>

          <LabeledValue
            label={t("ABC Category", {
              ns: "OrganisationProfile",
            })}
          >
            {abcCategoryLabels[crmCompany.abcCategory]}
          </LabeledValue>

          <Divider />

          <LabeledTextValue
            label={t("Notes", { ns: "OrganisationProfile" })}
            text={crmCompany.notices}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />

          <LabeledValue label={t("Tags", { ns: "OrganisationProfile" })}>
            {crmCompany.customTags.length > 0 ||
            crmCompany.systemTags.length > 0 ? (
              <TagChips
                tags={[...crmCompany.systemTags, ...crmCompany.customTags]}
              />
            ) : (
              "–"
            )}
          </LabeledValue>
        </>
      )}
      <LabeledAddressValue
        label={t("Billing address", {
          ns: "OrganisationProfile",
        })}
        address={crmCompany.billingAddress}
      />
      <LabeledAddressValue
        label={t("Branch address", {
          ns: "OrganisationProfile",
        })}
        address={crmCompany.branchAddress}
      />
      {isSupplier && (
        <LabeledAddressValue
          label={t("Pick-up location", {
            ns: "OrganisationProfile",
          })}
          address={crmCompany.pickupAddress}
        />
      )}
    </Stack>
  );
}

interface FormValues {
  title: string;
  description: string;
  notices: string;
  billingAddress: AddressDetails__AddressFragment | null;
  branchAddress: AddressDetails__AddressFragment | null;
  pickupAddress: AddressDetails__AddressFragment | null;
  phones: PhoneInput[];
  website: string;
  email: string;
  skillset: Skill[];
  tags: { type: "CUSTOM" | "SYSTEM"; value: string }[];
  abcCategory: CrmOrganisationAbcCategory;
  contactType: CrmOrganisationContactType;
}

function CrmCompanyDetailsForm({
  crmCompany,
}: {
  crmCompany: CrmCompanyDetailsForm_CrmCompanyFragment;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const isEmailAddressValid = useEmailAddressValidation();
  const { abcCategoryOptions } = useCrmOrganisationAbcCategories();
  const { contactTypeOptions } = useCrmOrganisationContactTypes();

  const client = useApolloClient();

  const { customTags } = useOrganisationTags("CRM_COMPANY");

  const [modifyCrmOrganisation] =
    useCrmCompanyDetailsForm_ModifyCrmCompanyMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationTags],
      awaitRefetchQueries: true,
    });

  const isCraftsman = crmCompany.systemTags.includes("CRAFTSMAN");
  const isSupplier = crmCompany.systemTags.includes("SUPPLIER");

  const initialValues: FormValues = {
    title: crmCompany.title,
    description: crmCompany.description,
    email: crmCompany.email,
    website: crmCompany.website,
    billingAddress: crmCompany?.billingAddress ?? null,
    branchAddress: crmCompany?.branchAddress ?? null,
    pickupAddress: crmCompany?.pickupAddress ?? null,
    phones:
      crmCompany.phones?.length > 0 ? crmCompany.phones : [defaultPhoneType],
    abcCategory: crmCompany.abcCategory,
    contactType: crmCompany.contactType ?? "COMPANY",
    skillset: crmCompany ? crmCompany.skillset : [],
    notices: crmCompany.notices,
    tags: [
      ...crmCompany.systemTags.map(tag => ({
        type: "SYSTEM" as const,
        value: tag,
      })),
      ...crmCompany.customTags.map(tag => ({
        type: "CUSTOM" as const,
        value: tag,
      })),
    ],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Account Name", {
          ns: "OrganisationProfile",
        })
      )
      .required(),
    email: Yup.string()
      .label(
        t("Email", {
          ns: "OrganisationProfile",
        })
      )
      .email()
      .trim(),
    skillset: isCraftsman
      ? Yup.array()
          .label(
            t("Services", {
              ns: "OrganisationProfile",
            })
          )
          .required()
      : Yup.array().label(
          t("Services", {
            ns: "OrganisationProfile",
          })
        ),
    website: Yup.string()
      .label(
        t("Website", {
          ns: "OrganisationProfile",
        })
      )
      .matches(
        SITE_REG_EXP,
        t("Website is not valid", {
          ns: "OrganisationProfile",
        })
      ),
    phones: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        main: Yup.boolean(),
        type: Yup.string(),
        number: Yup.string().matches(
          PHONE_REG_EXP,
          t("Phone number is not valid", {
            ns: "OrganisationProfile",
          })
        ),
        // .required(t("Phone number is required")),
      })
    ),
    tags: Yup.array()
      .of(Yup.object().shape({ value: Yup.string() }))
      .label(
        t("Tags", {
          ns: "OrganisationProfile",
        })
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyCrmOrganisation({
        variables: {
          crmCompanies: [
            {
              id: crmCompany.id,
              title: values.title,
              description: values.description,
              billingAddress: isNull(values.billingAddress)
                ? null
                : values.billingAddress
                  ? omit(values.billingAddress, "__typename", "id")
                  : undefined,
              branchAddress: isNull(values.branchAddress)
                ? null
                : values.branchAddress
                  ? omit(values.branchAddress, "__typename", "id")
                  : undefined,
              pickupAddress: isNull(values.pickupAddress)
                ? null
                : values.pickupAddress
                  ? omit(values.pickupAddress, "__typename", "id")
                  : undefined,
              website: values.website,
              email: values.email,
              phones: values.phones
                .filter(p => p.number.trim())
                .map(p => omit(p, "__typename", "id")),
              notices: values.notices,
              tags: values.tags
                .filter(tag => tag.type === "CUSTOM")
                .map(tag => tag.value),
              abcCategory: values.abcCategory,
              contactType: values.contactType,
              skillset: values.skillset,
            },
          ],
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            {isSupplier ? (
              <LabeledValue
                label={t("Account Name", {
                  ns: "OrganisationProfile",
                })}
              >
                {crmCompany.title ||
                  t("Not set", {
                    ns: "Global",
                  })}
              </LabeledValue>
            ) : (
              <Field
                component={TextField}
                name="title"
                label={t("Account Name", {
                  ns: "OrganisationProfile",
                })}
                required
                disabled={false}
              />
            )}
            <Field
              component={TextField}
              name="description"
              label={t("Description", {
                ns: "OrganisationProfile",
              })}
              disabled={false}
              multiline
            />
            {!isSupplier && (
              <>
                <Divider />
                <SelectField
                  label={t("Contact type", {
                    ns: "OrganisationProfile",
                  })}
                  name="contactType"
                  options={contactTypeOptions}
                  disabled={false}
                />
                <SelectField
                  label={t("ABC Category", {
                    ns: "OrganisationProfile",
                  })}
                  name="abcCategory"
                  options={abcCategoryOptions}
                  disabled={false}
                />
                <Field
                  component={TextField}
                  multiline
                  name="notices"
                  label={t("Notes", {
                    ns: "OrganisationProfile",
                  })}
                  disabled={false}
                />
                <TagsField
                  inputLabel={t("Tags", {
                    ns: "OrganisationProfile",
                  })}
                  name="tags"
                  options={customTags}
                  // reservedOptions={[]}
                  disabled={false}
                />
              </>
            )}
            <Divider />
            <Field
              component={TextField}
              disabled={false}
              name="website"
              label={t("Website", {
                ns: "OrganisationProfile",
              })}
            />
            <DetachedEmailField
              name="email"
              label={t("Email", {
                ns: "OrganisationProfile",
              })}
              validate={isEmailAddressValid}
              isValid={crmCompany.isEmailValid}
            />
            <PhonesField
              name="phones"
              buttonLabel={t("Add phone number", {
                ns: "OrganisationProfile",
              })}
              value={formikProps.values.phones}
              disabled={false}
            />
            {(isCraftsman || isSupplier) && (
              <>
                <Divider />
                <CategoriesField
                  name="skillset"
                  label={t("Services", {
                    ns: "OrganisationProfile",
                  })}
                  disabled={false}
                />
              </>
            )}
            <Divider />
            <AddressComboField
              label={t("Billing address", {
                ns: "OrganisationProfile",
              })}
              address={formikProps.values.billingAddress}
              handleChange={address =>
                formikProps.setFieldValue("billingAddress", address)
              }
            />
            <AddressComboField
              label={t("Branch address", {
                ns: "OrganisationProfile",
              })}
              address={formikProps.values.branchAddress}
              handleChange={address =>
                formikProps.setFieldValue("branchAddress", address)
              }
            />
            {isSupplier && (
              <AddressComboField
                label={t("Pick-up location", {
                  ns: "OrganisationProfile",
                })}
                address={formikProps.values.pickupAddress}
                handleChange={address =>
                  formikProps.setFieldValue("pickupAddress", address)
                }
              />
            )}

            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
