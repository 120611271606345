import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField as MuiTextField } from "formik-mui";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import {
  useRestoreTemplateVersionModalQuery,
  useRestoreTemplateVersionMutation,
} from "./RestoreTemplateVersionModal.generated";

type FormValues = { versionNumber: number | null };

interface Props {
  docId: string;
  handleClose: () => void;
  handleComplete: (handleClose: () => void) => Promise<void> | void;
}

export function RestoreTemplateVersionModal({
  docId,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Global", "Templates"]);

  const client = useApolloClient();
  const query = useRestoreTemplateVersionModalQuery({
    client,
    variables: { docId },
  });

  const [restoreTemplateVersionMutation] = useRestoreTemplateVersionMutation({
    client,
  });

  const doc = query.data?.quoteTemplateLatest;

  const initialValues = {
    versionNumber: doc?.latestPublishedVersionNumber ?? null,
  };

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      versionNumber: Yup.number()
        .integer()
        .min(1)
        .max(doc?.latestPublishedVersionNumber ?? 0)
        .required(),
    });
  }, [doc?.latestPublishedVersionNumber]);

  const handleSubmit = async (values: FormValues) => {
    if (values.versionNumber) {
      await restoreTemplateVersionMutation({
        variables: { input: { docId, versionNumber: values.versionNumber } },
      });
      await handleComplete(handleClose);
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {formikProps => (
        <Modal
          title={t("Restore a specific version", {
            ns: "Templates",
          })}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
              },
            },
            {
              label: t("Restore version", {
                ns: "Templates",
              }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid,
              },
            },
          ]}
          handleClose={handleClose}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <Field
                name={"versionNumber"}
                component={MuiTextField}
                type={"number"}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
