import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { omit, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { Stack } from "../../commons/layout/Stack";
import {
  AddressInput as AddressInputGQLType,
  DeliveryAddressType,
} from "../../../clients/graphqlTypes";
import { AddressDetails__AddressFragment } from "../addresses/Addresses.generated";
import { AddressInput } from "../addresses/AddressInput";
import { getAddressLabel } from "../addresses/helpers";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect";
import {
  useSelectSupplierAndDeliveryModalQuery,
  useSupplierPickupAddressesQuery,
} from "./PurchaseOrderSelectSupplierAndDeliveryModal.generated";

const TAGS_ALL: string[] = ["SUPPLIER"];

interface FormValues {
  supplierId: string | null;
  addressType: DeliveryAddressType;
  address: AddressInputGQLType | null;
}

interface Props {
  projectId: string;
  supplierId?: string;
  address?: AddressDetails__AddressFragment | null;
  addressType?: DeliveryAddressType | null;
  title?: string;
  buttonTitle?: string;
  canEditSupplier?: boolean;
  handleClose: () => void;
  handleComplete: (
    supplierId: string,
    address: AddressInputGQLType,
    addressType: DeliveryAddressType
  ) => Promise<void>;
}

export const SelectSupplierAndDeliveryModal = ({
  projectId,
  supplierId: passedSupplierId,
  address: passedAddress,
  addressType: passedAddressType,
  title,
  buttonTitle,
  handleClose,
  handleComplete,
  canEditSupplier = true,
}: Props) => {
  const { t } = useTranslate(["PurchaseOrders", "Global"]);

  const client = useApolloClient();
  const query = useSelectSupplierAndDeliveryModalQuery({
    client,
    variables: { projectId },
  });

  const [supplierId, setSupplierId] = React.useState<string | null>(
    passedSupplierId ?? null
  );

  const supplierPickupAddressesQuery = useSupplierPickupAddressesQuery({
    client,
    variables: { supplierId: supplierId! },
    skip: !supplierId,
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const crmCompany = getDataOrNull(
    supplierPickupAddressesQuery.data?.crmCompany
  )?.crmCompany;

  const { addressOptions, addressTypeOptions, addressTypeLabels } =
    useAddresses(
      project?.building?.buildingAddress,
      query.data?.viewer?.organisation?.branchAddress,
      crmCompany?.pickupAddress,
      crmCompany?.branchAddress
    );

  const initialValues: FormValues = {
    supplierId: passedSupplierId ?? null,
    addressType: passedAddressType ?? "delivery",
    address: passedAddress ?? null,
  };

  const validationSchema = Yup.object().shape({
    supplierId: Yup.string()
      .label(t("Supplier", { ns: "PurchaseOrders" }))
      .nullable()
      .required(),
    addressType: Yup.string()
      .label(t("Address type", { ns: "PurchaseOrders" }))
      .oneOf(["delivery", "pickup"])
      .required(),
    address: Yup.object()
      .label(t("Delivery address", { ns: "PurchaseOrders" }))
      .shape({
        streetLine1: Yup.string(),
        postalCode: Yup.string(),
        city: Yup.string(),
      })
      .nullable()
      .required(),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!values.supplierId || !values.address) return;
    await handleComplete(
      values.supplierId,
      omit(values.address, "id", "__typename"),
      values.addressType
    );
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          title={title ?? t("Order details", { ns: "PurchaseOrders" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: buttonTitle ?? t("Next", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled:
                  !formikProps.dirty || !formikProps.isValid || query.loading,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <CrmCompanySelect
                disabled={!canEditSupplier}
                inputLabel={t("Supplier", { ns: "PurchaseOrders" })}
                placeholder={t("Select supplier", { ns: "PurchaseOrders" })}
                filterTagsAll={TAGS_ALL}
                required
                canCreateNew={false}
                crmCompanyId={formikProps.values.supplierId}
                onChange={crmCompany => {
                  setSupplierId(crmCompany?.id ?? null);
                  formikProps.setValues({
                    supplierId: crmCompany?.id ?? null,
                    addressType: "delivery",
                    address: null,
                  });
                }}
                error={formikProps.errors?.supplierId}
              />
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={formikProps.values.addressType}
                  onChange={(event, value) => {
                    formikProps.setValues({
                      ...formikProps.values,
                      addressType: value as DeliveryAddressType,
                      address: null,
                    });
                  }}
                >
                  {addressTypeOptions.map(addressType => (
                    <FormControlLabel
                      key={addressType.value}
                      value={addressType.value}
                      control={<Radio />}
                      label={addressType.label}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <AddressInput
                key={formikProps.values.addressType}
                label={addressTypeLabels[formikProps.values.addressType]}
                options={addressOptions[formikProps.values.addressType].map(
                  option => ({
                    value: option.value,
                    label:
                      getAddressLabel(
                        option.value,
                        ", ",
                        false,
                        option.label
                      ) ?? "",
                  })
                )}
                value={formikProps.values.address}
                onChange={value => {
                  formikProps.setValues({
                    ...formikProps.values,
                    address: value,
                  });
                }}
                error={formikProps.errors?.address}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

function useAddresses(
  projectBuildingAddress?: AddressDetails__AddressFragment | null,
  selfBranchAddress?: AddressDetails__AddressFragment | null,
  supplierPickupAddress?: AddressDetails__AddressFragment | null,
  supplierBranchAddress?: AddressDetails__AddressFragment | null
) {
  const { t } = useTranslate("PurchaseOrders");

  const addressTypeLabels: Record<DeliveryAddressType, string> = {
    delivery: t("Delivery address"),
    pickup: t("Pick-up location"),
  };

  const ALL_DELIVERY_ADDRESS_TYPES: DeliveryAddressType[] = [
    "delivery",
    "pickup",
  ];

  const addressTypeOptions = ALL_DELIVERY_ADDRESS_TYPES.map(type => ({
    value: type,
    label: addressTypeLabels[type],
  }));

  const deliveryAddresses = [
    ...(projectBuildingAddress
      ? [
          {
            label: t("Project location"),
            value: projectBuildingAddress,
          },
        ]
      : []),
    ...(selfBranchAddress
      ? [
          {
            label: t("My organisation branch"),
            value: selfBranchAddress,
          },
        ]
      : []),
  ];

  const pickupAddresses = [
    ...(supplierPickupAddress
      ? [
          {
            label: t("Supplier pick-up address"),
            value: supplierPickupAddress,
          },
        ]
      : []),
    ...(supplierBranchAddress
      ? [
          {
            label: t("Supplier branch address"),
            value: supplierBranchAddress,
          },
        ]
      : []),
  ];

  const addressOptions = {
    delivery: deliveryAddresses,
    pickup: pickupAddresses,
  };

  return { addressOptions, addressTypeOptions, addressTypeLabels };
}
