import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Autocomplete,
  ModalActions,
  ModalContent,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import {
  DialogContentText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField";
import { FormattedMoneyField } from "../../../commons/form-fields/FormattedMoneyField";
import {
  Money,
  ProjectSourcePageName,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { selectLabelForBuilding } from "../../buildings/helper";
import { BuildingCreateModal } from "../../buildings/modals/BuildingCreateModal";
import { CrmCompanySelect } from "../../crm-companies/CrmCompanySelect";
import { useCreateQuoteMutation } from "../../quotes/modals/QuoteCreateModal.generated";
import { useCreateProjectMutation } from "../Projects.generated";
import {
  useCreateTicketModal_CreateDocumentItemMutation,
  useTicketCreateModalCrmOrganisationsBuildingQuery,
  useTicketCreateModalQuery,
} from "./CreateTicketModalContent.generated";

interface FormValues {
  title: string;
  description: string;
  clientId: string;
  buildingId: string;
  buildingItemId: string;
  urgent: boolean;
  earliestStart: Moment | null;
  deadline: Moment | null;
  budgetAmount?: Money;
}

interface Props {
  crmOrganisationId?: string;
  pageName?: ProjectSourcePageName;
  desiredProjectInitialStatus: "opportunity" | "contracted";
  handleClose: () => void;
  handleComplete?: (projectId: string) => Promise<void> | void;
  refetchQueries?: string[];
  topInfo?: React.ReactNode;
}

export const CreateTicketModalContent = ({
  crmOrganisationId,
  pageName,
  handleClose,
  handleComplete,
  desiredProjectInitialStatus,
  refetchQueries,
  topInfo,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["TicketCreate", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useTicketCreateModalQuery({
    client,
    fetchPolicy: "cache-first",
  });

  const [createProject] = useCreateProjectMutation({ client });
  const [createQuote] = useCreateQuoteMutation({ client });
  const [createItems] = useCreateTicketModal_CreateDocumentItemMutation({
    client,
  });

  const [clientId, setClientId] = useState<string | undefined>(
    crmOrganisationId
  ); // refetch building data on demand!

  const crmOrganisationBuildingsQuery =
    useTicketCreateModalCrmOrganisationsBuildingQuery({
      client,
      variables: { crmCompanyId: clientId! },
      skip: !clientId,
    });

  const crmCompany = getDataOrNull(
    crmOrganisationBuildingsQuery.data?.crmCompany
  )?.crmCompany;

  const onSubmit = async (values: FormValues) => {
    const res = await createProject({
      variables: {
        input: {
          title: values.title,
          description: values.description,
          type: "RENEWAL",
          crmOrganisationId: values.clientId,
          buildingItemId: values.buildingItemId,
          ticket: true,
          categories: [],
          urgent: values.urgent,
          earliestStart:
            values.earliestStart?.format("YYYY-MM-DD") || undefined,
          deadline: values.deadline?.format("YYYY-MM-DD") || undefined,
          budgetAmount: values.budgetAmount?.amount,
          pageName: pageName ?? "CreateTicketModal",
          desiredProjectInitialStatus,
        },
      },
      // TODO: instead of problems with refetchObservableQueries we are refetching explicitely. Fix if necessary!
      refetchQueries: [
        namedOperations.Query.PlanningMapProjects,
        namedOperations.Query.PlanningProjects,
        namedOperations.Query.ProjectsList,
        ...(refetchQueries ?? []),
      ],
    });

    const project = res.data!.createProject.project;
    const projectId = project.id;
    // TODO: why are we creating a quote when creating a ticket?
    const createQuoteRes = await createQuote({
      variables: {
        input: {
          projectId,
          title: t("Ticket Quoute: {title}", {
            ns: "TicketCreate",
            title: values.title,
          }),
          contractType: "fmsp_fr",
          isBinding: true,
          discountPercentage: 0,
        },
      },
    });

    if (!createQuoteRes) throw new Error(`createQuote failed`);

    const hourlyRate = getDataOrNull(
      query.data?.organisationDefaults
    )?.defaultVerticalSettings.find(
      s => s.vertical === "general"
    )?.averageWorkSellingPrice;

    const createItemRes = await createItems({
      variables: {
        input: {
          docId: createQuoteRes.data!.createQuote.quote.id,
          projectId,
          parentItemId: createQuoteRes.data!.createQuote.rootItem.id,
          items: [
            {
              type: "paid",
              title: values.title,
              decisionBehaviorOfSubitems: "NONE",
              estimatedTime:
                values.budgetAmount?.amount && hourlyRate
                  ? (values.budgetAmount?.amount / hourlyRate) * 60
                  : undefined,
            },
          ],
        },
      },
    });

    if (!createItemRes) throw new Error(`createItem failed`);

    if (res) {
      const project = res.data!.createProject.project;
      const projectId = project.id;

      enqueueSnackbar(
        t("Ticket created", {
          ns: "TicketCreate",
        })
      );

      handleClose();
      handleComplete?.(projectId);
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = {
    title: "",
    description: "",
    clientId: crmOrganisationId ?? "",
    buildingId: "",
    buildingItemId: "",
    urgent: false,
    earliestStart: null,
    deadline: null,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Ticket name", {
          ns: "TicketCreate",
        })
      )
      .required(),
    description: Yup.string().label(
      t("Description", {
        ns: "TicketCreate",
      })
    ),
    earliestStart: Yup.date()
      .label(
        t("Earliest start", {
          ns: "TicketCreate",
        })
      )
      .nullable(),
    deadline: Yup.date()
      .label(
        t("Deadline", {
          ns: "TicketCreate",
        })
      )
      .nullable(),
    clientId: Yup.string().required(),
    buildingId: Yup.string().required(),
    buildingItemId: Yup.string().required(),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ setFieldValue, setValues, values, isValid, isSubmitting }) => (
        <>
          <ModalContent isLoading={query.loading}>
            {topInfo}
            <DialogContentText>
              {t(
                "With a new ticket, you can create a mini-project for small but frequent tasks, such as water-heater decalcification",
                {
                  ns: "TicketCreate",
                }
              )}
            </DialogContentText>
            <Form id={formId}>
              <Stack direction="column" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <Typography color="textSecondary" variant="h4">
                    {t("Add ticket details", {
                      ns: "TicketCreate",
                    })}
                  </Typography>

                  <Field
                    component={TextField}
                    name="title"
                    label={t("Ticket name", {
                      ns: "TicketCreate",
                    })}
                    required
                  />

                  <Field
                    component={TextField}
                    name="description"
                    label={t("Description", {
                      ns: "TicketCreate",
                    })}
                    multiline
                  />
                  <FormattedMoneyField
                    name="budgetAmount"
                    label={t("Budget", {
                      ns: "TicketCreate",
                    })}
                    type="money"
                  />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography color="textSecondary" variant="h4">
                    {t("Add client details", {
                      ns: "TicketCreate",
                    })}
                  </Typography>

                  {viewer.organisation.capabilities.includes("QUOTING") && (
                    <CrmCompanySelect
                      crmCompanyId={values.clientId}
                      inputLabel={t("Select client", { ns: "TicketCreate" })}
                      required
                      onChange={crmCompany => {
                        setClientId(crmCompany?.id);
                        setValues(values => ({
                          ...values,
                          clientId: crmCompany?.id ?? "",
                          buildingId: "",
                          buildingItemId: "",
                        }));
                      }}
                      canCreateNew
                      disabled={Boolean(crmOrganisationId)}
                      createNewLabel={t("Add new contact", {
                        ns: "TicketCreate",
                      })}
                      createModalTitle={t("Create client for ticket", {
                        ns: "TicketCreate",
                      })}
                    />
                  )}
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Autocomplete
                        inputLabel={t("Select location", {
                          ns: "TicketCreate",
                        })}
                        options={
                          values.clientId && crmCompany?.id === values.clientId
                            ? crmCompany.buildings
                            : []
                        }
                        getOptionLabel={option =>
                          selectLabelForBuilding(option)
                        }
                        required
                        disabled={
                          values.clientId ? values.clientId.length === 0 : true
                        }
                        value={
                          values.clientId && crmCompany?.id === values.clientId
                            ? crmCompany.buildings.find(
                                building => building.id === values.buildingId
                              )
                            : null
                        }
                        onChange={value => {
                          const buildingId = value?.id ?? "";
                          const buildingItemId = value?.rootItem.id ?? "";
                          setValues(values => ({
                            ...values,
                            buildingId,
                            buildingItemId,
                          }));
                        }}
                      />
                      <ModalOpenButton
                        Modal={BuildingCreateModal}
                        modalProps={{
                          buildingOwningOrganisationId: values.clientId,
                          handleComplete: async (
                            buildingId,
                            buildingRootItemId
                          ) => {
                            await crmOrganisationBuildingsQuery.refetch();
                            setValues(values => ({
                              ...values,
                              buildingId,
                              buildingItemId: buildingRootItemId,
                            }));
                          },
                        }}
                      >
                        <IconButton color="primary" size="large">
                          <AddIcon />
                        </IconButton>
                      </ModalOpenButton>
                    </Stack>
                    <Autocomplete
                      inputLabel={t("Placement", {
                        ns: "TicketCreate",
                      })}
                      value={
                        values.clientId && crmCompany?.id === values.clientId
                          ? crmCompany.buildings
                              .find(
                                building => building.id === values.buildingId
                              )
                              ?.items?.find(
                                buildingItem =>
                                  buildingItem.id === values.buildingItemId
                              ) ?? null
                          : null
                      }
                      required
                      disabled={
                        values.clientId && values.buildingId
                          ? values.clientId.length === 0 &&
                            values.buildingId.length === 0
                          : true
                      }
                      getOptionLabel={option =>
                        `${option.pathForPdf} ${option.title}`
                      }
                      options={
                        values.clientId && crmCompany?.id === values.clientId
                          ? crmCompany.buildings.find(
                              building => building.id === values.buildingId
                            )?.items ?? []
                          : []
                      }
                      onChange={value => {
                        const buildingItemId = value?.id ?? "";
                        setFieldValue("buildingItemId", buildingItemId);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography color="textSecondary" variant="h4">
                    {t("Urgency", {
                      ns: "TicketCreate",
                    })}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <CheckboxField
                        label={t("Urgent", {
                          ns: "TicketCreate",
                        })}
                        name="urgent"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePickerField
                        label={t("Earliest start", {
                          ns: "TicketCreate",
                        })}
                        name="earliestStart"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePickerField
                        label={t("Deadline", {
                          ns: "TicketCreate",
                        })}
                        name="deadline"
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Form>
          </ModalContent>
          <ModalActions
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Create ticket", {
                  ns: "TicketCreate",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: !isValid || query.loading,
                  loading: isSubmitting,
                },
              },
            ]}
          />
        </>
      )}
    </Formik>
  );
};
