/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CraftsmenSearchQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterMyContacts?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterCategories?: Types.InputMaybe<Array<Types.Skill>>;
  filterOnlyMeisterCraftsmen?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterOmitOrganisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type CraftsmenSearchQuery = { searchCraftsmen: { __typename: 'CraftsmanSearchResultConnection', totalCount: number, edges: Array<{ __typename: 'CraftsmanSearchResultEdge', node: { __typename: 'CraftsmanSearchResult', organisation: { __typename: 'Company', id: string, title: string, isMeister: boolean, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } } }> } };

export type CraftsmenSearchOrganisationFragment = { __typename: 'Company', id: string, title: string, isMeister: boolean, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null };

export const CraftsmenSearchOrganisationFragmentDoc = gql`
    fragment CraftsmenSearchOrganisation on Company {
  id
  title
  isMeister
  logo {
    ...AttachmentSnapshot
  }
  profile {
    id
    slug
  }
  branchAddress {
    ...AddressDetails__Address
  }
}
    ${AttachmentSnapshotFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;
export const CraftsmenSearchDocument = gql`
    query CraftsmenSearch($offset: Int, $limit: Int!, $projectId: ID, $filterSearchTerm: String, $filterMyContacts: Boolean, $filterCategories: [Skill!], $filterOnlyMeisterCraftsmen: Boolean, $filterOmitOrganisationIds: [ID!]) {
  searchCraftsmen(
    offset: $offset
    limit: $limit
    projectId: $projectId
    filters: {myContacts: $filterMyContacts, categories: $filterCategories, onlyMeisterCraftsmen: $filterOnlyMeisterCraftsmen, omitOrganisationIds: $filterOmitOrganisationIds}
    search: $filterSearchTerm
  ) {
    edges {
      node {
        organisation {
          id
          ...CraftsmenSearchOrganisation
        }
      }
    }
    totalCount
  }
}
    ${CraftsmenSearchOrganisationFragmentDoc}`;

/**
 * __useCraftsmenSearchQuery__
 *
 * To run a query within a React component, call `useCraftsmenSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCraftsmenSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCraftsmenSearchQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      projectId: // value for 'projectId'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      filterMyContacts: // value for 'filterMyContacts'
 *      filterCategories: // value for 'filterCategories'
 *      filterOnlyMeisterCraftsmen: // value for 'filterOnlyMeisterCraftsmen'
 *      filterOmitOrganisationIds: // value for 'filterOmitOrganisationIds'
 *   },
 * });
 */
export function useCraftsmenSearchQuery(baseOptions: Apollo.QueryHookOptions<CraftsmenSearchQuery, CraftsmenSearchQueryVariables> & ({ variables: CraftsmenSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>(CraftsmenSearchDocument, options);
      }
export function useCraftsmenSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>(CraftsmenSearchDocument, options);
        }
export function useCraftsmenSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>(CraftsmenSearchDocument, options);
        }
export type CraftsmenSearchQueryHookResult = ReturnType<typeof useCraftsmenSearchQuery>;
export type CraftsmenSearchLazyQueryHookResult = ReturnType<typeof useCraftsmenSearchLazyQuery>;
export type CraftsmenSearchSuspenseQueryHookResult = ReturnType<typeof useCraftsmenSearchSuspenseQuery>;
export type CraftsmenSearchQueryResult = Apollo.QueryResult<CraftsmenSearchQuery, CraftsmenSearchQueryVariables>;