/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../../features/doc-items/Fragments.generated';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../../features/attachments/Attachments.generated';
import { TemplateQuoteWarnings_QuoteTemplateFragmentDoc } from '../../../features/templates/quote/TemplateQuoteWarnings.generated';
import { TemplateQuoteStatuses_QuoteTemplateFragmentDoc } from '../../../features/templates/quote/TemplateQuoteStatuses.generated';
import { QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc } from '../../../features/templates/quote/buttons/QuoteTemplateAddToShopButton.generated';
import { QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc } from '../../../features/templates/quote/buttons/QuoteTemplateSubscribeButton.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuotesQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationQuoteTemplatesSorting>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdAfter?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  createdBefore?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  priceMin?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  priceMax?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  tagsAny?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  addedToShop?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  mineShareStatus?: Types.InputMaybe<Types.TemplateShareStatus>;
  sources?: Types.InputMaybe<Array<Types.TemplateSource>>;
}>;


export type TemplateQuotesQuery = { quoteTemplates: { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, customTags: Array<string>, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, latestPublishedVersionNumber?: number | null, containsUnpublishedChanged: boolean, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, addedToShop: boolean, sharedPublicly: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } }> } };

export type TemplateQuotes_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, customTags: Array<string>, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, latestPublishedVersionNumber?: number | null, containsUnpublishedChanged: boolean, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, addedToShop: boolean, sharedPublicly: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export type BulkActionsShare_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> };

export const BulkActionsShare_QuoteTemplateFragmentDoc = gql`
    fragment BulkActionsShare_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  sharedWithOrganisations {
    id
    title
    logo {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const TemplateQuotes_TemplateFragmentDoc = gql`
    fragment TemplateQuotes_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  tplApplicableFor
  title
  description
  createdAt
  proposedCalculation {
    ...ItemCalculation
  }
  projectId
  sharedOnGallery
  shopCatalogProductImage {
    id
    url
  }
  customTags
  isLikedByMe
  owningSystemOrganisationId
  publicDescription
  attachments {
    ...Attachment
  }
  ...BulkActionsShare_QuoteTemplate
  ...TemplateQuoteWarnings_QuoteTemplate
  ...TemplateQuoteStatuses_QuoteTemplate
  ...QuoteTemplateAddToShopButton_QuoteTemplate
  ...QuoteTemplateSubscribeButton_QuoteTemplate
}
    ${ItemCalculationFragmentDoc}
${AttachmentFragmentDoc}
${BulkActionsShare_QuoteTemplateFragmentDoc}
${TemplateQuoteWarnings_QuoteTemplateFragmentDoc}
${TemplateQuoteStatuses_QuoteTemplateFragmentDoc}
${QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc}
${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}`;
export const TemplateQuotesDocument = gql`
    query TemplateQuotes($offset: Int, $limit: Int!, $sorting: [OrganisationQuoteTemplatesSorting!], $searchTerm: String, $createdAfter: Date, $createdBefore: Date, $priceMin: Float, $priceMax: Float, $tagsAny: [String!], $addedToShop: Boolean, $mineShareStatus: TemplateShareStatus, $sources: [TemplateSource!]) {
  quoteTemplates(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {createdAfter: $createdAfter, createdBefore: $createdBefore, priceMin: $priceMin, priceMax: $priceMax, sources: $sources, addedToShop: $addedToShop, mineShareStatus: $mineShareStatus, tagsAny: $tagsAny}
    search: $searchTerm
  ) {
    edges {
      node {
        ...TemplateQuotes_Template
      }
    }
    totalCount
  }
}
    ${TemplateQuotes_TemplateFragmentDoc}`;

/**
 * __useTemplateQuotesQuery__
 *
 * To run a query within a React component, call `useTemplateQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuotesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      searchTerm: // value for 'searchTerm'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *      priceMin: // value for 'priceMin'
 *      priceMax: // value for 'priceMax'
 *      tagsAny: // value for 'tagsAny'
 *      addedToShop: // value for 'addedToShop'
 *      mineShareStatus: // value for 'mineShareStatus'
 *      sources: // value for 'sources'
 *   },
 * });
 */
export function useTemplateQuotesQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuotesQuery, TemplateQuotesQueryVariables> & ({ variables: TemplateQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuotesQuery, TemplateQuotesQueryVariables>(TemplateQuotesDocument, options);
      }
export function useTemplateQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuotesQuery, TemplateQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuotesQuery, TemplateQuotesQueryVariables>(TemplateQuotesDocument, options);
        }
export function useTemplateQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuotesQuery, TemplateQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuotesQuery, TemplateQuotesQueryVariables>(TemplateQuotesDocument, options);
        }
export type TemplateQuotesQueryHookResult = ReturnType<typeof useTemplateQuotesQuery>;
export type TemplateQuotesLazyQueryHookResult = ReturnType<typeof useTemplateQuotesLazyQuery>;
export type TemplateQuotesSuspenseQueryHookResult = ReturnType<typeof useTemplateQuotesSuspenseQuery>;
export type TemplateQuotesQueryResult = Apollo.QueryResult<TemplateQuotesQuery, TemplateQuotesQueryVariables>;