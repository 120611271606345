import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValueWithButton,
  MenuButton,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TemplateIcon from "@mui/icons-material/FileCopy";
import FunctionsIcon from "@mui/icons-material/Functions";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { differenceWith } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  EntitySearchArrayOfFilterOperator,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
} from "../../../../clients/graphqlTypes";
import { ViewMode, ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import {
  ArrayFilterOperatorOptions,
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../../products/ProductSearchFilterOperatorOptions";
import {
  ItemTemplateSearchFilterPropertyFilterComputedFragment,
  ItemTemplateSearchFilterPropertyFilterFragment,
} from "../../templates/templateSearchFilters.generated";
import { TemplateTypeAtVersionWarningButton } from "../../templateTypes/TemplateTypeAtVersionWarningButton";
import { TemplateTypesSearchModal } from "../../templateTypes/TemplateTypesSearchModal";
import { ProductSearchFilterExpressionModal } from "../modals/ProductSearchFilterExpressionModal";
import { getEnhancedPropertyLabel } from "../properties";
import { adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2 } from "../templateSearchFilterExpressionUtils";
import {
  templateSearchFilterPropertyFilterExpression2Input,
  useTemplateSearchFilterExpressionMutations,
} from "../useTemplateSearchFilterExpressionMutations";
import {
  TemplateTypeBox_ItemFragment,
  TemplateTypeBox_QuoteFragment,
  TemplateTypeBox_QuoteTemplateFragment,
  useImplementsTemplateTypeMutation,
  useSetPlaceholderForTemplateTypeMutation,
} from "./TemplateTypeBox.generated";

interface Props {
  projectId: string | null;
  doc: TemplateTypeBox_QuoteTemplateFragment | TemplateTypeBox_QuoteFragment;
  item: TemplateTypeBox_ItemFragment;
  onUpdateDataRefetchQueries?: string[];
  isEditable?: boolean;
}

export const TemplateTypeBox = ({
  projectId,
  doc,
  item,
  onUpdateDataRefetchQueries,
  isEditable = false,
}: Props) => {
  const { t } = useTranslate([
    "QuoteItem",
    "ItemPropertyField",
    "Global",
    "TemplateTypes",
  ]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();

  const [implementsTemplateType] = useImplementsTemplateTypeMutation({
    client,
  });
  const [setPlaceholderForTemplateType] =
    useSetPlaceholderForTemplateTypeMutation({ client });

  const { propertyFilters: propertySearchFiltersExpressions } =
    item.templateSearchFilterExpressions;

  const {
    handleSetTemplateSearchFilterPropertyExpression,
    handleDeleteTemplateSearchFilterPropertyExpression,
    defineItemTemplateSearchFilterExpressions,
  } = useTemplateSearchFilterExpressionMutations({
    projectId: projectId,
    docId: doc.id,
    itemId: item.id,
    propertySearchFiltersExpressions,
    refetchQueries: onUpdateDataRefetchQueries,
  });

  const unusedProperties = differenceWith(
    item.placeholderForTemplateType?.templateType?.props2 ?? [],
    propertySearchFiltersExpressions,
    (p, f) => p.key === f.key
  );

  if (doc.__typename === "QuoteTemplate" && item.isRootItem) {
    return (
      <CardContainer
        title={t("Template type implementation", { ns: "QuoteItem" })}
        isExpandable
        ActionButton={
          isEditable && !doc.implementsTemplateType ? (
            <ModalOpenButton
              Modal={TemplateTypesSearchModal}
              modalProps={{
                handleTemplateTypeChoice: async templateType => {
                  await implementsTemplateType({
                    variables: {
                      input: {
                        templateId: doc.id,
                        templateTypeId: templateType.id,
                      },
                    },
                  });
                },
              }}
            >
              <IconButton color="primary" size="small">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          ) : undefined
        }
      >
        {doc.implementsTemplateType ? (
          <ListItem
            disablePadding
            secondaryAction={
              isEditable ? (
                <Stack flexDirection={"row"} alignItems={"center"}>
                  {doc.implementsTemplateType.atRevision !==
                    doc.implementsTemplateType.templateType.revision && (
                    <TemplateTypeAtVersionWarningButton
                      templateTypeRevision={
                        doc.implementsTemplateType.templateType.revision
                      }
                      atRevision={doc.implementsTemplateType.atRevision}
                    />
                  )}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={async () => {
                      await implementsTemplateType({
                        variables: {
                          input: { templateId: doc.id, templateTypeId: null },
                        },
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ) : undefined
            }
          >
            <ListItemButton
              component={Link}
              to={`/templates/types/${doc.implementsTemplateType.templateType.id}`}
              dense
            >
              <ListItemIcon
                sx={theme => ({
                  minWidth: theme.layout.listItemMinWidth.sm,
                })}
              >
                <TemplateIcon />
              </ListItemIcon>
              <ListItemText>
                {doc.implementsTemplateType.templateType.title}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ) : (
          <Stack padding={2} alignItems="center" justifyContent={"center"}>
            <Typography
              color={theme => theme.palette.grey[600]}
              variant="body2"
            >
              {t("No template type defined", { ns: "QuoteItem" })}
            </Typography>
          </Stack>
        )}
      </CardContainer>
    );
  }

  if (
    !item.isRootItem &&
    (item.type === "section" || item.placeholderForTemplateType)
  ) {
    return (
      <CardContainer
        title={t("Template type placeholder", { ns: "QuoteItem" })}
        isExpandable
        ActionButton={
          !item.placeholderForTemplateType ? (
            <ModalOpenButton
              Modal={TemplateTypesSearchModal}
              modalProps={{
                handleTemplateTypeChoice: async templateType => {
                  setPlaceholderForTemplateType({
                    variables: {
                      input: {
                        itemId: item.id,
                        templateId: doc.id,
                        templateTypeId: templateType.id,
                      },
                    },
                  });
                },
              }}
            >
              <IconButton color="primary" size="small">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          ) : item.placeholderForTemplateType &&
            item.placeholderForTemplateType.atRevision !==
              item.placeholderForTemplateType.templateType.revision ? (
            <TemplateTypeAtVersionWarningButton
              templateTypeRevision={
                item.placeholderForTemplateType.templateType.revision
              }
              atRevision={item.placeholderForTemplateType.atRevision}
              onResolve={async () => {
                if (item.placeholderForTemplateType) {
                  await setPlaceholderForTemplateType({
                    variables: {
                      input: {
                        itemId: item.id,
                        templateId: doc.id,
                        templateTypeId:
                          item.placeholderForTemplateType.templateType.id,
                      },
                    },
                  });

                  await defineItemTemplateSearchFilterExpressions({
                    variables: {
                      input: {
                        docId: doc.id,
                        projectId: projectId,
                        itemId: item.id,
                        templateSearchFilterExpressions: {
                          propertyFilterExpressions:
                            adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2(
                              {
                                filters: propertySearchFiltersExpressions,
                                templateTypeProps:
                                  item.placeholderForTemplateType.templateType
                                    .props2,
                              }
                            ).map(
                              templateSearchFilterPropertyFilterExpression2Input
                            ),
                        },
                      },
                    },
                  });
                }
              }}
            />
          ) : undefined
        }
      >
        {item.placeholderForTemplateType ? (
          <Stack>
            <ListItem
              disablePadding
              secondaryAction={
                <Stack flexDirection={"row"} alignItems={"center"}>
                  {item.placeholderForTemplateType.atRevision !==
                    item.placeholderForTemplateType.templateType.revision && (
                    <TemplateTypeAtVersionWarningButton
                      templateTypeRevision={
                        item.placeholderForTemplateType.templateType.revision
                      }
                      atRevision={item.placeholderForTemplateType.atRevision}
                      onResolve={async () => {
                        if (item.placeholderForTemplateType) {
                          await setPlaceholderForTemplateType({
                            variables: {
                              input: {
                                itemId: item.id,
                                templateId: doc.id,
                                templateTypeId:
                                  item.placeholderForTemplateType.templateType
                                    .id,
                              },
                            },
                          });

                          await defineItemTemplateSearchFilterExpressions({
                            variables: {
                              input: {
                                docId: doc.id,
                                projectId: projectId,
                                itemId: item.id,
                                templateSearchFilterExpressions: {
                                  propertyFilterExpressions:
                                    adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2(
                                      {
                                        filters:
                                          propertySearchFiltersExpressions,
                                        templateTypeProps:
                                          item.placeholderForTemplateType
                                            .templateType.props2,
                                      }
                                    ).map(
                                      templateSearchFilterPropertyFilterExpression2Input
                                    ),
                                },
                              },
                            },
                          });
                        }
                      }}
                    />
                  )}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setPlaceholderForTemplateType({
                        variables: {
                          input: {
                            itemId: item.id,
                            templateId: doc.id,
                            templateTypeId: null,
                          },
                        },
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemButton
                component={Link}
                to={`/templates/types/${item.placeholderForTemplateType.templateType.id}`}
                dense
              >
                <ListItemIcon
                  sx={theme => ({
                    minWidth: theme.layout.listItemMinWidth.sm,
                  })}
                >
                  <TemplateIcon />
                </ListItemIcon>
                <ListItemText>
                  {item.placeholderForTemplateType.templateType.title}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <CollapseSection
              p={1}
              title={t("Search expressions", { ns: "TemplateTypes" })}
              ActionButtons={
                <>
                  <MenuButton
                    Icon={<AddIcon />}
                    buttonProps={{
                      disabled: unusedProperties.length === 0,
                    }}
                  >
                    {unusedProperties.map(prop => {
                      switch (prop.__typename) {
                        case "Props2Bool": {
                          return (
                            <ModalOpenButton
                              key={prop.key}
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchBoolFilterOperator
                                  | EntitySearchBoolInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: prop.key,
                                operator: "eq",
                                allowedSearchFilterOperators:
                                  BoolFilterOperatorOptions,
                                expression: "",
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    prop.key,
                                    value.operator === "in"
                                      ? {
                                          boolInFilter: {
                                            key: prop.key,
                                            operatorBoolIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            boolFilter: {
                                              operatorBool: value.operator,
                                              expr: value.expr,
                                              key: prop.key,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <MenuItem>
                                {getEnhancedPropertyLabel(prop, false)}
                              </MenuItem>
                            </ModalOpenButton>
                          );
                        }
                        case "Props2Number": {
                          return (
                            <ModalOpenButton
                              key={prop.key}
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchNumberFilterOperator
                                  | EntitySearchNumberInFilterOperator
                                  | EntitySearchNumberBetweenFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: prop.key,
                                operator: "eq",
                                allowedSearchFilterOperators:
                                  NumberFilterOperatorOptions,
                                expression: "",
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    prop.key,
                                    value.operator === "in"
                                      ? {
                                          numberInFilter: {
                                            key: prop.key,
                                            operatorNumberIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "between"
                                        ? {
                                            numberBetweenFilter: {
                                              key: prop.key,
                                              operatorNumberBetween:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq" ||
                                            value.operator === "lt" ||
                                            value.operator === "lte" ||
                                            value.operator === "gt" ||
                                            value.operator === "gte"
                                          ? {
                                              numberFilter: {
                                                key: prop.key,
                                                operatorNumber: value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <MenuItem>
                                {getEnhancedPropertyLabel(prop, false)}
                              </MenuItem>
                            </ModalOpenButton>
                          );
                        }
                        case "Props2NumberArray": {
                          return (
                            <ModalOpenButton
                              key={prop.key}
                              Modal={
                                ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: prop.key,
                                operator: "anyOf",
                                allowedSearchFilterOperators:
                                  ArrayFilterOperatorOptions,
                                expression: "",
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    prop.key,
                                    {
                                      numberArrayOfFilter: {
                                        key: prop.key,
                                        operatorNumberArrayOf: value.operator,
                                        expr: value.expr,
                                      },
                                    }
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <MenuItem>
                                {getEnhancedPropertyLabel(prop, false)}
                              </MenuItem>
                            </ModalOpenButton>
                          );
                        }

                        case "Props2Text": {
                          return (
                            <ModalOpenButton
                              key={prop.key}
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchTextFilterOperator
                                  | EntitySearchTextInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: prop.key,
                                operator: "eq",
                                allowedSearchFilterOperators:
                                  TextFilterOperatorOptions,
                                expression: "",
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    prop.key,
                                    value.operator === "in"
                                      ? {
                                          textInFilter: {
                                            key: prop.key,
                                            operatorTextIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            textFilter: {
                                              key: prop.key,
                                              operatorText: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <MenuItem>
                                {getEnhancedPropertyLabel(prop, false)}
                              </MenuItem>
                            </ModalOpenButton>
                          );
                        }
                        case "Props2TextArray": {
                          return (
                            <ModalOpenButton
                              key={prop.key}
                              Modal={
                                ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                              }
                              modalProps={{
                                projectId: projectId,
                                docId: doc.id,
                                itemId: item.id,
                                filterKey: prop.key,
                                operator: "anyOf",
                                allowedSearchFilterOperators:
                                  ArrayFilterOperatorOptions,
                                expression: "",
                                handleComplete: async (value, handleClose) => {
                                  await handleSetTemplateSearchFilterPropertyExpression(
                                    prop.key,
                                    {
                                      textArrayOfFilter: {
                                        key: prop.key,
                                        operatorTextArrayOf: value.operator,
                                        expr: value.expr,
                                      },
                                    }
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <MenuItem>
                                {getEnhancedPropertyLabel(prop, false)}
                              </MenuItem>
                            </ModalOpenButton>
                          );
                        }
                        default:
                          assertNever(prop);
                      }
                    })}
                  </MenuButton>
                  <MenuButton>
                    <ViewModeMenuItem
                      viewMode={viewMode}
                      onViewModeChange={setViewMode}
                      allowedModes={[null, "delete"]}
                    />
                  </MenuButton>
                </>
              }
            >
              <Grid
                container
                columns={2}
                rowSpacing={1}
                columnSpacing={2}
                padding={1}
              >
                {propertySearchFiltersExpressions.map(
                  (propertySearchFilter, index) => {
                    switch (propertySearchFilter.__typename) {
                      case "EntitySearchPropertyFilterBoolFilterComputed":
                      case "EntitySearchPropertyFilterBoolInFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchBoolFilterOperator
                                    | EntitySearchBoolInFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterBoolInFilterComputed"
                                      ? propertySearchFilter.operatorBoolIn
                                      : propertySearchFilter.operatorBool,
                                  allowedSearchFilterOperators:
                                    BoolFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            boolInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorBoolIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq"
                                          ? {
                                              boolFilter: {
                                                operatorBool: value.operator,
                                                expr: value.expr,
                                                key: propertySearchFilter.key,
                                              },
                                            }
                                          : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterNumberFilterComputed":
                      case "EntitySearchPropertyFilterNumberInFilterComputed":
                      case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchNumberFilterOperator
                                    | EntitySearchNumberInFilterOperator
                                    | EntitySearchNumberBetweenFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterNumberFilterComputed"
                                      ? propertySearchFilter.operatorNumber
                                      : propertySearchFilter.__typename ===
                                          "EntitySearchPropertyFilterNumberInFilterComputed"
                                        ? propertySearchFilter.operatorNumberIn
                                        : propertySearchFilter.operatorNumberBetween,
                                  allowedSearchFilterOperators:
                                    NumberFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            numberInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "between"
                                          ? {
                                              numberBetweenFilter: {
                                                key: propertySearchFilter.key,
                                                operatorNumberBetween:
                                                  value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : value.operator === "eq" ||
                                              value.operator === "lt" ||
                                              value.operator === "lte" ||
                                              value.operator === "gt" ||
                                              value.operator === "gte"
                                            ? {
                                                numberFilter: {
                                                  key: propertySearchFilter.key,
                                                  operatorNumber:
                                                    value.operator,
                                                  expr: value.expr,
                                                },
                                              }
                                            : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterTextFilterComputed":
                      case "EntitySearchPropertyFilterTextInFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchTextFilterOperator
                                    | EntitySearchTextInFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterTextFilterComputed"
                                      ? propertySearchFilter.operatorText
                                      : propertySearchFilter.operatorTextIn,
                                  allowedSearchFilterOperators:
                                    TextFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            textInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorTextIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq"
                                          ? {
                                              textFilter: {
                                                key: propertySearchFilter.key,
                                                operatorText: value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.operatorNumberArrayOf,
                                  allowedSearchFilterOperators:
                                    ArrayFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "allOf" ||
                                        value.operator === "anyOf"
                                        ? {
                                            numberArrayOfFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberArrayOf:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.operatorTextArrayOf,
                                  allowedSearchFilterOperators:
                                    ArrayFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "allOf" ||
                                        value.operator === "anyOf"
                                        ? {
                                            textArrayOfFilter: {
                                              key: propertySearchFilter.key,
                                              operatorTextArrayOf:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      default:
                        assertNever(propertySearchFilter);
                    }
                  }
                )}
              </Grid>
            </CollapseSection>
          </Stack>
        ) : (
          <Stack padding={2} alignItems="center" justifyContent={"center"}>
            <Typography
              color={theme => theme.palette.grey[600]}
              variant="body2"
            >
              {t("No template type defined", { ns: "QuoteItem" })}
            </Typography>
          </Stack>
        )}
      </CardContainer>
    );
  }

  return null;
};

export function getValueWithOperator(
  filter:
    | ItemTemplateSearchFilterPropertyFilterFragment
    | ItemTemplateSearchFilterPropertyFilterComputedFragment
    | undefined
    | null,
  unit?: string | null | undefined
) {
  const placeholder = "-";
  if (!filter) return placeholder;

  switch (filter.__typename) {
    case "EntitySearchPropertyFilterNumberFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumber}` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetween.min}-${filter.valueNumberBetween.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      if (
        filter.valueNumberComputed === null ||
        filter.valueNumberComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      if (
        filter.valueNumberInComputed === null ||
        filter.valueNumberInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOf.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      if (
        filter.valueNumberArrayOfComputed === null ||
        filter.valueNumberArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOfComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      if (
        filter.valueNumberBetweenComputed === null ||
        filter.valueNumberBetweenComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetweenComputed.min}-${filter.valueNumberBetweenComputed.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueText}"` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      if (
        filter.valueTextComputed === null ||
        filter.valueTextComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueTextComputed}"` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextInFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextIn.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      if (
        filter.valueTextInComputed === null ||
        filter.valueTextInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextInComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOf.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      if (
        filter.valueTextArrayOfComputed === null ||
        filter.valueTextArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOfComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterBoolFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return `${operatorLabel} ${filter.valueBool}` + (unit ? ` ${unit}` : "");
    }
    case "EntitySearchPropertyFilterBoolInFilter": {
      const operatorLabel = BoolFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      if (
        filter.valueBoolComputed === null ||
        filter.valueBoolComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      if (
        filter.valueBoolInComputed === null ||
        filter.valueBoolInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    default:
      assertNever(filter);
  }
}
