import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import { DataGrid, GridColDef, GridRowId, Modal, TableInner } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Avatar } from "../../commons/Avatar";
import { CrmCompaniesSelect } from "../crm-companies/CrmCompaniesSelect";
import {
  CrmCompanySelect_CrmCompaniesRecordFragment,
  useCrmCompanySelectLazyQuery,
} from "../crm-companies/CrmCompanySelect.generated";
import {
  useCopyQuoteSapS4HanaSalesQuoteMutation,
  useS4HanaQuoteCopyModalQuery,
} from "./S4HanaQuoteCopyModal.generated";

interface Props {
  projectId: string;
  docId: string;
  handleClose: () => void;
}

export const S4HanaQuoteCopyModal = ({
  projectId,
  docId,
  handleClose,
  ...props
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const [stage, setStage] = React.useState<
    "initial" | "submitted" | "completed"
  >("initial");
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const [selectedItemIds, setSelectedItemIds] = React.useState<GridRowId[]>([]);
  const [selectedCrmCompanyIds, setSelectedCrmCompanyIds] = React.useState<
    string[]
  >([]);

  const client = useApolloClient();

  const query = useS4HanaQuoteCopyModalQuery({
    client,
    variables: { projectId, quoteId: docId },
  });
  const [fetchCrmCompaniesQuery, queryCrmCompanies] =
    useCrmCompanySelectLazyQuery({
      client,
    });
  const [copyQuote, { loading: copyQuoteLoading }] =
    useCopyQuoteSapS4HanaSalesQuoteMutation({
      client,
    });

  const items = React.useMemo(
    () => getDataOrNull(query.data?.outgoingQuote)?.quote?.items ?? [],
    [query.data?.outgoingQuote]
  );
  const sapS4HanaData = React.useMemo(
    () => getDataOrNull(query.data?.quoteItemSapS4HanaData)?.items ?? [],
    [query.data?.quoteItemSapS4HanaData]
  );

  const crmCompanies =
    getDataOrNull(queryCrmCompanies.data?.crmCompanies)?.edges.map(
      e => e.node
    ) ?? [];

  const xIdByItemIds = React.useMemo(() => {
    return sapS4HanaData.reduce(
      (acc, item) => {
        if (!item.id || !item.xId) return acc;
        acc[item.id] = item.xId;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [sapS4HanaData]);
  const itemsWithXId = React.useMemo(() => {
    return items
      .filter(i => Boolean(xIdByItemIds[i.id]))
      .map(item => ({
        ...item,
        xId: xIdByItemIds[item.id],
      }));
  }, [items, xIdByItemIds]);

  React.useEffect(() => {
    if (itemsWithXId.length > 0) {
      setSelectedItemIds(itemsWithXId.map(i => i.id));
    }
  }, [itemsWithXId]);

  const handleCopy = async () => {
    if (!selectedCrmCompanyIds.length)
      throw new Error("No crm companies selected");

    setStage("submitted");
    setErrors({});

    try {
      await fetchCrmCompaniesQuery({
        variables: {
          limit: selectedCrmCompanyIds.length,
          filterIds: selectedCrmCompanyIds,
        },
      });

      const result = await copyQuote({
        variables: {
          input: {
            contracteeCrmOrganisationIds: selectedCrmCompanyIds,
            projectId,
            docId,
            onlySalesQuotationItems: selectedItemIds
              .map(id => xIdByItemIds[id] ?? null)
              .filter(notNull),
          },
        },
      });

      const errors =
        result.data?.copyQuoteSapS4HanaSalesQuote.failedForCrmCompanies.reduce(
          (acc, r) => ({ ...acc, [r.crmCompany.id]: r.errorMessage }),
          {} as Record<string, string>
        ) || {};
      setErrors(errors);

      setStage("completed");
    } catch (e) {
      setStage("initial");
    }
  };

  const columns = React.useMemo(
    (): GridColDef<{ id: string; pathForPdf: string; title: string }>[] => [
      {
        field: "pathForPdf",
        headerName: t("Position", { ns: "Global" }),
        sortable: false,
        groupable: false,
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
      },
      {
        field: "title",
        headerName: t("Title", { ns: "Global" }),
        sortable: false,
        groupable: false,
        flex: 4,
      },
    ],
    [t]
  );

  return (
    <Modal
      title={t("Copy quote to another companies", { ns: "SapS4Hana" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label:
            stage === "initial"
              ? t("Cancel", { ns: "Global" })
              : t("Close", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Submit", { ns: "Global" }),
          handleClick: async () => {
            await handleCopy();
          },
          buttonProps: {
            loading: stage === "submitted",
            disabled:
              !selectedCrmCompanyIds.length ||
              !selectedItemIds.length ||
              stage !== "initial",
          },
        },
      ]}
    >
      <Stack direction="column" spacing={1}>
        {stage === "initial" && itemsWithXId.length > 0 && (
          <>
            <Typography>
              {t("Select companies to copy the quote to", {
                ns: "SapS4Hana",
              })}
            </Typography>
            <CrmCompaniesSelect
              inputLabel={t("Companies", { ns: "SapS4Hana" })}
              onChange={crmCompanies => {
                setSelectedCrmCompanyIds(crmCompanies.map(c => c.id));
              }}
              crmCompanyIds={selectedCrmCompanyIds}
              {...props}
            />
            <Typography>
              {t("Select positions to copy", { ns: "SapS4Hana" })}
            </Typography>
            <DataGrid
              loading={false}
              columns={columns}
              disableColumnMenu
              disableColumnFilter
              disableRowGrouping
              rows={itemsWithXId}
              getRowId={row => row.id}
              paginationMode="client"
              sortingMode="client"
              checkboxSelection
              disableRowSelectionOnClick
              rowSelectionModel={selectedItemIds}
              onRowSelectionModelChange={setSelectedItemIds}
              pagination={false}
            />
          </>
        )}

        {stage !== "initial" && crmCompanies.length > 0 && (
          <TableInner<CrmCompanySelect_CrmCompaniesRecordFragment>
            size="extra-small"
            columns={[
              {
                id: "name",
                title: t("Company", { ns: "SapS4Hana" }),
                colSpan: 1,
                cellStyle: { minWidth: "200px" },
                render: row => (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      size="s"
                      url={row.logo?.url}
                      initials={row.title.substring(0, 2)}
                    />
                    <Typography>{row.title}</Typography>
                  </Stack>
                ),
              },
              {
                id: "status",
                title: t("Status", { ns: "SapS4Hana" }),
                colSpan: 1,
                render: row =>
                  stage === "completed" ? (
                    <Typography color={errors[row.id] ? "error" : "success"}>
                      {errors[row.id] || t("Success", { ns: "Global" })}
                    </Typography>
                  ) : (
                    <Typography color="grey.600">
                      {t("Processing", { ns: "Global" })}
                    </Typography>
                  ),
              },
            ]}
            items={crmCompanies}
          />
        )}
      </Stack>
    </Modal>
  );
};
