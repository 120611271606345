/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteEditTroubleshootQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateQuoteEditTroubleshootQuery = { quoteTemplateLatest?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string } | null, compileDocIsolatedExpression: { __typename: 'CompileDocIsolatedExpressionResult', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, attribute: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, key: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, propertyKey?: string | null, filterKind: Types.ProductSearchFilterExpressionFilterKind, filterKey: Types.ProductSearchFilterExpressionFilterKey, operator: Types.EntitySearchFilterOperator, allowedOperators: Array<Types.EntitySearchFilterOperator>, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, toKey: string, fromExpr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null }> } };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, attribute: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, key: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, propertyKey?: string | null, filterKind: Types.ProductSearchFilterExpressionFilterKind, filterKey: Types.ProductSearchFilterExpressionFilterKey, operator: Types.EntitySearchFilterOperator, allowedOperators: Array<Types.EntitySearchFilterOperator>, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, toKey: string, fromExpr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragment = TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment | TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment | TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment | TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment | TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment | TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment;

export const TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc = gql`
    fragment TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic on CompileDocIsolatedExpressionResultDiagnostic {
  ... on CompileDocIsolatedExpressionResultDiagnosticComputedProp {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    key
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticAttribute {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    attribute
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticPropertyMapping {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    toKey
    fromExpr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    propertyKey
    filterKind
    filterKey
    operator
    allowedOperators
    versionNumber
  }
}
    `;
export const TemplateQuoteEditTroubleshootDocument = gql`
    query TemplateQuoteEditTroubleshoot($id: ID!) {
  quoteTemplateLatest(id: $id) {
    id
    resolvedAsReadModelVersionNumber
    title
  }
  compileDocIsolatedExpression(input: {docId: $id, deep: true}) {
    results {
      ...TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic
    }
  }
}
    ${TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc}`;

/**
 * __useTemplateQuoteEditTroubleshootQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteEditTroubleshootQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteEditTroubleshootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteEditTroubleshootQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateQuoteEditTroubleshootQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables> & ({ variables: TemplateQuoteEditTroubleshootQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>(TemplateQuoteEditTroubleshootDocument, options);
      }
export function useTemplateQuoteEditTroubleshootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>(TemplateQuoteEditTroubleshootDocument, options);
        }
export function useTemplateQuoteEditTroubleshootSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>(TemplateQuoteEditTroubleshootDocument, options);
        }
export type TemplateQuoteEditTroubleshootQueryHookResult = ReturnType<typeof useTemplateQuoteEditTroubleshootQuery>;
export type TemplateQuoteEditTroubleshootLazyQueryHookResult = ReturnType<typeof useTemplateQuoteEditTroubleshootLazyQuery>;
export type TemplateQuoteEditTroubleshootSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteEditTroubleshootSuspenseQuery>;
export type TemplateQuoteEditTroubleshootQueryResult = Apollo.QueryResult<TemplateQuoteEditTroubleshootQuery, TemplateQuoteEditTroubleshootQueryVariables>;