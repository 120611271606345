import { AutocompleteMultiple } from "@msys/ui";
import { Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { FilterModal } from "../../../commons/filters/FilterModal";
import { Stack } from "../../../commons/layout/Stack";
import {
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSorting,
} from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import {
  FilterCreatedAfter,
  FilterCreatedBefore,
  FilterMaxPrice,
  FilterMinPrice,
  QuoteTemplatesSorting,
} from "../filters";

export const DEFAULT_SORTING: OrganisationQuoteTemplatesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

export type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  "createdBefore" | "createdAfter" | "priceMin" | "priceMax" | "tagsAny"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  resetFilters: () => void;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  handleClose: () => void;
  tags: string[];
}

export const TemplateQuotesFilterModal = <F extends Filters>({
  filters,
  setFilters,
  resetFilters,
  sorting,
  setSorting,
  handleClose,
  tags,
}: Props<F>) => {
  const { t } = useTranslate(["Templates", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  return (
    <FilterModal
      title={t("Filters", { ns: "Global" })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <QuoteTemplatesSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">
        {t("Filter by", {
          ns: "Global",
        })}
      </Typography>
      <Stack>
        <FilterMinPrice
          value={filtersState.priceMin ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMin: newValue,
            }))
          }
        />
        <FilterMaxPrice
          value={filtersState.priceMax ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMax: newValue,
            }))
          }
        />
      </Stack>
      <Stack>
        <FilterCreatedAfter
          value={filtersState.createdAfter}
          setValue={newValue => {
            setFiltersState(filters => ({
              ...filters,
              createdAfter: newValue,
            }));
          }}
        />
        <FilterCreatedBefore
          value={filtersState.createdBefore}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              createdBefore: newValue,
            }))
          }
        />
      </Stack>
      <AutocompleteMultiple
        inputLabel={t("Tags", { ns: "Templates" })}
        options={tags}
        value={filtersState.tagsAny ?? []}
        onChange={value => {
          setFiltersState(state => ({ ...state, tagsAny: value }));
        }}
        filterOptions={(options, state) =>
          options.filter(option =>
            option
              .toLocaleLowerCase()
              .trim()
              .includes(state.inputValue.toLocaleLowerCase().trim())
          )
        }
      />
    </FilterModal>
  );
};
