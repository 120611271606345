import type { AppRouter } from "@msys/trpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { createTRPCReact, TRPCClient } from "@trpc/react-query";
import React from "react";
import superjson from "superjson";

type Client = TRPCClient<AppRouter>;
export const trpc = createTRPCReact<AppRouter>();

export const TRPCProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { queryClient, trpcClient } = useTrpcClient();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};

function useTrpcClient() {
  const [queryClient] = React.useState(() => new QueryClient());
  const [trpcClient] = React.useState<Client>(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: "/api/trpc",
          // optional
          // headers() {
          //   return {
          //     authorization: getAuthCookie(),
          //   };
          // },
        }),
      ],
    })
  );

  return { queryClient, trpcClient };
}
