/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import { BulkActionsShare_QuoteTemplateFragmentDoc } from '../../../main-routes/templates/quote/TemplateQuotes.generated';
import { TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc } from './TemplateQuotePublishingStatus.generated';
import { QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateAddToShopButton.generated';
import { QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateSubscribeButton.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplatesQuoteSetsSearchModalQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  filters?: Types.InputMaybe<Types.SearchQuoteTemplatesFilters>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type TemplatesQuoteSetsSearchModalQuery = { searchQuoteTemplates: { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, addedToShop: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } }> } };

export type TemplatesQuoteSetsSearchModal_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, addedToShop: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export type TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplatesQuoteSetsSearchModal_TemplateTypeQuery = { templateType: { __typename: 'TemplateType', id: string, title: string } };

export const TemplatesQuoteSetsSearchModal_TemplateFragmentDoc = gql`
    fragment TemplatesQuoteSetsSearchModal_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  tplApplicableFor
  title
  description
  createdAt
  proposedCalculation {
    ...ItemCalculation
  }
  projectId
  sharedOnGallery
  shopCatalogProductImage {
    id
    url
  }
  isLikedByMe
  owningSystemOrganisationId
  publicDescription
  attachments {
    ...Attachment
  }
  ...BulkActionsShare_QuoteTemplate
  ...TemplateQuotePublishingStatus_QuoteTemplate
  ...QuoteTemplateAddToShopButton_QuoteTemplate
  ...QuoteTemplateSubscribeButton_QuoteTemplate
}
    ${ItemCalculationFragmentDoc}
${AttachmentFragmentDoc}
${BulkActionsShare_QuoteTemplateFragmentDoc}
${TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc}
${QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc}
${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}`;
export const TemplatesQuoteSetsSearchModalDocument = gql`
    query TemplatesQuoteSetsSearchModal($offset: Int, $limit: Int!, $filters: SearchQuoteTemplatesFilters, $searchTerm: String) {
  searchQuoteTemplates(
    offset: $offset
    limit: $limit
    filters: $filters
    searchTerm: $searchTerm
  ) {
    edges {
      node {
        ...TemplatesQuoteSetsSearchModal_Template
      }
    }
    totalCount
  }
}
    ${TemplatesQuoteSetsSearchModal_TemplateFragmentDoc}`;

/**
 * __useTemplatesQuoteSetsSearchModalQuery__
 *
 * To run a query within a React component, call `useTemplatesQuoteSetsSearchModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuoteSetsSearchModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuoteSetsSearchModalQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useTemplatesQuoteSetsSearchModalQuery(baseOptions: Apollo.QueryHookOptions<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables> & ({ variables: TemplatesQuoteSetsSearchModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>(TemplatesQuoteSetsSearchModalDocument, options);
      }
export function useTemplatesQuoteSetsSearchModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>(TemplatesQuoteSetsSearchModalDocument, options);
        }
export function useTemplatesQuoteSetsSearchModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>(TemplatesQuoteSetsSearchModalDocument, options);
        }
export type TemplatesQuoteSetsSearchModalQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModalQuery>;
export type TemplatesQuoteSetsSearchModalLazyQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModalLazyQuery>;
export type TemplatesQuoteSetsSearchModalSuspenseQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModalSuspenseQuery>;
export type TemplatesQuoteSetsSearchModalQueryResult = Apollo.QueryResult<TemplatesQuoteSetsSearchModalQuery, TemplatesQuoteSetsSearchModalQueryVariables>;
export const TemplatesQuoteSetsSearchModal_TemplateTypeDocument = gql`
    query TemplatesQuoteSetsSearchModal_TemplateType($id: ID!) {
  templateType(id: $id) {
    id
    title
  }
}
    `;

/**
 * __useTemplatesQuoteSetsSearchModal_TemplateTypeQuery__
 *
 * To run a query within a React component, call `useTemplatesQuoteSetsSearchModal_TemplateTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuoteSetsSearchModal_TemplateTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuoteSetsSearchModal_TemplateTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplatesQuoteSetsSearchModal_TemplateTypeQuery(baseOptions: Apollo.QueryHookOptions<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables> & ({ variables: TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>(TemplatesQuoteSetsSearchModal_TemplateTypeDocument, options);
      }
export function useTemplatesQuoteSetsSearchModal_TemplateTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>(TemplatesQuoteSetsSearchModal_TemplateTypeDocument, options);
        }
export function useTemplatesQuoteSetsSearchModal_TemplateTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>(TemplatesQuoteSetsSearchModal_TemplateTypeDocument, options);
        }
export type TemplatesQuoteSetsSearchModal_TemplateTypeQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModal_TemplateTypeQuery>;
export type TemplatesQuoteSetsSearchModal_TemplateTypeLazyQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModal_TemplateTypeLazyQuery>;
export type TemplatesQuoteSetsSearchModal_TemplateTypeSuspenseQueryHookResult = ReturnType<typeof useTemplatesQuoteSetsSearchModal_TemplateTypeSuspenseQuery>;
export type TemplatesQuoteSetsSearchModal_TemplateTypeQueryResult = Apollo.QueryResult<TemplatesQuoteSetsSearchModal_TemplateTypeQuery, TemplatesQuoteSetsSearchModal_TemplateTypeQueryVariables>;