import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import {
  CollapseSection,
  LabeledValueWithButton,
  MenuButton,
  Modal,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FunctionsIcon from "@mui/icons-material/Functions";
import { Grid, IconButton, MenuItem, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { differenceWith, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  EntitySearchArrayOfFilterOperator,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
  ItemType,
  ModifyItemRecommendedTemplateTypeValuesInput,
} from "../../../clients/graphqlTypes";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { getValueWithOperator } from "../doc-items/boxes/TemplateTypeBox";
import {
  ExpressionInputWithVariables,
  ExpressionItemSource,
  VariableInputState,
} from "../doc-items/modals/ExpressionModal";
import { ProductSearchFilterExpressionModal } from "../doc-items/modals/ProductSearchFilterExpressionModal";
import { useExpressionValidation } from "../doc-items/modals/useExpressionValidation";
import { templateSearchFilterPropertyFilterExpression2Input } from "../doc-items/useTemplateSearchFilterExpressionMutations";
import {
  ArrayFilterOperatorOptions,
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../products/ProductSearchFilterOperatorOptions";
import {
  EditRecommendedTemplateTypeModal_ItemRecommendationConfigFragment,
  EditRecommendedTemplateTypeModal_ItemRecommendationFragment,
  EditRecommendedTemplateTypeModal_RecommendedTemplateTypeConfigFragment,
  useEditRecommendedTemplateTypeModalQuery,
} from "./EditRecommendedTemplateTypeModal.generated";

const EXPRESSION_SOURCES: ExpressionItemSource[] = ["self"];

interface FormValues {
  includeIfExpr: VariableInputState;
  eligibleIfExpr: VariableInputState;
  templateSearchFilterExpressions: Omit<
    EditRecommendedTemplateTypeModal_RecommendedTemplateTypeConfigFragment["templateSearchFilterExpressions"],
    "__typename" | "id"
  >;
}

interface Props {
  docId: string;
  itemId: string;
  templateTypeId: string;
  recommendation: EditRecommendedTemplateTypeModal_ItemRecommendationFragment;
  recommendationConfig: EditRecommendedTemplateTypeModal_ItemRecommendationConfigFragment;
  recommendedTemplateTypeConfig: EditRecommendedTemplateTypeModal_RecommendedTemplateTypeConfigFragment;
  allowedItemTypes: ItemType[];
  handleClose(): void;
  handleComplete(
    values: ModifyItemRecommendedTemplateTypeValuesInput | null
  ): Promise<void> | void;
}

export const EditRecommendedTemplateTypeModal = ({
  docId,
  itemId,
  templateTypeId,
  recommendation,
  recommendationConfig,
  recommendedTemplateTypeConfig,
  allowedItemTypes,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useEditRecommendedTemplateTypeModalQuery({
    client,
    variables: { templateTypeId },
  });
  const templateType = query.data?.templateType;

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [isPropertyExpressionDeleteMode, setIsPropertyExpressionDeleteMode] =
    React.useState<boolean>(false);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const { expressionCheckResults, validateExpressions } =
    useExpressionValidation(docId, itemId);
  const eligibleIfExprError = expressionCheckResults?.find(
    value =>
      value.__typename ===
      "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf"
  );
  const includeIfExprError = expressionCheckResults?.find(
    value =>
      value.__typename ===
      "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf"
  );

  const initialValues: FormValues = {
    includeIfExpr: {
      expression: recommendationConfig.includeIfExpr,
      cursorIndex: null,
    },
    eligibleIfExpr: {
      expression: recommendationConfig.eligibleIfExpr,
      cursorIndex: null,
    },
    templateSearchFilterExpressions:
      recommendedTemplateTypeConfig.templateSearchFilterExpressions,
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    includeIfExpr: Yup.object().required().shape({
      expression: Yup.string(),
      cursorIndex: Yup.number().nullable(),
    }),
    eligibleIfExpr: Yup.object().required().shape({
      expression: Yup.string(),
      cursorIndex: Yup.number().nullable(),
    }),
    templateSearchFilterExpressions: Yup.mixed().required(),
  });

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        const result = await validateExpressions({
          docId,
          itemId,
          overrides: [
            {
              itemId,
              recommended: {
                eligibleIf: values.eligibleIfExpr.expression,
                includeIf: values.includeIfExpr.expression,
                recommendationId: recommendation.id,
              },
            },
          ],
        });

        const expressionCheckResult =
          result.data?.compileDocIsolatedExpression.results.filter(
            result =>
              result.__typename ===
                "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf" ||
              result.__typename ===
                "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf"
          );

        if (expressionCheckResult.length > 0) {
          console.error("compile document error", expressionCheckResult);
          expressionCheckResult.forEach(result => {
            enqueueSnackbar(`Expression is invalid: ${result.messageText}`, {
              variant: "error",
            });
          });
          return;
        }

        await handleComplete({
          includeIfExpr: values.includeIfExpr.expression,
          eligibleIfExpr: values.eligibleIfExpr.expression,
          templateSearchFilterExpressions: {
            propertyFilterExpressions:
              values.templateSearchFilterExpressions.propertyFilters.map(
                templateSearchFilterPropertyFilterExpression2Input
              ),
          },
        });
        handleClose();
      }}
    >
      {formikProps => {
        const propertySearchFiltersExpressions =
          formikProps.values.templateSearchFilterExpressions?.propertyFilters;

        const unusedProperties = differenceWith(
          templateType?.props2 ?? [],
          propertySearchFiltersExpressions,
          (p, f) => p.key === f.key
        );

        return (
          <Modal
            maxWidth="md"
            title={t("Configure template type: {templateName}", {
              ns: "QuoteItem",
              templateName: recommendation.teaserTitle,
            })}
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Cancel", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: {
                  variant: "text",
                  disabled: formikProps.isSubmitting,
                },
              },
              {
                label: t("Remove template type", { ns: "QuoteItem" }),
                handleClick: async () => {
                  if (isDeleting) return;
                  const result = await startConfirmProcess({
                    title: t("Confirm deletion", { ns: "Global" }),
                    text: t(
                      "Are you sure you would like to delete this recommended template type?",
                      {
                        ns: "QuoteItem",
                      }
                    ),
                    confirmButtonLabel: t("Delete", { ns: "Global" }),
                  });
                  if (!result) return;
                  setIsDeleting(true);
                  try {
                    await handleComplete(null);
                  } finally {
                    setIsDeleting(false);
                  }
                  handleClose();
                },
                buttonProps: {
                  variant: "outlined",
                  disabled: formikProps.isSubmitting || isDeleting,
                },
              },
              {
                label: t("Save", { ns: "Global" }),
                buttonProps: {
                  form: formId,
                  type: "submit",
                  loading: formikProps.isSubmitting,
                  disabled: !formikProps.isValid || !formikProps.dirty,
                },
              },
            ]}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={2}>
                <CollapseSection
                  title={t("Rules", { ns: "QuoteItem" })}
                  isInitiallyExpanded
                >
                  <Stack direction="column" spacing={1}>
                    <ExpressionInputWithVariables
                      label={t("Eligible if", { ns: "QuoteItem" })}
                      placeholder={t("Formula", { ns: "QuoteItem" })}
                      itemId={itemId}
                      docId={docId}
                      projectId={null}
                      expressionError={
                        eligibleIfExprError
                          ? new Error(eligibleIfExprError.messageText)
                          : null
                      }
                      inputState={formikProps.values.eligibleIfExpr}
                      onInputStateChange={expr => {
                        formikProps.setFieldValue("eligibleIfExpr", expr);
                      }}
                      autoFocus={false}
                      direction="row"
                      allowedSources={EXPRESSION_SOURCES}
                    />
                    <ExpressionInputWithVariables
                      label={t("Include if", { ns: "QuoteItem" })}
                      placeholder={t("Formula", { ns: "QuoteItem" })}
                      itemId={itemId}
                      docId={docId}
                      projectId={null}
                      expressionError={
                        includeIfExprError
                          ? new Error(includeIfExprError.messageText)
                          : null
                      }
                      inputState={formikProps.values.includeIfExpr}
                      onInputStateChange={expr => {
                        formikProps.setFieldValue("includeIfExpr", expr);
                      }}
                      autoFocus={false}
                      direction="row"
                      allowedSources={EXPRESSION_SOURCES}
                    />
                  </Stack>
                </CollapseSection>
                <CollapseSection
                  title={"Search expressions"}
                  ActionButtons={
                    <>
                      <MenuButton
                        Icon={<AddIcon />}
                        buttonProps={{
                          disabled: unusedProperties.length === 0,
                        }}
                      >
                        {unusedProperties.map(prop => (
                          <MenuItem
                            key={prop.key}
                            onClick={() =>
                              formikProps.setValues(values => ({
                                ...values,
                                templateSearchFilterExpressions: {
                                  propertyFilters:
                                    values.templateSearchFilterExpressions.propertyFilters.concat(
                                      prop.__typename === "Props2Bool"
                                        ? {
                                            __typename:
                                              "EntitySearchPropertyFilterBoolFilterComputed",
                                            key: prop.key,
                                            expr: "",
                                            missingValue: true,
                                            operatorBool: "eq",
                                            valueBoolComputed: null,
                                          }
                                        : prop.__typename === "Props2Number"
                                          ? {
                                              __typename:
                                                "EntitySearchPropertyFilterNumberFilterComputed",
                                              key: prop.key,
                                              expr: "",
                                              missingValue: true,
                                              operatorNumber: "eq",
                                              valueNumberComputed: null,
                                            }
                                          : prop.__typename ===
                                              "Props2NumberArray"
                                            ? {
                                                __typename:
                                                  "EntitySearchPropertyFilterNumberArrayOfFilterComputed",
                                                key: prop.key,
                                                expr: "",
                                                missingValue: true,
                                                operatorNumberArrayOf: "allOf",
                                                valueNumberArrayOfComputed:
                                                  null,
                                              }
                                            : prop.__typename === "Props2Text"
                                              ? {
                                                  __typename:
                                                    "EntitySearchPropertyFilterTextFilterComputed",
                                                  key: prop.key,
                                                  expr: "",
                                                  missingValue: true,
                                                  operatorText: "eq",
                                                  valueTextComputed: null,
                                                }
                                              : prop.__typename ===
                                                  "Props2TextArray"
                                                ? {
                                                    __typename:
                                                      "EntitySearchPropertyFilterTextArrayOfFilterComputed",
                                                    key: prop.key,
                                                    expr: "",
                                                    missingValue: true,
                                                    operatorTextArrayOf:
                                                      "allOf",
                                                    valueTextArrayOfComputed:
                                                      null,
                                                  }
                                                : assertNever(prop)
                                    ),
                                },
                              }))
                            }
                          >
                            {prop.key}
                          </MenuItem>
                        ))}
                      </MenuButton>
                      <MenuButton>
                        <MenuItem
                          onClick={() =>
                            setIsPropertyExpressionDeleteMode(value => !value)
                          }
                        >
                          {t("Delete mode", {
                            ns: "Global",
                          })}
                        </MenuItem>
                      </MenuButton>
                    </>
                  }
                >
                  <Grid container columns={2} rowSpacing={1} columnSpacing={2}>
                    {propertySearchFiltersExpressions.map(
                      (propertySearchFilter, index) => {
                        switch (propertySearchFilter.__typename) {
                          case "EntitySearchPropertyFilterBoolFilterComputed":
                          case "EntitySearchPropertyFilterBoolInFilterComputed": {
                            return (
                              <Grid key={propertySearchFilter.key} item xs={1}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <ModalOpenButton
                                    Modal={
                                      ProductSearchFilterExpressionModal<
                                        | EntitySearchBoolFilterOperator
                                        | EntitySearchBoolInFilterOperator
                                      >
                                    }
                                    modalProps={{
                                      projectId: null,
                                      docId: docId,
                                      itemId: itemId,
                                      filterKey: propertySearchFilter.key,
                                      operator:
                                        propertySearchFilter.__typename ===
                                        "EntitySearchPropertyFilterBoolInFilterComputed"
                                          ? propertySearchFilter.operatorBoolIn
                                          : propertySearchFilter.operatorBool,
                                      allowedSearchFilterOperators:
                                        BoolFilterOperatorOptions,
                                      expression: propertySearchFilter.expr,
                                      handleComplete: (value, handleClose) => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters
                                                .filter(
                                                  v =>
                                                    v.key !==
                                                    propertySearchFilter.key
                                                )
                                                .concat(
                                                  value.operator === "in"
                                                    ? {
                                                        __typename:
                                                          "EntitySearchPropertyFilterBoolInFilterComputed",
                                                        key: propertySearchFilter.key,
                                                        operatorBoolIn:
                                                          value.operator,
                                                        expr: value.expr,
                                                        missingValue: true,
                                                        valueBoolInComputed:
                                                          null,
                                                      }
                                                    : value.operator === "eq"
                                                      ? {
                                                          __typename:
                                                            "EntitySearchPropertyFilterBoolFilterComputed",
                                                          operatorBool:
                                                            value.operator,
                                                          expr: value.expr,
                                                          key: propertySearchFilter.key,
                                                          missingValue: true,
                                                          valueBoolComputed:
                                                            null,
                                                        }
                                                      : assertNever(
                                                          value.operator
                                                        )
                                                ),
                                          },
                                        }));
                                        handleClose();
                                      },
                                    }}
                                    disabled={false}
                                  >
                                    <LabeledValueWithButton
                                      label={propertySearchFilter.key}
                                      labelIcon={FunctionsIcon}
                                    >
                                      {getValueWithOperator(
                                        propertySearchFilter
                                      )}
                                    </LabeledValueWithButton>
                                  </ModalOpenButton>
                                  {isPropertyExpressionDeleteMode && (
                                    <IconButton
                                      onClick={() => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters.filter(
                                                v =>
                                                  v.key !==
                                                  propertySearchFilter.key
                                              ),
                                          },
                                        }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Grid>
                            );
                          }
                          case "EntitySearchPropertyFilterNumberFilterComputed":
                          case "EntitySearchPropertyFilterNumberInFilterComputed":
                          case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                            return (
                              <Grid key={propertySearchFilter.key} item xs={1}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <ModalOpenButton
                                    Modal={
                                      ProductSearchFilterExpressionModal<
                                        | EntitySearchNumberFilterOperator
                                        | EntitySearchNumberInFilterOperator
                                        | EntitySearchNumberBetweenFilterOperator
                                      >
                                    }
                                    modalProps={{
                                      projectId: null,
                                      docId: docId,
                                      itemId: itemId,
                                      filterKey: propertySearchFilter.key,
                                      operator:
                                        propertySearchFilter.__typename ===
                                        "EntitySearchPropertyFilterNumberFilterComputed"
                                          ? propertySearchFilter.operatorNumber
                                          : propertySearchFilter.__typename ===
                                              "EntitySearchPropertyFilterNumberInFilterComputed"
                                            ? propertySearchFilter.operatorNumberIn
                                            : propertySearchFilter.operatorNumberBetween,
                                      allowedSearchFilterOperators:
                                        NumberFilterOperatorOptions,
                                      expression: propertySearchFilter.expr,
                                      handleComplete: (value, handleClose) => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters
                                                .filter(
                                                  v =>
                                                    v.key !==
                                                    propertySearchFilter.key
                                                )
                                                .concat(
                                                  value.operator === "in"
                                                    ? {
                                                        __typename:
                                                          "EntitySearchPropertyFilterNumberInFilterComputed",
                                                        key: propertySearchFilter.key,
                                                        operatorNumberIn:
                                                          value.operator,
                                                        expr: value.expr,
                                                        missingValue: true,
                                                        valueNumberInComputed:
                                                          null,
                                                      }
                                                    : value.operator ===
                                                        "between"
                                                      ? {
                                                          __typename:
                                                            "EntitySearchPropertyFilterNumberBetweenFilterComputed",
                                                          key: propertySearchFilter.key,
                                                          operatorNumberBetween:
                                                            value.operator,
                                                          expr: value.expr,
                                                          missingValue: true,
                                                          valueNumberBetweenComputed:
                                                            null,
                                                        }
                                                      : value.operator ===
                                                            "eq" ||
                                                          value.operator ===
                                                            "lt" ||
                                                          value.operator ===
                                                            "lte" ||
                                                          value.operator ===
                                                            "gt" ||
                                                          value.operator ===
                                                            "gte"
                                                        ? {
                                                            __typename:
                                                              "EntitySearchPropertyFilterNumberFilterComputed",
                                                            key: propertySearchFilter.key,
                                                            operatorNumber:
                                                              value.operator,
                                                            expr: value.expr,
                                                            missingValue: true,
                                                            valueNumberComputed:
                                                              null,
                                                          }
                                                        : assertNever(
                                                            value.operator
                                                          )
                                                ),
                                          },
                                        }));

                                        handleClose();
                                      },
                                    }}
                                    disabled={false}
                                  >
                                    <LabeledValueWithButton
                                      label={propertySearchFilter.key}
                                      labelIcon={FunctionsIcon}
                                    >
                                      {getValueWithOperator(
                                        propertySearchFilter
                                      )}
                                    </LabeledValueWithButton>
                                  </ModalOpenButton>
                                  {isPropertyExpressionDeleteMode && (
                                    <IconButton
                                      onClick={() => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters.filter(
                                                v =>
                                                  v.key !==
                                                  propertySearchFilter.key
                                              ),
                                          },
                                        }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Grid>
                            );
                          }
                          case "EntitySearchPropertyFilterTextFilterComputed":
                          case "EntitySearchPropertyFilterTextInFilterComputed": {
                            return (
                              <Grid key={propertySearchFilter.key} item xs={1}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <ModalOpenButton
                                    Modal={
                                      ProductSearchFilterExpressionModal<
                                        | EntitySearchTextFilterOperator
                                        | EntitySearchTextInFilterOperator
                                      >
                                    }
                                    modalProps={{
                                      projectId: null,
                                      docId: docId,
                                      itemId: itemId,
                                      filterKey: propertySearchFilter.key,
                                      operator:
                                        propertySearchFilter.__typename ===
                                        "EntitySearchPropertyFilterTextFilterComputed"
                                          ? propertySearchFilter.operatorText
                                          : propertySearchFilter.operatorTextIn,
                                      allowedSearchFilterOperators:
                                        TextFilterOperatorOptions,
                                      expression: propertySearchFilter.expr,
                                      handleComplete: (value, handleClose) => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters
                                                .filter(
                                                  v =>
                                                    v.key !==
                                                    propertySearchFilter.key
                                                )
                                                .concat(
                                                  value.operator === "in"
                                                    ? {
                                                        __typename:
                                                          "EntitySearchPropertyFilterTextInFilterComputed",
                                                        key: propertySearchFilter.key,
                                                        operatorTextIn:
                                                          value.operator,
                                                        expr: value.expr,
                                                        missingValue: true,
                                                        valueTextInComputed:
                                                          null,
                                                      }
                                                    : value.operator === "eq"
                                                      ? {
                                                          __typename:
                                                            "EntitySearchPropertyFilterTextFilterComputed",
                                                          key: propertySearchFilter.key,
                                                          operatorText:
                                                            value.operator,
                                                          expr: value.expr,
                                                          missingValue: true,
                                                          valueTextComputed:
                                                            null,
                                                        }
                                                      : assertNever(
                                                          value.operator
                                                        )
                                                ),
                                          },
                                        }));

                                        handleClose();
                                      },
                                    }}
                                    disabled={false}
                                  >
                                    <LabeledValueWithButton
                                      label={propertySearchFilter.key}
                                      labelIcon={FunctionsIcon}
                                    >
                                      {getValueWithOperator(
                                        propertySearchFilter
                                      )}
                                    </LabeledValueWithButton>
                                  </ModalOpenButton>
                                  {isPropertyExpressionDeleteMode && (
                                    <IconButton
                                      onClick={() => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters.filter(
                                                v =>
                                                  v.key !==
                                                  propertySearchFilter.key
                                              ),
                                          },
                                        }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Grid>
                            );
                          }
                          case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
                            return (
                              <Grid key={propertySearchFilter.key} item xs={1}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <ModalOpenButton
                                    Modal={
                                      ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                    }
                                    modalProps={{
                                      projectId: null,
                                      docId: docId,
                                      itemId: itemId,
                                      filterKey: propertySearchFilter.key,
                                      operator:
                                        propertySearchFilter.operatorNumberArrayOf,
                                      allowedSearchFilterOperators:
                                        ArrayFilterOperatorOptions,
                                      expression: propertySearchFilter.expr,
                                      handleComplete: (value, handleClose) => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters
                                                .filter(
                                                  v =>
                                                    v.key !==
                                                    propertySearchFilter.key
                                                )
                                                .concat(
                                                  value.operator === "allOf" ||
                                                    value.operator === "anyOf"
                                                    ? {
                                                        __typename:
                                                          "EntitySearchPropertyFilterNumberArrayOfFilterComputed",
                                                        key: propertySearchFilter.key,
                                                        operatorNumberArrayOf:
                                                          value.operator,
                                                        expr: value.expr,
                                                        missingValue: true,
                                                        valueNumberArrayOfComputed:
                                                          null,
                                                      }
                                                    : assertNever(
                                                        value.operator
                                                      )
                                                ),
                                          },
                                        }));

                                        handleClose();
                                      },
                                    }}
                                    disabled={false}
                                  >
                                    <LabeledValueWithButton
                                      label={propertySearchFilter.key}
                                      labelIcon={FunctionsIcon}
                                    >
                                      {getValueWithOperator(
                                        propertySearchFilter
                                      )}
                                    </LabeledValueWithButton>
                                  </ModalOpenButton>
                                  {isPropertyExpressionDeleteMode && (
                                    <IconButton
                                      onClick={() => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters.filter(
                                                v =>
                                                  v.key !==
                                                  propertySearchFilter.key
                                              ),
                                          },
                                        }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Grid>
                            );
                          }
                          case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
                            return (
                              <Grid key={propertySearchFilter.key} item xs={1}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <ModalOpenButton
                                    Modal={
                                      ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                    }
                                    modalProps={{
                                      projectId: null,
                                      docId: docId,
                                      itemId: itemId,
                                      filterKey: propertySearchFilter.key,
                                      operator:
                                        propertySearchFilter.operatorTextArrayOf,
                                      allowedSearchFilterOperators:
                                        ArrayFilterOperatorOptions,
                                      expression: propertySearchFilter.expr,
                                      handleComplete: (value, handleClose) => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters
                                                .filter(
                                                  v =>
                                                    v.key !==
                                                    propertySearchFilter.key
                                                )
                                                .concat(
                                                  value.operator === "allOf" ||
                                                    value.operator === "anyOf"
                                                    ? {
                                                        __typename:
                                                          "EntitySearchPropertyFilterTextArrayOfFilterComputed",
                                                        key: propertySearchFilter.key,
                                                        operatorTextArrayOf:
                                                          value.operator,
                                                        expr: value.expr,
                                                        missingValue: true,
                                                        valueTextArrayOfComputed:
                                                          null,
                                                      }
                                                    : assertNever(
                                                        value.operator
                                                      )
                                                ),
                                          },
                                        }));

                                        handleClose();
                                      },
                                    }}
                                    disabled={false}
                                  >
                                    <LabeledValueWithButton
                                      label={propertySearchFilter.key}
                                      labelIcon={FunctionsIcon}
                                    >
                                      {getValueWithOperator(
                                        propertySearchFilter
                                      )}
                                    </LabeledValueWithButton>
                                  </ModalOpenButton>
                                  {isPropertyExpressionDeleteMode && (
                                    <IconButton
                                      onClick={() => {
                                        formikProps.setValues(values => ({
                                          ...values,
                                          templateSearchFilterExpressions: {
                                            propertyFilters:
                                              values.templateSearchFilterExpressions.propertyFilters.filter(
                                                v =>
                                                  v.key !==
                                                  propertySearchFilter.key
                                              ),
                                          },
                                        }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Grid>
                            );
                          }
                          default:
                            assertNever(propertySearchFilter);
                        }
                      }
                    )}
                  </Grid>
                </CollapseSection>
              </Stack>
            </Form>
            <ConfirmProcess ref={confirmProcessRef} />
          </Modal>
        );
      }}
    </Formik>
  );
};
