import { gql, useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { assign } from "lodash";
import React from "react";
import {
  DefineItemTemplateSearchFilterExpressionsInput,
  ItemTemplateSearchFilterPropertyFilterComputed,
  TemplateSearchFilterPropertyFilterExpressionInput,
} from "../../../clients/graphqlTypes";
import { ItemTemplateSearchFilterPropertyFilterComputedFragment } from "../templates/templateSearchFilters.generated";
import { useTemplateSearchFiltersSection_DefineTemplateSearchFilterExpressionsMutation } from "./useTemplateSearchFilterExpressionMutations.generated";

gql`
  mutation TemplateSearchFiltersSection_DefineTemplateSearchFilterExpressions(
    $input: DefineItemTemplateSearchFilterExpressionsInput!
  ) {
    defineItemTemplateSearchFilterExpressions(input: $input) {
      doc {
        ... on WithDocumentInfo {
          id
        }
        ... on QuoteTemplate {
          resolvedAsReadModelVersionNumber
        }
        ...DocumentStatus_Document
        ...Document_Calculations
      }
      item {
        id
        originVersionNumber
        ...ProductBox_ItemProduct
      }
    }
  }
`;

type AllowedTemplateSearchFilterExpressionKey =
  keyof DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"];

type TemplateSearchFilterExpressionsValue =
  DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"][AllowedTemplateSearchFilterExpressionKey];
export type HandleSetTemplateSearchFilterExpression = (
  key: AllowedTemplateSearchFilterExpressionKey,
  value: TemplateSearchFilterExpressionsValue
) => Promise<void>;
export type HandleSetTemplateSearchFilterPropertyExpression = (
  propertyKey: string,
  value: DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"]["propertyFilterExpressions"][number]
) => Promise<void>;

export function useTemplateSearchFilterExpressionMutations({
  projectId,
  docId,
  itemId,
  propertySearchFiltersExpressions,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  propertySearchFiltersExpressions: ItemTemplateSearchFilterPropertyFilterComputed[];
  refetchQueries?: string[];
}) {
  const client = useApolloClient();
  const [defineItemTemplateSearchFilterExpressions] =
    useTemplateSearchFiltersSection_DefineTemplateSearchFilterExpressionsMutation(
      {
        client,
        refetchQueries,
      }
    );

  const handleSetTemplateSearchFilterExpression = React.useCallback(
    async (
      key: AllowedTemplateSearchFilterExpressionKey,
      value: TemplateSearchFilterExpressionsValue
    ) => {
      const existingExpressions: Pick<
        DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"],
        AllowedTemplateSearchFilterExpressionKey
      > = {
        propertyFilterExpressions: propertySearchFiltersExpressions.map(
          templateSearchFilterPropertyFilterExpression2Input
        ),
      };

      await defineItemTemplateSearchFilterExpressions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            templateSearchFilterExpressions: {
              ...assign(existingExpressions, { [key]: value }),
            },
          },
        },
      });
    },
    [
      defineItemTemplateSearchFilterExpressions,
      docId,
      itemId,
      projectId,
      propertySearchFiltersExpressions,
    ]
  );
  const handleSetTemplateSearchFilterPropertyExpression = (
    propertyKey: string,
    value: DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"]["propertyFilterExpressions"][number]
  ) => {
    return handleSetTemplateSearchFilterExpression(
      "propertyFilterExpressions",
      [
        ...propertySearchFiltersExpressions
          .filter(filter => {
            return filter.key !== propertyKey;
          })
          .map(templateSearchFilterPropertyFilterExpression2Input),
        value,
      ]
    );
  };
  const handleDeleteTemplateSearchFilterExpression = async (
    key: Exclude<
      AllowedTemplateSearchFilterExpressionKey,
      "templateSearchFilterPropertyFilterExpressions"
    >
  ) => {
    return handleSetTemplateSearchFilterExpression(key, []);
  };
  const handleDeleteTemplateSearchFilterPropertyExpression = async (
    propertyKey: string
  ) => {
    return handleSetTemplateSearchFilterExpression(
      "propertyFilterExpressions",
      propertySearchFiltersExpressions
        .filter(filter => filter.key !== propertyKey)
        .map(templateSearchFilterPropertyFilterExpression2Input)
    );
  };

  return {
    handleSetTemplateSearchFilterExpression,
    handleSetTemplateSearchFilterPropertyExpression,
    handleDeleteTemplateSearchFilterExpression,
    handleDeleteTemplateSearchFilterPropertyExpression,
    defineItemTemplateSearchFilterExpressions,
  };
}

export function templateSearchFilterPropertyFilterExpression2Input(
  filter: ItemTemplateSearchFilterPropertyFilterComputedFragment
): TemplateSearchFilterPropertyFilterExpressionInput {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      return {
        boolInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBoolIn: filter.operatorBoolIn,
        },
      };
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      return {
        boolFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBool: filter.operatorBool,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      return {
        numberInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberIn: filter.operatorNumberIn,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      return {
        numberBetweenFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberBetween: filter.operatorNumberBetween,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      return {
        numberArrayOfFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberArrayOf: filter.operatorNumberArrayOf,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      return {
        numberFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumber: filter.operatorNumber,
        },
      };
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      return {
        textInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorTextIn: filter.operatorTextIn,
        },
      };
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      return {
        textFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorText: filter.operatorText,
        },
      };
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      return {
        textArrayOfFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorTextArrayOf: filter.operatorTextArrayOf,
        },
      };
    }
    default: {
      assertNever(filter);
    }
  }
}
