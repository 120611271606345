import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CollapseSection,
  LoadingSpinner as LoadingIndicator,
  Modal,
} from "@msys/ui";
import {
  Box,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffectOnce } from "react-use";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { AutocompleteField } from "../../../commons/form-fields/AutocompleteField";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { ContractTypeField } from "../../../commons/form-fields/ContractTypeField";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField";
import {
  AskWhom,
  ContractType,
  Meister1Flow,
  VatRateType,
} from "../../../../clients/graphqlTypes";
import {
  VatField,
  VatFieldFormValues,
  getVatValues,
  useVatFieldValidationSchema,
} from "../../../vat/form-fields/VatField";
import { useDecisionProcess } from "../../doc-items/hooks/useDecisionProcess";
import { DecisionWizardModalWithDefaultActions } from "../../doc-items/modals/DecisionWizardModal";
import {
  Meister1Process,
  Meister1ProcessRef,
} from "../../m1-calculators/Meister1Process";
import {
  M1Calculator,
  useM1Calculators,
} from "../../m1-calculators/useM1Calculators";
import { useM1Integration } from "../../m1-calculators/useM1Integration";
import { TemplateQuoteSelectField } from "../../templates/quote/TemplateQuoteSelectField";
import {
  Meister1PollForQuoteDocOfLeadIdDocument,
  Meister1PollForQuoteDocOfLeadIdQuery,
  Meister1PollForQuoteDocOfLeadIdQueryVariables,
  useCreateQuoteMutation,
  useQuoteCreateModalQuery,
  useQuoteCreateModal_RequirementQuery,
} from "./QuoteCreateModal.generated";

const DELIMITER = "///";

enum CreateQuoteSource {
  Empty = "empty",
  Template = "template",
  M1 = "m1",
}

interface FormValues extends VatFieldFormValues {
  title: string;
  template: {
    id: string;
    title: string;
    owningSystemOrganisationId: string;
  } | null;
  integration: Meister1Flow | null;
  request: {
    id: string;
    title: string;
  } | null;
  contractType: ContractType;
  discountPercentage?: number;
  importRequirement: boolean;
}

interface Props {
  projectId: string;
  requestId?: string;
  requirementDocId?: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (
    quoteId: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
  refetchQueries?: string[];
}

export const QuoteCreateModal = ({
  requestId,
  projectId,
  requirementDocId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["QuoteCreate", "Global"]);
  const { hasMeister1Integration, meister1Flows, meister1Loading } =
    useM1Integration();
  const { calculators, fallback } = useM1Calculators();

  const meister1ProcessRef = React.useRef<Meister1ProcessRef>(null);
  const [quoteId, setQuoteId] = React.useState<string | null>(null);

  const client = useApolloClient();
  const query = useQuoteCreateModalQuery({
    client,
    variables: {
      projectId: projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  const templateCount = query.data?.quoteTemplates.totalCount ?? 0;
  const hasTemplateOptions = templateCount > 0;
  const initialTemplate =
    templateCount === 1 && query.data?.quoteTemplates?.edges[0]?.node
      ? query.data.quoteTemplates.edges[0].node
      : null;

  const requirementQuery = useQuoteCreateModal_RequirementQuery({
    client,
    variables: {
      projectId,
      requirementId: requirementDocId!,
    },
    skip: !requirementDocId,
  });
  const requirement = getDataOrNull(
    requirementQuery.data?.requirement
  )?.requirement;

  // const [meister1ImportMultiBrandLead, { loading: m1Loading }] =
  //   useMeister1ImportMultiBrandLeadMutation({ client, refetchQueries });

  // TODO: use dedicated mutation for creating quote for opportunity?
  const [createQuote] = useCreateQuoteMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const integrationOptions = React.useMemo(() => {
    meister1Flows.forEach(flow => {
      // filter out flow for calculators not defined on frontend. log error
      if (!calculators[flow.label as M1Calculator]) {
        // eslint-disable-next-line no-console
        console.error(`Calculator definition missing for ${flow.label}`);
      }
    });

    return meister1Flows.map(flow => ({
      ...flow,
      // create a unique ID to not duplicate options
      id: [flow.id, flow.identName, flow.label].join(DELIMITER),
    }));
  }, [meister1Flows, calculators]);

  const requestOptions = React.useMemo(
    () =>
      project?.incomingQuoteRequests.filter(r => r.status === "PUBLISHED") ??
      [],
    [project?.incomingQuoteRequests]
  );

  const hasMeister1Options =
    hasMeister1Integration && integrationOptions.length > 0;
  const isOnlyEmptyOptionAvailable = !(
    hasMeister1Options || hasTemplateOptions
  );

  const [source, setSource] = React.useState<CreateQuoteSource>(
    CreateQuoteSource.Empty
  );
  React.useEffect(() => {
    setSource(
      hasMeister1Options
        ? CreateQuoteSource.M1
        : hasTemplateOptions
          ? CreateQuoteSource.Template
          : CreateQuoteSource.Empty
    );
  }, [hasMeister1Options, hasTemplateOptions]);

  const canImportRequirement = Boolean(
    requestId || requirementDocId || requestOptions.length > 0
  );

  const handleSubmit = async (values: FormValues) => {
    try {
      if (source === CreateQuoteSource.M1) {
        if (!values.integration) return;
        meister1ProcessRef.current?.startProcess({
          identName: values.integration.identName,
          completeBeforeConfirmPage: true,
          meister1State: {
            type: "existing-project",
            projectId,
            requestId:
              requestId ??
              (requestOptions.length === 1
                ? requestOptions[0].id ?? null
                : values.request?.id ?? null),
            userId: viewer.id,
          },
          inputs: project?.building?.buildingAddress?.postalCode
            ? {
                zip: project?.building?.buildingAddress?.postalCode,
              }
            : undefined,
          onComplete: async leadId => {
            let quoteId;

            while (true) {
              await new Promise(resolve =>
                setTimeout(() => resolve(null), 2000)
              );

              const res = await client.query<
                Meister1PollForQuoteDocOfLeadIdQuery,
                Meister1PollForQuoteDocOfLeadIdQueryVariables
              >({
                query: Meister1PollForQuoteDocOfLeadIdDocument,
                variables: {
                  input: {
                    m1LeadId: leadId,
                    projectId,
                  },
                },
              });

              if (
                res.data &&
                res.data.meister1PollForQuoteDocOfLeadId &&
                res.data.meister1PollForQuoteDocOfLeadId.length > 0
              ) {
                if (res.data.meister1PollForQuoteDocOfLeadId.length === 1) {
                  quoteId = res.data.meister1PollForQuoteDocOfLeadId[0];
                }
                break;
              }
            }

            if (refetchQueries && refetchQueries.length > 0) {
              await client.refetchQueries({
                include: refetchQueries,
              });
            }

            if (handleComplete) {
              await handleComplete(quoteId, handleClose);
            } else {
              handleClose();
            }

            enqueueSnackbar(t("Quotes created", { ns: "QuoteCreate" }));
          },
        });
        return;
      }

      let title = values.title;

      if (source === CreateQuoteSource.Template) {
        if (!requirement?.title && values.template?.title)
          title = `${values.template.title} - ${t("Quote", {
            ns: "QuoteCreate",
          })}`;
      }

      const newQuote = await createQuote({
        variables: {
          input: {
            projectId: projectId,
            title,
            discountPercentage: values.discountPercentage || 0,
            contractType: values.contractType,
            quoteForRequirementId: requirementDocId,
            templateQuoteId:
              source === CreateQuoteSource.Template
                ? values.template?.id ?? undefined
                : undefined,
            // applyTemplate: true,
            importRequirement:
              source === CreateQuoteSource.Empty &&
              canImportRequirement &&
              values.importRequirement,
            requestId:
              requestId ??
              (requestOptions.length === 1
                ? requestOptions[0].id ?? null
                : values.request?.id ?? null),
            vatRate: values.vatRate,
            vatInfo: {
              countryCode: values.vatInfo.countryCode,
              rateType: values.vatInfo.rateType as VatRateType,
            },
          },
        },
      });

      const docId = newQuote.data?.createQuote.quote.id;

      if (!docId) {
        enqueueSnackbar(
          t("Error while creating quote", {
            ns: "QuoteCreate",
          })
        );
        return;
      }

      enqueueSnackbar(
        t("Quote created", {
          ns: "QuoteCreate",
        })
      );

      setQuoteId(docId);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "Global",
        })
      )
      .required(),
    importRequirement: Yup.boolean(),
    discountPercentage: Yup.number()
      .label(
        t("Discount", {
          ns: "QuoteCreate",
        })
      )
      .min(0)
      .max(1),
    template: Yup.object()
      .shape({
        id: Yup.string().required(),
        title: Yup.string().required(),
      })
      .test(
        "templateRequired",
        t("Template must be selected", {
          ns: "QuoteCreate",
        }),
        function (template) {
          return !(source === CreateQuoteSource.Template && !template);
        }
      )
      .nullable(),
    integration: Yup.object()
      .test(
        "integrationRequired",
        t("Calculator must be selected", {
          ns: "QuoteCreate",
        }),
        function (integration) {
          return !(source === CreateQuoteSource.M1 && !integration);
        }
      )
      .nullable(),
    ...(!requestId && requestOptions.length > 1
      ? {
          request: Yup.object()
            .shape({
              id: Yup.string().required(),
              title: Yup.string().required(),
            })
            .required(
              t("Request must be selected", {
                ns: "QuoteCreate",
              })
            )
            .nullable(),
        }
      : {}),
    ...useVatFieldValidationSchema(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: `${requirement?.title ?? project?.title} - ${t("Quote", {
        ns: "QuoteCreate",
      })}`,
      discountPercentage: 0,
      contractType: organisationDefaults?.defaultContractType ?? "fup",
      template: initialTemplate,
      integration:
        integrationOptions.length === 1 ? integrationOptions[0] : null,
      request: null,
      importRequirement: canImportRequirement,
      ...getVatValues(viewer.organisation.billingAddress?.countryCode),
    }),
    [
      t,
      requirement?.title,
      project?.title,
      viewer.organisation.billingAddress?.countryCode,
      organisationDefaults?.defaultContractType,
      initialTemplate,
      integrationOptions,
      canImportRequirement,
    ]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  const isAnyLoading = query.loading || meister1Loading;

  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  React.useEffect(() => {
    formikRef.current?.validateForm();
  }, [source]);

  return (
    <Formik<FormValues>
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
    >
      {formikProps => (
        <Modal
          id={"quote-create-modal"}
          title={
            title ??
            (project
              ? t("Create quote for {recipient}", {
                  ns: "QuoteCreate",
                  recipient: project?.crmOrganisation?.title ?? "",
                })
              : undefined)
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create Quote", {
                ns: "QuoteCreate",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid || isAnyLoading,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={isAnyLoading}
        >
          {isAnyLoading ? (
            <Stack direction="column" alignItems="center" spacing={1}>
              <LoadingIndicator />
              <Typography
                variant="body1"
                color="primary"
                align="center"
                sx={{ pb: 6 }}
              >
                {t("Quotes are being created, please wait...", {
                  ns: "QuoteCreate",
                })}
              </Typography>
            </Stack>
          ) : (
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                {!requestId && requestOptions.length > 1 && (
                  <AutocompleteField
                    name="request"
                    inputLabel={t("Quote request", {
                      ns: "QuoteCreate",
                    })}
                    options={requestOptions}
                    getOptionLabel={option => option.title}
                    noOptionsText={t("No existing entry", {
                      ns: "Global",
                    })}
                  />
                )}
                <Stack direction="column" spacing={1}>
                  {hasMeister1Integration && integrationOptions.length > 0 && (
                    <>
                      <FormControlLabel
                        style={{ marginLeft: 0, marginRight: 0 }}
                        name="source"
                        control={
                          <Radio
                            name="source"
                            value={CreateQuoteSource.M1}
                            checked={source === CreateQuoteSource.M1}
                            onChange={() => setSource(CreateQuoteSource.M1)}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {t("Create a quote with Meister1 integration", {
                              ns: "QuoteCreate",
                            })}
                          </Typography>
                        }
                      />
                      {source === CreateQuoteSource.M1 && (
                        <Box pl="42px">
                          <AutocompleteField
                            name="integration"
                            inputLabel={t("Meister1 calculator", {
                              ns: "QuoteCreate",
                            })}
                            options={integrationOptions}
                            getOptionLabel={option =>
                              calculators[option.label as M1Calculator]
                                ?.displayName ??
                              fallback(option.label).displayName
                            }
                            renderOption={(props, option) => (
                              <ListItem {...props}>
                                <ListItemIcon>
                                  {calculators[option.label as M1Calculator]
                                    ?.icon ?? fallback(option.label).icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    calculators[option.label as M1Calculator]
                                      ?.displayName ??
                                    fallback(option.label).displayName
                                  }
                                />
                              </ListItem>
                            )}
                            noOptionsText={t("No existing entry", {
                              ns: "Global",
                            })}
                          />
                        </Box>
                      )}
                      <Meister1Process ref={meister1ProcessRef} />
                    </>
                  )}
                  {hasTemplateOptions && (
                    <>
                      <FormControlLabel
                        style={{ marginLeft: 0, marginRight: 0 }}
                        name="source"
                        control={
                          <Radio
                            name="source"
                            value={CreateQuoteSource.Template}
                            checked={source === CreateQuoteSource.Template}
                            onChange={() =>
                              setSource(CreateQuoteSource.Template)
                            }
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {t("Use a quote template", {
                              ns: "QuoteCreate",
                            })}
                          </Typography>
                        }
                      />

                      {source === CreateQuoteSource.Template && (
                        <Box paddingLeft="42px">
                          <TemplateQuoteSelectField
                            inputLabel={t("Quote Template", {
                              ns: "QuoteCreate",
                            })}
                            filterDocumentType="QUOTE"
                            value={formikProps.values.template}
                            onChange={value => {
                              formikProps.setFieldValue("template", value);
                            }}
                          />
                        </Box>
                      )}
                    </>
                  )}

                  {!isOnlyEmptyOptionAvailable && (
                    <FormControlLabel
                      style={{ marginLeft: 0, marginRight: 0 }}
                      name="source"
                      control={
                        <Radio
                          name="source"
                          value={CreateQuoteSource.Empty}
                          checked={source === CreateQuoteSource.Empty}
                          onChange={() => setSource(CreateQuoteSource.Empty)}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {t("Start with an empty quote", {
                            ns: "QuoteCreate",
                          })}
                        </Typography>
                      }
                    />
                  )}
                  {source === CreateQuoteSource.Empty && (
                    <Stack
                      direction="column"
                      spacing={1}
                      paddingLeft={!isOnlyEmptyOptionAvailable ? "42px" : 0}
                    >
                      <Field
                        autoFocus
                        label={t("Title", {
                          ns: "Global",
                        })}
                        name="title"
                        component={TextField}
                        required
                      />
                      {canImportRequirement && (
                        <CheckboxField
                          label={t("Import client’s requirements", {
                            ns: "QuoteCreate",
                          })}
                          name="importRequirement"
                        />
                      )}
                    </Stack>
                  )}
                </Stack>

                {source === CreateQuoteSource.Empty ||
                (source === CreateQuoteSource.Template &&
                  formikProps.values.template &&
                  formikProps.values.template.owningSystemOrganisationId !==
                    viewer.organisation.id) ? (
                  <CollapseSection
                    title={t("Further Options", {
                      ns: "QuoteCreate",
                    })}
                    isInitiallyExpanded={false}
                  >
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <ContractTypeField
                          name="contractType"
                          label={t("Contract Type", {
                            ns: "QuoteCreate",
                          })}
                          required
                        />
                        <FormattedPercentageField
                          label={t("Discount", {
                            ns: "QuoteCreate",
                          })}
                          name="discountPercentage"
                          min={0}
                          max={100}
                        />
                      </Stack>
                      <VatField />
                    </Stack>
                  </CollapseSection>
                ) : null}
              </Stack>
            </Form>
          )}
          {quoteId && (
            <QuoteCreateWizard
              projectId={projectId}
              quoteId={quoteId}
              onClose={handleClose}
              onComplete={handleComplete}
            />
          )}
        </Modal>
      )}
    </Formik>
  );
};

const VIEWER_DECISION_ROLE: AskWhom = "contractor";
const EMPTY_ARRAY: any[] = [];

function QuoteCreateWizard({
  projectId,
  quoteId,
  onClose,
  onComplete,
}: {
  projectId: string;
  quoteId: string;
  onClose: () => void;
  onComplete?: (
    quoteId: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
}) {
  const handleClose = React.useCallback(async () => {
    if (onComplete) {
      await onComplete(quoteId, onClose);
    } else {
      onClose();
    }
  }, [onClose, onComplete, quoteId]);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: quoteId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole: VIEWER_DECISION_ROLE,
    decisionContext: "onQuoteCreate",
    onClose: handleClose,
  });

  useEffectOnce(() => {
    (async () => {
      const startProcess = decisionProcess.start;
      const hasDecisions = await startProcess();
      if (!hasDecisions) {
        if (onComplete) {
          await onComplete(quoteId, onClose);
        } else {
          onClose();
        }
      }
    })();
  });

  return (
    <DecisionWizardModalWithDefaultActions
      processState={decisionProcess.state}
      projectId={projectId}
      docType="QUOTE"
      docId={quoteId}
      viewerDecisionRole={VIEWER_DECISION_ROLE}
      decisionContext={decisionProcess.decisionContext}
      expandedItemIds={EMPTY_ARRAY}
      handleClose={decisionProcess.close}
      handleNextItem={decisionProcess.next}
      handlePreviousItem={decisionProcess.previous}
    />
  );
}
