/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
export type CrmCompanyBuildingsBox__BuildingFragment = { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } };

export const CrmCompanyBuildingsBox__BuildingFragmentDoc = gql`
    fragment CrmCompanyBuildingsBox__Building on Building {
  id
  title
  buildingAddress {
    ...AddressDetails__Address
  }
  rootItem {
    id
    originVersionNumber
  }
}
    ${AddressDetails__AddressFragmentDoc}`;