import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import { DEFAULT_SORTING } from "../opportunities/OpportunitiesList";

interface BaseQueryVariables {
  searchTerm?: string | null;
  offset?: number | null;
  limit: number;
}

export const useTemplateTypesList = <
  QueryVariables extends BaseQueryVariables
>({
  activeViewStorageKey,
  activeViewInitialDesktopValue,
  activeViewInitialMobileValue,
  initialFilters,
}: {
  activeViewStorageKey: string;
  activeViewInitialDesktopValue: CollectionView;
  activeViewInitialMobileValue: CollectionView;
  initialFilters: Omit<QueryVariables, "offset" | "limit" | "sorting">;
}) => {
  const {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<
    [],
    Omit<QueryVariables, "offset" | "limit" | "sorting">
  >(initialFilters, [], PAGE_LIST_RESULTS_PER_PAGE);

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    activeViewStorageKey,
    activeViewInitialDesktopValue,
    activeViewInitialMobileValue
  );

  return {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables: QUERY_BASE_VARIABLES,
    toRemoveParams,
  };
};
