import { gql, useApolloClient } from "@apollo/client";
import { isPicture } from "@msys/common";
import {
  getFormattedPrice,
  isImageOr3dModel,
  isPdfOrFile,
  Modal,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import {
  Box,
  Button,
  DialogActions,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { partition } from "lodash";
import React from "react";
import { GalleryRow } from "../../../commons/images/GalleryRow";
import { useTemplatesQuoteOverviewModalQuery } from "./TemplatesQuoteOverviewModal.generated";
import { useTranslate } from "@tolgee/react";
import { FilesBoxTable } from "../../attachments/FilesBoxTable";
import { TemplatesQuotePreviewItems } from "./TemplateQuotePreviewItems";
import {
  templateQuoteItemHasDescription,
  TemplateQuotePreviewItemDescription,
} from "./TemplateQuotePreviewItemDescription";
import { useExpandedStoreWithLocalStorage } from "../../../trees-virtual/hooks/useExpandedStore";

interface Props {
  docId: string;
  versionNumber: number;
  headerActions?: React.ReactNode;
  handleClose: () => void;
  dialogActionButton?: React.ReactNode;
  canEdit?: boolean;
}

export const TemplatesQuoteOverviewModal = ({
  docId,
  versionNumber,
  handleClose,
  headerActions,
  dialogActionButton,
}: Props) => {
  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const { t } = useTranslate(["Shop", "Global", "Quote"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const client = useApolloClient();
  const query = useTemplatesQuoteOverviewModalQuery({
    client,
    variables: {
      id: docId,
      version: versionNumber,
    },
  });

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    docId
  );

  const doc = query?.data?.quoteTemplateVersion;

  const rootItem = doc?.rootItem;

  const [images, files] = partition(
    (rootItem?.attachments ?? [])
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment),
    isImageOr3dModel
  );

  return (
    <Modal
      title={doc?.title}
      handleClose={handleClose}
      dialogProps={{
        maxWidth: "md",
      }}
      isLoading={query.loading}
      headerActions={headerActions}
      dialogActions={
        <DialogActions>
          <Stack
            direction={isMinTablet ? "row" : "column"}
            spacing={2}
            alignItems="center"
          >
            {doc && (
              <Box>
                <Typography
                  variant="h4"
                  component="div"
                  align={isMinTablet ? "right" : "left"}
                >
                  {t("Price", {
                    ns: "Shop",
                  })}
                  :{" "}
                  {getFormattedPrice(
                    doc.proposedCalculation?.priceTotal ?? 0,
                    language
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  sx={theme => ({ color: theme.palette.grey[500] })}
                  align={isMinTablet ? "right" : "left"}
                >
                  {t(
                    "Includes VAT. Final price in the quote may be different depending on selected options.",
                    {
                      ns: "Shop",
                    }
                  )}
                </Typography>
              </Box>
            )}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              alignSelf={isMinTablet ? undefined : "flex-end"}
            >
              <Button onClick={handleClose} color="primary" variant="text">
                {t("Close", {
                  ns: "Global",
                })}
              </Button>
              {dialogActionButton}
            </Stack>
          </Stack>
        </DialogActions>
      }
    >
      {doc && rootItem && (
        <Stack width="100%" direction="column" spacing={1}>
          {(images.length > 0 || templateQuoteItemHasDescription(rootItem)) && (
            <Paper>
              <Stack padding={2} flexDirection={"column"} spacing={2}>
                <GalleryRow
                  images={images}
                  maxItems={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
                />

                <TemplateQuotePreviewItemDescription
                  item={rootItem}
                  columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
                />
              </Stack>
            </Paper>
          )}

          <TemplatesQuotePreviewItems
            canEdit={false}
            docId={docId}
            allDocItems={doc.items}
            contractType={doc.contractType}
            isBinding={doc.isBinding}
            expandedStore={expandedStore}
          />

          {files.length > 0 && (
            <FilesBoxTable
              title={t("Attachments", { ns: "Quote" })}
              attachments={files}
              canEdit={false}
              gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
            />
          )}
        </Stack>
      )}
    </Modal>
  );
};
