/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptQuoteModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
}>;


export type AcceptQuoteModalQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, contractee: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', city: string } | null } } | null }, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null } | null }, viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, billingAddress?: { __typename: 'Address', id: string, city: string } | null } } | null };

export type AcceptQuoteModal_SetQuoteContracteeAddressMutationVariables = Types.Exact<{
  input: Types.SetQuoteContracteeAddressInput;
}>;


export type AcceptQuoteModal_SetQuoteContracteeAddressMutation = { setQuoteContracteeAddress: { __typename: 'SetQuoteContracteeAddressResult', docActor: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null } } };


export const AcceptQuoteModalDocument = gql`
    query AcceptQuoteModal($projectId: ID!, $docId: ID!) {
  quote(projectId: $projectId, docId: $docId) {
    ... on QuoteResult {
      quote {
        id
        contractee {
          id
          address {
            city
          }
        }
      }
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        building {
          id
          buildingAddress {
            id
            ...AddressDetails__Address
          }
        }
      }
    }
  }
  viewer {
    id
    organisation {
      id
      billingAddress {
        id
        city
        __typename
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;

/**
 * __useAcceptQuoteModalQuery__
 *
 * To run a query within a React component, call `useAcceptQuoteModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptQuoteModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useAcceptQuoteModalQuery(baseOptions: Apollo.QueryHookOptions<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables> & ({ variables: AcceptQuoteModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>(AcceptQuoteModalDocument, options);
      }
export function useAcceptQuoteModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>(AcceptQuoteModalDocument, options);
        }
export function useAcceptQuoteModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>(AcceptQuoteModalDocument, options);
        }
export type AcceptQuoteModalQueryHookResult = ReturnType<typeof useAcceptQuoteModalQuery>;
export type AcceptQuoteModalLazyQueryHookResult = ReturnType<typeof useAcceptQuoteModalLazyQuery>;
export type AcceptQuoteModalSuspenseQueryHookResult = ReturnType<typeof useAcceptQuoteModalSuspenseQuery>;
export type AcceptQuoteModalQueryResult = Apollo.QueryResult<AcceptQuoteModalQuery, AcceptQuoteModalQueryVariables>;
export const AcceptQuoteModal_SetQuoteContracteeAddressDocument = gql`
    mutation AcceptQuoteModal_SetQuoteContracteeAddress($input: SetQuoteContracteeAddressInput!) {
  setQuoteContracteeAddress(input: $input) {
    docActor {
      id
      address {
        countryCode
        postalCode
        city
        streetLines1
      }
    }
  }
}
    `;
export type AcceptQuoteModal_SetQuoteContracteeAddressMutationFn = Apollo.MutationFunction<AcceptQuoteModal_SetQuoteContracteeAddressMutation, AcceptQuoteModal_SetQuoteContracteeAddressMutationVariables>;

/**
 * __useAcceptQuoteModal_SetQuoteContracteeAddressMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteModal_SetQuoteContracteeAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteModal_SetQuoteContracteeAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteModalSetQuoteContracteeAddressMutation, { data, loading, error }] = useAcceptQuoteModal_SetQuoteContracteeAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptQuoteModal_SetQuoteContracteeAddressMutation(baseOptions?: Apollo.MutationHookOptions<AcceptQuoteModal_SetQuoteContracteeAddressMutation, AcceptQuoteModal_SetQuoteContracteeAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptQuoteModal_SetQuoteContracteeAddressMutation, AcceptQuoteModal_SetQuoteContracteeAddressMutationVariables>(AcceptQuoteModal_SetQuoteContracteeAddressDocument, options);
      }
export type AcceptQuoteModal_SetQuoteContracteeAddressMutationHookResult = ReturnType<typeof useAcceptQuoteModal_SetQuoteContracteeAddressMutation>;
export type AcceptQuoteModal_SetQuoteContracteeAddressMutationResult = Apollo.MutationResult<AcceptQuoteModal_SetQuoteContracteeAddressMutation>;
export type AcceptQuoteModal_SetQuoteContracteeAddressMutationOptions = Apollo.BaseMutationOptions<AcceptQuoteModal_SetQuoteContracteeAddressMutation, AcceptQuoteModal_SetQuoteContracteeAddressMutationVariables>;