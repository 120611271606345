/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanySelectQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  filterProjectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterTagsAll?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterTagsAny?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterExcludeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CrmCompanySelectQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', totalCount: number, edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, title: string, systemTags: Array<string>, customTags: Array<string>, email: any, abcCategory: Types.CrmOrganisationAbcCategory, businessPartnerStatus?: Types.CrmCompanyBusinessPartnerStatus | null, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | { __typename: 'MissingPermissions' } };

export type CrmCompanySelect_CrmCompaniesRecordFragment = { __typename: 'CrmCompaniesRecord', id: string, title: string, systemTags: Array<string>, customTags: Array<string>, email: any, abcCategory: Types.CrmOrganisationAbcCategory, businessPartnerStatus?: Types.CrmCompanyBusinessPartnerStatus | null, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export const CrmCompanySelect_CrmCompaniesRecordFragmentDoc = gql`
    fragment CrmCompanySelect_CrmCompaniesRecord on CrmCompaniesRecord {
  id
  title
  systemTags
  customTags
  email
  abcCategory
  contactPerson {
    id
    fullname
  }
  billingAddress {
    ...AddressDetails__Address
  }
  businessPartnerStatus
  logo {
    ...AttachmentSnapshot
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const CrmCompanySelectDocument = gql`
    query CrmCompanySelect($limit: Int!, $filterProjectId: ID, $filterTagsAll: [String!], $filterTagsAny: [String!], $filterIds: [ID!], $filterExcludeIds: [ID!], $searchTerm: String) {
  crmCompanies(
    limit: $limit
    filters: {projectId: $filterProjectId, tagsAll: $filterTagsAll, tagsAny: $filterTagsAny, ids: $filterIds, excludeIds: $filterExcludeIds}
    search: $searchTerm
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          ...CrmCompanySelect_CrmCompaniesRecord
        }
      }
      totalCount
    }
  }
}
    ${CrmCompanySelect_CrmCompaniesRecordFragmentDoc}`;

/**
 * __useCrmCompanySelectQuery__
 *
 * To run a query within a React component, call `useCrmCompanySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanySelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filterProjectId: // value for 'filterProjectId'
 *      filterTagsAll: // value for 'filterTagsAll'
 *      filterTagsAny: // value for 'filterTagsAny'
 *      filterIds: // value for 'filterIds'
 *      filterExcludeIds: // value for 'filterExcludeIds'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCrmCompanySelectQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanySelectQuery, CrmCompanySelectQueryVariables> & ({ variables: CrmCompanySelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>(CrmCompanySelectDocument, options);
      }
export function useCrmCompanySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>(CrmCompanySelectDocument, options);
        }
export function useCrmCompanySelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>(CrmCompanySelectDocument, options);
        }
export type CrmCompanySelectQueryHookResult = ReturnType<typeof useCrmCompanySelectQuery>;
export type CrmCompanySelectLazyQueryHookResult = ReturnType<typeof useCrmCompanySelectLazyQuery>;
export type CrmCompanySelectSuspenseQueryHookResult = ReturnType<typeof useCrmCompanySelectSuspenseQuery>;
export type CrmCompanySelectQueryResult = Apollo.QueryResult<CrmCompanySelectQuery, CrmCompanySelectQueryVariables>;