import { assertNever, pick } from "@msys/common";
import { getFormattedDateTime } from "@msys/formatting";
import { useTolgee } from "@tolgee/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { trpc } from "../../../clients/trpc";

const styles = {
  table: { border: "1px solid black", textAlign: "left" as const },
  th: { border: "1px solid black", textAlign: "left" as const },
  td: {
    border: "1px solid black",
    textAlign: "left" as const,
    padding: "0.2rem 0.5rem",
  },
};

export function OrganisationTemplateOverrides() {
  const listQuery = trpc.templateOverrides.list.useQuery();
  const delMutation = trpc.templateOverrides.del.useMutation();
  const setEnabledMutation = trpc.templateOverrides.setEnabled.useMutation();

  const language = useTolgee(["language"]).getLanguage()!;

  if (listQuery.status === "loading") return <div>loading...</div>;
  if (listQuery.status === "error")
    return <div>error: {listQuery.error.message}</div>;

  return (
    <main style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
      <h1>template overrides</h1>

      <Form
        onSuccess={() => {
          listQuery.refetch();
        }}
      />

      <div style={{ padding: "1rem", border: "1px solid black" }}>
        <h1>existing template item actions</h1>
        <hr />
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>enabled</th>
              <th style={styles.th}>template</th>
              <th style={styles.th}>item</th>
              <th style={styles.th}>scope</th>
              <th style={styles.th}>action</th>
              <th style={styles.th}>value</th>
              <th style={styles.th}>default-value</th>
              <th style={styles.th}>created</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {listQuery.data.entries.map(e => (
              <tr key={e.id}>
                <td style={styles.td}>
                  <input
                    type="checkbox"
                    checked={e.enabled}
                    onChange={evt => {
                      evt.preventDefault();
                      setEnabledMutation.mutate(
                        {
                          enabled: evt.target.checked,
                          id: e.id,
                          overrideActionType: e.overrideActionType,
                        },
                        {
                          onSuccess() {
                            listQuery.refetch();
                          },
                        }
                      );
                    }}
                  />
                </td>
                <td style={styles.td}>
                  <Link to={`/templates/documents/${e.templateId}/latest/edit`}>
                    {e.templateTitle}
                  </Link>
                </td>
                <td style={styles.td}>
                  <Link
                    to={`/templates/documents/${e.templateId}/latest/edit/items/${e.itemId}`}
                  >
                    {e.itemTitle}
                  </Link>
                </td>
                <td style={styles.td}>
                  {e.scopeIncludeUserIds.length === 0 &&
                  e.scopeExcludeUserIds.length === 0 ? (
                    <span>user.all</span>
                  ) : null}
                  {e.scopeIncludeUserIds.length > 0 ? (
                    <span>
                      user.include: {JSON.stringify(e.scopeIncludeUserIds)}
                    </span>
                  ) : null}
                  {e.scopeExcludeUserIds.length > 0 ? (
                    <span>
                      user.exclude: {JSON.stringify(e.scopeExcludeUserIds)}
                    </span>
                  ) : null}
                </td>
                <td style={styles.td}>{e.overrideActionType}</td>
                <td style={styles.td}>
                  <textarea
                    readOnly
                    disabled
                    style={{ width: 300, height: 80 }}
                    defaultValue={JSON.stringify(
                      (() => {
                        switch (e.overrideActionType) {
                          case "SetContingency": {
                            return pick(
                              e,
                              "decisionContingentItemPreselection",
                              "decisionIsContingentItem"
                            );
                          }
                          case "SetEstimated": {
                            return pick(
                              e,
                              "estimatedQuantity",
                              "estimatedTime"
                            );
                          }
                          case "SetProduct": {
                            return pick(
                              e,
                              "productArticleNumber",
                              "productSupplierId"
                            );
                          }
                          case "SetProp": {
                            return pick(e, "propKey", "propValue");
                          }
                          case "SetSubitemPreselection": {
                            return pick(e, "decisionSubitemsPreselection");
                          }
                          case "DeleteItem": {
                            return {};
                          }
                          case "AddItemFromTemplate": {
                            return pick(e, "addTemplateId");
                          }
                          case "SetRecommendedTemplateEligibleIf": {
                            return pick(
                              e,
                              "eligibleIf",
                              "recommendedTemplateId"
                            );
                          }
                          case "SetRecommendedTemplateIncludeIf": {
                            return pick(
                              e,
                              "includeIf",
                              "recommendedTemplateId"
                            );
                          }
                          case "SetTitle": {
                            return pick(e, "title");
                          }
                          default: {
                            throw assertNever(e);
                          }
                        }
                      })(),
                      null,
                      2
                    )}
                  ></textarea>
                </td>
                <td style={styles.td}>
                  <textarea
                    readOnly
                    disabled
                    style={{ width: 300, height: 80 }}
                    defaultValue={JSON.stringify(
                      (() => {
                        switch (e.overrideActionType) {
                          case "SetContingency": {
                            return pick(
                              e,
                              "defaultDecisionContingentItemPreselection",
                              "defaultDecisionIsContingentItem"
                            );
                          }
                          case "SetEstimated": {
                            return pick(
                              e,
                              "defaultEstimatedQuantity",
                              "defaultEstimatedTime"
                            );
                          }
                          case "SetProduct": {
                            return pick(
                              e,
                              "defaultArticleNumber",
                              "defaultSupplierId"
                            );
                          }
                          case "SetProp": {
                            return pick(e, "defaultValue");
                          }
                          case "SetSubitemPreselection": {
                            return pick(
                              e,
                              "defaultDecisionSubitemsPreselection"
                            );
                          }
                          case "DeleteItem": {
                            return {};
                          }
                          case "AddItemFromTemplate": {
                            return {};
                          }
                          case "SetRecommendedTemplateEligibleIf": {
                            return {};
                          }
                          case "SetRecommendedTemplateIncludeIf": {
                            return {};
                          }
                          case "SetTitle": {
                            return pick(e, "defaultTitle");
                          }
                          default: {
                            throw assertNever(e);
                          }
                        }
                      })(),
                      null,
                      2
                    )}
                  ></textarea>
                </td>
                <td style={styles.td}>
                  {getFormattedDateTime(e.createdAt, language)}
                </td>
                <td style={styles.td}>
                  <button
                    type="button"
                    onClick={evt => {
                      evt.preventDefault();

                      if (confirm("Really?")) {
                        delMutation.mutate(
                          {
                            id: e.id,
                            overrideActionType: e.overrideActionType,
                          },
                          {
                            onSuccess() {
                              listQuery.refetch();
                            },
                          }
                        );
                      }
                    }}
                  >
                    delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}

type OverrideArg = Parameters<
  ReturnType<typeof trpc.templateOverrides.createOverride.useMutation>["mutate"]
>[0];

type OverrideArg_SetEstimated = OverrideArg & {
  overrideActionType: "SetEstimated";
};
type OverrideArg_SetProduct = OverrideArg & {
  overrideActionType: "SetProduct";
};
type OverrideArg_SetContingency = OverrideArg & {
  overrideActionType: "SetContingency";
};
type OverrideArg_SetProp = OverrideArg & {
  overrideActionType: "SetProp";
};

type OverrideArg_SetSubitemPreselection = OverrideArg & {
  overrideActionType: "SetSubitemPreselection";
};
type OverrideArg_DeleteItem = OverrideArg & {
  overrideActionType: "DeleteItem";
};
type OverrideArg_AddItemFromTemplate = OverrideArg & {
  overrideActionType: "AddItemFromTemplate";
};
type OverrideArg_SetRecommendedTemplateEligibleIf = OverrideArg & {
  overrideActionType: "SetRecommendedTemplateEligibleIf";
};
type OverrideArg_SetRecommendedTemplateIncludeIf = OverrideArg & {
  overrideActionType: "SetRecommendedTemplateIncludeIf";
};
type OverrideArg_SetTitle = OverrideArg & {
  overrideActionType: "SetTitle";
};

type OverrideActionType = OverrideArg["overrideActionType"];

function Form(props: { onSuccess: () => void }) {
  const [templateId, setTemplateId] = useState("");
  const [itemId, setItemId] = useState("");

  const templateListQuery = trpc.templateOverrides.templates.useQuery();
  const itemListQuery = trpc.templateOverrides.items.useQuery({ templateId });
  const [overrideActionType, setOverrideActionType] = useState<
    OverrideActionType | ""
  >("");

  const [scopeIncludeUserIds, setScopeIncludeUserIds] = useState("[]");
  const [scopeExcludeUserIds, setScopeExcludeUserIds] = useState("[]");

  const itemDefaultsQuery = trpc.templateOverrides.itemDefaults.useQuery(
    {
      itemId,
      templateId,
    },
    { enabled: !!itemId && !!templateId }
  );

  const createOverrideMutation =
    trpc.templateOverrides.createOverride.useMutation();

  if (templateListQuery.status === "loading") return <div>loading...</div>;
  if (templateListQuery.status === "error")
    return <div>error: {templateListQuery.error.message}</div>;

  if (itemListQuery.status === "loading") return <div>loading...</div>;
  if (itemListQuery.status === "error")
    return <div>error: {itemListQuery.error.message}</div>;

  return (
    <form
      style={{ padding: "1rem", border: "1px solid black" }}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <h1>create new template item action</h1>
      <hr />
      <div
        style={{
          border: "1px dotted #c0c0c0",
          padding: "0.5rem",
          marginBottom: "0.5rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <label htmlFor="template-input">template</label>
        <select
          value={templateId}
          onChange={e => setTemplateId(e.target.value)}
          required
        >
          <option value="">select template</option>
          {templateListQuery.data.entries.map(e => (
            <option key={e.id} value={e.id}>
              {e.title}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          border: "1px dotted #c0c0c0",
          padding: "0.5rem",
          marginBottom: "0.5rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <label>item</label>
        <select
          required
          value={itemId}
          onChange={e => setItemId(e.target.value)}
        >
          <option value="">select item</option>
          {itemListQuery.data.entries.map(e => (
            <option key={e.id} value={e.id}>
              {e.path}. {e.title}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          border: "1px dotted #c0c0c0",
          padding: "0.5rem",
          marginBottom: "0.5rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <label>scope</label>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>include:</span>
            <textarea
              required
              value={scopeIncludeUserIds}
              onChange={e => setScopeIncludeUserIds(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>exclude:</span>
            <textarea
              required
              value={scopeExcludeUserIds}
              onChange={e => setScopeExcludeUserIds(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          border: "1px dotted #c0c0c0",
          padding: "0.5rem",
          marginBottom: "0.5rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <label>action</label>
        <select
          required
          value={overrideActionType}
          onChange={e =>
            setOverrideActionType(e.target.value as OverrideActionType)
          }
        >
          <option value="">select action</option>
          <option value="SetEstimated">set estimated</option>
          <option value="SetContingency">set contingency</option>
          <option value="SetProduct">set product</option>
          <option value="SetProp">set prop</option>
          <option value="SetSubitemPreselection">
            set subitem preselection
          </option>
          <option value="DeleteItem">delete item</option>
          <option value="AddItemFromTemplate">add item from template</option>
          <option value="SetRecommendedTemplateEligibleIf">
            set recommended template eligibleIf
          </option>
          <option value="SetRecommendedTemplateIncludeIf">
            set recommended template includeIf
          </option>
          <option value="SetTitle">set title</option>
        </select>
      </div>
      <hr />

      {itemDefaultsQuery.data?.itemDefaults && overrideActionType ? (
        <>
          {(() => {
            switch (overrideActionType) {
              case "SetEstimated": {
                return (
                  <Form_OverrideArg_SetEstimated
                    defaults={{
                      estimatedQuantity:
                        itemDefaultsQuery.data.itemDefaults.estimatedQuantity,
                      estimatedTime:
                        itemDefaultsQuery.data.itemDefaults.estimatedTime,
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          estimatedQuantity: args.estimatedQuantity,
                          estimatedTime: args.estimatedTime,
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetContingency": {
                return (
                  <Form_OverrideArg_SetContingency
                    defaults={{
                      decisionIsContingentItem:
                        itemDefaultsQuery.data.itemDefaults
                          .decisionIsContingentItem,
                      decisionContingentItemPreselection:
                        itemDefaultsQuery.data.itemDefaults
                          .decisionContingentItemPreselection,
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          decisionIsContingentItem:
                            args.decisionIsContingentItem,
                          decisionContingentItemPreselection:
                            args.decisionContingentItemPreselection,
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetProduct": {
                return (
                  <Form_OverrideArg_SetProduct
                    defaults={{
                      articleNumber:
                        itemDefaultsQuery.data.itemDefaults.articleNumber ?? "",
                      supplierId:
                        itemDefaultsQuery.data.itemDefaults.supplierId ?? "",
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          articleNumber: args.articleNumber,
                          supplierId: args.supplierId,
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetProp": {
                return (
                  <Form_OverrideArg_SetProp
                    defaults={{
                      propKey: "",
                      propValue: null,
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          propKey: args.propKey,
                          propValue: args.propValue,
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetSubitemPreselection": {
                return (
                  <Form_OverrideArg_SetSubitemPreselection
                    defaults={{
                      decisionSubitemsPreselection:
                        itemDefaultsQuery.data.itemDefaults
                          .decisionSubitemsPreselection,
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          decisionSubitemsPreselection:
                            args.decisionSubitemsPreselection,
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "DeleteItem": {
                return (
                  <Form_OverrideArg_DeleteItem
                    defaults={{}}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          templateId,
                          itemId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }

              case "AddItemFromTemplate": {
                return (
                  <Form_OverrideArg_AddItemFromTemplate
                    defaults={{}}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          templateId,
                          itemId,
                          addTemplateId: args.addTemplateId,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetRecommendedTemplateEligibleIf": {
                return (
                  <Form_OverrideArg_SetRecommendedTemplateEligibleIf
                    defaults={{}}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          templateId,
                          itemId,
                          recommendedTemplateId: args.recommendedTemplateId,
                          eligibleIf: args.eligibleIf,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetRecommendedTemplateIncludeIf": {
                return (
                  <Form_OverrideArg_SetRecommendedTemplateIncludeIf
                    defaults={{}}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          templateId,
                          itemId,
                          recommendedTemplateId: args.recommendedTemplateId,
                          includeIf: args.includeIf,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              case "SetTitle": {
                return (
                  <Form_OverrideArg_SetTitle
                    defaults={{
                      title: itemDefaultsQuery.data.itemDefaults.title,
                    }}
                    onSubmit={args => {
                      createOverrideMutation.mutate(
                        {
                          overrideActionType,
                          scopeExcludeUserIds: JSON.parse(scopeExcludeUserIds),
                          scopeIncludeUserIds: JSON.parse(scopeIncludeUserIds),
                          templateId,
                          itemId,
                          title: args.title,
                        },
                        {
                          onSuccess() {
                            props.onSuccess();
                          },
                        }
                      );
                    }}
                  />
                );
              }
              default: {
                throw assertNever(overrideActionType);
              }
            }
          })()}
        </>
      ) : null}
    </form>
  );
}

function Form_OverrideArg_SetEstimated(props: {
  defaults: Pick<
    OverrideArg_SetEstimated,
    "estimatedQuantity" | "estimatedTime"
  >;
  onSubmit: (
    args: Pick<OverrideArg_SetEstimated, "estimatedQuantity" | "estimatedTime">
  ) => void;
}) {
  const [estimatedQuantity, setEstimatedQuantity] = useState<string>(
    props.defaults.estimatedQuantity.toString()
  );
  const [estimatedTime, setEstimatedTime] = useState<string>(
    props.defaults.estimatedTime.toString()
  );

  return (
    <>
      <div>
        <label>estimated quantity</label>
        <input
          required
          value={estimatedQuantity}
          onChange={e => setEstimatedQuantity(e.target.value)}
          type="number"
        />
      </div>
      <div>
        <label>estimated time</label>
        <input
          required
          value={estimatedTime}
          onChange={e => setEstimatedTime(e.target.value)}
          type="number"
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            estimatedQuantity: parseInt(estimatedQuantity, 10),
            estimatedTime: parseInt(estimatedTime, 10),
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetContingency(props: {
  defaults: Pick<
    OverrideArg_SetContingency,
    "decisionIsContingentItem" | "decisionContingentItemPreselection"
  >;
  onSubmit: (
    args: Pick<
      OverrideArg_SetContingency,
      "decisionIsContingentItem" | "decisionContingentItemPreselection"
    >
  ) => void;
}) {
  const [decisionIsContingentItem, setDecisionIsContingentItem] =
    useState<string>(props.defaults.decisionIsContingentItem.toString());

  const [
    decisionContingentItemPreselection,
    setDecisionContingentItemPreselection,
  ] = useState<string>(
    props.defaults.decisionContingentItemPreselection?.toString() ?? "null"
  );

  return (
    <>
      <div>
        <label>decisionIsContingentItem</label>
        <input
          type="checkbox"
          value={decisionIsContingentItem}
          onChange={e =>
            setDecisionIsContingentItem(e.target.checked.toString())
          }
        />
      </div>
      <div>
        <label>decisionContingentItemPreselection</label>
        <select
          value={decisionContingentItemPreselection}
          onChange={e => setDecisionContingentItemPreselection(e.target.value)}
        >
          <option>true</option>
          <option>false</option>
          <option>null</option>
        </select>
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            decisionIsContingentItem: decisionIsContingentItem === "true",
            decisionContingentItemPreselection:
              decisionContingentItemPreselection === "null"
                ? null
                : decisionContingentItemPreselection === "true",
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetProduct(props: {
  defaults: Pick<OverrideArg_SetProduct, "articleNumber" | "supplierId">;
  onSubmit: (
    args: Pick<OverrideArg_SetProduct, "articleNumber" | "supplierId">
  ) => void;
}) {
  const [articleNumber, setArticleNumber] = useState<string>(
    props.defaults.articleNumber.toString()
  );

  const [supplierId, setSupplierId] = useState<string>(
    props.defaults.supplierId.toString()
  );

  return (
    <>
      <div>
        <label>articleNumber</label>
        <input
          type="text"
          required
          value={articleNumber}
          onChange={e => setArticleNumber(e.target.value)}
        />
      </div>
      <div>
        <label>supplierId</label>
        <input
          type="text"
          required
          value={supplierId}
          onChange={e => setSupplierId(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            supplierId,
            articleNumber,
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetProp(props: {
  defaults: Pick<OverrideArg_SetProp, "propKey" | "propValue">;
  onSubmit: (args: Pick<OverrideArg_SetProp, "propKey" | "propValue">) => void;
}) {
  const [propKey, setPropKey] = useState<string>(
    props.defaults.propKey.toString()
  );

  const [propValue, setPropValue] = useState<string>(
    JSON.stringify(props.defaults.propValue)
  );

  return (
    <>
      <div>
        <label>propKey</label>
        <input
          type="text"
          required
          value={propKey}
          onChange={e => setPropKey(e.target.value)}
        />
      </div>
      <div>
        <label>propValue</label>
        <input
          type="text"
          required
          value={propValue}
          onChange={e => setPropValue(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            propKey,
            propValue: JSON.parse(propValue),
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetSubitemPreselection(props: {
  defaults: Pick<
    OverrideArg_SetSubitemPreselection,
    "decisionSubitemsPreselection"
  >;
  onSubmit: (
    args: Pick<
      OverrideArg_SetSubitemPreselection,
      "decisionSubitemsPreselection"
    >
  ) => void;
}) {
  const [decisionSubitemsPreselection, setDecisionSubitemsPreselection] =
    useState<string>(
      JSON.stringify(props.defaults.decisionSubitemsPreselection)
    );

  return (
    <>
      <div>
        <label>decisionSubitemsPreselection</label>
        <textarea
          required
          style={{ width: 300, height: 60 }}
          value={decisionSubitemsPreselection}
          onChange={e => setDecisionSubitemsPreselection(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            decisionSubitemsPreselection: JSON.parse(
              decisionSubitemsPreselection
            ),
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_DeleteItem(props: {
  defaults: {};
  onSubmit: (args: {}) => void;
}) {
  return (
    <>
      <button
        type="button"
        onClick={() => {
          props.onSubmit({});
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_AddItemFromTemplate(props: {
  defaults: {};
  onSubmit: (
    args: Pick<OverrideArg_AddItemFromTemplate, "addTemplateId">
  ) => void;
}) {
  const [addTemplateId, setAddTemplateId] = useState<string>("");

  return (
    <>
      <div>
        <label>addTemplateId</label>
        <input
          type="text"
          required
          value={addTemplateId}
          onChange={e => setAddTemplateId(e.target.value)}
        />
      </div>

      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            addTemplateId,
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetRecommendedTemplateEligibleIf(props: {
  defaults: {};
  onSubmit: (
    args: Pick<
      OverrideArg_SetRecommendedTemplateEligibleIf,
      "eligibleIf" | "recommendedTemplateId"
    >
  ) => void;
}) {
  const [eligibleIf, setEligibleIf] = useState<string>("");
  const [recommendedTemplateId, setRecommendedTemplateId] =
    useState<string>("");

  return (
    <>
      <div>
        <label>recommendedTemplateId</label>
        <input
          type="text"
          required
          value={recommendedTemplateId}
          onChange={e => setRecommendedTemplateId(e.target.value)}
        />
      </div>
      <div>
        <label>eligibleIf</label>
        <textarea
          required
          style={{ width: 300, height: 60 }}
          value={eligibleIf}
          onChange={e => setEligibleIf(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            eligibleIf,
            recommendedTemplateId,
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetRecommendedTemplateIncludeIf(props: {
  defaults: {};
  onSubmit: (
    args: Pick<
      OverrideArg_SetRecommendedTemplateIncludeIf,
      "includeIf" | "recommendedTemplateId"
    >
  ) => void;
}) {
  const [includeIf, setIncludeIf] = useState<string>("");
  const [recommendedTemplateId, setRecommendedTemplateId] =
    useState<string>("");

  return (
    <>
      <div>
        <label>recommendedTemplateId</label>
        <input
          type="text"
          required
          value={recommendedTemplateId}
          onChange={e => setRecommendedTemplateId(e.target.value)}
        />
      </div>
      <div>
        <label>includeIf</label>
        <textarea
          required
          style={{ width: 300, height: 60 }}
          value={includeIf}
          onChange={e => setIncludeIf(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            includeIf,
            recommendedTemplateId,
          });
        }}
      >
        create
      </button>
    </>
  );
}

function Form_OverrideArg_SetTitle(props: {
  defaults: Pick<OverrideArg_SetTitle, "title">;
  onSubmit: (args: Pick<OverrideArg_SetTitle, "title">) => void;
}) {
  const [title, setTitle] = useState<string>(props.defaults.title);

  return (
    <>
      <div>
        <label>title</label>
        <input
          type="text"
          required
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <hr />
      <button
        type="button"
        onClick={() => {
          props.onSubmit({
            title,
          });
        }}
      >
        create
      </button>
    </>
  );
}
