import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { Props2NonComputed } from "../../../../clients/graphqlTypes";
import { nonComputedPropToEntry } from "../properties";
import { useDecisionItemMutations } from "./useDecisionItemMutations";
import { DecisionMutations_ItemFragment } from "./useDecisionItemMutations.generated";
import {
  useUseDefaultDecisionActions_EnterItemProps2ValueMutation,
  useUseDefaultDecisionActions_FinalizeProductSelectMutation,
  useUseDefaultDecisionActions_SelectProductMutation,
} from "./useDefaultDecisionActions.generated";
import { useLatest } from "react-use";

export function useDefaultDecisionActions<
  T extends DecisionMutations_ItemFragment
>({
  projectId,
  docId,
  canFinalize,
  expandedItemIds,
  refetch,
}: {
  projectId: string | null;
  docId: string;
  canFinalize: boolean;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
}) {
  const [shouldRefetch, setShouldRefetch] = React.useState(false);
  const shouldRefetchLatest = useLatest(shouldRefetch);

  const refetchMeybe = React.useCallback(async () => {
    if (refetch && shouldRefetchLatest.current) {
      await refetch();
      setShouldRefetch(false);
    }
  }, [refetch, shouldRefetchLatest]);

  const {
    canFinalizeContingencyDecision,
    handleContingencyPreselectionChange,
    handleFinalizeContingencyDecision,
    finalizeContingencyDecisionLoading,

    isSubitemFinalizeDisabled,
    canFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    handleDecisionPreselectionChange,
    handleFinalizeSubitemDecision,
    finalizeSubitemDecisionLoading,

    handleEliminateOption,
    handleResetEliminateOption,

    isItemGoingToBeRemoved,
    loading,
  } = useDecisionItemMutations<T>(
    projectId,
    docId,
    canFinalize,
    expandedItemIds
  );

  const memoizedHandleContingencyPreselectionChange = React.useCallback(
    async (...args: Parameters<typeof handleContingencyPreselectionChange>) => {
      await handleContingencyPreselectionChange(...args);
      setShouldRefetch(true);
    },
    [handleContingencyPreselectionChange]
  );
  const memoizedHandleFinalizeContingencyDecision = React.useCallback(
    async (...args: Parameters<typeof handleFinalizeContingencyDecision>) => {
      await handleFinalizeContingencyDecision(...args);
      setShouldRefetch(true);
    },
    [handleFinalizeContingencyDecision]
  );
  const memoizedHandleDecisionPreselectionChange = React.useCallback(
    async (...args: Parameters<typeof handleDecisionPreselectionChange>) => {
      await handleDecisionPreselectionChange(...args);
      setShouldRefetch(true);
    },
    [handleDecisionPreselectionChange]
  );
  const memoizedHandleFinalizeSubitemDecision = React.useCallback(
    async (...args: Parameters<typeof handleFinalizeSubitemDecision>) => {
      await handleFinalizeSubitemDecision(...args);
      setShouldRefetch(true);
    },
    [handleFinalizeSubitemDecision]
  );
  const memoizedHandleEliminateOption = React.useCallback(
    async (...args: Parameters<typeof handleEliminateOption>) => {
      await handleEliminateOption(...args);
      setShouldRefetch(true);
    },
    [handleEliminateOption]
  );
  const memoizedHandleResetEliminateOption = React.useCallback(
    async (...args: Parameters<typeof handleResetEliminateOption>) => {
      await handleResetEliminateOption(...args);
      setShouldRefetch(true);
    },
    [handleResetEliminateOption]
  );

  const client = useApolloClient();
  const [enterItemProps2, { loading: enterPropertiesLoading }] =
    useUseDefaultDecisionActions_EnterItemProps2ValueMutation({
      client,
    });
  const setPropertyValues = React.useCallback(
    async (itemId: string, properties: Props2NonComputed[]) => {
      await enterItemProps2({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            entries: properties.map(nonComputedPropToEntry),
            expandedItemIds,
          },
        },
      });
      setShouldRefetch(true);
    },
    [docId, enterItemProps2, expandedItemIds, projectId]
  );

  const [setItemProductMutation, { loading: setItemProductLoading }] =
    useUseDefaultDecisionActions_SelectProductMutation({
      client,
    });
  const setItemProduct = React.useCallback(
    async (
      itemId: string,
      productId: { articleNumber: string; supplierId: string }
    ) => {
      await setItemProductMutation({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            productArticleNumber: productId.articleNumber,
            productSupplierId: productId.supplierId,
          },
        },
      });
      setShouldRefetch(true);
    },
    [docId, projectId, setItemProductMutation]
  );
  const [
    finalizeItemProductSelect,
    { loading: finalizeItemProductSelectLoading },
  ] = useUseDefaultDecisionActions_FinalizeProductSelectMutation({
    client,
  });
  const finalizeProductSelect = React.useCallback(
    async (itemId: string) => {
      await finalizeItemProductSelect({
        variables: { projectId, docId, itemId },
      });
      setShouldRefetch(true);
    },
    [docId, finalizeItemProductSelect, projectId]
  );

  return {
    canFinalizeContingencyDecision,
    handleContingencyPreselectionChange:
      memoizedHandleContingencyPreselectionChange,
    handleFinalizeContingencyDecision:
      memoizedHandleFinalizeContingencyDecision,
    finalizeContingencyDecisionLoading,
    isSubitemFinalizeDisabled,
    canFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    handleDecisionPreselectionChange: memoizedHandleDecisionPreselectionChange,
    handleFinalizeSubitemDecision: memoizedHandleFinalizeSubitemDecision,
    finalizeSubitemDecisionLoading,
    handleEliminateOption: memoizedHandleEliminateOption,
    handleResetEliminateOption: memoizedHandleResetEliminateOption,
    isItemGoingToBeRemoved,
    loading,
    setPropertyValues,
    enterPropertiesLoading,
    setItemProduct,
    setItemProductLoading: setItemProductLoading,
    finalizeProductSelect,
    finalizeProductSelectLoading: finalizeItemProductSelectLoading,
    refetch: refetchMeybe,
  };
}
