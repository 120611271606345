import { gql, useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { TemplatesQuoteTemplateAction } from "../../../features/templates/quote/TemplatesQuoteTemplateAction";
import { ThreeD } from "../../../features/threeD/ThreeD";
import { ThreeDEditorRef } from "../../../features/threeD/ThreeDEditor";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils";
import { useTemplateQuoteThreeDVersionQuery } from "./TemplateQuoteVersionThreeD.generated";

interface Props {
  docId: string;
  pathToDoc: string;
  pathToDocVersion: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  returnPath?: string | null;
  pageSubHeader: React.ReactElement | null;
}

export const TemplateQuoteThreeDVersion = ({
  docId,
  pathToDoc,
  pathToDocVersion,
  submenuItems,
  prefixBreadcrumbs,
  pageSubHeader,
}: Props) => {
  const { t } = useTranslate(["Templates"]);

  const { versionNumber: versionNumberFromParam } = useParams();
  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParam
  );
  if (!versionNumber) throw new Error("Version number missing");
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  const client = useApolloClient();
  const query = useTemplateQuoteThreeDVersionQuery({
    client,
    variables: {
      id: docId,
      versionNumber,
    },
  });

  const templateVersion = query.data?.quoteTemplateVersion;
  const allDocItems = React.useMemo(() => {
    if (!templateVersion) return [];

    return templateVersion.items;
  }, [templateVersion]);

  const threeDEditorRef = React.useRef<ThreeDEditorRef>(null);

  return (
    <Page
      title={templateVersion?.title ?? ""}
      subtitle={t("Document Templates", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      subHeader={pageSubHeader}
      action={
        templateVersion ? (
          <TemplatesQuoteTemplateAction
            doc={templateVersion}
            pathToDoc={pathToDoc}
          />
        ) : undefined
      }
    >
      {templateVersion && (
        <PageContainer $fullHeight $noPadding>
          <ThreeD
            threeDEditorRef={threeDEditorRef}
            projectId={null}
            docId={docId}
            returnPath={returnPath ?? pathToDocVersion}
            allDocItems={allDocItems}
            isEditable={false}
          />
        </PageContainer>
      )}
    </Page>
  );
};
