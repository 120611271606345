import { assertNever } from "@msys/common";
import { LabeledValue, Modal, ModalOpenButton } from "@msys/ui";
import { Unit } from "@msys/units";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { merge, omit, uniqueId } from "lodash";
import React from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import {
  DefineItemProps2Entry,
  DefineItemProps2EntryBool,
  DefineItemProps2EntryBoolComputed,
  DefineItemProps2EntryNumber,
  DefineItemProps2EntryNumberArray,
  DefineItemProps2EntryNumberArrayComputed,
  DefineItemProps2EntryNumberComputed,
  DefineItemProps2EntryText,
  DefineItemProps2EntryTextArray,
  DefineItemProps2EntryTextArrayComputed,
  DefineItemProps2EntryTextComputed,
  Props2,
} from "../../../../clients/graphqlTypes";
import { AutocompleteField } from "../../../commons/form-fields/AutocompleteField";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { FormattedFloatOptionalField } from "../../../commons/form-fields/FormattedFloatOptionalField";
import { TextField } from "../../../commons/form-fields/TextField";
import {
  QuestionControlSection,
  QuestionControlSectionFormValues,
} from "../QuestionControlSection";
import {
  AllowedValuesField,
  AllowedValuesFormValues,
  useAllowedValuesFieldValidationSchema,
} from "../form-fields/PropertyAllowedValuesField";
import {
  getPropertyType,
  isNonComputedProp,
  isNumberType,
  isTextType,
  propToDefineInput,
} from "../properties";
import { usePropertyUnits } from "../usePropertyUnits";
import { PropertyKeyEditModal } from "./PropertyKeyEditModal";

interface FormValues
  extends AllowedValuesFormValues,
    QuestionControlSectionFormValues {
  label: string;
  unit: Unit | null;
  setRange: boolean;
  range: {
    min: number | null;
    max: number | null;
  };
  essential: boolean;
  group: string;
  clientVisibility: boolean;
}

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
  property: Props2;
  handleClose: () => void;
  handleComplete: (changedProperty: DefineItemProps2Entry) => Promise<void>;
}

export function PropertyEditModal({
  projectId,
  docId,
  itemId,
  property,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const { getUnits } = usePropertyUnits();
  const unitsOptions = React.useMemo(() => getUnits(), [getUnits]);
  const [error, setError] = React.useState<Error | null>(null);

  const initialValues: FormValues = React.useMemo(
    () => ({
      label: property.label,
      defineAllowedValues:
        isNonComputedProp(property) &&
        ((isTextType(property) && property.allowedValuesText.length > 0) ||
          (isNumberType(property) && property.allowedValuesNumber.length > 0)),
      allowedValues: isNonComputedProp(property)
        ? isTextType(property)
          ? property.allowedValuesText.map(allowedValueText => ({
              key: v4(),
              media: allowedValueText.media ?? null,
              value: allowedValueText.allowedText,
            }))
          : isNumberType(property)
            ? property.allowedValuesNumber.map(allowedValueNumber => ({
                key: v4(),
                media: allowedValueNumber.media ?? null,
                value: allowedValueNumber.allowedNumber,
              }))
            : []
        : [],
      setRange:
        isNumberType(property) &&
        isNonComputedProp(property) &&
        Boolean(property.range),
      range:
        isNumberType(property) && isNonComputedProp(property)
          ? merge(
              { min: null, max: null },
              { min: property.range?.min, max: property.range?.max }
            )
          : { min: null, max: null },
      essential: false,
      unit:
        isNumberType(property) && property.unit
          ? unitsOptions.find(option => option.key === property.unit) ?? null
          : null,
      group: property.group,
      clientVisibility: property.clientVisibility,
      prompt: isNonComputedProp(property) ? property.prompt : "",
      askWhen: isNonComputedProp(property) ? property.askWhen : [],
      askWhom: isNonComputedProp(property) ? property.askWhom : [],
    }),
    [property, unitsOptions]
  );

  const allowedValuesSchema = useAllowedValuesFieldValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string()
          .required()
          .min(1)
          .label(
            t("Name", {
              ns: "Global",
            })
          ),
        setRange: Yup.boolean(),
        range: Yup.object().shape({
          min: Yup.number().nullable(),
          max: Yup.number().nullable(),
        }),
        essential: Yup.boolean().required(),
        unit: Yup.object().shape({ key: Yup.string().required() }).nullable(),
        group: Yup.string(),
        prompt: Yup.string(),
        askWhen: Yup.array(Yup.string()),
        askWhom: Yup.array(Yup.string()),
        ...allowedValuesSchema,
      }),
    [t, allowedValuesSchema]
  );

  const onSubmit = async (values: FormValues) => {
    try {
      await handleComplete(createPropertyEntry(values, property));
      handleClose();
    } catch (err) {
      const error = err instanceof Error ? err : new Error(JSON.stringify(err));
      setError(error);
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const type = getPropertyType(property);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Edit property", {
            ns: "QuoteItem",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Confirm", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid || !formikProps.dirty,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack spacing={1}>
              {error && <Alert severity="error">{error.message}</Alert>}
              <Stack direction={"row"} alignSelf="flex-start" spacing={2}>
                <LabeledValue
                  label={t("Key", {
                    ns: "QuoteItem",
                  })}
                >
                  {property.key}
                </LabeledValue>
                <ModalOpenButton
                  Modal={PropertyKeyEditModal}
                  modalProps={{ projectId, docId, itemId, property }}
                  disabled={formikProps.dirty}
                >
                  <Tooltip
                    title={
                      formikProps.dirty
                        ? t("Can't change key with unsaved changes", {
                            ns: "QuoteItem",
                          })
                        : ""
                    }
                  >
                    <Box display="flex">
                      <IconButton
                        color={"primary"}
                        size="small"
                        disabled={formikProps.dirty}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </ModalOpenButton>
              </Stack>

              <TextField
                name={"label"}
                label={t("Label", {
                  ns: "Global",
                })}
                autoFocus
              />
              <LabeledValue
                label={t("Type", {
                  ns: "QuoteItem",
                })}
              >
                {type}
              </LabeledValue>
              {type === "NUMBER" && (
                <AutocompleteField
                  name="unit"
                  inputLabel={t("Unit", {
                    ns: "QuoteItem",
                  })}
                  options={unitsOptions}
                  getOptionLabel={option => option.value}
                  renderOption={(props, option) => (
                    <li key={option.key} {...props}>
                      {option.value}
                    </li>
                  )}
                />
              )}
              {type !== "BOOLEAN" && (
                <CheckboxField
                  name={"isArray"}
                  label={t("Allow multiple values", {
                    ns: "QuoteItem",
                  })}
                  disabled
                />
              )}
              {type !== "BOOLEAN" && isNonComputedProp(property) && (
                <AllowedValuesField
                  type={type}
                  disabled={type === "NUMBER" && formikProps.values.setRange}
                />
              )}
              {type === "NUMBER" && isNonComputedProp(property) && (
                <CheckboxField
                  name={"setRange"}
                  label={t("Set min and/or max", {
                    ns: "QuoteItem",
                  })}
                  disabled={formikProps.values.defineAllowedValues}
                />
              )}
              {type === "NUMBER" &&
                isNonComputedProp(property) &&
                formikProps.values.setRange && (
                  <Stack direction={"row"} spacing={1} paddingLeft={4} flex={1}>
                    <FormattedFloatOptionalField
                      name={"range.min"}
                      label={t("Min", {
                        ns: "QuoteItem",
                      })}
                    />
                    <FormattedFloatOptionalField
                      name={"range.max"}
                      label={t("Max", {
                        ns: "QuoteItem",
                      })}
                    />
                  </Stack>
                )}
              <TextField name={"group"} label={"Group"} />
              <CheckboxField
                name={`clientVisibility`}
                label={t("Visibility", { ns: "Global" })}
              />
              {isNonComputedProp(property) && (
                <QuestionControlSection isInitiallyExpanded />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

function createPropertyEntry(
  values: FormValues,
  property: Props2
): DefineItemProps2Entry {
  switch (property.__typename) {
    case "Props2Bool": {
      return {
        bool: {
          ...propToDefineInput(property).bool,
          ...createBooleanPropertyEntry(values),
        },
      };
    }
    case "Props2BoolComputed": {
      return {
        boolComputed: {
          ...propToDefineInput(property).boolComputed,
          ...createBooleanComputedPropertyEntry(values),
        },
      };
    }
    case "Props2Number": {
      return {
        number: {
          ...propToDefineInput(property).number,
          ...createNumberPropertyEntry(values),
        },
      };
    }
    case "Props2NumberComputed": {
      return {
        numberComputed: {
          ...propToDefineInput(property).numberComputed,
          ...createNumberComputedPropertyEntry(values),
        },
      };
    }
    case "Props2NumberArray": {
      return {
        numberArray: {
          ...propToDefineInput(property).numberArray,
          ...createNumberArrayPropertyEntry(values),
        },
      };
    }
    case "Props2NumberArrayComputed": {
      return {
        numberArrayComputed: {
          ...propToDefineInput(property).numberArrayComputed,
          ...createNumberArrayComputedPropertyEntry(values),
        },
      };
    }
    case "Props2Text": {
      return {
        text: {
          ...propToDefineInput(property).text,
          ...createTextPropertyEntry(values),
        },
      };
    }
    case "Props2TextComputed": {
      return {
        textComputed: {
          ...propToDefineInput(property).textComputed,
          ...createTextComputedPropertyEntry(values),
        },
      };
    }
    case "Props2TextArray": {
      return {
        textArray: {
          ...propToDefineInput(property).textArray,
          ...createTextArrayPropertyEntry(values),
        },
      };
    }
    case "Props2TextArrayComputed": {
      return {
        textArrayComputed: {
          ...propToDefineInput(property).textArrayComputed,
          ...createTextArrayComputedPropertyEntry(values),
        },
      };
    }
    default: {
      assertNever(property);
    }
  }
}

type EntryInputValues = FormValues;

function createBooleanPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryBool, "key" | "valueBool"> {
  return {
    label: values.label,
    group: values.group,
    essential: values.essential,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createBooleanComputedPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryBoolComputed, "key" | "expr"> {
  return {
    label: values.label,
    clientVisibility: values.clientVisibility,
    group: values.group,
  };
}
function createNumberPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryNumber, "key" | "valueNumber"> {
  return {
    label: values.label,
    group: values.group,
    essential: values.essential,
    allowedValuesNumber: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedNumber: v.value as number,
          media: v.media ? omit(v.media, "id", "__typename", "__type") : null,
        }))
      : [],
    range: values.setRange ? values.range : null,
    unit: values.unit?.key,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createNumberComputedPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryNumberComputed, "key" | "expr"> {
  return {
    label: values.label,
    clientVisibility: values.clientVisibility,
    group: values.group,
  };
}
function createNumberArrayPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryNumberArray, "key" | "valueNumberArray"> {
  return {
    label: values.label,
    group: values.group,
    essential: values.essential,
    allowedValuesNumber: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedNumber: v.value as number,
          media: v.media ? omit(v.media, "id", "__typename", "__type") : null,
        }))
      : [],
    range: values.setRange ? values.range : null,
    unit: values.unit?.key,
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createNumberArrayComputedPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryNumberArrayComputed, "key" | "expr"> {
  return {
    label: values.label,
    clientVisibility: values.clientVisibility,
    group: values.group,
  };
}
function createTextPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryText, "key" | "valueText"> {
  return {
    label: values.label,
    group: values.group,
    essential: values.essential,
    allowedValuesText: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedText: v.value as string,
          media: v.media ? omit(v.media, "id", "__typename", "__type") : null,
        }))
      : [],
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createTextComputedPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryTextComputed, "key" | "expr"> {
  return {
    label: values.label,
    clientVisibility: values.clientVisibility,
    group: values.group,
  };
}
function createTextArrayPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryTextArray, "key" | "valueTextArray"> {
  return {
    label: values.label,
    group: values.group,
    essential: values.essential,
    allowedValuesText: values.defineAllowedValues
      ? values.allowedValues.map(v => ({
          allowedText: v.value as string,
          media: v.media ? omit(v.media, "id", "__typename", "__type") : null,
        }))
      : [],
    clientVisibility: values.clientVisibility,
    prompt: values.prompt,
    askWhen: values.askWhen,
    askWhom: values.askWhom,
  };
}
function createTextArrayComputedPropertyEntry(
  values: EntryInputValues
): Omit<DefineItemProps2EntryTextArrayComputed, "key" | "expr"> {
  return {
    label: values.label,
    clientVisibility: values.clientVisibility,
    group: values.group,
  };
}
