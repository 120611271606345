/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteVersionTroubleshootQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  versionNumber: Types.Scalars['Int']['input'];
}>;


export type TemplateQuoteVersionTroubleshootQuery = { quoteTemplateVersion?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string } | null, compileDocIsolatedExpression: { __typename: 'CompileDocIsolatedExpressionResult', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, attribute: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, key: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, propertyKey?: string | null, filterKind: Types.ProductSearchFilterExpressionFilterKind, filterKey: Types.ProductSearchFilterExpressionFilterKey, operator: Types.EntitySearchFilterOperator, allowedOperators: Array<Types.EntitySearchFilterOperator>, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, toKey: string, fromExpr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null }> } };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, attribute: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, key: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, propertyKey?: string | null, filterKind: Types.ProductSearchFilterExpressionFilterKind, filterKey: Types.ProductSearchFilterExpressionFilterKey, operator: Types.EntitySearchFilterOperator, allowedOperators: Array<Types.EntitySearchFilterOperator>, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, toKey: string, fromExpr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment = { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf', docId: string, itemId: string, itemPdfPath: string, itemTitle: string, expr: string, messageText: string, versionNumber?: number | null };

export type TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragment = TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticAttribute_Fragment | TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticComputedProp_Fragment | TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression_Fragment | TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticPropertyMapping_Fragment | TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf_Fragment | TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic_CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf_Fragment;

export const TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc = gql`
    fragment TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic on CompileDocIsolatedExpressionResultDiagnostic {
  ... on CompileDocIsolatedExpressionResultDiagnosticComputedProp {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    key
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticAttribute {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    attribute
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticPropertyMapping {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    toKey
    fromExpr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    versionNumber
  }
  ... on CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression {
    docId
    __typename
    itemId
    itemPdfPath
    itemTitle
    expr
    messageText
    propertyKey
    filterKind
    filterKey
    operator
    allowedOperators
    versionNumber
  }
}
    `;
export const TemplateQuoteVersionTroubleshootDocument = gql`
    query TemplateQuoteVersionTroubleshoot($id: ID!, $versionNumber: Int!) {
  quoteTemplateVersion(id: $id, versionNumber: $versionNumber) {
    id
    resolvedAsReadModelVersionNumber
    title
  }
  compileDocIsolatedExpression(
    input: {docId: $id, deep: true, versionNumber: $versionNumber}
  ) {
    results {
      ...TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic
    }
  }
}
    ${TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnosticFragmentDoc}`;

/**
 * __useTemplateQuoteVersionTroubleshootQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteVersionTroubleshootQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteVersionTroubleshootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteVersionTroubleshootQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionNumber: // value for 'versionNumber'
 *   },
 * });
 */
export function useTemplateQuoteVersionTroubleshootQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables> & ({ variables: TemplateQuoteVersionTroubleshootQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>(TemplateQuoteVersionTroubleshootDocument, options);
      }
export function useTemplateQuoteVersionTroubleshootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>(TemplateQuoteVersionTroubleshootDocument, options);
        }
export function useTemplateQuoteVersionTroubleshootSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>(TemplateQuoteVersionTroubleshootDocument, options);
        }
export type TemplateQuoteVersionTroubleshootQueryHookResult = ReturnType<typeof useTemplateQuoteVersionTroubleshootQuery>;
export type TemplateQuoteVersionTroubleshootLazyQueryHookResult = ReturnType<typeof useTemplateQuoteVersionTroubleshootLazyQuery>;
export type TemplateQuoteVersionTroubleshootSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteVersionTroubleshootSuspenseQuery>;
export type TemplateQuoteVersionTroubleshootQueryResult = Apollo.QueryResult<TemplateQuoteVersionTroubleshootQuery, TemplateQuoteVersionTroubleshootQueryVariables>;