/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiscardUnpublishedChangesMutationVariables = Types.Exact<{
  input: Types.DiscardUnpublishedChangesInput;
}>;


export type DiscardUnpublishedChangesMutation = { discardUnpublishedChanges: boolean };


export const DiscardUnpublishedChangesDocument = gql`
    mutation DiscardUnpublishedChanges($input: DiscardUnpublishedChangesInput!) {
  discardUnpublishedChanges(input: $input)
}
    `;
export type DiscardUnpublishedChangesMutationFn = Apollo.MutationFunction<DiscardUnpublishedChangesMutation, DiscardUnpublishedChangesMutationVariables>;

/**
 * __useDiscardUnpublishedChangesMutation__
 *
 * To run a mutation, you first call `useDiscardUnpublishedChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardUnpublishedChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardUnpublishedChangesMutation, { data, loading, error }] = useDiscardUnpublishedChangesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardUnpublishedChangesMutation(baseOptions?: Apollo.MutationHookOptions<DiscardUnpublishedChangesMutation, DiscardUnpublishedChangesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiscardUnpublishedChangesMutation, DiscardUnpublishedChangesMutationVariables>(DiscardUnpublishedChangesDocument, options);
      }
export type DiscardUnpublishedChangesMutationHookResult = ReturnType<typeof useDiscardUnpublishedChangesMutation>;
export type DiscardUnpublishedChangesMutationResult = Apollo.MutationResult<DiscardUnpublishedChangesMutation>;
export type DiscardUnpublishedChangesMutationOptions = Apollo.BaseMutationOptions<DiscardUnpublishedChangesMutation, DiscardUnpublishedChangesMutationVariables>;