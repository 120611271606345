import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import StoreIcon from "@mui/icons-material/Store";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/Page";
import { TemplateTypeIcon } from "../../features/templateTypes/TemplateTypeIcon";
import { TemplateIcon } from "../../features/templates/TemplateIcon";
import { TemplateQuoteRoutes } from "./quote/TemplateQuoteRoutes";
import { TemplateQuoteVersionRoutes } from "./quote/TemplateQuoteVersionRoutes";
import { TemplateQuotes } from "./quote/TemplateQuotes";
import { TemplateTypeOverview } from "./quote/TemplateTypeOverview";
import { TemplateTypes } from "./quote/TemplateTypes";

type PageSubmenuItem = PageTopbarItem;

const submenuItemsEmpty: PageSubmenuItem[] = [];

export const TemplatesRoutes: React.FC = () => {
  const { t } = useTranslate("Templates");

  const submenuItems = React.useMemo(() => {
    const items: PageSubmenuItem[] = [
      {
        name: "templates-quotes-all",
        label: t("All Available"),
        path: `/templates/documents/all`,
        icon: <TemplateIcon />,
      },
      {
        name: "templates-quotes-my",
        label: t("My Templates"),
        path: `/templates/documents/my`,
        icon: <CorporateFareIcon />,
      },
      {
        name: "templates-quotes-store",
        label: t("Meister Store"),
        path: `/templates/documents/store`,
        icon: <StoreIcon />,
      },
      {
        name: "templates-types",
        label: t("Template types"),
        path: `/templates/types`,
        icon: <TemplateTypeIcon />,
      },
    ];

    return items.filter(item => !item.isHidden);
  }, [t]);

  const pathToDocList = "/templates/documents";
  const pathToTemplateTypesList = "/templates/types";

  return (
    <Routes>
      <Route index element={<Navigate to="documents" replace />} />

      <Route path="documents">
        <Route index element={<Navigate to={"all"} replace />} />
        <Route
          path={"all"}
          element={
            <TemplateQuotes
              key={"all"}
              submenuItems={submenuItems}
              section="ALL"
              pathToDocList={pathToDocList}
            />
          }
        />
        <Route
          path={"my"}
          element={
            <TemplateQuotes
              key={"my"}
              submenuItems={submenuItems}
              section="MY"
              pathToDocList={pathToDocList}
            />
          }
        />
        <Route
          path={"store"}
          element={
            <TemplateQuotes
              key={"store"}
              submenuItems={submenuItems}
              section="MEISTER_STORE"
              pathToDocList={pathToDocList}
            />
          }
        />
        <Route
          path={":id/latest/*"}
          element={<TemplateQuoteRoutes submenuItems={submenuItemsEmpty} />}
        />
        <Route
          path={":id/:versionNumber/*"}
          element={
            <TemplateQuoteVersionRoutes submenuItems={submenuItemsEmpty} />
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="types">
        <Route index element={<Navigate to={"all"} replace />} />
        <Route
          path={"all"}
          element={
            <TemplateTypes
              key={"all"}
              submenuItems={submenuItems}
              pathToDocList={pathToTemplateTypesList}
            />
          }
        />
        <Route
          path={":id"}
          element={
            <TemplateTypeOverview
              pathToDocList={pathToTemplateTypesList}
              submenuItems={submenuItemsEmpty}
            />
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Legacy redirects */}
      <Route path="quotes/*" element={<LegacyRouteRedirect />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function LegacyRouteRedirect() {
  const location = useLocation();
  const newPath = location.pathname.replace(
    "/templates/quotes",
    "/templates/documents"
  );

  return <Navigate to={newPath} replace />;
}
