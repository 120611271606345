import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { DocType, PermissionName } from "../../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionByTypeWithDebug } from "../../../auth/RestrictedByDocumentPermissionByType";
import { useUserData } from "../../../auth/useUserData";
import { FilesBoxTable } from "../../attachments/FilesBoxTable";
import { useItemAttachments } from "../../attachments/useAttachments";
import {
  ItemFilesBox_ItemFragment,
  useSetItemAttachmentClientVisbilityMutation,
} from "./ItemFilesBox.generated";

interface Props {
  projectId: string | null;
  docId: string;
  docType: DocType;
  viewerPermissions: PermissionName[];
  item: ItemFilesBox_ItemFragment;
  isEditable?: boolean;
  isInitiallyClosed?: boolean;
  onUpdateDataRefetchQueries?: string[];
}

export const ItemFilesBox = ({
  projectId,
  docId,
  docType,
  viewerPermissions,
  item,
  isEditable = true,
  isInitiallyClosed,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { t } = useTranslate("Global");
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const [setClientVisibility] = useSetItemAttachmentClientVisbilityMutation({
    client,
  });

  const setAttachmentClientVisibility = React.useCallback(
    async (attachmentId: string, clientVisibility: boolean) => {
      await setClientVisibility({
        variables: {
          input: {
            projectId,
            docId,
            itemId: item.id,
            attachmentId,
            clientVisibility,
          },
        },
      });
    },
    [docId, item.id, projectId, setClientVisibility]
  );

  const canChangeVisibility =
    item.authorOrganisationId === viewer.organisation.id;

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useItemAttachments(item.id, onUpdateDataRefetchQueries);

  return (
    <FilesBoxTable
      title={t("Attachments")}
      attachments={item.attachments}
      isInitiallyClosed={isInitiallyClosed}
      renderImages={false}
      canEdit={isEditable}
      setClientVisibility={
        canChangeVisibility ? setAttachmentClientVisibility : undefined
      }
      addAttachments={addAttachments}
      modifyAttachment={modifyAttachment}
      removeAttachment={removeAttachment}
      loading={loading}
      renderEditIconButton={button => (
        <RestrictedByDocumentPermissionByTypeWithDebug
          permissionByType={{
            QUOTE: "MANAGE_QUOTES",
            REQUIREMENT: "MANAGE_REQUIREMENTS",
            TEMPLATE: "MANAGE_ORG",
            BUILDING: "MANAGE_ORG",
          }}
          document={{ docType, viewerPermissions }}
        >
          {button}
        </RestrictedByDocumentPermissionByTypeWithDebug>
      )}
    />
  );
};
