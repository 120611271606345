/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { InvitationContactFragmentDoc } from '../../invitation/InvitationTokenProvider.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvitationRow__ProjectContracteeInvitationFragment = { __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState, invitationToken: string, title: string, projectId: string, messageBody: string, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, author: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, company: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type InvitationRow__OrganisationMembershipInvitationFragment = { __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState, invitationToken: string, title: string, organisationId: string, messageBody: string, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, author: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, organisationRoles?: Array<{ __typename: 'OrganisationRole', id: string, internalName: string, label: string }> | null, organisation: { __typename: 'Organisation', id: string, title: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type InvitationRow__ReceivedLinkingInvitationFragment = { __typename: 'ReceivedLinkingInvitation', id: string, title: string, token: string, publicProfileSlug?: string | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type InvitationListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InvitationListQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, receivedLinkingInvitations: Array<{ __typename: 'ReceivedLinkingInvitation', id: string, title: string, token: string, publicProfileSlug?: string | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> }, projectContracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState, invitationToken: string, title: string, projectId: string, messageBody: string, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, author: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, company: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } }> } | null };

export type InvitationListAcceptProjectContracteeInvitationMutationVariables = Types.Exact<{
  input: Types.AcceptProjectContracteeInvitationInput;
}>;


export type InvitationListAcceptProjectContracteeInvitationMutation = { acceptProjectContracteeInvitation: { __typename: 'AcceptProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState, invitationToken: string, title: string, projectId: string, messageBody: string, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, author: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, company: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } } } };

export type InvitationListDeclineProjectContracteeInvitationMutationVariables = Types.Exact<{
  invitationToken: Types.Scalars['String']['input'];
}>;


export type InvitationListDeclineProjectContracteeInvitationMutation = { declineProjectContracteeInvitation: { __typename: 'DeclineProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState, invitationToken: string, title: string, projectId: string, messageBody: string, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, author: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, company: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } } } };

export const InvitationRow__ProjectContracteeInvitationFragmentDoc = gql`
    fragment InvitationRow__ProjectContracteeInvitation on ProjectContracteeInvitation {
  id
  status
  invitationToken
  title
  contact {
    ...InvitationContact
  }
  author {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
    email
    phones {
      id
      main
      type
      number
    }
    avatar {
      ...AttachmentSnapshot
    }
    company {
      id
      title
      description
      website
      isMeister
      contactPerson {
        id
        title
        description
        website
        isMeister
        familyname
        firstname
        fullname
        locale
        skillset
      }
      logo {
        ...AttachmentSnapshot
      }
    }
  }
  projectId
  messageBody
}
    ${InvitationContactFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const InvitationRow__OrganisationMembershipInvitationFragmentDoc = gql`
    fragment InvitationRow__OrganisationMembershipInvitation on OrganisationMembershipInvitation {
  id
  status
  invitationToken
  title
  contact {
    ...InvitationContact
  }
  author {
    id
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
    email
    phones {
      id
      main
      type
      number
    }
    avatar {
      ...AttachmentSnapshot
    }
  }
  organisationRoles {
    id
    internalName
    label
  }
  organisation {
    id
    title
    branchAddress {
      ...AddressDetails__Address
    }
    billingAddress {
      ...AddressDetails__Address
    }
    logo {
      ...AttachmentSnapshot
    }
  }
  organisationId
  messageBody
}
    ${InvitationContactFragmentDoc}
${AttachmentSnapshotFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;
export const InvitationRow__ReceivedLinkingInvitationFragmentDoc = gql`
    fragment InvitationRow__ReceivedLinkingInvitation on ReceivedLinkingInvitation {
  id
  title
  token
  publicProfileSlug
  logo {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const InvitationListDocument = gql`
    query InvitationList {
  viewer {
    id
    organisation {
      id
      receivedLinkingInvitations {
        ...InvitationRow__ReceivedLinkingInvitation
      }
    }
    projectContracteeInvitations(filterState: [PENDING]) {
      ...InvitationRow__ProjectContracteeInvitation
    }
  }
}
    ${InvitationRow__ReceivedLinkingInvitationFragmentDoc}
${InvitationRow__ProjectContracteeInvitationFragmentDoc}`;

/**
 * __useInvitationListQuery__
 *
 * To run a query within a React component, call `useInvitationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationListQuery(baseOptions?: Apollo.QueryHookOptions<InvitationListQuery, InvitationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationListQuery, InvitationListQueryVariables>(InvitationListDocument, options);
      }
export function useInvitationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationListQuery, InvitationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationListQuery, InvitationListQueryVariables>(InvitationListDocument, options);
        }
export function useInvitationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationListQuery, InvitationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationListQuery, InvitationListQueryVariables>(InvitationListDocument, options);
        }
export type InvitationListQueryHookResult = ReturnType<typeof useInvitationListQuery>;
export type InvitationListLazyQueryHookResult = ReturnType<typeof useInvitationListLazyQuery>;
export type InvitationListSuspenseQueryHookResult = ReturnType<typeof useInvitationListSuspenseQuery>;
export type InvitationListQueryResult = Apollo.QueryResult<InvitationListQuery, InvitationListQueryVariables>;
export const InvitationListAcceptProjectContracteeInvitationDocument = gql`
    mutation InvitationListAcceptProjectContracteeInvitation($input: AcceptProjectContracteeInvitationInput!) {
  acceptProjectContracteeInvitation(input: $input) {
    invitation {
      id
      ...InvitationRow__ProjectContracteeInvitation
    }
  }
}
    ${InvitationRow__ProjectContracteeInvitationFragmentDoc}`;
export type InvitationListAcceptProjectContracteeInvitationMutationFn = Apollo.MutationFunction<InvitationListAcceptProjectContracteeInvitationMutation, InvitationListAcceptProjectContracteeInvitationMutationVariables>;

/**
 * __useInvitationListAcceptProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useInvitationListAcceptProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationListAcceptProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationListAcceptProjectContracteeInvitationMutation, { data, loading, error }] = useInvitationListAcceptProjectContracteeInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvitationListAcceptProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<InvitationListAcceptProjectContracteeInvitationMutation, InvitationListAcceptProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitationListAcceptProjectContracteeInvitationMutation, InvitationListAcceptProjectContracteeInvitationMutationVariables>(InvitationListAcceptProjectContracteeInvitationDocument, options);
      }
export type InvitationListAcceptProjectContracteeInvitationMutationHookResult = ReturnType<typeof useInvitationListAcceptProjectContracteeInvitationMutation>;
export type InvitationListAcceptProjectContracteeInvitationMutationResult = Apollo.MutationResult<InvitationListAcceptProjectContracteeInvitationMutation>;
export type InvitationListAcceptProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<InvitationListAcceptProjectContracteeInvitationMutation, InvitationListAcceptProjectContracteeInvitationMutationVariables>;
export const InvitationListDeclineProjectContracteeInvitationDocument = gql`
    mutation InvitationListDeclineProjectContracteeInvitation($invitationToken: String!) {
  declineProjectContracteeInvitation(invitationToken: $invitationToken) {
    invitation {
      id
      ...InvitationRow__ProjectContracteeInvitation
    }
  }
}
    ${InvitationRow__ProjectContracteeInvitationFragmentDoc}`;
export type InvitationListDeclineProjectContracteeInvitationMutationFn = Apollo.MutationFunction<InvitationListDeclineProjectContracteeInvitationMutation, InvitationListDeclineProjectContracteeInvitationMutationVariables>;

/**
 * __useInvitationListDeclineProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useInvitationListDeclineProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationListDeclineProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationListDeclineProjectContracteeInvitationMutation, { data, loading, error }] = useInvitationListDeclineProjectContracteeInvitationMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useInvitationListDeclineProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<InvitationListDeclineProjectContracteeInvitationMutation, InvitationListDeclineProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitationListDeclineProjectContracteeInvitationMutation, InvitationListDeclineProjectContracteeInvitationMutationVariables>(InvitationListDeclineProjectContracteeInvitationDocument, options);
      }
export type InvitationListDeclineProjectContracteeInvitationMutationHookResult = ReturnType<typeof useInvitationListDeclineProjectContracteeInvitationMutation>;
export type InvitationListDeclineProjectContracteeInvitationMutationResult = Apollo.MutationResult<InvitationListDeclineProjectContracteeInvitationMutation>;
export type InvitationListDeclineProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<InvitationListDeclineProjectContracteeInvitationMutation, InvitationListDeclineProjectContracteeInvitationMutationVariables>;