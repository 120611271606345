/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProjectListItemFragmentDoc } from '../projects/components/ProjectListItem.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RelatedToBox_ProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type RelatedToBox_ProjectQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, state: Types.ProjectStateMachineStatus, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null } | null } };

export type RelatedToBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, state: Types.ProjectStateMachineStatus, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null };

export type RelatedToBox_CrmCompanyQueryVariables = Types.Exact<{
  crmCompanyId: Types.Scalars['ID']['input'];
}>;


export type RelatedToBox_CrmCompanyQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } | null } | { __typename: 'MissingPermissions' } };

export type RelatedToBox_CompanyFragment = { __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type RelatedToBox_CrmPersonQueryVariables = Types.Exact<{
  crmPersonId: Types.Scalars['ID']['input'];
}>;


export type RelatedToBox_CrmPersonQuery = { crmPerson: { __typename: 'CrmPersonResult', crmPerson?: { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } | null } | { __typename: 'MissingPermissions' } };

export type RelatedToBox_CrmPersonFragment = { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export const RelatedToBox_ProjectFragmentDoc = gql`
    fragment RelatedToBox_Project on Project {
  id
  ...ProjectListItem
}
    ${ProjectListItemFragmentDoc}`;
export const RelatedToBox_CompanyFragmentDoc = gql`
    fragment RelatedToBox_Company on CrmCompany {
  id
  title
  logo {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const RelatedToBox_CrmPersonFragmentDoc = gql`
    fragment RelatedToBox_CrmPerson on CrmPerson {
  id
  firstname
  familyname
  fullname
  avatar {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const RelatedToBox_ProjectDocument = gql`
    query RelatedToBox_Project($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        ...RelatedToBox_Project
      }
    }
  }
}
    ${RelatedToBox_ProjectFragmentDoc}`;

/**
 * __useRelatedToBox_ProjectQuery__
 *
 * To run a query within a React component, call `useRelatedToBox_ProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedToBox_ProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedToBox_ProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRelatedToBox_ProjectQuery(baseOptions: Apollo.QueryHookOptions<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables> & ({ variables: RelatedToBox_ProjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>(RelatedToBox_ProjectDocument, options);
      }
export function useRelatedToBox_ProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>(RelatedToBox_ProjectDocument, options);
        }
export function useRelatedToBox_ProjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>(RelatedToBox_ProjectDocument, options);
        }
export type RelatedToBox_ProjectQueryHookResult = ReturnType<typeof useRelatedToBox_ProjectQuery>;
export type RelatedToBox_ProjectLazyQueryHookResult = ReturnType<typeof useRelatedToBox_ProjectLazyQuery>;
export type RelatedToBox_ProjectSuspenseQueryHookResult = ReturnType<typeof useRelatedToBox_ProjectSuspenseQuery>;
export type RelatedToBox_ProjectQueryResult = Apollo.QueryResult<RelatedToBox_ProjectQuery, RelatedToBox_ProjectQueryVariables>;
export const RelatedToBox_CrmCompanyDocument = gql`
    query RelatedToBox_CrmCompany($crmCompanyId: ID!) {
  crmCompany(id: $crmCompanyId) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        ...RelatedToBox_Company
      }
    }
  }
}
    ${RelatedToBox_CompanyFragmentDoc}`;

/**
 * __useRelatedToBox_CrmCompanyQuery__
 *
 * To run a query within a React component, call `useRelatedToBox_CrmCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedToBox_CrmCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedToBox_CrmCompanyQuery({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useRelatedToBox_CrmCompanyQuery(baseOptions: Apollo.QueryHookOptions<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables> & ({ variables: RelatedToBox_CrmCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>(RelatedToBox_CrmCompanyDocument, options);
      }
export function useRelatedToBox_CrmCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>(RelatedToBox_CrmCompanyDocument, options);
        }
export function useRelatedToBox_CrmCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>(RelatedToBox_CrmCompanyDocument, options);
        }
export type RelatedToBox_CrmCompanyQueryHookResult = ReturnType<typeof useRelatedToBox_CrmCompanyQuery>;
export type RelatedToBox_CrmCompanyLazyQueryHookResult = ReturnType<typeof useRelatedToBox_CrmCompanyLazyQuery>;
export type RelatedToBox_CrmCompanySuspenseQueryHookResult = ReturnType<typeof useRelatedToBox_CrmCompanySuspenseQuery>;
export type RelatedToBox_CrmCompanyQueryResult = Apollo.QueryResult<RelatedToBox_CrmCompanyQuery, RelatedToBox_CrmCompanyQueryVariables>;
export const RelatedToBox_CrmPersonDocument = gql`
    query RelatedToBox_CrmPerson($crmPersonId: ID!) {
  crmPerson(id: $crmPersonId) {
    ... on CrmPersonResult {
      crmPerson {
        id
        ...RelatedToBox_CrmPerson
      }
    }
  }
}
    ${RelatedToBox_CrmPersonFragmentDoc}`;

/**
 * __useRelatedToBox_CrmPersonQuery__
 *
 * To run a query within a React component, call `useRelatedToBox_CrmPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedToBox_CrmPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedToBox_CrmPersonQuery({
 *   variables: {
 *      crmPersonId: // value for 'crmPersonId'
 *   },
 * });
 */
export function useRelatedToBox_CrmPersonQuery(baseOptions: Apollo.QueryHookOptions<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables> & ({ variables: RelatedToBox_CrmPersonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>(RelatedToBox_CrmPersonDocument, options);
      }
export function useRelatedToBox_CrmPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>(RelatedToBox_CrmPersonDocument, options);
        }
export function useRelatedToBox_CrmPersonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>(RelatedToBox_CrmPersonDocument, options);
        }
export type RelatedToBox_CrmPersonQueryHookResult = ReturnType<typeof useRelatedToBox_CrmPersonQuery>;
export type RelatedToBox_CrmPersonLazyQueryHookResult = ReturnType<typeof useRelatedToBox_CrmPersonLazyQuery>;
export type RelatedToBox_CrmPersonSuspenseQueryHookResult = ReturnType<typeof useRelatedToBox_CrmPersonSuspenseQuery>;
export type RelatedToBox_CrmPersonQueryResult = Apollo.QueryResult<RelatedToBox_CrmPersonQuery, RelatedToBox_CrmPersonQueryVariables>;