import { DatePicker, FormattedPriceInput } from "@msys/ui";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Moment } from "moment";
import { browserHasInputDate } from "../../featureDetection";
import { OrganisationUserSelect } from "../../features/organisations/OrganisationUserSelect";
import { OrganisationUsersSelect } from "../../features/organisations/OrganisationUsersSelect";

export interface FilterProps<T> {
  value: T;
  setValue: (newValue: T) => void;
}

type FilterByEnumProps<T> = {
  label: string;
  anyEnum: Record<string, T>;
  getOptionLabel?: (option: T) => string;
} & (
  | {
      type: "one";
      setValue: (newValue: T) => void;
      value: T;
    }
  | {
      type: "many";
      setValue: (newValue: T[]) => void;
      value: T[];
    }
);

export function FilterByEnum<T extends string>(props: FilterByEnumProps<T>) {
  const { t } = useTranslate(["Global"]);

  if (props.type !== "many") {
    return (
      <FormControl fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select
          onChange={e => {
            props.setValue(e.target.value as T);
          }}
          value={props.value}
        >
          {Object.entries(props.anyEnum).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {String(value)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Autocomplete
      multiple
      openOnFocus
      disableCloseOnSelect
      noOptionsText={t("No existing entry", {
        ns: "Global",
      })}
      options={Object.entries(props.anyEnum).map(([key, value]) => value)}
      value={props.value as T[]}
      onChange={(event, newValues) => {
        // @ts-ignore // FIXME
        props.setValue(newValues);
      }}
      getOptionLabel={value =>
        props.getOptionLabel ? props.getOptionLabel(value) : String(value)
      }
      renderInput={params => <TextField {...params} label={props.label} />}
    />
  );
}

type FilterByComplexEnumProps<T> = {
  label: string;
  anyEnum: Record<string, T>;
  getOptionLabel: (option: T) => string;
} & (
  | {
      type: "one";
      setValue: (newValue: T) => void;
      value: T;
    }
  | {
      type: "many";
      setValue: (newValue: T[]) => void;
      value: T[];
    }
);

export function FilterByComplexEnum<T>(props: FilterByComplexEnumProps<T>) {
  const { t } = useTranslate(["Global"]);

  if (props.type !== "many") {
    return (
      <FormControl fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select
          onChange={e => {
            props.setValue(e.target.value as T);
          }}
          value={props.value}
        >
          {Object.entries(props.anyEnum).map(([key, value]) => (
            <MenuItem key={key} value={props.getOptionLabel(value)}>
              {String(value)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Autocomplete
      multiple
      openOnFocus
      disableCloseOnSelect
      noOptionsText={t("No existing entry", {
        ns: "Global",
      })}
      options={Object.entries(props.anyEnum).map(([key, value]) => value)}
      value={props.value as T[]}
      onChange={(event, newValues) => {
        // @ts-ignore // FIXME
        props.setValue(newValues);
      }}
      getOptionLabel={value =>
        props.getOptionLabel ? props.getOptionLabel(value) : String(value)
      }
      renderInput={params => <TextField {...params} label={props.label} />}
    />
  );
}

export function FilterByText(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<string>
) {
  return (
    <TextField
      label={props.label}
      type="text"
      helperText={props.helperText}
      value={props.value}
      onChange={e => props.setValue(e.target.value)}
      placeholder={props.placeholder}
    />
  );
}

export function FilterByNumber(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<number>
) {
  return (
    <FilterByText
      label={props.label}
      placeholder={props.placeholder}
      value={props.value.toString()}
      helperText={props.helperText}
      setValue={value =>
        props.setValue(
          !Number.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0
        )
      }
    />
  );
}

export function FilterByPrice(
  props: {
    label: string;
    placeholder: string;
    helperText?: string;
  } & FilterProps<number | null>
) {
  return (
    <FormattedPriceInput
      label={props.label}
      value={props.value}
      placeholder={props.placeholder}
      helperText={props.helperText}
      onChange={value => props.setValue(value)}
    />
  );
}

export function FilterByDate(
  props: {
    label: string;
  } & FilterProps<Moment | null>
) {
  return (
    <DatePicker
      browserHasInputDate={browserHasInputDate}
      label={props.label}
      value={props.value}
      onChange={(event, value) => props.setValue(value)}
    />
  );
}

export function FilterByOrganisationUser(
  props: {
    label: string;
    placeholder: string;
  } & FilterProps<string | null>
) {
  return (
    <OrganisationUserSelect
      inputLabel={props.label}
      placeholder={props.placeholder}
      userId={props.value}
      onChange={value => props.setValue(value)}
    />
  );
}

export function FilterByOrganisationUsers(
  props: {
    label: string;
    placeholder: string;
  } & FilterProps<string[]>
) {
  return (
    <OrganisationUsersSelect
      inputLabel={props.label}
      placeholder={props.placeholder}
      userIds={props.value}
      onChange={value => props.setValue(value)}
    />
  );
}

export function FilterByBoolean(
  props: FilterProps<boolean | undefined> & {
    label: string;
    yesLabel?: string;
    noLabel?: string;
    allLabel?: string;
  }
) {
  const { t } = useTranslate("Global");
  const toValueLabel = (value: boolean | undefined): "yes" | "no" | "all" =>
    value === true ? "yes" : value === false ? "no" : "all";
  const fromValueLabel = (value: "yes" | "no" | "all"): boolean | undefined =>
    value === "yes" ? true : value === "no" ? false : undefined;
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        onChange={e => {
          props.setValue(
            fromValueLabel(e.target.value as "yes" | "no" | "all")
          );
        }}
        value={toValueLabel(props.value)}
      >
        <MenuItem value={"all"}>{props.allLabel || t("All")}</MenuItem>
        <MenuItem value={"yes"}>{props.yesLabel || t("Yes")}</MenuItem>
        <MenuItem value={"no"}>{props.noLabel || t("No")}</MenuItem>
      </Select>
    </FormControl>
  );
}

export function FilterCheckbox(
  props: FilterProps<boolean | undefined> & {
    label: string;
  }
) {
  return (
    <FormControl variant="standard">
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            indeterminate={false}
            onChange={(event, checked) => {
              props.setValue(checked || undefined);
            }}
            size="small"
          />
        }
        label={props.label}
      />
    </FormControl>
  );
}
