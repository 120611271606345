import { isArray, isBoolean, isNumber, isString } from "lodash";
import { getFormattedNumber } from "@msys/formatting";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../commons/filters/FilterChip";
import { useTolgee, useTranslate } from "@tolgee/react";

type PropertyFilterValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[];

type PropertyFilter = {
  key: string;
  operator:
    | "eq"
    | "lt"
    | "gt"
    | "gte"
    | "lte"
    | "in"
    | "between"
    | "anyOf"
    | "allOf";
  value: PropertyFilterValue;
};

export function PropertiesFilterChips(
  props: Omit<FilterChipGroupProps<PropertyFilter>, "getValueLabel">
) {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["Global"]);

  const showPropertyValue = (value: PropertyFilterValue): string => {
    if (isArray(value)) return value.map(v => showPropertyValue(v)).join(", ");
    if (isNumber(value)) return getFormattedNumber(value, language);
    if (isBoolean(value))
      return value ? t("Yes", { ns: "Global" }) : t("No", { ns: "Global" });
    if (isString(value)) return value;
    return value;
  };
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value =>
        `${value.key}${mapOperator(value.operator)}${showPropertyValue(
          value.value
        )}`
      }
    />
  );
}

function mapOperator(
  operator:
    | "eq"
    | "lt"
    | "gt"
    | "gte"
    | "lte"
    | "in"
    | "between"
    | "anyOf"
    | "allOf"
) {
  switch (operator) {
    case "eq":
      return ": ";
    case "lt":
      return " < ";
    case "lte":
      return " ≤ ";
    case "gt":
      return " > ";
    case "gte":
      return " ≥ ";
    case "in":
      return " in ";
    case "between":
      return " between ";
    case "allOf":
      return " all of ";
    case "anyOf":
      return " any of ";
    default:
      return operator;
  }
}
