/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Meister1FlowsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Meister1FlowsQuery = { meister1Flows: Array<{ __typename: 'Meister1Flow', id: string, label: string, identName: string, calculatorCategoryCraftId?: string | null, calculatorId: number }>, organisationIntegrations: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OrganisationIntegrationConnection', edges: Array<{ __typename: 'OrganisationIntegrationEdge', node: { __typename: 'OrganisationIntegration', id: string, foreignAccountId?: string | null, settings: any, integration: { __typename: 'Integration', id: string, name: string } } }> } };

export type Meister1IntegrationFragment = { __typename: 'OrganisationIntegration', id: string, foreignAccountId?: string | null, settings: any, integration: { __typename: 'Integration', id: string, name: string } };

export const Meister1IntegrationFragmentDoc = gql`
    fragment Meister1Integration on OrganisationIntegration {
  id
  integration {
    id
    name
  }
  foreignAccountId
  settings
}
    `;
export const Meister1FlowsDocument = gql`
    query Meister1Flows {
  meister1Flows {
    id
    label
    identName
    calculatorCategoryCraftId
    calculatorId
  }
  organisationIntegrations(filter: {integrationId: ["meister1"]}, limit: 1) {
    ... on OrganisationIntegrationConnection {
      edges {
        node {
          id
          ...Meister1Integration
        }
      }
    }
  }
}
    ${Meister1IntegrationFragmentDoc}`;

/**
 * __useMeister1FlowsQuery__
 *
 * To run a query within a React component, call `useMeister1FlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeister1FlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeister1FlowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeister1FlowsQuery(baseOptions?: Apollo.QueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
      }
export function useMeister1FlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
        }
export function useMeister1FlowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Meister1FlowsQuery, Meister1FlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Meister1FlowsQuery, Meister1FlowsQueryVariables>(Meister1FlowsDocument, options);
        }
export type Meister1FlowsQueryHookResult = ReturnType<typeof useMeister1FlowsQuery>;
export type Meister1FlowsLazyQueryHookResult = ReturnType<typeof useMeister1FlowsLazyQuery>;
export type Meister1FlowsSuspenseQueryHookResult = ReturnType<typeof useMeister1FlowsSuspenseQuery>;
export type Meister1FlowsQueryResult = Apollo.QueryResult<Meister1FlowsQuery, Meister1FlowsQueryVariables>;