import {
  CollapseButton,
  CollapseSection,
  FormattedPrice,
  isImageOr3dModel,
  isPdfOrFile,
  ModalOpenButton,
  processAttachment,
  TextWithOverflow,
  useScreenWidth,
} from "@msys/ui";
import {
  Box,
  Button,
  IconButton,
  List,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash";
import React from "react";
import { AskWhom, PermissionName } from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { GalleryRow } from "../../commons/images/GalleryRow";
import {
  ALL_KEY,
  ExpandedStore,
} from "../../trees-virtual/hooks/useExpandedStore";
import { FileRow } from "../attachments/FileRow";
import { FilesBoxTable } from "../attachments/FilesBoxTable";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton";
import { ContingencyCheckbox } from "../doc-items/fields/ContingencyCheckbox";
import { DecisionOptionRadioOrCheckbox } from "../doc-items/fields/DecisionOptionRadioOrCheckbox";
import { SubitemsOptionsNoneSection } from "../doc-items/fields/SubitemsOptionsNoneSection";
import { getViewerDecisionRole, hasAnyQuestions } from "../doc-items/helpers";
import { useDecisionItemMutations } from "../doc-items/hooks/useDecisionItemMutations";
import { useQuantityUnits } from "../doc-items/useQuantityUnits";
import { DocumentPreviewItems } from "../documents/DocumentPreviewItems";
import { Fields } from "../documents/DocumentPreviewItemsTypes";
import { M1Inputs } from "./M1Inputs";
import { RequirementPreviewHeader } from "./preview/RequirementPreviewHeader";
import {
  requirementItemHasDescription,
  RequirementPreviewItemDescription,
} from "./preview/RequirementPreviewItemDescription";
import {
  RequirementPreview_ItemFragment,
  RequirementPreview_RequirementFragment,
} from "./RequirementPreview.generated";
import EditIcon from "@mui/icons-material/Edit";
import { RequirementDocumentActorsModal } from "./RequirementDocumentActorsModal";
import { DocumentActorData } from "../documents/DocumentActorData";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";

interface Props<CanFinalize extends boolean> {
  navigateToItem?: (item: RequirementPreview_ItemFragment) => void;
  selectedItemId?: string | null;
  requirement: RequirementPreview_RequirementFragment;
  canFinalize: CanFinalize;
  expandedStore?: ExpandedStore;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
  container?: HTMLElement | null;
}

export const RequirementPreview = <CanFinalize extends boolean>({
  navigateToItem,
  selectedItemId,
  requirement,
  canFinalize,
  expandedStore,
  expandedItemIds,
  refetch,
  container,
}: Props<CanFinalize>) => {
  const viewer = useUserData().currentUser!;
  const { isMinTablet, isMinDesktop } = useScreenWidth();

  const { t } = useTranslate(["Quote", "Decisions", "Global"]);

  const allDocItems = requirement.items;

  const rootItem = allDocItems.find(item => item.isRootItem);

  const [images, files] = partition(
    (rootItem?.attachments ?? [])
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment),
    isImageOr3dModel
  );

  const viewerIsOwner =
    viewer.organisation.id === requirement.owningSystemOrganisationId;

  const viewerDecisionRole = getViewerDecisionRole(requirement.actors);

  const extraActors = React.useMemo(
    () =>
      requirement.actors.filter(actor => !["CONTRACTEE"].includes(actor.type)),
    [requirement.actors]
  );

  return (
    <>
      <Stack direction="column" width="100%" spacing={1}>
        <RequirementPreviewHeader requirement={requirement} />

        {extraActors.length > 0 && (
          <Paper sx={{ position: "relative", padding: 2, paddingRight: 2 }}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyItems="space-between"
              spacing={4}
            >
              <Stack direction="row" spacing={4} flex={1}>
                {extraActors.map(actor => (
                  <DocumentActorData actorType={actor.type} actor={actor} />
                ))}
              </Stack>
              <RestrictedByDocumentPermissionWithDebug
                permission="MANAGE_REQUIREMENTS_ACTORS"
                document={requirement}
              >
                <ModalOpenButton
                  Modal={RequirementDocumentActorsModal}
                  modalProps={{
                    projectId: requirement.projectId,
                    requirementId: requirement.id,
                  }}
                >
                  <IconButton color="secondary" size="small">
                    <EditIcon />
                  </IconButton>
                </ModalOpenButton>
              </RestrictedByDocumentPermissionWithDebug>
            </Stack>
          </Paper>
        )}

        <Paper>
          <Box padding={2}>
            {canFinalize &&
              rootItem &&
              hasAnyQuestions(rootItem, "REQUIREMENT", viewerDecisionRole) && (
                <div style={{ float: "right", display: "flex" }}>
                  <DecisionModalButton
                    projectId={requirement.projectId}
                    docType="REQUIREMENT"
                    docId={requirement.id}
                    itemId={rootItem.id}
                    canFinalize={true}
                    viewerDecisionRole={viewerDecisionRole}
                    expandedItemIds={expandedItemIds}
                    type="icon"
                    refetch={refetch}
                    isOptionHidden={item => !viewerIsOwner && item.isHidden}
                  />
                </div>
              )}
            <Typography variant="h2">{requirement.title}</Typography>
          </Box>
        </Paper>

        {requirement.m1 ? <M1Inputs m1={requirement.m1} /> : null}

        {rootItem &&
          (images.length > 0 || requirementItemHasDescription(rootItem)) && (
            <Paper>
              <Stack padding={2} direction={"column"} spacing={2}>
                <GalleryRow
                  images={images}
                  maxItems={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
                />

                <RequirementPreviewItemDescription
                  item={rootItem}
                  columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
                />
              </Stack>
            </Paper>
          )}

        <RequirementPreviewItems
          viewerIsOwner={viewerIsOwner}
          viewerDecisionRole={viewerDecisionRole}
          projectId={
            /* prettier-ignore */
            (canFinalize ? requirement.projectId : null) as (CanFinalize extends true ? string : null)
          }
          requirementId={requirement.id}
          canFinalize={canFinalize}
          allDocItems={allDocItems}
          navigateToItem={navigateToItem}
          selectedItemId={selectedItemId}
          expandedStore={expandedStore}
          expandedItemIds={expandedItemIds}
          container={container}
        />

        <Paper>
          <Stack direction="column" p={2} spacing={1}>
            <Typography variant="h2" component="div">
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <span>
                  {t("Subtotal", {
                    ns: "Quote",
                  })}
                </span>
                <FormattedPrice isPlaceholder={true} />
              </Stack>
            </Typography>
            <Typography variant="h1" component="div">
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <span>
                  {t("Total", {
                    ns: "Quote",
                  })}
                </span>
                <FormattedPrice isPlaceholder={true} />
              </Stack>
            </Typography>
          </Stack>
        </Paper>

        {files.length > 0 && (
          <FilesBoxTable
            title={t("Attachments", { ns: "Quote" })}
            attachments={files}
            canEdit={false}
            gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
          />
        )}
      </Stack>
    </>
  );
};

export const RequirementPreviewItems = <CanFinalize extends boolean>({
  viewerDecisionRole,
  projectId,
  requirementId,
  canFinalize,
  navigateToItem,
  selectedItemId,
  allDocItems,
  expandedStore,
  expandedItemIds,
  refetch,
  container,
  viewerIsOwner,
}: {
  viewerDecisionRole: AskWhom | undefined;
  projectId: CanFinalize extends true ? string : null;
  requirementId: string;
  canFinalize: CanFinalize;
  navigateToItem?: (item: RequirementPreview_ItemFragment) => void;
  selectedItemId?: string | null;
  allDocItems: RequirementPreview_ItemFragment[];
  expandedStore?: ExpandedStore;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
  container?: HTMLElement | null;
  viewerIsOwner: boolean;
}) => {
  const { t } = useTranslate(["Quote", "Decisions", "Global"]);

  const { quantityUnitLabels } = useQuantityUnits();

  const {
    handleDecisionPreselectionChange,
    handleContingencyPreselectionChange,
    handleFinalizeContingencyDecision,
    handleFinalizeSubitemDecision,
    canForceFinalizeSubitemDecision,
    canFinalizeContingencyDecision,
    loading,
  } = useDecisionItemMutations<RequirementPreview_ItemFragment>(
    projectId,
    requirementId,
    canFinalize,
    expandedItemIds
  );

  const collapseItemAndChildrenRecursively = React.useCallback(
    (item: RequirementPreview_ItemFragment) => {
      if (!expandedStore) return;
      const toCollapseIds = allDocItems
        .filter(i => i.pathForPdf.startsWith(item.pathForPdf))
        .map(i => i.id);
      const toExpandItemIds = expandedStore.areAllItemsExpanded
        ? allDocItems
            .filter(i => i.hasChildren && !toCollapseIds.includes(i.id))
            .map(i => i.id)
        : [];
      expandedStore.setExpandedState(
        Object.fromEntries([
          [ALL_KEY, false],
          ...toExpandItemIds.map(id => [id, true]),
          ...toCollapseIds.map(id => [id, false]),
        ])
      );
    },
    [expandedStore, allDocItems]
  );

  const fields = React.useMemo(
    (): Fields<RequirementPreview_ItemFragment> => ({
      CollapseButton: {
        render: item => {
          if (!expandedStore) return null;
          const expanded = expandedStore.isItemExpanded(item.id);
          return item.hasChildren ? (
            <CollapseButton
              aria-label={expanded ? "Collapse" : "Expand"}
              isExpanded={expanded}
              setIsExpanded={newExpanded => {
                if (newExpanded) {
                  expandedStore.setItemExpanded(item.id, newExpanded);
                } else {
                  collapseItemAndChildrenRecursively(item);
                }
              }}
            />
          ) : null;
        },
      },
      NonBindingCaption: {
        render: item => {
          if (item.type === "paid" && item.binding === "non_binding") {
            return t("Non-Binding item", {
              ns: "Quote",
            });
          }

          return null;
        },
      },
      ContingencyOptionalCaption: {
        render: item => {
          if (item.decisionIsContingentItem) {
            const captions: string[] = [];
            captions.push(
              t("Optional item", {
                ns: "Quote",
              })
            );

            if (item.decisionContingentItemPreselection) {
              captions.push(
                t("Recommended", {
                  ns: "Quote",
                })
              );
            }

            return captions.join(" • ");
          }

          return null;
        },
      },
      ContingencyOptionsButtons: {
        render: item => {
          return item.decisionIsContingentItem ? (
            <ContingencyCheckbox
              item={item}
              loading={loading}
              canEdit={true}
              handleContingencyPreselectionChange={async (...args) => {
                await handleContingencyPreselectionChange(...args);
                await refetch?.();
              }}
            />
          ) : null;
        },
      },
      ContingencyFinalizeButton: {
        render: item => {
          return item.decisionIsContingentItem && canFinalize ? (
            <Button
              disabled={loading || !canFinalizeContingencyDecision(item)}
              size="extra-small"
              variant="contained"
              color="primary"
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                await handleFinalizeContingencyDecision(item.id);
                await refetch?.();
              }}
            >
              {t("Resolve", {
                ns: "Quote",
              })}
            </Button>
          ) : null;
        },
      },
      DecisionOptionButton: {
        render: (item, parent) => {
          return parent?.type === "decision" &&
            !item.decisionOptionElimination ? (
            <DecisionOptionRadioOrCheckbox
              docId={requirementId}
              projectId={projectId}
              item={item}
              disabled={!canFinalize || loading}
              expandedItemIds={expandedItemIds}
              refetch={refetch}
            />
          ) : null;
        },
      },
      DecisionNoneOptionButton: {
        render: item => {
          return item.decisionBehaviorOfSubitems === "SELECT_ONE" ? (
            <SubitemsOptionsNoneSection
              item={item}
              loading={loading}
              canEdit={true}
              handleDecisionPreselectionChange={async (...args) => {
                await handleDecisionPreselectionChange(...args);
                await refetch?.();
              }}
            />
          ) : null;
        },
      },
      DecisionButton: {
        render: item =>
          canFinalize &&
          hasAnyQuestions(item, "REQUIREMENT", viewerDecisionRole) ? (
            <DecisionModalButton
              projectId={projectId}
              docType="REQUIREMENT"
              docId={requirementId}
              itemId={item.id}
              canFinalize={true}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedItemIds}
              type="icon"
              refetch={refetch}
              isOptionHidden={item => !viewerIsOwner && item.isHidden}
            />
          ) : null,
      },
      DecisionFinalizeButton: {
        render: item => {
          return item.type === "decision" && canFinalize ? (
            <Stack direction="row" spacing={0.5} flexWrap="wrap">
              <Button
                disabled={loading || !canForceFinalizeSubitemDecision(item)}
                size="extra-small"
                variant="contained"
                color="primary"
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (item.decisionIsContingentItem) {
                    if (item.decisionContingentItemPreselection !== true) {
                      await handleContingencyPreselectionChange(item.id, true);
                    }
                    await handleFinalizeContingencyDecision(item.id);
                  }

                  await handleFinalizeSubitemDecision(item.id);
                  await refetch?.();
                }}
              >
                {t("Finalize decision", {
                  ns: "Quote",
                })}
              </Button>
            </Stack>
          ) : null;
        },
      },
      Pos: {
        render: item => <TextWithOverflow>{item.pathForPdf}</TextWithOverflow>,
      },
      Title: {
        render: item => {
          return <Typography variant="h3">{item.title}</Typography>;
        },
      },
      Description: {
        render: item => {
          if (!requirementItemHasDescription(item)) return null;
          return <RequirementPreviewItemDescription item={item} columns={3} />;
        },
      },
      Images: {
        render: item => {
          const images = item.attachments
            .filter(a => a.clientVisibility)
            .map(processAttachment)
            .filter(isImageOr3dModel);
          return images.length > 0 ? (
            <GalleryRow
              images={images}
              maxItems={4}
              style={item.level > 2 ? { maxWidth: "300px" } : undefined}
            />
          ) : null;
        },
      },
      Attachments: {
        render: item => {
          const files = item.attachments
            .filter(a => a.clientVisibility)
            .map(processAttachment)
            .filter(isPdfOrFile);
          return files.length > 0 ? (
            <CollapseSection
              title={t("Attachments", { ns: "Quote" })}
              titleVariant="body2"
              titleColor="secondary"
              iconButtonColor="secondary"
              minHeight="auto"
              isInitiallyExpanded={false}
              collapseTimeout={0}
            >
              <List
                disablePadding
                dense
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {files.map(attachment => (
                  <FileRow
                    key={attachment.id}
                    file={attachment}
                    button={false}
                    divider={false}
                    sx={{ minWidth: "200px", maxWidth: "320px", width: "auto" }}
                  />
                ))}
              </List>
            </CollapseSection>
          ) : null;
        },
      },
      Quantity: {
        render: item => {
          const { proposedCalculation } = item;

          if (item.type === "section") {
            if (proposedCalculation && proposedCalculation.quantity !== 1) {
              return (
                <Box display="flex">
                  {proposedCalculation
                    ? `${proposedCalculation.quantity}`
                    : "0"}
                </Box>
              );
            }
          }

          if (item.type === "paid") {
            return (
              <Box display="flex">
                {proposedCalculation
                  ? `${proposedCalculation.quantity} ${
                      quantityUnitLabels[proposedCalculation.quantityUnit]
                    }`
                  : "0"}
              </Box>
            );
          }

          return null;
        },
      },
      UnitPrice: {
        render: () => null,
      },
      Subtotal: {
        render: (item, parent) => {
          return !parent?.isRootItem ? <FormattedPrice isPlaceholder /> : null;
        },
      },
      TotalPrice: {
        render: (item, parent) => {
          return parent?.isRootItem ? <FormattedPrice isPlaceholder /> : null;
        },
      },
    }),
    [
      expandedStore,
      collapseItemAndChildrenRecursively,
      t,
      loading,
      handleContingencyPreselectionChange,
      refetch,
      canFinalize,
      canFinalizeContingencyDecision,
      handleFinalizeContingencyDecision,
      requirementId,
      projectId,
      expandedItemIds,
      handleDecisionPreselectionChange,
      viewerDecisionRole,
      viewerIsOwner,
      canForceFinalizeSubitemDecision,
      handleFinalizeSubitemDecision,
      quantityUnitLabels,
    ]
  );

  return (
    <DocumentPreviewItems<RequirementPreview_ItemFragment>
      doc={{
        id: requirementId,
        contractType: "fup",
        isBinding: false,
        agreement: "NONE",
      }}
      fields={fields}
      showCollapseButton={Boolean(expandedStore)}
      shouldRenderItem={item =>
        !item.isHidden &&
        !item.isItemEliminated &&
        !item.isAnyParentItemEliminated
      }
      allDocItems={allDocItems}
      navigateToItem={navigateToItem}
      selectedItemId={selectedItemId}
      isTimeAndMaterialContract={false}
      getItemStyle={getRequirementItemStyle}
      container={container}
      expandedStore={expandedStore}
    />
  );
};

const getRequirementItemStyle = (
  theme: Theme,
  item: RequirementPreview_ItemFragment
): React.CSSProperties | undefined => ({
  color:
    item.decisionOptionElimination ||
    item.isParentDecisionNotPreselected ||
    item.isParentContingencyNotPreselected ||
    (item.decisionIsContingentItem &&
      item.decisionContingentItemPreselection !== true)
      ? theme.palette.grey[600]
      : undefined,
});
