/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { SearchGlobalSuppliersRowFragmentDoc } from '../../features/crm/Crm.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalSuppliersTableQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sorting?: Types.InputMaybe<Array<Types.QuerySearchSuppliersSorting>>;
}>;


export type GlobalSuppliersTableQuery = { searchSuppliers: { __typename: 'SupplierSearchResultConnection', totalCount: number, edges: Array<{ __typename: 'SupplierSearchResultEdge', node: { __typename: 'SupplierSearchResult', distance?: number | null, organisation: { __typename: 'Company', id: string, title: string, description: string, website: string, isMeister: boolean, email: any, contactPerson?: { __typename: 'Person', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }> } } }> } };


export const GlobalSuppliersTableDocument = gql`
    query GlobalSuppliersTable($offset: Int!, $limit: Int!, $search: String, $sorting: [QuerySearchSuppliersSorting!]) {
  searchSuppliers(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {excludeIfAlreadyCrm: false}
    search: $search
  ) {
    edges {
      node {
        distance
        organisation {
          ...SearchGlobalSuppliersRow
        }
      }
    }
    totalCount
  }
}
    ${SearchGlobalSuppliersRowFragmentDoc}`;

/**
 * __useGlobalSuppliersTableQuery__
 *
 * To run a query within a React component, call `useGlobalSuppliersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSuppliersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSuppliersTableQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGlobalSuppliersTableQuery(baseOptions: Apollo.QueryHookOptions<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables> & ({ variables: GlobalSuppliersTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>(GlobalSuppliersTableDocument, options);
      }
export function useGlobalSuppliersTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>(GlobalSuppliersTableDocument, options);
        }
export function useGlobalSuppliersTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>(GlobalSuppliersTableDocument, options);
        }
export type GlobalSuppliersTableQueryHookResult = ReturnType<typeof useGlobalSuppliersTableQuery>;
export type GlobalSuppliersTableLazyQueryHookResult = ReturnType<typeof useGlobalSuppliersTableLazyQuery>;
export type GlobalSuppliersTableSuspenseQueryHookResult = ReturnType<typeof useGlobalSuppliersTableSuspenseQuery>;
export type GlobalSuppliersTableQueryResult = Apollo.QueryResult<GlobalSuppliersTableQuery, GlobalSuppliersTableQueryVariables>;