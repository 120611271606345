import { useApolloClient } from "@apollo/client";
import { MenuButton, ModalOpenButton } from "@msys/ui";
import { Icon, MenuItem, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { namedOperations, PermissionName } from "../../../clients/graphqlTypes";
import { ReactComponent as SapLogo } from "../../assets/icons/sap-logo.svg";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import {
  S4HanaCancelReasonSelectProcess,
  S4HanaCancelReasonSelectProcessRef,
} from "./S4HanaCancelReasonSelectProcess";
import { S4HanaQuoteCopyModal } from "./S4HanaQuoteCopyModal";
import {
  useS4HanaCancelQuoteMutation,
  useS4HanaCreateQuoteMutation,
  useS4HanaQuoteDataQuery,
  useS4HanaSimulateQuotationMutation,
  useS4HanaUpdateQuoteMutation,
  useSapS4HanaUpdateQuoteFromRemoteMutation,
} from "./S4HanaQuoteMenuButton.generated";

const REFETCH_QUERIES = [
  namedOperations.Query.S4HanaQuoteData,
  namedOperations.Query.S4HanaQuoteItemDataBox,
  namedOperations.Query.S4HanaQuoteDataBox,
];

interface Props {
  projectId: string;
  quoteId: string;
  expandedItemIds: string[] | undefined;
  docViewerPermissions: PermissionName[];
}

export const S4HanaQuoteMenuButton = ({
  projectId,
  quoteId,
  expandedItemIds,
  docViewerPermissions,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const cancelReasonSelectProcessRef =
    React.useRef<S4HanaCancelReasonSelectProcessRef>(null);

  const client = useApolloClient();
  const query = useS4HanaQuoteDataQuery({
    client,
    variables: { projectId, quoteId },
  });
  const [simulateQuotation, { loading: simulateQuotationIsLoading }] =
    useS4HanaSimulateQuotationMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [createQuote, { loading: createQuoteIsLoading }] =
    useS4HanaCreateQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [updateQuote, { loading: updateQuoteIsLoading }] =
    useS4HanaUpdateQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });
  const [cancelQuote, { loading: cancelQuoteIsLoading }] =
    useS4HanaCancelQuoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
      awaitRefetchQueries: true,
    });

  const [updateQuoteFromRemote, { loading: updateQuoteFromRemoteLoading }] =
    useSapS4HanaUpdateQuoteFromRemoteMutation({
      client,
      variables: { input: { projectId, docId: quoteId, expandedItemIds } },
      refetchQueries: REFETCH_QUERIES,
    });

  const availableCancelReasons =
    query.data?.quoteSapS4HanaData
      ?.xAvailableSalesQuotationItemSalesDocumentRejectionReasons;
  const defaultCancelReason =
    query.data?.quoteSapS4HanaData
      ?.xSalesQuotationItemSalesDocumentRejectionReasonDefault;

  const menuItems = [
    <MenuItem
      key="quotation-simutation"
      onClick={async () => {
        await simulateQuotation();
      }}
      disabled={simulateQuotationIsLoading}
    >
      {t("SAP quotation simulation", { ns: "SapS4Hana" })}
    </MenuItem>,
    ...(!query.loading && query.data?.quoteSapS4HanaData?.xId
      ? [
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES"
            document={{ viewerPermissions: docViewerPermissions }}
            key="copy-sap-quote"
          >
            <ModalOpenButton
              Modal={S4HanaQuoteCopyModal}
              modalProps={{ projectId, docId: quoteId }}
            >
              <MenuItem>
                {t("Copy with reference", { ns: "SapS4Hana" })}
              </MenuItem>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>,
          <MenuItem
            key="update-sap-quote"
            onClick={async () => {
              await updateQuote();
            }}
            disabled={updateQuoteIsLoading}
          >
            {t("Update SAP quote", { ns: "SapS4Hana" })}
          </MenuItem>,
          <MenuItem
            key="cancel-sap-quote"
            onClick={async () => {
              const cancelReasonCode =
                await cancelReasonSelectProcessRef.current?.startProcess({
                  availableReasons: availableCancelReasons ?? [],
                  defaultReason: defaultCancelReason || null,
                  isReasonRequired: true,
                });
              if (!cancelReasonCode) return;
              await cancelQuote({
                variables: {
                  input: {
                    projectId,
                    docId: quoteId,
                    reason: cancelReasonCode,
                    expandedItemIds,
                  },
                },
                refetchQueries: REFETCH_QUERIES,
                awaitRefetchQueries: true,
              });
              enqueueSnackbar(t("Quote cancelled", { ns: "SapS4Hana" }));
            }}
            disabled={cancelQuoteIsLoading}
          >
            {t("Cancel SAP quote", { ns: "SapS4Hana" })}
            <S4HanaCancelReasonSelectProcess
              ref={cancelReasonSelectProcessRef}
            />
          </MenuItem>,
        ]
      : []),
    ...(!query.loading && !query.data?.quoteSapS4HanaData?.xId
      ? [
          <MenuItem
            key="create-sap-quote"
            onClick={async () => {
              await createQuote();
            }}
            disabled={createQuoteIsLoading}
          >
            {t("Create SAP quote", { ns: "SapS4Hana" })}
          </MenuItem>,
        ]
      : []),
    ...(!query.loading && !!query.data?.quoteSapS4HanaData?.xId
      ? [
          <MenuItem
            key="update-quote-from-remote"
            onClick={async () => {
              await updateQuoteFromRemote();
            }}
            disabled={updateQuoteFromRemoteLoading}
          >
            {t("Update quote from remote", { ns: "SapS4Hana" })}
          </MenuItem>,
        ]
      : []),
  ];

  return (
    <MenuButton
      Icon={
        <Tooltip title={t("SAP actions", { ns: "SapS4Hana" })}>
          <Icon>
            <SapLogo />
          </Icon>
        </Tooltip>
      }
    >
      {menuItems}
    </MenuButton>
  );
};
