import { useApolloClient } from "@apollo/client";
import {
  LoadingSpinner,
  isImageOr3dModel,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import {
  Box,
  Container,
  Stack as MuiStack,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition, pick } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  Agreement,
  ItemType,
  ModifyItemProductValuesInput,
  PermissionName,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { useFeature } from "../../../../common/FeatureFlags";
import { useUserData } from "../../../auth/useUserData";
import { PageNotFound } from "../../../commons/PageNotFound";
import { usePageWidth } from "../../../commons/hooks/usePageWidth";
import { GalleryRow } from "../../../commons/images/GalleryRow";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageColumn } from "../../../commons/layout/PageColumn";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { Stack } from "../../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants";
import { FilesBoxTable } from "../../../features/attachments/FilesBoxTable";
import { ProfitabilityBox } from "../../../features/doc-items/boxes/ProfitabilityBox";
import { DecisionModalButton } from "../../../features/doc-items/buttons/DecisionModalButton";
import {
  isAllowedToPaste,
  rootItemHasCalculationConstraints,
  rootItemHasItemTableConstraints,
} from "../../../features/doc-items/constraints";
import {
  hasAnyQuestions,
  isTreePreviewItemVisible,
} from "../../../features/doc-items/helpers";
import { useCreateItem } from "../../../features/doc-items/hooks/useCreateItem";
import { useDocItemsClipboard } from "../../../features/doc-items/hooks/useDocItemsClipboard";
import { ProductSearchItem__ProductSearchResultFragment } from "../../../features/products/Product.generated";
import { ClosingTextInput } from "../../../features/quotes/QuoteClosingText";
import { OpeningTextInput } from "../../../features/quotes/QuoteOpeningText";
import { useQuoteQuickFilters } from "../../../features/quotes/QuoteQuickFilters";
import { QuotePreviewSummary } from "../../../features/quotes/preview/QuotePreviewSummary";
import { CreateQuoteTemplateProcessRef } from "../../../features/templates/CreateQuoteTemplateModal";
import {
  allowHaveTemplateChildren,
  shouldRenderTemplateCreateInput,
} from "../../../features/templates/helpers";
import {
  TemplateQuotePreviewItemDescription,
  templateQuoteItemHasDescription,
} from "../../../features/templates/quote/TemplateQuotePreviewItemDescription";
import { TemplatesQuotePreviewItems } from "../../../features/templates/quote/TemplateQuotePreviewItems";
import { TemplatesQuotePriceSummaryBox } from "../../../features/templates/quote/TemplatesQuotePriceSummaryBox";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../../features/templates/quote/TemplatesQuoteSelectMultipleModal.generated";
import { TemplatesQuoteTemplateAction } from "../../../features/templates/quote/TemplatesQuoteTemplateAction";
import { TemplatesQuoteTimeSummaryBox } from "../../../features/templates/quote/TemplatesQuoteTimeSummaryBox";
import {
  createTreeItem,
  createTreeItemInput,
} from "../../../features/templates/quote/trees";
import { VirtualItemTree } from "../../../trees-virtual/VirtualItemTree";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  ExpandedStore,
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../../trees-virtual/hooks/useExpandedStore";
import { TreeToggleAllExpandedButton } from "../../../trees/components/TreeToggleButton";
import {
  TemplateQuoteEdit_ItemFragment,
  useModifyQuoteTemplateTexts_TemplatesQuotePreviewMutation,
  useTemplateQuoteEditPreviewQuery,
  useTemplateQuoteEditQuery,
  useTemplateQuoteEdit_CreateItemsMutation,
  useTemplateQuoteEdit_CreateTemplateMutation,
  useTemplateQuoteEdit_PasteItemFromClipboardMutation,
  useTemplateQuoteEdit_SetItemProductMutation,
} from "./TemplateQuoteEdit.generated";

interface Props {
  docId: string;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocPage: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  pageSubHeader: React.ReactElement | null;
}

export const TemplateQuoteEdit = ({
  docId,
  pathToDocList,
  pathToDoc,
  pathToDocPage,
  submenuItems,
  prefixBreadcrumbs,
  pageSubHeader,
}: Props) => {
  const { t } = useTranslate(["Templates"]);
  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;

  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);

  // const [treeItemsCount, setTreeItemsCount] = React.useState<number>(0);

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<TemplateQuoteEdit_ItemFragment>(false);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    docId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useTemplateQuoteEditQuery({
    client,
    variables: {
      id: docId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const doc = query.data?.quoteTemplateLatest;

  const navigateToItem = React.useCallback(
    (id: string) => {
      return navigate(`${pathToDocPage}/items/${id}`, {
        replace: true,
      });
    },
    [navigate, pathToDocPage]
  );

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const viewerPermissions: PermissionName[] = viewer.organisationPermissions;
  const agreement: Agreement = "NONE";

  const createQuoteTemplateRef =
    React.useRef<CreateQuoteTemplateProcessRef | null>(null);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const { isMinTablet } = useScreenWidth();

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    createItemWithLinkedTemplate,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateTemplateItems({
    templateId: docId,
    createQuoteTemplateRef,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(
    () =>
      createTreeItem({
        pathToDocList,
        pathToDocPage,
        projectId: null,
        document: { viewerPermissions },
        docId,
        docAgreement: agreement,
        startConfirmProcess,
        canEdit: true,
        navigateToItem,
        expandedItemIds: expandedStore.expandedItemIds,
        setItemExpanded: expandedStore.setItemExpanded,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      pathToDocList,
      pathToDocPage,
      viewerPermissions,
      docId,
      startConfirmProcess,
      navigateToItem,
      expandedStore.expandedItemIds,
      expandedStore.setItemExpanded,
      pasteItem,
      isAllowedToPasteItem,
    ]
  );

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        docId,
        isDraft: !doc?.hasAnyPublishedVersion,
        createQuoteTemplateRef,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        createItemWithLinkedTemplate,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      docId,
      doc?.hasAnyPublishedVersion,
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      createItemWithLinkedTemplate,
      pasteItem,
      isAllowedToPasteItem,
    ]
  );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems.filter(
      item => !item.isAnyParentItemEliminated
    );
    const filteredItems = quickFilter(treeItems);
    return [filteredItems, rootItem, allDocItems];
  }, [doc, quickFilter]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  React.useEffect(() => {
    if (query.loading || query.error) return;
    if (
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
    query.error,
  ]);

  const filterItem = React.useCallback(
    (i: TemplateQuoteEdit_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  return (
    <Page
      title={doc?.title ?? ""}
      subtitle={t("Document Templates", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      action={
        doc ? (
          <TemplatesQuoteTemplateAction doc={doc} pathToDoc={pathToDoc} />
        ) : undefined
      }
      subHeader={pageSubHeader}
    >
      <PageColumn
        $hasRightBorder
        $hasBackground
        $display="block"
        ref={isMinTablet ? setTreeContainer : undefined}
      >
        {doc && rootItem && (
          <Stack flexDirection={"column"} spacing={2}>
            <Stack width="100%" alignItems="center" spacing={1}>
              <TreeToggleAllExpandedButton
                areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                setAllItemsExpanded={expandedStore.setAllItemsExpanded}
              />
              <QuickFiltersTabs />
            </Stack>

            <Box>
              <VirtualBareTreeStandaloneItem
                item={rootItem}
                items={filteredTreeItems}
                allItems={allDocItems}
                depth={0}
                itemComponent={TreeItem}
                selected={isRootSelected}
                sticky={isRootSelected}
                top={0}
                clickable
                onClick={() => navigateToItem(rootItem.id)}
                to={`${pathToDocPage}/items/${rootItem.id}`}
              />
              {/* <TreeConnector $isAtTheTopOfTree={treeItemsCount > 0} /> */}
              <VirtualItemTree<TemplateQuoteEdit_ItemFragment, true>
                docId={doc.id}
                projectId={null}
                items={filteredTreeItems}
                allItems={allDocItems}
                filterItem={isClientVisibilityEnabled ? filterItem : undefined}
                selectedItemId={selectedItemId}
                enableCreating={true}
                enableDragging={true}
                shouldRenderCreateInput={shouldRenderTemplateCreateInput}
                allowHaveChildren={allowHaveTemplateChildren}
                documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
                container={treeContainer}
                itemComponent={TreeItem}
                inputComponent={TreeItemInput}
                // onItemsCountChange={setTreeItemsCount}
                expandedStore={expandedStore}
              />
            </Box>

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              doc.proposedCalculation && (
                <TemplatesQuoteTimeSummaryBox
                  calculated={doc.proposedCalculation}
                />
              )}

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              doc.proposedCalculation && (
                <TemplatesQuotePriceSummaryBox
                  docId={doc.id}
                  discountPercentage={doc.discountPercentage}
                  calculated={doc.proposedCalculation}
                  isReadOnly={false}
                />
              )}

            {rootItemHasCalculationConstraints.includes(rootItem.type) &&
              doc.proposedCalculation && (
                <ProfitabilityBox calculated={doc.proposedCalculation} />
              )}
          </Stack>
        )}
      </PageColumn>
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole: undefined,
        }}
      />

      {doc && isMinOneColumnWithPreview && (
        <PageContainer
          $fullHeight
          onClick={() => {
            if (!isMinTwoColumnsWithPreview)
              navigate(pathToDocPage, { replace: true });
          }}
          ref={setPreviewContainer}
        >
          <Container maxWidth="md">
            <TemplateQuoteEditPreview
              docId={docId}
              selectedItemId={selectedItemId}
              navigateToItem={({ id }) => navigateToItem(id)}
              container={previewContainer}
              expandedStore={expandedStore}
            />
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

interface PreviewProps {
  docId: string;
  selectedItemId?: string | null;
  navigateToItem?: React.ComponentProps<
    typeof TemplatesQuotePreviewItems
  >["navigateToItem"];
  container?: HTMLElement | null;
  expandedStore: ExpandedStore;
}

const TemplateQuoteEditPreview = ({
  docId,
  navigateToItem,
  selectedItemId,
  container,
  expandedStore,
}: PreviewProps) => {
  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Quote", "Global", "Decisions"]);

  const client = useApolloClient();
  const query = useTemplateQuoteEditPreviewQuery({
    client,
    variables: { id: docId, expandedItemIds: expandedStore.expandedItemIds },
  });

  const quoteTemplate = query.data?.quoteTemplateLatest;
  const rootItem = quoteTemplate?.rootItem;
  const allDocItems = quoteTemplate?.previewItems ?? [];
  const quoteTexts = quoteTemplate?.quoteTexts;

  const [images, files] = partition(
    (rootItem?.attachments ?? [])
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment),
    isImageOr3dModel
  );

  const [modifyQuoteTemplateTexts] =
    useModifyQuoteTemplateTexts_TemplatesQuotePreviewMutation({
      client,
      refetchQueries: [namedOperations.Query.TemplateQuoteRoutes],
    });

  const saveQuoteText = async (content: any) => {
    try {
      await modifyQuoteTemplateTexts({
        variables: {
          input: {
            quoteTemplateId: docId,
            values: {
              quoteTexts: content,
            },
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (query.loading && !quoteTemplate) return <LoadingSpinner />;
  if (!quoteTemplate || !rootItem) return <PageNotFound />;

  return (
    <>
      <MuiStack width="100%" direction="column" spacing={1}>
        <Paper>
          <Box padding={2}>
            {hasAnyQuestions(rootItem, "TEMPLATE", undefined) && (
              <div style={{ float: "right", display: "flex" }}>
                <DecisionModalButton
                  projectId={null}
                  docType="TEMPLATE"
                  docId={docId}
                  itemId={rootItem.id}
                  canFinalize={false}
                  viewerDecisionRole={undefined}
                  expandedItemIds={expandedStore.expandedItemIds}
                  type="icon"
                  disabled
                />
              </div>
            )}
            <MuiStack direction="column">
              <Typography variant="h2">{quoteTemplate.title}</Typography>
              {quoteTemplate.isBinding === false && (
                <Typography
                  fontWeight="bold"
                  color="textSecondary"
                  component="div"
                >
                  {t("Non-Binding Template", {
                    ns: "Quote",
                  })}
                </Typography>
              )}
              {rootItem.decisionIsContingentItem && (
                <Typography
                  fontWeight="bold"
                  color="textSecondary"
                  component="div"
                >
                  {t("Optional Template", { ns: "Quote" })}
                </Typography>
              )}
            </MuiStack>
          </Box>
        </Paper>

        <OpeningTextInput
          canEdit={true}
          content={quoteTexts?.opening}
          onSave={saveQuoteText}
          showInfo={true}
        />

        {(images.length > 0 || templateQuoteItemHasDescription(rootItem)) && (
          <Paper>
            <Stack padding={2} flexDirection={"column"} spacing={2}>
              <GalleryRow
                images={images}
                maxItems={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
              />

              <TemplateQuotePreviewItemDescription
                item={rootItem}
                columns={isMinDesktop ? 4 : isMinTablet ? 2 : 1}
              />
            </Stack>
          </Paper>
        )}

        {rootItemHasItemTableConstraints.includes(rootItem.type) && (
          <TemplatesQuotePreviewItems
            docId={docId}
            canEdit={true}
            contractType={quoteTemplate.contractType}
            isBinding={quoteTemplate.isBinding}
            allDocItems={allDocItems}
            navigateToItem={navigateToItem}
            selectedItemId={selectedItemId}
            expandedStore={expandedStore}
            container={container}
          />
        )}

        {rootItemHasCalculationConstraints.includes(rootItem.type) &&
          quoteTemplate.proposedCalculation && (
            <QuotePreviewSummary
              vatVisibility={quoteTemplate.vatVisibility}
              discountPercentage={quoteTemplate.discountPercentage}
              discountReason={undefined}
              isDiscountPercentageVisible={true}
              calculation={quoteTemplate.proposedCalculation}
            />
          )}

        <ClosingTextInput
          canEdit={true}
          content={quoteTexts?.closing}
          onSave={saveQuoteText}
          showInfo={true}
        />

        {files.length > 0 && (
          <FilesBoxTable
            title={t("Attachments", { ns: "Quote" })}
            attachments={files}
            canEdit={false}
            gridColumns={isMinDesktop ? 8 : isMinTablet ? 6 : 4}
          />
        )}
      </MuiStack>
    </>
  );
};

function useCreateTemplateItems({
  templateId,
  createQuoteTemplateRef,
  expandedItemIds,
}: {
  templateId: string;
  createQuoteTemplateRef: React.RefObject<CreateQuoteTemplateProcessRef>;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useTemplateQuoteEdit_CreateItemsMutation({
    client,
  });

  const { createItem, createItemSection, createItemByType } = useCreateItem({
    projectId: null,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const createItemFromTemplate = React.useCallback(
    async (title: string, parentItemId: string, templateQuoteId: string) => {
      const result = await createItemSection(
        title,
        templateId,
        parentItemId,
        templateQuoteId
      );

      return result;
    },
    [createItemSection, templateId]
  );

  const createItemsFromTemplates = React.useCallback(
    async (
      parentItemId: string,
      templates: {
        quantity: number;
        template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId: null,
            docId: templateId,
            parentItemId,
            items: templates.map(opts => ({
              title: opts.template.title,
              type: "section",
              decisionBehaviorOfSubitems: "NONE",
              estimatedQuantity: opts.quantity,
              templateQuoteId: opts.template.id,
            })),
            expandedItemIds,
          },
        },
      });

      return result.data!.createItems.items;
    },
    [createItems, expandedItemIds, templateId]
  );

  const createItemsWithProducts = React.useCallback(
    async (
      parentItemId: string,
      products: {
        quantity: number;
        product:
          | ProductSearchItem__ProductSearchResultFragment
          | ModifyItemProductValuesInput;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId: null,
            docId: templateId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
          },
        },
      });

      return result.data!.createItems.items;
    },
    [createItems, expandedItemIds, templateId]
  );

  const [createQuoteTemplate] = useTemplateQuoteEdit_CreateTemplateMutation({
    client,
  });
  const [setItemProduct] = useTemplateQuoteEdit_SetItemProductMutation({
    client,
  });

  // TODO: ask for template when creating from tree input
  // const askRootItemType = useAskRootItemType();
  const createItemWithLinkedTemplate = React.useCallback(
    async (itemTitle: string, parentItemId: string) => {
      const values =
        (await createQuoteTemplateRef?.current?.createQuoteTemplate(
          itemTitle
        )) ?? null;
      if (!values) return null;

      const quoteTemplateResult = await createQuoteTemplate({
        variables: {
          input: {
            title: values.title,
            description: values.description,
            discountPercentage: 0,
            rootItemType: values.rootItemType,
          },
        },
      });

      const newTemplateId =
        quoteTemplateResult.data?.createQuoteTemplate.quoteTemplate.id;

      if (!newTemplateId) throw new Error("Document id missing");

      const rootItemId =
        quoteTemplateResult.data?.createQuoteTemplate.rootItem.id;
      const rootItemType =
        quoteTemplateResult.data?.createQuoteTemplate.rootItem.type;

      if (!rootItemId || !rootItemType) throw new Error("Root item id missing");

      if (rootItemType === "paid" && values.product) {
        await setItemProduct({
          variables: {
            input: {
              itemId: rootItemId,
              docId: newTemplateId,
              projectId: null,
              productArticleNumber: values.product.articleNumber,
              productSupplierId: values.product.supplierId,
              expandedItemIds: [parentItemId],
            },
          },
        });
      }

      await createItemSection(
        itemTitle,
        templateId,
        parentItemId,
        newTemplateId
      );
    },
    [
      createQuoteTemplateRef,
      createQuoteTemplate,
      createItemSection,
      setItemProduct,
      templateId,
    ]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useTemplateQuoteEdit_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: null,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: {
      type: ItemType;
      linkedQuoteTemplate?: object | null | undefined;
    }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;
      if (parentItem.linkedQuoteTemplate) return false;

      return isAllowedToPaste({
        documentItemTypes: QUOTE_TEMPLATE_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemSection,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    createItemWithLinkedTemplate,
    pasteItem,
    isAllowedToPasteItem,
  };
}
