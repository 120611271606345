import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid, splitStrategy } from "../../commons/layout/PageGrid";
import { OrganisationAccountingSettingsBox } from "../../features/organisations/boxes/OrganisationAccountingSettingsBox";
// import { OrganisationAgreementTemplatesBox } from "../../features/organisations/boxes/OrganisationAgreementTemplatesBox";
import { OrganisationCustomFieldConfigBox } from "../../features/organisations/boxes/OrganisationCustomFieldConfigBox";
import { OrganisationEmailSettingsBox } from "../../features/organisations/boxes/OrganisationEmailSettingsBox";
import { OrganisationEmbeddableWizardSnippetBox } from "../../features/organisations/boxes/OrganisationEmbeddableWizardSnippetBox";
import { OrganisationIntegrationsBox } from "../../features/organisations/boxes/OrganisationIntegrationsBox";
import { OrganisationProjectDefaultSettingsBox } from "../../features/organisations/boxes/OrganisationProjectDefaultSettingsBox";
import { OrganisationReferralBox } from "../../features/organisations/boxes/OrganisationReferralBox";
import { useOrganisationSettingsIntegrationsQuery } from "./OrganisationSettingsIntegrations.generated";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const OrganisationSettingsIntegrations = ({ submenuItems }: Props) => {
  const { t } = useTranslate("OrganisationPageTopbar");

  const client = useApolloClient();
  const query = useOrganisationSettingsIntegrationsQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const organisation = query.data?.viewer?.organisation;
  const organisationSettings = query.data?.organisationSettings;
  const organisationEmailSettings = getDataOrNull(
    query.data?.organisationEmailSettings
  );
  const organisationEmailTemplates =
    query.data?.organisationEmailTemplates ?? [];
  const organisationCounters = getDataOrNull(query.data?.organisationCounters);
  const integrations =
    getDataOrNull(query.data?.integrations)?.edges.map(e => e.node) ?? [];
  const organisationIntegrations =
    getDataOrNull(query.data?.organisationIntegrations)?.edges.map(
      e => e.node
    ) ?? [];

  return (
    <Page title={t("Data & Integrations")} submenuItems={submenuItems}>
      {organisation && organisationSettings && organisationCounters && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 3 }} strategy={splitStrategy}>
            <>
              <OrganisationAccountingSettingsBox
                organisationId={organisation.id}
                organisationCounters={organisationCounters}
                organisationSettings={organisationSettings}
              />
              <OrganisationProjectDefaultSettingsBox
                organisationId={organisation.id}
                organisationSettings={organisationSettings}
              />
              <RestrictedByCapabilityWithDebug capability="REFERRAL">
                <OrganisationReferralBox />
              </RestrictedByCapabilityWithDebug>
            </>
            <>
              <OrganisationCustomFieldConfigBox />
              {organisationEmailSettings && (
                <OrganisationEmailSettingsBox
                  organisation={organisation}
                  organisationEmailSettings={organisationEmailSettings}
                  organisationEmailTemplates={organisationEmailTemplates}
                />
              )}
            </>
            <>
              <RestrictedByCapabilityWithDebug capability="INTEGRATIONS">
                <OrganisationIntegrationsBox
                  integrationM1MsysId={organisation.integrationM1MsysId}
                  allAvailableIntegrations={integrations}
                  organisationIntegrations={organisationIntegrations}
                />
              </RestrictedByCapabilityWithDebug>
              <RestrictedByCapabilityWithDebug capability="TEMPLATING">
                <OrganisationEmbeddableWizardSnippetBox
                  organisation={organisation}
                />
              </RestrictedByCapabilityWithDebug>
              {/* <OrganisationAgreementTemplatesBox /> */}
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
};
