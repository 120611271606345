/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationAvatarFragmentDoc } from '../organisations/OrganisationAvatar.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type S4HanaQuoteDataBox_QuoteSapS4HanaDataFragment = { __typename: 'QuoteSapS4HanaData', xVersionIdentifier?: string | null, xId?: string | null, xReferenceDocumentId?: string | null, distributionChannel?: string | null, organisationDivision?: string | null, salesOrganisation?: string | null, salesQuotationType?: string | null, salesOffice?: string | null, xBindingPeriodValidityStartDate?: any | null, xCreatedAt?: any | null, createdAt: any, updatedAt: any, xUpdatedAt?: any | null, lastSyncedFromSapAt?: any | null, xCreatedByUser?: string | null, xCustomerPaymentTerms?: string | null, xPurchaseOrderByCustomer?: string | null, xResubmissionDate?: any | null, xSalesQuotationDate?: any | null, xSdDocumentReason?: string | null, xTransactionCurrency?: string | null, sapS4HanaDataFieldMetadata: Array<{ __typename: 'EntityFieldMetadata', key: string, required: boolean, editable: boolean, allowedValues?: Array<{ __typename: 'EntityFieldAllowedValue', value: string, label: string }> | null }>, headerTexts: Array<{ __typename: 'QuoteSapS4HanaHeaderText', id: string, xLongTextId: string, xLongTextValue: string }>, xAvailableSalesQuotationTexts: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }>, businessPartners: Array<{ __typename: 'QuoteSapS4HanaBusinessPartner', id: string, partnerFunction: string, partnerType: Types.SapS4HanaBusinessPartnerPartnerType, crmCompany: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } } }>, xAvailablePartnerFunctions: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }> };

export type S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragment = { __typename: 'QuoteSapS4HanaBusinessPartner', id: string, partnerFunction: string, partnerType: Types.SapS4HanaBusinessPartnerPartnerType, crmCompany: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } } };

export type S4HanaQuoteDataBoxQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type S4HanaQuoteDataBoxQuery = { quoteSapS4HanaData?: { __typename: 'QuoteSapS4HanaData', id: string, xVersionIdentifier?: string | null, xId?: string | null, xReferenceDocumentId?: string | null, distributionChannel?: string | null, organisationDivision?: string | null, salesOrganisation?: string | null, salesQuotationType?: string | null, salesOffice?: string | null, xBindingPeriodValidityStartDate?: any | null, xCreatedAt?: any | null, createdAt: any, updatedAt: any, xUpdatedAt?: any | null, lastSyncedFromSapAt?: any | null, xCreatedByUser?: string | null, xCustomerPaymentTerms?: string | null, xPurchaseOrderByCustomer?: string | null, xResubmissionDate?: any | null, xSalesQuotationDate?: any | null, xSdDocumentReason?: string | null, xTransactionCurrency?: string | null, sapS4HanaDataFieldMetadata: Array<{ __typename: 'EntityFieldMetadata', key: string, required: boolean, editable: boolean, allowedValues?: Array<{ __typename: 'EntityFieldAllowedValue', value: string, label: string }> | null }>, headerTexts: Array<{ __typename: 'QuoteSapS4HanaHeaderText', id: string, xLongTextId: string, xLongTextValue: string }>, xAvailableSalesQuotationTexts: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }>, businessPartners: Array<{ __typename: 'QuoteSapS4HanaBusinessPartner', id: string, partnerFunction: string, partnerType: Types.SapS4HanaBusinessPartnerPartnerType, crmCompany: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } } }>, xAvailablePartnerFunctions: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }> } | null };

export type ModifySapS4HanaSalesQuoteDataMutationVariables = Types.Exact<{
  input: Types.ModifySapS4HanaSalesQuoteDataInput;
}>;


export type ModifySapS4HanaSalesQuoteDataMutation = { modifySapS4HanaSalesQuoteData: { __typename: 'ModifySapS4HanaSalesQuoteDataResult', quote: { __typename: 'Quote', id: string }, quoteSapS4HanaData: { __typename: 'QuoteSapS4HanaData', id: string, xVersionIdentifier?: string | null, xId?: string | null, xReferenceDocumentId?: string | null, distributionChannel?: string | null, organisationDivision?: string | null, salesOrganisation?: string | null, salesQuotationType?: string | null, salesOffice?: string | null, xBindingPeriodValidityStartDate?: any | null, xCreatedAt?: any | null, createdAt: any, updatedAt: any, xUpdatedAt?: any | null, lastSyncedFromSapAt?: any | null, xCreatedByUser?: string | null, xCustomerPaymentTerms?: string | null, xPurchaseOrderByCustomer?: string | null, xResubmissionDate?: any | null, xSalesQuotationDate?: any | null, xSdDocumentReason?: string | null, xTransactionCurrency?: string | null, sapS4HanaDataFieldMetadata: Array<{ __typename: 'EntityFieldMetadata', key: string, required: boolean, editable: boolean, allowedValues?: Array<{ __typename: 'EntityFieldAllowedValue', value: string, label: string }> | null }>, headerTexts: Array<{ __typename: 'QuoteSapS4HanaHeaderText', id: string, xLongTextId: string, xLongTextValue: string }>, xAvailableSalesQuotationTexts: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }>, businessPartners: Array<{ __typename: 'QuoteSapS4HanaBusinessPartner', id: string, partnerFunction: string, partnerType: Types.SapS4HanaBusinessPartnerPartnerType, crmCompany: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } } }>, xAvailablePartnerFunctions: Array<{ __typename: 'QuoteSapS4HanaDataAvailableValue', value: string, label: string }> } } };

export const S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragmentDoc = gql`
    fragment S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartner on QuoteSapS4HanaBusinessPartner {
  id
  partnerFunction
  partnerType
  crmCompany {
    id
    ...OrganisationAvatar
  }
}
    ${OrganisationAvatarFragmentDoc}`;
export const S4HanaQuoteDataBox_QuoteSapS4HanaDataFragmentDoc = gql`
    fragment S4HanaQuoteDataBox_QuoteSapS4HanaData on QuoteSapS4HanaData {
  xVersionIdentifier
  xId
  xReferenceDocumentId
  distributionChannel
  organisationDivision
  salesOrganisation
  salesQuotationType
  salesOffice
  xBindingPeriodValidityStartDate
  xCreatedAt
  createdAt
  updatedAt
  xUpdatedAt
  lastSyncedFromSapAt
  xCreatedByUser
  xCustomerPaymentTerms
  xPurchaseOrderByCustomer
  xResubmissionDate
  xSalesQuotationDate
  xSdDocumentReason
  xTransactionCurrency
  sapS4HanaDataFieldMetadata {
    key
    required
    editable
    allowedValues {
      value
      label
    }
  }
  headerTexts {
    id
    xLongTextId
    xLongTextValue
  }
  xAvailableSalesQuotationTexts {
    value
    label
  }
  businessPartners {
    ...S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartner
  }
  xAvailablePartnerFunctions {
    value
    label
  }
}
    ${S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragmentDoc}`;
export const S4HanaQuoteDataBoxDocument = gql`
    query S4HanaQuoteDataBox($projectId: ID!, $quoteId: ID!) {
  quoteSapS4HanaData(projectId: $projectId, docId: $quoteId) {
    id
    ...S4HanaQuoteDataBox_QuoteSapS4HanaData
  }
}
    ${S4HanaQuoteDataBox_QuoteSapS4HanaDataFragmentDoc}`;

/**
 * __useS4HanaQuoteDataBoxQuery__
 *
 * To run a query within a React component, call `useS4HanaQuoteDataBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaQuoteDataBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaQuoteDataBoxQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useS4HanaQuoteDataBoxQuery(baseOptions: Apollo.QueryHookOptions<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables> & ({ variables: S4HanaQuoteDataBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>(S4HanaQuoteDataBoxDocument, options);
      }
export function useS4HanaQuoteDataBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>(S4HanaQuoteDataBoxDocument, options);
        }
export function useS4HanaQuoteDataBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>(S4HanaQuoteDataBoxDocument, options);
        }
export type S4HanaQuoteDataBoxQueryHookResult = ReturnType<typeof useS4HanaQuoteDataBoxQuery>;
export type S4HanaQuoteDataBoxLazyQueryHookResult = ReturnType<typeof useS4HanaQuoteDataBoxLazyQuery>;
export type S4HanaQuoteDataBoxSuspenseQueryHookResult = ReturnType<typeof useS4HanaQuoteDataBoxSuspenseQuery>;
export type S4HanaQuoteDataBoxQueryResult = Apollo.QueryResult<S4HanaQuoteDataBoxQuery, S4HanaQuoteDataBoxQueryVariables>;
export const ModifySapS4HanaSalesQuoteDataDocument = gql`
    mutation ModifySapS4HanaSalesQuoteData($input: ModifySapS4HanaSalesQuoteDataInput!) {
  modifySapS4HanaSalesQuoteData(input: $input) {
    quote {
      id
    }
    quoteSapS4HanaData {
      id
      ...S4HanaQuoteDataBox_QuoteSapS4HanaData
    }
  }
}
    ${S4HanaQuoteDataBox_QuoteSapS4HanaDataFragmentDoc}`;
export type ModifySapS4HanaSalesQuoteDataMutationFn = Apollo.MutationFunction<ModifySapS4HanaSalesQuoteDataMutation, ModifySapS4HanaSalesQuoteDataMutationVariables>;

/**
 * __useModifySapS4HanaSalesQuoteDataMutation__
 *
 * To run a mutation, you first call `useModifySapS4HanaSalesQuoteDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySapS4HanaSalesQuoteDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySapS4HanaSalesQuoteDataMutation, { data, loading, error }] = useModifySapS4HanaSalesQuoteDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifySapS4HanaSalesQuoteDataMutation(baseOptions?: Apollo.MutationHookOptions<ModifySapS4HanaSalesQuoteDataMutation, ModifySapS4HanaSalesQuoteDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifySapS4HanaSalesQuoteDataMutation, ModifySapS4HanaSalesQuoteDataMutationVariables>(ModifySapS4HanaSalesQuoteDataDocument, options);
      }
export type ModifySapS4HanaSalesQuoteDataMutationHookResult = ReturnType<typeof useModifySapS4HanaSalesQuoteDataMutation>;
export type ModifySapS4HanaSalesQuoteDataMutationResult = Apollo.MutationResult<ModifySapS4HanaSalesQuoteDataMutation>;
export type ModifySapS4HanaSalesQuoteDataMutationOptions = Apollo.BaseMutationOptions<ModifySapS4HanaSalesQuoteDataMutation, ModifySapS4HanaSalesQuoteDataMutationVariables>;