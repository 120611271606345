/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { TemplatesQuoteTemplateSubHeader_QuoteTemplateFragmentDoc, TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragmentDoc } from '../../../features/templates/quote/TemplatesQuoteTemplateSubHeader.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteVersionRoutesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  versionNumber: Types.Scalars['Int']['input'];
}>;


export type TemplateQuoteVersionRoutesQuery = { quoteTemplateVersion?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, projectId?: string | null, has3dRoomSnapshot: boolean, latestPublishedVersionNumber?: number | null, containsUnpublishedChanged: boolean, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, sharedPublicly: boolean, usedInOtherTemplates: boolean, addedToShop: boolean, isBinding: boolean, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null, type: Types.ItemType }, sharedWithOrganisations: Array<{ __typename: 'Company', id: string }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } | null };


export const TemplateQuoteVersionRoutesDocument = gql`
    query TemplateQuoteVersionRoutes($id: ID!, $versionNumber: Int!) {
  quoteTemplateVersion(id: $id, versionNumber: $versionNumber) {
    id
    resolvedAsReadModelVersionNumber
    title
    projectId
    ...TemplatesQuoteTemplateSubHeader_QuoteTemplate
    rootItem {
      id
      originVersionNumber
      ...TemplatesQuoteTemplateSubHeader_QuoteTemplateItem
    }
  }
}
    ${TemplatesQuoteTemplateSubHeader_QuoteTemplateFragmentDoc}
${TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragmentDoc}`;

/**
 * __useTemplateQuoteVersionRoutesQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteVersionRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteVersionRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteVersionRoutesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionNumber: // value for 'versionNumber'
 *   },
 * });
 */
export function useTemplateQuoteVersionRoutesQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables> & ({ variables: TemplateQuoteVersionRoutesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>(TemplateQuoteVersionRoutesDocument, options);
      }
export function useTemplateQuoteVersionRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>(TemplateQuoteVersionRoutesDocument, options);
        }
export function useTemplateQuoteVersionRoutesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>(TemplateQuoteVersionRoutesDocument, options);
        }
export type TemplateQuoteVersionRoutesQueryHookResult = ReturnType<typeof useTemplateQuoteVersionRoutesQuery>;
export type TemplateQuoteVersionRoutesLazyQueryHookResult = ReturnType<typeof useTemplateQuoteVersionRoutesLazyQuery>;
export type TemplateQuoteVersionRoutesSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteVersionRoutesSuspenseQuery>;
export type TemplateQuoteVersionRoutesQueryResult = Apollo.QueryResult<TemplateQuoteVersionRoutesQuery, TemplateQuoteVersionRoutesQueryVariables>;