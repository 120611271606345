/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProjectPhasesConfigurator_OrganisationProjectPhaseFragmentDoc } from './ProjectPhasesConfigurator.generated';
import { ProjectListItemFragmentDoc } from './components/ProjectListItem.generated';
import { ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragmentDoc } from './ProjectPhaseAndStateChangeMenuButton.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectsKanbanQueryVariables = Types.Exact<{
  filterIncludeState?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
}>;


export type ProjectsKanbanQuery = { organisationSettings: { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string }> } };

export type ProjectsKanbanListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  first: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationProjectsSorting>>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterType?: Types.InputMaybe<Array<Types.ProjectType>>;
  filterCategories?: Types.InputMaybe<Array<Types.Skill>>;
  filterBuildingZip?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterWithinRadiusKm?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterMinBudget?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterAssigneeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceCrmOrganisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceUserIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySources?: Types.InputMaybe<Array<Types.ProjectSourceType>>;
  filterByCrmLinkIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterIncludeState?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
  filterByCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterByPhaseId: Types.Scalars['ID']['input'];
}>;


export type ProjectsKanbanListQuery = { projects: { __typename: 'ProjectConnection', totalCount: number, pageInfo: { __typename: 'PageInfo', endCursor?: string | null, hasNextPage?: boolean | null }, edges: Array<{ __typename: 'ProjectEdge', cursor?: string | null, node: { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null } }> } };

export type ProjectPhaseColumnPhase_OrganisationProjectPhaseFragment = { __typename: 'OrganisationProjectPhase', id: string, name: string };

export type ProjectPhaseColumnAvailablePhases_OrganisationProjectPhaseFragment = { __typename: 'OrganisationProjectPhase', id: string, name: string };

export type ProjectCardItemMemoized_ProjectFragment = { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null };

export type ProjectCardItemMemoized_OrganisationProjectPhaseFragment = { __typename: 'OrganisationProjectPhase', id: string, name: string };

export const ProjectPhaseColumnPhase_OrganisationProjectPhaseFragmentDoc = gql`
    fragment ProjectPhaseColumnPhase_OrganisationProjectPhase on OrganisationProjectPhase {
  id
  name
}
    `;
export const ProjectCardItemMemoized_OrganisationProjectPhaseFragmentDoc = gql`
    fragment ProjectCardItemMemoized_OrganisationProjectPhase on OrganisationProjectPhase {
  id
  ...ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhase
}
    ${ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragmentDoc}`;
export const ProjectPhaseColumnAvailablePhases_OrganisationProjectPhaseFragmentDoc = gql`
    fragment ProjectPhaseColumnAvailablePhases_OrganisationProjectPhase on OrganisationProjectPhase {
  id
  ...ProjectCardItemMemoized_OrganisationProjectPhase
}
    ${ProjectCardItemMemoized_OrganisationProjectPhaseFragmentDoc}`;
export const ProjectCardItemMemoized_ProjectFragmentDoc = gql`
    fragment ProjectCardItemMemoized_Project on Project {
  id
  state
  ...ProjectListItem
}
    ${ProjectListItemFragmentDoc}`;
export const ProjectsKanbanDocument = gql`
    query ProjectsKanban($filterIncludeState: [ProjectStateMachineStatus!]) {
  organisationSettings {
    id
    projectPhases(filterIncludeState: $filterIncludeState) {
      id
      ...ProjectPhasesConfigurator_OrganisationProjectPhase
      ...ProjectPhaseColumnAvailablePhases_OrganisationProjectPhase
    }
  }
}
    ${ProjectPhasesConfigurator_OrganisationProjectPhaseFragmentDoc}
${ProjectPhaseColumnAvailablePhases_OrganisationProjectPhaseFragmentDoc}`;

/**
 * __useProjectsKanbanQuery__
 *
 * To run a query within a React component, call `useProjectsKanbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsKanbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsKanbanQuery({
 *   variables: {
 *      filterIncludeState: // value for 'filterIncludeState'
 *   },
 * });
 */
export function useProjectsKanbanQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>(ProjectsKanbanDocument, options);
      }
export function useProjectsKanbanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>(ProjectsKanbanDocument, options);
        }
export function useProjectsKanbanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>(ProjectsKanbanDocument, options);
        }
export type ProjectsKanbanQueryHookResult = ReturnType<typeof useProjectsKanbanQuery>;
export type ProjectsKanbanLazyQueryHookResult = ReturnType<typeof useProjectsKanbanLazyQuery>;
export type ProjectsKanbanSuspenseQueryHookResult = ReturnType<typeof useProjectsKanbanSuspenseQuery>;
export type ProjectsKanbanQueryResult = Apollo.QueryResult<ProjectsKanbanQuery, ProjectsKanbanQueryVariables>;
export const ProjectsKanbanListDocument = gql`
    query ProjectsKanbanList($after: ID, $first: Int!, $sorting: [OrganisationProjectsSorting!], $filterSearchTerm: String, $filterType: [ProjectType!], $filterCategories: [Skill!], $filterBuildingZip: String, $filterWithinRadiusKm: Int, $filterMinBudget: Int, $filterAssigneeIds: [ID!], $filterBySourceCrmOrganisationIds: [ID!], $filterBySourceUserIds: [ID!], $filterBySources: [ProjectSourceType!], $filterByCrmLinkIds: [ID!], $filterIncludeState: [ProjectStateMachineStatus!], $filterByCrmOrganisationId: ID, $filterByPhaseId: ID!) {
  projects(
    after: $after
    first: $first
    sorting: $sorting
    filters: {includeState: $filterIncludeState, type: $filterType, categories: $filterCategories, buildingZip: $filterBuildingZip, withinRadiusKm: $filterWithinRadiusKm, minBudget: $filterMinBudget, assigneeIds: $filterAssigneeIds, sources: $filterBySources, sourceCrmOrganisationIds: $filterBySourceCrmOrganisationIds, sourceUserIds: $filterBySourceUserIds, crmOrganisationId: $filterByCrmOrganisationId, crmLinkIds: $filterByCrmLinkIds, phaseId: $filterByPhaseId}
    search: $filterSearchTerm
  ) {
    ... on ProjectConnection {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...ProjectCardItemMemoized_Project
        }
        cursor
      }
    }
  }
}
    ${ProjectCardItemMemoized_ProjectFragmentDoc}`;

/**
 * __useProjectsKanbanListQuery__
 *
 * To run a query within a React component, call `useProjectsKanbanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsKanbanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsKanbanListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      sorting: // value for 'sorting'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      filterType: // value for 'filterType'
 *      filterCategories: // value for 'filterCategories'
 *      filterBuildingZip: // value for 'filterBuildingZip'
 *      filterWithinRadiusKm: // value for 'filterWithinRadiusKm'
 *      filterMinBudget: // value for 'filterMinBudget'
 *      filterAssigneeIds: // value for 'filterAssigneeIds'
 *      filterBySourceCrmOrganisationIds: // value for 'filterBySourceCrmOrganisationIds'
 *      filterBySourceUserIds: // value for 'filterBySourceUserIds'
 *      filterBySources: // value for 'filterBySources'
 *      filterByCrmLinkIds: // value for 'filterByCrmLinkIds'
 *      filterIncludeState: // value for 'filterIncludeState'
 *      filterByCrmOrganisationId: // value for 'filterByCrmOrganisationId'
 *      filterByPhaseId: // value for 'filterByPhaseId'
 *   },
 * });
 */
export function useProjectsKanbanListQuery(baseOptions: Apollo.QueryHookOptions<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables> & ({ variables: ProjectsKanbanListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>(ProjectsKanbanListDocument, options);
      }
export function useProjectsKanbanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>(ProjectsKanbanListDocument, options);
        }
export function useProjectsKanbanListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>(ProjectsKanbanListDocument, options);
        }
export type ProjectsKanbanListQueryHookResult = ReturnType<typeof useProjectsKanbanListQuery>;
export type ProjectsKanbanListLazyQueryHookResult = ReturnType<typeof useProjectsKanbanListLazyQuery>;
export type ProjectsKanbanListSuspenseQueryHookResult = ReturnType<typeof useProjectsKanbanListSuspenseQuery>;
export type ProjectsKanbanListQueryResult = Apollo.QueryResult<ProjectsKanbanListQuery, ProjectsKanbanListQueryVariables>;