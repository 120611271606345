import { useFormatting } from "@msys/ui";
import React, { Dispatch, SetStateAction } from "react";
import {
  FilterChip,
  FilterChipGroup,
} from "../../../commons/filters/FilterChip";
import { OrganisationQuoteTemplatesFilters } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { Stack } from "@mui/material";

type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  "createdAfter" | "createdBefore" | "priceMin" | "priceMax" | "tagsAny"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
}

export const TemplatesQuoteFilterChips = <F extends Filters>({
  filters,
  setFilters,
}: Props<F>) => {
  const { t } = useTranslate("Templates");
  const { getFormattedDate } = useFormatting();

  return canShowChips(filters) ? (
    <Stack direction="row" flexWrap="wrap" spacing={1}>
      <FilterChip
        label={t("Created after")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdAfter: newValue,
          }))
        }
        value={filters.createdAfter && getFormattedDate(filters.createdAfter)}
      />
      <FilterChip
        label={t("Created before")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdBefore: newValue,
          }))
        }
        value={filters.createdBefore && getFormattedDate(filters.createdBefore)}
      />

      <FilterChip
        label={t("Min price")}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMin: newValue,
          }))
        }
        value={filters.priceMin}
      />
      <FilterChip
        label={t("Max price")}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMax: newValue,
          }))
        }
        value={filters.priceMax}
      />
      <FilterChipGroup
        label={t("Tag")}
        values={filters.tagsAny}
        getValueLabel={value => value}
        setValues={value =>
          setFilters(state => ({
            ...state,
            tagsAny: value,
          }))
        }
      />
    </Stack>
  ) : null;
};

export const canShowChips = (filters: Filters): boolean =>
  !!(
    filters.priceMin ||
    filters.priceMax ||
    filters.createdAfter ||
    filters.createdBefore ||
    filters.tagsAny?.length
  );
