/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateDebugModalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateDebugModalQuery = { quoteTemplateLatest?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, unpublishedChanges: any, linkedTemplatesByThis: Array<{ __typename: 'LinkedTemplateRef', linkingItemVersionNumber?: number | null, linkedItemVersionNumber?: number | null, linkingItem: { __typename: 'ItemRef', id: string, title: string, docId: string, pathForPdf: string }, linkedItem: { __typename: 'ItemRef', id: string, title: string, docId: string, pathForPdf: string } }>, linkingTemplatesToThis: Array<{ __typename: 'LinkingTemplateRef', linkingItemVersionNumber?: number | null, linkedItemVersionNumber?: number | null, linkingItem: { __typename: 'ItemRef', id: string, title: string, docId: string, pathForPdf: string }, linkedItem: { __typename: 'ItemRef', id: string, title: string, docId: string, pathForPdf: string } }>, publishedVersions: Array<{ __typename: 'PublishedVersion', id: string, createdAt: any, versionNumber: number }> } | null };


export const TemplateDebugModalDocument = gql`
    query TemplateDebugModal($id: ID!) {
  quoteTemplateLatest(id: $id) {
    id
    resolvedAsReadModelVersionNumber
    linkedTemplatesByThis {
      linkingItem {
        id
        title
        docId
        pathForPdf
      }
      linkingItemVersionNumber
      linkedItem {
        id
        title
        docId
        pathForPdf
      }
      linkedItemVersionNumber
    }
    linkingTemplatesToThis {
      linkingItem {
        id
        title
        docId
        pathForPdf
      }
      linkingItemVersionNumber
      linkedItem {
        id
        title
        docId
        pathForPdf
      }
      linkedItemVersionNumber
    }
    publishedVersions {
      id
      createdAt
      versionNumber
    }
    unpublishedChanges
  }
}
    `;

/**
 * __useTemplateDebugModalQuery__
 *
 * To run a query within a React component, call `useTemplateDebugModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDebugModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDebugModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateDebugModalQuery(baseOptions: Apollo.QueryHookOptions<TemplateDebugModalQuery, TemplateDebugModalQueryVariables> & ({ variables: TemplateDebugModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>(TemplateDebugModalDocument, options);
      }
export function useTemplateDebugModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>(TemplateDebugModalDocument, options);
        }
export function useTemplateDebugModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>(TemplateDebugModalDocument, options);
        }
export type TemplateDebugModalQueryHookResult = ReturnType<typeof useTemplateDebugModalQuery>;
export type TemplateDebugModalLazyQueryHookResult = ReturnType<typeof useTemplateDebugModalLazyQuery>;
export type TemplateDebugModalSuspenseQueryHookResult = ReturnType<typeof useTemplateDebugModalSuspenseQuery>;
export type TemplateDebugModalQueryResult = Apollo.QueryResult<TemplateDebugModalQuery, TemplateDebugModalQueryVariables>;