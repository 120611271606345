import { gql } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import {
  Box,
  FilledInput,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useCopyToClipboard, useLocation } from "react-use";
import { copyTextToClipboard } from "../../addresses/helpers";
import { TemplateQuoteSelectField } from "../../templates/quote/TemplateQuoteSelectField";
import { OrganisationEmbeddableWizardSnippetBox_OrganisationFragment } from "./OrganisationEmbeddableWizardSnippetBox.generated";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  organisation: OrganisationEmbeddableWizardSnippetBox_OrganisationFragment;
}

export const OrganisationEmbeddableWizardSnippetBox = ({
  organisation,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<
      React.ComponentProps<typeof TemplateQuoteSelectField>["value"]
    >(null);

  const [{ value }, copy] = useCopyToClipboard();

  const snippet = React.useMemo(
    () =>
      selectedTemplate
        ? `<iframe src="https://${location.hostname}/wizard.html?contractorId=${organisation.id}&templateId=${selectedTemplate.id}" ></iframe>`
        : null,
    [location.hostname, organisation.id, selectedTemplate]
  );

  return (
    <CardContainer
      title={t("Embeddable wizard", {
        ns: "OrganisationSettings",
      })}
      isExpandable
    >
      <Stack direction={"column"} spacing={1} padding={1}>
        <TemplateQuoteSelectField
          filterDocumentType="QUOTE"
          inputLabel={t("Select a template", { ns: "OrganisationSettings" })}
          value={selectedTemplate}
          onChange={setSelectedTemplate}
        />
        {snippet && (
          <FormControl variant="filled">
            <InputLabel>
              {t("Snippet code", {
                ns: "OrganisationSettings",
              })}
            </InputLabel>
            <FilledInput
              value={snippet}
              readOnly
              multiline
              fullWidth
              endAdornment={
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={async () => {
                    if (snippet) await copyTextToClipboard(snippet);
                    enqueueSnackbar(
                      t("Copied to clipboard", {
                        ns: "Global",
                      })
                    );
                  }}
                >
                  <Tooltip
                    title={t("Copy snippet", {
                      ns: "OrganisationSettings",
                    })}
                  >
                    <ContentCopyIcon />
                  </Tooltip>
                </IconButton>
              }
            />
          </FormControl>
        )}
      </Stack>
    </CardContainer>
  );
};
