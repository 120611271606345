/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { TemplateQuoteAction_QuoteTemplateFragmentDoc } from '../../../features/templates/quote/TemplatesQuoteTemplateAction.generated';
import { VirtualItem__DocumentItemFragmentDoc } from '../../../trees-virtual/types.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteThreeDVersionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  versionNumber: Types.Scalars['Int']['input'];
}>;


export type TemplateQuoteThreeDVersionQuery = { quoteTemplateVersion?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, latestPublishedVersionNumber?: number | null, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, sharedPublicly: boolean, containsUnpublishedChanged: boolean, addedToShop: boolean, usedInOtherTemplates: boolean, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', updateAvailable: boolean, subscribedVersionNumber: number } | null, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } | null };

export type TemplateQuoteThreeDVersion_ItemFragment = { __typename: 'Item', id: string, title: string, decisionIsContingentItem: boolean, timeTrackingRequired: boolean, photoApprovalRequired: boolean, qaApprovalRequired: boolean, approvalRequired: boolean, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathForPdf: string, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const TemplateQuoteThreeDVersion_ItemFragmentDoc = gql`
    fragment TemplateQuoteThreeDVersion_Item on Item {
  id
  title
  decisionIsContingentItem
  timeTrackingRequired
  photoApprovalRequired
  qaApprovalRequired
  approvalRequired
  ...VirtualItem__DocumentItem
}
    ${VirtualItem__DocumentItemFragmentDoc}`;
export const TemplateQuoteThreeDVersionDocument = gql`
    query TemplateQuoteThreeDVersion($id: ID!, $versionNumber: Int!) {
  quoteTemplateVersion(id: $id, versionNumber: $versionNumber) {
    id
    resolvedAsReadModelVersionNumber
    title
    items {
      id
      originVersionNumber
    }
    ...TemplateQuoteAction_QuoteTemplate
  }
}
    ${TemplateQuoteAction_QuoteTemplateFragmentDoc}`;

/**
 * __useTemplateQuoteThreeDVersionQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteThreeDVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteThreeDVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteThreeDVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionNumber: // value for 'versionNumber'
 *   },
 * });
 */
export function useTemplateQuoteThreeDVersionQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables> & ({ variables: TemplateQuoteThreeDVersionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>(TemplateQuoteThreeDVersionDocument, options);
      }
export function useTemplateQuoteThreeDVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>(TemplateQuoteThreeDVersionDocument, options);
        }
export function useTemplateQuoteThreeDVersionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>(TemplateQuoteThreeDVersionDocument, options);
        }
export type TemplateQuoteThreeDVersionQueryHookResult = ReturnType<typeof useTemplateQuoteThreeDVersionQuery>;
export type TemplateQuoteThreeDVersionLazyQueryHookResult = ReturnType<typeof useTemplateQuoteThreeDVersionLazyQuery>;
export type TemplateQuoteThreeDVersionSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteThreeDVersionSuspenseQuery>;
export type TemplateQuoteThreeDVersionQueryResult = Apollo.QueryResult<TemplateQuoteThreeDVersionQuery, TemplateQuoteThreeDVersionQueryVariables>;