import { gql, useApolloClient } from "@apollo/client";
import { useScreenWidth } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useUserData } from "../../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../../commons/layout/Page";
import { PageColumn } from "../../../commons/layout/PageColumn";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { Stack } from "../../../commons/layout/Stack";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../constants";
import { isTreePreviewItemVisible } from "../../../features/doc-items/helpers";
import { useQuoteQuickFilters } from "../../../features/quotes/QuoteQuickFilters";
import { allowHaveTemplateChildren } from "../../../features/templates/helpers";
import { TemplatesQuoteTemplateAction } from "../../../features/templates/quote/TemplatesQuoteTemplateAction";
import { createUnnestedTemplateTreeItem } from "../../../features/templates/quote/trees";
import { ThreeD } from "../../../features/threeD/ThreeD";
import { ThreeDEditorRef } from "../../../features/threeD/ThreeDEditor";
import { useFeature } from "../../../../common/FeatureFlags";
import { PermissionName } from "../../../../clients/graphqlTypes";
import { VirtualBareTreeStandaloneItem } from "../../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../../trees-virtual/hooks/useExpandedStore";
import { VirtualItemTree } from "../../../trees-virtual/VirtualItemTree";
import { TreeConnector } from "../../../trees/components/TreeConnector";
import { TreeToggleAllExpandedButton } from "../../../trees/components/TreeToggleButton";
import {
  TemplateQuoteThreeDEdit_ItemFragment,
  useTemplateQuoteThreeDEditQuery,
} from "./TemplateQuoteEditThreeD.generated";

interface Props {
  docId: string;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocVersion: string;
  pathToDocPage: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  returnPath?: string | null;
  pageSubHeader: React.ReactElement | null;
}

export const TemplateQuoteThreeDEdit = ({
  docId,
  pathToDocList,
  pathToDoc,
  pathToDocVersion,
  pathToDocPage,
  submenuItems,
  prefixBreadcrumbs,
  pageSubHeader,
}: Props) => {
  const { t } = useTranslate(["Templates"]);
  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  const viewer = useUserData().currentUser!;
  const { isMinDesktop, isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<TemplateQuoteThreeDEdit_ItemFragment>(false);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote-template",
    docId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useTemplateQuoteThreeDEditQuery({
    client,
    variables: {
      id: docId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const doc = query.data?.quoteTemplateLatest;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [navigate, pathToDocPage]
  );

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const viewerPermissions: PermissionName[] = viewer.organisationPermissions;

  const UnnestedTemplateTreeItem = React.useMemo(
    () =>
      createUnnestedTemplateTreeItem(
        pathToDocPage,
        pathToDocList,
        { viewerPermissions },
        docId,
        navigateToItem,
        expandedStore.setItemExpanded
      ),
    [
      pathToDocPage,
      pathToDocList,
      viewerPermissions,
      docId,
      navigateToItem,
      expandedStore.setItemExpanded,
    ]
  );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems;
    const filteredItems = quickFilter(treeItems);
    return [filteredItems, rootItem, allDocItems];
  }, [doc, quickFilter]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  React.useEffect(() => {
    if (query.loading || query.error) return;
    if (
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
    query.error,
  ]);

  const filterItem = React.useCallback(
    (i: TemplateQuoteThreeDEdit_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  const threeDEditorRef = React.useRef<ThreeDEditorRef>(null);

  const isRootSelected = selectedItemId === rootItem?.id;

  const isOrganisationTemplate =
    !!doc && doc.owningSystemOrganisationId === viewer.organisation.id;
  const canEdit = isOrganisationTemplate;

  return (
    <Page
      title={doc?.title ?? ""}
      subtitle={t("Document Templates", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      action={
        doc ? (
          <TemplatesQuoteTemplateAction doc={doc} pathToDoc={pathToDoc} />
        ) : undefined
      }
      subHeader={pageSubHeader}
    >
      {doc && rootItem && (
        <>
          {isMinDesktop && canEdit && (
            <PageColumn
              $hasRightBorder
              $hasBackground
              $display="block"
              ref={isMinTablet ? setTreeContainer : undefined}
            >
              <Stack flexDirection={"column"} spacing={2}>
                <Stack width="100%" alignItems="center" spacing={1}>
                  <TreeToggleAllExpandedButton
                    areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                    setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                  />
                  <QuickFiltersTabs />
                </Stack>

                <Box>
                  <VirtualBareTreeStandaloneItem<TemplateQuoteThreeDEdit_ItemFragment>
                    item={rootItem}
                    items={filteredTreeItems}
                    allItems={allDocItems}
                    depth={0}
                    itemComponent={UnnestedTemplateTreeItem}
                    selected={isRootSelected}
                    sticky={isRootSelected}
                    top={0}
                    clickable
                    onClick={() => navigateToItem(rootItem.id)}
                  />

                  <TreeConnector
                    $isAtTheTopOfTree={filteredTreeItems.length > 0}
                  />
                  {filteredTreeItems.length > 0 && (
                    <VirtualItemTree<
                      TemplateQuoteThreeDEdit_ItemFragment,
                      false
                    >
                      docId={null}
                      projectId={null}
                      items={filteredTreeItems}
                      allItems={allDocItems}
                      filterItem={
                        isClientVisibilityEnabled ? filterItem : undefined
                      }
                      selectedItemId={selectedItemId}
                      enableCreating={false}
                      enableDragging={false}
                      shouldRenderCreateInput={shouldRenderCreateInput}
                      allowHaveChildren={allowHaveTemplateChildren}
                      documentItemTypes={QUOTE_TEMPLATE_ITEM_TYPES}
                      container={treeContainer}
                      itemComponent={UnnestedTemplateTreeItem}
                      expandedStore={expandedStore}
                    />
                  )}
                </Box>
              </Stack>
            </PageColumn>
          )}

          <Outlet
            context={{
              expandedItemIds: expandedStore.expandedItemIds,
              viewerDecisionRole: undefined,
            }}
          />

          <PageContainer $fullHeight $noPadding>
            <ThreeD
              threeDEditorRef={threeDEditorRef}
              projectId={null}
              docId={docId}
              returnPath={returnPath ?? pathToDocVersion}
              allDocItems={allDocItems}
              isEditable={isMinDesktop && canEdit}
            />
          </PageContainer>
        </>
      )}
    </Page>
  );
};

function shouldRenderCreateInput() {
  return false;
}
