import moment from "moment";
import React from "react";
import { useFeature } from "../../../common/FeatureFlags";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../auth/useUserData";
import { DecisionWizardButton } from "../doc-items/buttons/DecisionWizardButton";
import {
  QuoteAction_ProjectFragment,
  QuoteAction_QuoteFragment,
} from "./QuoteAction.generated";
import { AcceptChangesButton } from "./buttons/AcceptChangesButton";
import { AcceptQuoteButton } from "./buttons/AcceptQuoteButton";
import { DeclineChangesButton } from "./buttons/DeclineChangesButton";
import { DeclineQuoteButton } from "./buttons/DeclineQuoteButton";
import { DiscardPendingChangesButton } from "./buttons/DiscardPendingChangesButton";
import { FinalizeDecisionsInBulkButton } from "./buttons/FinalizeDecisionsInBulkButton";
import { MarkQuoteAsAcceptedButton } from "./buttons/MarkQuoteAsAcceptedButton";
import { PublishQuoteButton } from "./buttons/PublishQuoteButton";
import { ShopButton } from "./buttons/ShopButton";
import { UnpublishQuoteButton } from "./buttons/UnpublishQuoteButton";
import { SendQuoteViaEmailButton } from "./modals/SendQuoteViaEmailButton";

interface Props {
  doc: QuoteAction_QuoteFragment;
  project: QuoteAction_ProjectFragment;
  onDiscardPendingChangesRefetchQueries: string[];
  onDeclineChangesRefetchQueries: string[];
  startDecisionWizard?: () => unknown;
  startDecisionsInBulkProcess?: () => void;
  refetch?: () => Promise<unknown>;
}

export const QuoteAction = ({
  doc,
  project,
  onDiscardPendingChangesRefetchQueries,
  onDeclineChangesRefetchQueries,
  startDecisionWizard,
  startDecisionsInBulkProcess,
  refetch,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const isShopEnabled = useFeature("Shop");

  const hasPendingChanges = doc.agreement === "YES" && doc.needsAgreement; // Is this correct??? copied from "decline changes" permission app condition
  const isExpired =
    doc.expirationDate &&
    moment().startOf("day").isAfter(moment(doc.expirationDate));

  const actionButtons: React.ReactNode[] = [
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"send project quote to client"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="SHARE_QUOTES"
        document={doc}
      >
        {doc.isPublished && (
          <SendQuoteViaEmailButton
            quoteId={doc.id}
            quote={doc}
            projectId={project.id}
            project={project}
            calculationType={"proposed"}
          />
        )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"mark quote as accepted"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="DECIDE_QUOTES"
        document={doc}
      >
        {!doc.contractee.isMyOrganisation &&
          !doc.incomingRequest &&
          doc.agreement === "NONE" &&
          doc.isPublished &&
          doc.isBinding &&
          !isExpired && (
            <MarkQuoteAsAcceptedButton docId={doc.id} projectId={project.id} />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"accept quote"}
    >
      {doc.contractee.isMyOrganisation &&
        (!doc.incomingRequestRecipientStatus ||
          doc.incomingRequestRecipientStatus === "YES" ||
          doc.incomingRequestRecipientStatus === "MAYBE") &&
        doc.agreement === "NONE" &&
        doc.isPublished &&
        doc.isBinding &&
        !isExpired && (
          <AcceptQuoteButton
            docId={doc.id}
            projectId={project.id}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"decline quote"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.isPublished &&
        doc.agreement === "NONE" &&
        doc.isBinding &&
        (!doc.expirationDate ||
          moment().startOf("day").isBefore(moment(doc.expirationDate))) && (
          <DeclineQuoteButton
            docId={doc.id}
            projectId={project.id}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"accept changes"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.agreement === "YES" &&
        doc.needsAgreement && (
          <AcceptChangesButton
            docId={doc.id}
            projectId={project.id}
            defaultPlace={
              project.building?.buildingAddress?.city ??
              doc.contractee?.address?.city ??
              ""
            }
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="DECIDE_QUOTES"
      document={doc}
      key={"decline changes"}
    >
      {(doc.contractee.isMyOrganisation || !doc.incomingRequest) &&
        doc.agreement === "YES" &&
        doc.needsAgreement && (
          <DeclineChangesButton
            docId={doc.id}
            projectId={project.id}
            refetchQueries={onDeclineChangesRefetchQueries}
            handleComplete={refetch}
          />
        )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByDocumentPermissionWithDebug
      permission="MANAGE_QUOTES"
      document={doc}
      key={"discard pending changes"}
    >
      {hasPendingChanges && (
        <DiscardPendingChangesButton
          projectId={project.id}
          quoteId={doc.id}
          itemId={doc.rootItem.id}
          refetchQueries={onDiscardPendingChangesRefetchQueries}
          handleComplete={refetch}
        />
      )}
    </RestrictedByDocumentPermissionWithDebug>,
    <RestrictedByCapabilityWithDebug capability="QUOTING" key={"publish quote"}>
      <RestrictedByDocumentPermissionWithDebug
        permission="SHARE_QUOTES"
        document={doc}
      >
        {!doc.isPublished &&
          doc.agreement !== "YES" &&
          (!doc.incomingRequest ||
            doc.incomingRequest.status === "PUBLISHED") && (
            <PublishQuoteButton
              doc={doc}
              project={project}
              docId={doc.id}
              projectId={project.id}
              handleComplete={refetch}
              calculationType={"proposed"}
            />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    <RestrictedByCapabilityWithDebug
      capability="QUOTING"
      key={"unpublish quote"}
    >
      <RestrictedByDocumentPermissionWithDebug
        permission="SHARE_QUOTES"
        document={doc}
      >
        {doc.isPublished &&
          doc.agreement !== "YES" &&
          (!doc.incomingRequest ||
            doc.incomingRequest.status === "PUBLISHED") && (
            <UnpublishQuoteButton
              docId={doc.id}
              projectId={project.id}
              handleComplete={refetch}
            />
          )}
      </RestrictedByDocumentPermissionWithDebug>
    </RestrictedByCapabilityWithDebug>,
    startDecisionWizard && (
      <DecisionWizardButton
        start={startDecisionWizard}
        key={"decision-wizard"}
      />
    ),
    startDecisionsInBulkProcess && (
      <FinalizeDecisionsInBulkButton
        onClick={startDecisionsInBulkProcess}
        key={"finalize-decisions"}
      />
    ),
    isShopEnabled && (
      <RestrictedByCapabilityWithDebug capability="SHOP" key={"shop"}>
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={doc}
        >
          <ShopButton docId={doc.id} />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ),
  ].filter(Boolean);

  return actionButtons;
};
