/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { IncomingRequestListItemFragmentDoc } from '../../features/requests/RequestListItem.generated';
import { AddressDetails__AddressFragmentDoc } from '../../features/addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOpportunitiesQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterType?: Types.InputMaybe<Array<Types.ProjectType>>;
  filterCategories?: Types.InputMaybe<Array<Types.Skill>>;
  filterBuildingZip?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterWithinRadiusKm?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterMinBudget?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sorting?: Types.InputMaybe<Array<Types.MarketplaceOpportunitiesSorting>>;
}>;


export type MarketplaceOpportunitiesQuery = { marketplaceOpportunities: { __typename: 'MarketplaceOpportunityConnection', totalCount: number, edges: Array<{ __typename: 'MarketplaceOpportunityEdge', node: { __typename: 'Request', id: string, title: string, description: string, createdAt?: any | null, owningSystemOrganisationId: string, earliestStart?: any | null, deadline?: any | null, distance?: number | null, categories: Array<Types.Skill>, urgent: boolean, overdue: boolean, viewerRecipientNumPublishedQuotes: number, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, address?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, requestorDoc: { __typename: 'Requirement', id: string, contractee: { __typename: 'DocActor', id: string, companyName: string, firstname: string, familyname: string } }, budget?: { __typename: 'Money', currency: string, amount: number } | null } }> } | { __typename: 'MissingCapabilities' } };

export type RequestRowFragment = { __typename: 'Request', id: string, title: string, description: string, createdAt?: any | null, owningSystemOrganisationId: string, earliestStart?: any | null, deadline?: any | null, distance?: number | null, categories: Array<Types.Skill>, urgent: boolean, address?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, requestorDoc: { __typename: 'Requirement', id: string, contractee: { __typename: 'DocActor', id: string, companyName: string, firstname: string, familyname: string } }, budget?: { __typename: 'Money', currency: string, amount: number } | null };

export const RequestRowFragmentDoc = gql`
    fragment RequestRow on Request {
  id
  title
  description
  createdAt
  owningSystemOrganisationId
  address {
    ...AddressDetails__Address
  }
  requestorDoc {
    id
    contractee {
      id
      companyName
      firstname
      familyname
    }
  }
  earliestStart
  deadline
  distance
  categories
  urgent
  budget {
    currency
    amount
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const MarketplaceOpportunitiesDocument = gql`
    query MarketplaceOpportunities($offset: Int, $limit: Int!, $filterSearchTerm: String, $filterType: [ProjectType!], $filterCategories: [Skill!], $filterBuildingZip: String, $filterWithinRadiusKm: Int, $filterMinBudget: Int, $sorting: [MarketplaceOpportunitiesSorting!]) {
  marketplaceOpportunities(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {type: $filterType, categories: $filterCategories, buildingZip: $filterBuildingZip, withinRadiusKm: $filterWithinRadiusKm, minBudget: $filterMinBudget}
    search: $filterSearchTerm
  ) {
    ... on MarketplaceOpportunityConnection {
      totalCount
      edges {
        node {
          ...RequestRow
          ...IncomingRequestListItem
        }
      }
    }
  }
}
    ${RequestRowFragmentDoc}
${IncomingRequestListItemFragmentDoc}`;

/**
 * __useMarketplaceOpportunitiesQuery__
 *
 * To run a query within a React component, call `useMarketplaceOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceOpportunitiesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      filterType: // value for 'filterType'
 *      filterCategories: // value for 'filterCategories'
 *      filterBuildingZip: // value for 'filterBuildingZip'
 *      filterWithinRadiusKm: // value for 'filterWithinRadiusKm'
 *      filterMinBudget: // value for 'filterMinBudget'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMarketplaceOpportunitiesQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables> & ({ variables: MarketplaceOpportunitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>(MarketplaceOpportunitiesDocument, options);
      }
export function useMarketplaceOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>(MarketplaceOpportunitiesDocument, options);
        }
export function useMarketplaceOpportunitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>(MarketplaceOpportunitiesDocument, options);
        }
export type MarketplaceOpportunitiesQueryHookResult = ReturnType<typeof useMarketplaceOpportunitiesQuery>;
export type MarketplaceOpportunitiesLazyQueryHookResult = ReturnType<typeof useMarketplaceOpportunitiesLazyQuery>;
export type MarketplaceOpportunitiesSuspenseQueryHookResult = ReturnType<typeof useMarketplaceOpportunitiesSuspenseQuery>;
export type MarketplaceOpportunitiesQueryResult = Apollo.QueryResult<MarketplaceOpportunitiesQuery, MarketplaceOpportunitiesQueryVariables>;