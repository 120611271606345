import React from "react";
import { useFeature } from "../../../../common/FeatureFlags";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../../auth/useUserData";
import { TemplateQuoteAddedToShopIcon } from "./TemplateQuoteAddedToShopStatus";
import { TemplateQuotePublishingIcon } from "./TemplateQuotePublishingStatus";
import { TemplateQuoteStatuses_QuoteTemplateFragment } from "./TemplateQuoteStatuses.generated";
import { TemplateQuoteSubscriptionStatusIcon } from "./TemplateQuoteSubscriptionStatus";

export const useTemplateQuoteStatuses = () => {
  const viewer = useUserData().currentUser!;
  const isShopEnabled = useFeature("Shop");

  const getTemplateQuoteStatusIcons = (
    doc: TemplateQuoteStatuses_QuoteTemplateFragment
  ) => {
    const isOwnTemplate =
      viewer.organisation.id === doc.owningSystemOrganisationId;
    const haveExplicitAccess = doc.accessType === "explicit";

    const statusIcons: React.ReactNode[] = [
      !isOwnTemplate && haveExplicitAccess && (
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_QUOTES"
          key={"template-subscribe-status"}
        >
          <TemplateQuoteSubscriptionStatusIcon doc={doc} />
        </RestrictedByOrganisationPermissionWithDebug>
      ),
      isOwnTemplate && doc.hasAnyPublishedVersion && (
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_QUOTES"
          key={"template-share-status"}
        >
          <TemplateQuotePublishingIcon doc={doc} />
        </RestrictedByOrganisationPermissionWithDebug>
      ),
      isShopEnabled && haveExplicitAccess && doc.hasAnyPublishedVersion && (
        <RestrictedByCapabilityWithDebug
          capability="SHOP"
          key={"template-add-to-shop-status"}
        >
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_QUOTES">
            <TemplateQuoteAddedToShopIcon doc={doc} />
          </RestrictedByOrganisationPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      ),
    ].filter(Boolean);

    return statusIcons;
  };

  return { getTemplateQuoteStatusIcons };
};
