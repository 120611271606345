/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
export type RequestHeaderBoxFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, title: string, description: string, categories: Array<Types.Skill>, type: Types.ProjectType, earliestStart?: any | null, deadline?: any | null, marketplaceFullAddress: boolean, requestorProjectId: string, budget?: { __typename: 'Money', currency: string, amount: number } | null, address?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export const RequestHeaderBoxFragmentDoc = gql`
    fragment RequestHeaderBox on Request {
  id
  status
  title
  description
  categories
  type
  earliestStart
  deadline
  budget {
    currency
    amount
  }
  marketplaceFullAddress
  address {
    ...AddressDetails__Address
  }
  attachments {
    ...Attachment
  }
  requestorProjectId
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentFragmentDoc}`;