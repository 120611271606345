/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationEmailTemplateModal_OrganisationEmailTemplateFragmentDoc } from '../modals/OrganisationEmailTemplateModal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationEmailSettingsBox_OrganisationFragment = { __typename: 'ViewerOrganisation', id: string };

export type OrganisationEmailSettingsBox_OrganisationEmailSettingsFragment = { __typename: 'OrganisationEmailSettings', id: string, replyToAddress?: string | null };

export type OrganisationEmailSettingsBox_OrganisationEmailTemplateFragment = { __typename: 'OrganisationEmailTemplate', id: string, context: Types.EmailTemplateContext, description: string, subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, url: string, title: string, mimeType: string, group: string }> };

export type UpdateOrganisationEmailReplyToAddressMutationVariables = Types.Exact<{
  input: Types.OrganisationEmailSettingsUpdateInput;
}>;


export type UpdateOrganisationEmailReplyToAddressMutation = { updateOrganisationEmailSettings: { __typename: 'OrganisationEmailSettingsUpdateResult', organisationEmailSettings: { __typename: 'OrganisationEmailSettings', id: string, replyToAddress?: string | null } } };

export type OrganisationEmailTemplates_OrganisationEmailTemplateFragment = { __typename: 'OrganisationEmailTemplate', id: string, context: Types.EmailTemplateContext, description: string, subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, url: string, title: string, mimeType: string, group: string }> };

export type AddOrganisationEmailTemplateMutationVariables = Types.Exact<{
  input: Types.AddOrganisationEmailTemplateInput;
}>;


export type AddOrganisationEmailTemplateMutation = { addOrganisationEmailTemplate: { __typename: 'AddOrganisationEmailTemplateResult', emailTemplate: { __typename: 'OrganisationEmailTemplate', id: string, context: Types.EmailTemplateContext, description: string, subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, url: string, title: string, mimeType: string, group: string }> } } };

export type ModifyOrganisationEmailTemplateMutationVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
  input: Types.ModifyOrganisationEmailTemplateInput;
}>;


export type ModifyOrganisationEmailTemplateMutation = { modifyOrganisationEmailTemplate: { __typename: 'ModifyOrganisationEmailTemplateResult', emailTemplate: { __typename: 'OrganisationEmailTemplate', id: string, context: Types.EmailTemplateContext, description: string, subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, url: string, title: string, mimeType: string, group: string }> } } };

export type RemoveOrganisationEmailTemplateMutationVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type RemoveOrganisationEmailTemplateMutation = { removeOrganisationEmailTemplate: { __typename: 'RemoveOrganisationEmailTemplateResult', templateId: string } };

export const OrganisationEmailSettingsBox_OrganisationFragmentDoc = gql`
    fragment OrganisationEmailSettingsBox_Organisation on ViewerOrganisation {
  id
}
    `;
export const OrganisationEmailSettingsBox_OrganisationEmailSettingsFragmentDoc = gql`
    fragment OrganisationEmailSettingsBox_OrganisationEmailSettings on OrganisationEmailSettings {
  id
  replyToAddress
}
    `;
export const OrganisationEmailTemplates_OrganisationEmailTemplateFragmentDoc = gql`
    fragment OrganisationEmailTemplates_OrganisationEmailTemplate on OrganisationEmailTemplate {
  id
  context
  description
  attachments {
    id
    url
    title
    mimeType
    group
  }
  ...OrganisationEmailTemplateModal_OrganisationEmailTemplate
}
    ${OrganisationEmailTemplateModal_OrganisationEmailTemplateFragmentDoc}`;
export const OrganisationEmailSettingsBox_OrganisationEmailTemplateFragmentDoc = gql`
    fragment OrganisationEmailSettingsBox_OrganisationEmailTemplate on OrganisationEmailTemplate {
  id
  ...OrganisationEmailTemplates_OrganisationEmailTemplate
}
    ${OrganisationEmailTemplates_OrganisationEmailTemplateFragmentDoc}`;
export const UpdateOrganisationEmailReplyToAddressDocument = gql`
    mutation UpdateOrganisationEmailReplyToAddress($input: OrganisationEmailSettingsUpdateInput!) {
  updateOrganisationEmailSettings(input: $input) {
    organisationEmailSettings {
      id
      replyToAddress
    }
  }
}
    `;
export type UpdateOrganisationEmailReplyToAddressMutationFn = Apollo.MutationFunction<UpdateOrganisationEmailReplyToAddressMutation, UpdateOrganisationEmailReplyToAddressMutationVariables>;

/**
 * __useUpdateOrganisationEmailReplyToAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationEmailReplyToAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationEmailReplyToAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationEmailReplyToAddressMutation, { data, loading, error }] = useUpdateOrganisationEmailReplyToAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationEmailReplyToAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationEmailReplyToAddressMutation, UpdateOrganisationEmailReplyToAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationEmailReplyToAddressMutation, UpdateOrganisationEmailReplyToAddressMutationVariables>(UpdateOrganisationEmailReplyToAddressDocument, options);
      }
export type UpdateOrganisationEmailReplyToAddressMutationHookResult = ReturnType<typeof useUpdateOrganisationEmailReplyToAddressMutation>;
export type UpdateOrganisationEmailReplyToAddressMutationResult = Apollo.MutationResult<UpdateOrganisationEmailReplyToAddressMutation>;
export type UpdateOrganisationEmailReplyToAddressMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationEmailReplyToAddressMutation, UpdateOrganisationEmailReplyToAddressMutationVariables>;
export const AddOrganisationEmailTemplateDocument = gql`
    mutation AddOrganisationEmailTemplate($input: AddOrganisationEmailTemplateInput!) {
  addOrganisationEmailTemplate(input: $input) {
    emailTemplate {
      id
      ...OrganisationEmailTemplates_OrganisationEmailTemplate
    }
  }
}
    ${OrganisationEmailTemplates_OrganisationEmailTemplateFragmentDoc}`;
export type AddOrganisationEmailTemplateMutationFn = Apollo.MutationFunction<AddOrganisationEmailTemplateMutation, AddOrganisationEmailTemplateMutationVariables>;

/**
 * __useAddOrganisationEmailTemplateMutation__
 *
 * To run a mutation, you first call `useAddOrganisationEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationEmailTemplateMutation, { data, loading, error }] = useAddOrganisationEmailTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganisationEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganisationEmailTemplateMutation, AddOrganisationEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganisationEmailTemplateMutation, AddOrganisationEmailTemplateMutationVariables>(AddOrganisationEmailTemplateDocument, options);
      }
export type AddOrganisationEmailTemplateMutationHookResult = ReturnType<typeof useAddOrganisationEmailTemplateMutation>;
export type AddOrganisationEmailTemplateMutationResult = Apollo.MutationResult<AddOrganisationEmailTemplateMutation>;
export type AddOrganisationEmailTemplateMutationOptions = Apollo.BaseMutationOptions<AddOrganisationEmailTemplateMutation, AddOrganisationEmailTemplateMutationVariables>;
export const ModifyOrganisationEmailTemplateDocument = gql`
    mutation ModifyOrganisationEmailTemplate($templateId: ID!, $input: ModifyOrganisationEmailTemplateInput!) {
  modifyOrganisationEmailTemplate(templateId: $templateId, input: $input) {
    emailTemplate {
      id
      ...OrganisationEmailTemplates_OrganisationEmailTemplate
    }
  }
}
    ${OrganisationEmailTemplates_OrganisationEmailTemplateFragmentDoc}`;
export type ModifyOrganisationEmailTemplateMutationFn = Apollo.MutationFunction<ModifyOrganisationEmailTemplateMutation, ModifyOrganisationEmailTemplateMutationVariables>;

/**
 * __useModifyOrganisationEmailTemplateMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationEmailTemplateMutation, { data, loading, error }] = useModifyOrganisationEmailTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationEmailTemplateMutation, ModifyOrganisationEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationEmailTemplateMutation, ModifyOrganisationEmailTemplateMutationVariables>(ModifyOrganisationEmailTemplateDocument, options);
      }
export type ModifyOrganisationEmailTemplateMutationHookResult = ReturnType<typeof useModifyOrganisationEmailTemplateMutation>;
export type ModifyOrganisationEmailTemplateMutationResult = Apollo.MutationResult<ModifyOrganisationEmailTemplateMutation>;
export type ModifyOrganisationEmailTemplateMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationEmailTemplateMutation, ModifyOrganisationEmailTemplateMutationVariables>;
export const RemoveOrganisationEmailTemplateDocument = gql`
    mutation RemoveOrganisationEmailTemplate($templateId: ID!) {
  removeOrganisationEmailTemplate(templateId: $templateId) {
    templateId
  }
}
    `;
export type RemoveOrganisationEmailTemplateMutationFn = Apollo.MutationFunction<RemoveOrganisationEmailTemplateMutation, RemoveOrganisationEmailTemplateMutationVariables>;

/**
 * __useRemoveOrganisationEmailTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveOrganisationEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganisationEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganisationEmailTemplateMutation, { data, loading, error }] = useRemoveOrganisationEmailTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useRemoveOrganisationEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganisationEmailTemplateMutation, RemoveOrganisationEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganisationEmailTemplateMutation, RemoveOrganisationEmailTemplateMutationVariables>(RemoveOrganisationEmailTemplateDocument, options);
      }
export type RemoveOrganisationEmailTemplateMutationHookResult = ReturnType<typeof useRemoveOrganisationEmailTemplateMutation>;
export type RemoveOrganisationEmailTemplateMutationResult = Apollo.MutationResult<RemoveOrganisationEmailTemplateMutation>;
export type RemoveOrganisationEmailTemplateMutationOptions = Apollo.BaseMutationOptions<RemoveOrganisationEmailTemplateMutation, RemoveOrganisationEmailTemplateMutationVariables>;