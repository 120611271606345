import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  DataGrid,
  InfoLabelMessage,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { DetachedEmailField } from "../../../commons/form-fields/EmailField";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { useEmailAddressValidation } from "../../email/validateEmailAddress";
import {
  OrganisationEmailSettingsBox_OrganisationEmailSettingsFragment,
  OrganisationEmailSettingsBox_OrganisationEmailTemplateFragment,
  OrganisationEmailSettingsBox_OrganisationFragment,
  OrganisationEmailTemplates_OrganisationEmailTemplateFragment,
  useAddOrganisationEmailTemplateMutation,
  useModifyOrganisationEmailTemplateMutation,
  useRemoveOrganisationEmailTemplateMutation,
  useUpdateOrganisationEmailReplyToAddressMutation,
} from "./OrganisationEmailSettingsBox.generated";
import { OrganisationEmailTemplateModal } from "../modals/OrganisationEmailTemplateModal";
import { useEmailTemplateContexts } from "./useEmailTemplateContexts";

interface FormValues {
  emailReplyToAddress: string | null;
}

interface Props {
  organisation: OrganisationEmailSettingsBox_OrganisationFragment;
  organisationEmailSettings: OrganisationEmailSettingsBox_OrganisationEmailSettingsFragment;
  organisationEmailTemplates: OrganisationEmailSettingsBox_OrganisationEmailTemplateFragment[];
}

export function OrganisationEmailSettingsBox({
  organisation,
  organisationEmailSettings,
  organisationEmailTemplates,
}: Props) {
  const { t } = useTranslate(["OrganisationSettings"]);

  const isEmailAddressValid = useEmailAddressValidation();

  const client = useApolloClient();
  const [updateReplyToAddress] =
    useUpdateOrganisationEmailReplyToAddressMutation({
      client,
    });

  const handleSubmit = React.useCallback(
    async (values: FormValues) => {
      await updateReplyToAddress({
        variables: {
          input: {
            id: organisation.id,
            replyToAddress: values.emailReplyToAddress,
          },
        },
      });
    },
    [organisation.id, updateReplyToAddress]
  );

  return (
    <CardContainer
      title={t("Email settings", {
        ns: "OrganisationSettings",
      })}
      isExpandable
    >
      <Formik
        initialValues={{
          emailReplyToAddress: organisationEmailSettings.replyToAddress ?? null,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .label(
              t("Reply-to email address", {
                ns: "OrganisationSettings",
              })
            )
            .email()
            .trim(),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack padding={1} spacing={1}>
            <Stack spacing={0.5}>
              <Typography variant={"h4"}>
                {t("Reply-to email address", {
                  ns: "OrganisationSettings",
                })}
              </Typography>
              <InfoLabelMessage
                message={t(
                  "If you set a custom reply-to email address, replies to your emails will not be visible in MeisterSystems",
                  {
                    ns: "OrganisationSettings",
                  }
                )}
              />
              <DetachedEmailField
                name={"emailReplyToAddress"}
                label={t("Reply-to email address", {
                  ns: "OrganisationSettings",
                })}
                hideLabelInReadView={true}
                validate={isEmailAddressValid}
                isValid={undefined}
              />
            </Stack>

            <Divider />

            <OrganisationEmailTemplates
              organisationId={organisation.id}
              emailTemplates={organisationEmailTemplates}
            />
          </Stack>
          <AutoSave />
        </Form>
      </Formik>
    </CardContainer>
  );
}

function OrganisationEmailTemplates({
  emailTemplates,
}: {
  organisationId: string;
  emailTemplates: OrganisationEmailTemplates_OrganisationEmailTemplateFragment[];
}) {
  const { t } = useTranslate(["OrganisationSettings", "AttachmentField"]);
  const { labels: emailTemplateContextLabels } = useEmailTemplateContexts();

  const client = useApolloClient();
  const [addOrganisationEmailTemplate] =
    useAddOrganisationEmailTemplateMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
    });
  const [modifyOrganisationEmailTemplate] =
    useModifyOrganisationEmailTemplateMutation({
      client,
    });
  const [removeOrganisationEmailTemplate] =
    useRemoveOrganisationEmailTemplateMutation({
      client,
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
    });

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant={"h4"}>
          {t("Email templates", { ns: "OrganisationSettings" })}
        </Typography>
        <ModalOpenButton
          Modal={OrganisationEmailTemplateModal}
          modalProps={{
            title: t("New email template", { ns: "OrganisationSettings" }),
            handleComplete: async (values, handleClose) => {
              await addOrganisationEmailTemplate({
                variables: { input: values },
              });
              handleClose();
            },
          }}
        >
          <IconButton color={"primary"}>
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>
      <DataGrid
        columns={[
          {
            field: "description",
            headerName: t("Template", { ns: "OrganisationSettings" }),
            flex: 1,
            sortable: false,
            valueGetter: ({ row }) => row.description,
          },
          {
            field: "context",
            headerName: t("Context", { ns: "OrganisationSettings" }),
            sortable: false,
            valueGetter: ({ row }) => emailTemplateContextLabels[row.context],
          },
          {
            field: "attachmentCount",
            flex: 0,
            maxWidth: 50,
            align: "right",
            sortable: false,
            renderHeader: () => <AttachFileIcon />,
            headerAlign: "right",
            valueGetter: ({ row }) => row.attachments.length,
          },
          {
            field: "actions",
            headerName: "",
            flex: 0,
            maxWidth: 92,
            sortable: false,
            renderCell: ({ row }) => (
              <Stack direction="row" spacing={0.5}>
                <ModalOpenButton
                  Modal={OrganisationEmailTemplateModal}
                  modalProps={{
                    template: row,
                    title: t("Edit email template", {
                      ns: "OrganisationSettings",
                    }),
                    handleComplete: async (values, handleClose) => {
                      modifyOrganisationEmailTemplate({
                        variables: {
                          templateId: row.id,
                          input: {
                            description: values.description,
                            subject: values.subject,
                            content: values.content,
                            attachments: values.attachments.map(attachment => ({
                              id: attachment.id,
                              url: attachment.url,
                              title: attachment.title,
                              mimeType: attachment.mimeType,
                              group: attachment.group,
                            })),
                          },
                        },
                      });
                      handleClose();
                    },
                  }}
                >
                  <IconButton color="primary" size="small">
                    <EditIcon />
                  </IconButton>
                </ModalOpenButton>
                <ModalOpenButton
                  Modal={ConfirmModal}
                  modalProps={{
                    handleConfirm: async () => {
                      await removeOrganisationEmailTemplate({
                        variables: { templateId: row.id },
                      });
                    },
                  }}
                >
                  <IconButton color="primary" size="small">
                    <DeleteIcon />
                  </IconButton>
                </ModalOpenButton>
              </Stack>
            ),
          },
        ]}
        rows={emailTemplates}
        hideFooter
        paginationMode="client"
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        sx={{ "& .MuiDataGrid-row.MuiDataGrid-row": { cursor: "default" } }}
      />
    </Stack>
  );
}
