/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import { BulkActionsShare_QuoteTemplateFragmentDoc } from '../../../main-routes/templates/quote/TemplateQuotes.generated';
import { TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc } from './TemplateQuotePublishingStatus.generated';
import { QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateAddToShopButton.generated';
import { QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateSubscribeButton.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplatesQuoteSelectMultipleComponentQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationQuoteTemplatesSorting>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdAfter?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  createdBefore?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  priceMin?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  priceMax?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  tagsAny?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  excludeTemplateIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  rootItemTypes?: Types.InputMaybe<Array<Types.ItemType>>;
}>;


export type TemplatesQuoteSelectMultipleComponentQuery = { quoteTemplates: { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, addedToShop: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } }> } };

export type TemplatesQuoteSelectMultipleComponent_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, tplApplicableFor: Array<Types.TplApplicableFor>, title: string, description: string, createdAt: any, projectId?: string | null, sharedOnGallery: boolean, isLikedByMe: boolean, owningSystemOrganisationId: string, publicDescription: string, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, addedToShop: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export const TemplatesQuoteSelectMultipleComponent_TemplateFragmentDoc = gql`
    fragment TemplatesQuoteSelectMultipleComponent_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  tplApplicableFor
  title
  description
  createdAt
  proposedCalculation {
    ...ItemCalculation
  }
  projectId
  sharedOnGallery
  shopCatalogProductImage {
    id
    url
  }
  isLikedByMe
  owningSystemOrganisationId
  publicDescription
  attachments {
    ...Attachment
  }
  ...BulkActionsShare_QuoteTemplate
  ...TemplateQuotePublishingStatus_QuoteTemplate
  ...QuoteTemplateAddToShopButton_QuoteTemplate
  ...QuoteTemplateSubscribeButton_QuoteTemplate
}
    ${ItemCalculationFragmentDoc}
${AttachmentFragmentDoc}
${BulkActionsShare_QuoteTemplateFragmentDoc}
${TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc}
${QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc}
${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}`;
export const TemplatesQuoteSelectMultipleComponentDocument = gql`
    query TemplatesQuoteSelectMultipleComponent($offset: Int, $limit: Int!, $sorting: [OrganisationQuoteTemplatesSorting!], $searchTerm: String, $createdAfter: Date, $createdBefore: Date, $priceMin: Float, $priceMax: Float, $tagsAny: [String!], $excludeTemplateIds: [ID!], $rootItemTypes: [ItemType!]) {
  quoteTemplates(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {createdAfter: $createdAfter, createdBefore: $createdBefore, priceMin: $priceMin, priceMax: $priceMax, sources: [available], excludeTemplateIds: $excludeTemplateIds, rootItemTypes: $rootItemTypes, tagsAny: $tagsAny}
    search: $searchTerm
  ) {
    edges {
      node {
        ...TemplatesQuoteSelectMultipleComponent_Template
      }
    }
    totalCount
  }
}
    ${TemplatesQuoteSelectMultipleComponent_TemplateFragmentDoc}`;

/**
 * __useTemplatesQuoteSelectMultipleComponentQuery__
 *
 * To run a query within a React component, call `useTemplatesQuoteSelectMultipleComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuoteSelectMultipleComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuoteSelectMultipleComponentQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      searchTerm: // value for 'searchTerm'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *      priceMin: // value for 'priceMin'
 *      priceMax: // value for 'priceMax'
 *      tagsAny: // value for 'tagsAny'
 *      excludeTemplateIds: // value for 'excludeTemplateIds'
 *      rootItemTypes: // value for 'rootItemTypes'
 *   },
 * });
 */
export function useTemplatesQuoteSelectMultipleComponentQuery(baseOptions: Apollo.QueryHookOptions<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables> & ({ variables: TemplatesQuoteSelectMultipleComponentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>(TemplatesQuoteSelectMultipleComponentDocument, options);
      }
export function useTemplatesQuoteSelectMultipleComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>(TemplatesQuoteSelectMultipleComponentDocument, options);
        }
export function useTemplatesQuoteSelectMultipleComponentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>(TemplatesQuoteSelectMultipleComponentDocument, options);
        }
export type TemplatesQuoteSelectMultipleComponentQueryHookResult = ReturnType<typeof useTemplatesQuoteSelectMultipleComponentQuery>;
export type TemplatesQuoteSelectMultipleComponentLazyQueryHookResult = ReturnType<typeof useTemplatesQuoteSelectMultipleComponentLazyQuery>;
export type TemplatesQuoteSelectMultipleComponentSuspenseQueryHookResult = ReturnType<typeof useTemplatesQuoteSelectMultipleComponentSuspenseQuery>;
export type TemplatesQuoteSelectMultipleComponentQueryResult = Apollo.QueryResult<TemplatesQuoteSelectMultipleComponentQuery, TemplatesQuoteSelectMultipleComponentQueryVariables>;