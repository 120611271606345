import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { ModalOpenButton } from "@msys/ui";
import DecisionIcon from "@mui/icons-material/HelpOutline";
import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ComponentProps } from "react";
import { QuoteTreeItem_TreeItemFragment } from "../../projects/quote-trees.generated";
import { TemplatesQuoteSetsSearchModal } from "../../templates/quote/TemplatesQuoteSetsSearchModal";
import { useDefaultDecisionActions } from "../hooks/useDefaultDecisionActions";
import { useSetItemTemplatePlaceholderTemplateMutation } from "./TemplateSetDecisionModalButton.generated";

interface Props {
  projectId: string | null;
  templateSearchFilterDefaults: QuoteTreeItem_TreeItemFragment["templateSearchFilterDefaults"];
  docId: string;
  itemId: string;
  canFinalize: boolean;
  setItemExpanded: (id: string, expanded: boolean) => void;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
  type?: "button" | "icon";
  color?: ButtonProps["color"];
  label?: string;
  disabled?: boolean;
}

export const TemplateSetDecisionModalButton = ({
  projectId,
  templateSearchFilterDefaults,
  docId,
  itemId,
  canFinalize,
  setItemExpanded,
  expandedItemIds,
  refetch,
  type = "button",
  color = "secondary",
  label,
  disabled,
}: Props) => {
  const { t } = useTranslate(["Decisions"]);

  const client = useApolloClient();

  const decisionActions = useDefaultDecisionActions({
    projectId,
    docId,
    canFinalize,
    expandedItemIds,
    refetch,
  });

  const [setItemTemplatePlaceholderTemplateMutation] =
    useSetItemTemplatePlaceholderTemplateMutation({
      client,
      onCompleted: ({
        setItemTemplatePlaceholderTemplate: {
          item: { id },
        },
      }) => setItemExpanded(id, true),
    });

  const button =
    type === "button" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        startIcon={<DecisionIcon />}
        title={label ?? t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label ?? t("Question", { ns: "Decisions" })}
      </Button>
    ) : type === "icon" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        title={t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ minWidth: 32, width: 32 }}
      >
        <DecisionIcon fontSize="small" style={{ fontSize: "1.15rem" }} />
      </Button>
    ) : (
      assertNever(type)
    );

  return (
    <ModalOpenButton
      Modal={TemplatesQuoteSetsSearchModal}
      modalProps={{
        initialFilters: definitionsToFormValues(templateSearchFilterDefaults),
        handleTemplateChoice: async template => {
          if (template.resolvedAsReadModelVersionNumber) {
            await setItemTemplatePlaceholderTemplateMutation({
              variables: {
                input: {
                  docId,
                  itemId,
                  projectId,
                  templateQuoteId: template.id,
                  templateQuoteIdAtVersionNumber:
                    template.resolvedAsReadModelVersionNumber,
                },
              },
            });
            await decisionActions.refetch();
          }
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};

function definitionsToFormValues(
  definitions: QuoteTreeItem_TreeItemFragment["templateSearchFilterDefaults"]
): ComponentProps<typeof TemplatesQuoteSetsSearchModal>["initialFilters"] {
  return {
    templateTypeIds: definitions.templateTypeIdFilter
      ? [definitions.templateTypeIdFilter.valueId]
      : null,
    propertiesBool: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterBoolFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorBool,
                value: propertyFilter.valueBool,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterBoolInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorBoolIn,
                  value: propertyFilter.valueBoolIn,
                }
              : []
        )
      : null,
    propertiesNumber: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename ===
          "EntitySearchPropertyFilterNumberBetweenFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorNumberBetween,
                value: propertyFilter.valueNumberBetween,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterNumberInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorNumberIn,
                  value: propertyFilter.valueNumberIn,
                }
              : propertyFilter.__typename ===
                  "EntitySearchPropertyFilterNumberFilter"
                ? {
                    key: propertyFilter.key,
                    operator: propertyFilter.operatorNumber,
                    value: propertyFilter.valueNumber,
                  }
                : []
        )
      : null,
    propertiesText: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterTextInFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorTextIn,
                value: propertyFilter.valueTextIn,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterTextFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorText,
                  value: propertyFilter.valueText,
                }
              : []
        )
      : null,
  };
}
