import { stripHtmlTags, truncateText } from "@msys/common";
import {
  DataGrid,
  getFormattedPrice,
  GridColDef,
  LoadingSpinner,
  StateStore,
  useScreenWidth,
} from "@msys/ui";
import { Box, Grid, Stack, TablePagination } from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import React from "react";
import { To, useNavigate } from "react-router-dom";
import { useCardsNumber } from "../../../commons/hooks/useCardsNumber";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import { PimSearchGroupedProductsNodeFragment } from "../../../main-routes/products/ProductsCatalogues.generated";
import { getProductFullDescription } from "../helper";
import { ProductSearchItem__ProductSearchResultFragment } from "../Product.generated";
import { ProductCardItem, ProductListItem } from "./ProductItem";

interface Props<
  P extends
    | ProductSearchItem__ProductSearchResultFragment
    | PimSearchGroupedProductsNodeFragment["product"]
> {
  items: P[];
  totalCount: number;
  activeView: CollectionView;
  loading: boolean;
  CardActionButton: React.ComponentType<{ product: P }>;
  TableActions: React.ComponentType<{ product: P }>;
  TableCheckbox?: React.ComponentType<{ product: P }>;
  onClick?: (product: P) => void;
  getProductTo?: (product: P) => To;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  stateStore: StateStore;
  tableActionsWidth: number;
}

export function ProductsListView<
  P extends
    | ProductSearchItem__ProductSearchResultFragment
    | PimSearchGroupedProductsNodeFragment["product"]
>({
  items,
  totalCount,
  activeView,
  loading,
  CardActionButton,
  TableActions,
  TableCheckbox,
  onClick,
  getProductTo,
  paginationModel,
  setPaginationModel,
  stateStore,
  tableActionsWidth,
}: Props<P>) {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["Product", "Global"]);
  const navigate = useNavigate();
  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  switch (activeView) {
    case "gallery": {
      if (loading) {
        return <LoadingSpinner />;
      }
      if (!items.length) {
        return (
          <Box padding={2} display="flex" justifyContent="center">
            {t("There seems to be nothing here", {
              ns: "Global",
            })}
          </Box>
        );
      }
      return (
        <Stack
          ref={sizeMeasureRef}
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Stack
            direction="column"
            spacing={1}
            overflow="auto"
            flex={1}
            minHeight={0}
          >
            <Grid container spacing={spacing}>
              {items.map((product, index) => (
                <Grid key={product.id} item {...columns}>
                  <ProductCardItem
                    key={product.id}
                    product={product}
                    getProductTo={getProductTo}
                    onClick={onClick}
                    ActionButton={CardActionButton}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      );
    }
    case "list":
      if (loading) {
        return <LoadingSpinner />;
      }
      if (!items.length) {
        return (
          <Box padding={2} display="flex" justifyContent="center">
            {t("There seems to be nothing here", {
              ns: "Global",
            })}
          </Box>
        );
      }
      return (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Stack
            direction="column"
            spacing={1}
            overflow="auto"
            flex={1}
            minHeight={0}
          >
            {items.map((product, index) => (
              <ProductListItem
                key={product.id}
                product={product}
                getProductTo={getProductTo}
                onClick={onClick}
                ActionButton={CardActionButton}
              />
            ))}
          </Stack>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      );

    case "table":
      return (
        <DataGrid
          stateStore={stateStore}
          density="compact"
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (
              ["title", "description", "supplier", "articleNumber"].includes(
                params.field
              )
            ) {
              onClick?.(params.row);
              if (getProductTo) {
                navigate(getProductTo(params.row));
              }
            }
          }}
          loading={loading}
          hideFooter={totalCount === 0}
          columns={[
            ...(TableCheckbox
              ? ([
                  {
                    field: "checkbox",
                    headerName: "",
                    width: 50,
                    sortable: false,
                    resizable: false,
                    renderCell: ({ row: product }) => (
                      <TableCheckbox product={product} />
                    ),
                  },
                ] as GridColDef<P>[])
              : []),
            {
              field: "title",
              headerName: t("Title", {
                ns: "Product",
              }),
              flex: 2,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: product }) => product.texts?.title,
            },
            {
              field: "description",
              headerName: t("Description", {
                ns: "Product",
              }),
              flex: 3,
              minWidth: 150,
              sortable: false,
              renderCell: ({ row: product }) =>
                truncateText(
                  stripHtmlTags(getProductFullDescription(product)),
                  140
                ),
            },
            {
              field: "supplier",
              headerName: t("Supplier", {
                ns: "Product",
              }),
              flex: 2,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: product }) => product.supplier?.title,
            },
            {
              field: "articleNumber",
              headerName: t("Article Number", {
                ns: "Product",
              }),
              flex: 1,
              minWidth: 50,
              sortable: false,
              renderCell: ({ row: product }) => product.articleNumber,
            },
            {
              field: "netPrice",
              headerName: t("Net Price", {
                ns: "Product",
              }),
              flex: 1,
              minWidth: 50,
              sortable: false,
              renderCell: ({ row: product }) =>
                product.pricing?.netPrice
                  ? getFormattedPrice(product.pricing?.netPrice, language)
                  : "–",
            },
            {
              field: "listPrice",
              headerName: t("List Price", {
                ns: "Product",
              }),
              flex: 1,
              minWidth: 50,
              sortable: false,
              renderCell: ({ row: product }) =>
                product.pricing?.listPrice
                  ? getFormattedPrice(product.pricing?.listPrice, language)
                  : "–",
            },
            {
              field: "actions",
              headerName: "",
              width: tableActionsWidth,
              sortable: false,
              resizable: false,
              align: "right",
              renderCell: ({ row: product }) => (
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <TableActions product={product} />
                </Stack>
              ),
            },
          ]}
          rows={items}
          // onRowClick={({ row: product }) => {
          //   onSelectItem(product);
          // }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
        />
      );
  }
  return null;
}
