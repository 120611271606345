import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

export const RecommendationListItem = ({
  icon,
  text,
  link,
  secondaryAction,
}: {
  icon: React.ReactElement;
  text: string;
  link: string;
  secondaryAction: ListItemProps["secondaryAction"];
}) => {
  return (
    <ListItem disablePadding secondaryAction={secondaryAction}>
      <ListItemButton component={Link} to={link} dense>
        <ListItemIcon
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.sm,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
