import { gql, useApolloClient } from "@apollo/client";
import { Box, DialogContentText, Divider } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AddressInput } from "../../../../clients/graphqlTypes";
import { useRequestSupplierOrganisationMutation } from "./RequestSupplierModal.generated";
import { useTranslate } from "@tolgee/react";
import { useUserData } from "../../../auth/useUserData";
import { AddressField } from "../../addresses/AddressField";
import { Stack } from "../../../commons/layout/Stack";
import { Modal } from "@msys/ui";

interface FormValues {
  title: string;
  address: AddressInput | null;
  message: string;
}

interface Props {
  title?: string;
  handleClose: () => void;
  handleComplete?: () => Promise<void>;
}

export const RequestSupplierModal: React.FC<Props> = ({
  title,
  handleClose,
  handleComplete,
}) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["OrganisationInvite", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [requestSupplier, { loading: isSendingMail }] =
    useRequestSupplierOrganisationMutation({
      client,
    });

  const initialValues: FormValues = {
    title: "",
    address: null,
    message: t(
      "Hello, please add the supplier to MeisterSystems. Kind regards, {senderName} {senderEmail}",
      {
        ns: "OrganisationInvite",
        senderName: viewer.firstname + " " + viewer.familyname,
        senderEmail: viewer.email,
      }
    ),
  };

  const onSubmit = React.useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      await requestSupplier({
        variables: {
          input: {
            title: values.title,
            branchAddress: values.address,
            messageSubject: `Request ${values.title} as supplier on MeisterSystems.`,
            messageBody: values.message,
          },
        },
      });

      enqueueSnackbar(
        t("Supplier requested", {
          ns: "OrganisationInvite",
        })
      );
      resetForm();

      if (handleComplete) {
        await handleComplete();
      }
      handleClose();
    },
    [requestSupplier, handleComplete, handleClose, t, enqueueSnackbar]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(
            t("Supplier name", {
              ns: "OrganisationInvite",
            })
          )
          .required(),
        address: Yup.object()
          .shape({
            streetLine1: Yup.string(),
            postalCode: Yup.string(),
            city: Yup.string(),
          })
          .label(
            t("Supplier address", {
              ns: "OrganisationInvite",
            })
          )
          .nullable()
          .required(),
        message: Yup.string()
          .label(
            t("Personal Message", {
              ns: "OrganisationInvite",
            })
          )
          .required(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Modal
          title={
            title ??
            t("Request supplier", {
              ns: "OrganisationInvite",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Send", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          isLoading={isSendingMail}
        >
          <DialogContentText>
            {t(
              "You can request a supplier to be created if you do not find him listed. We will inform you once it is created. Sorry for this inconvinience.",
              {
                ns: "OrganisationInvite",
              }
            )}
          </DialogContentText>

          <Form id={formId}>
            <Stack flexDirection="column">
              <Box display="flex" flexWrap="wrap" style={{ gap: "5px" }}>
                <Box flex={2} style={{ minWidth: "200px" }}>
                  <Field
                    label={t("Supplier name", {
                      ns: "OrganisationInvite",
                    })}
                    name="title"
                    component={TextField}
                  />
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" style={{ gap: "5px" }}>
                <Box flex={2} style={{ minWidth: "200px" }}>
                  <AddressField
                    label={t("Supplier address", {
                      ns: "OrganisationInvite",
                    })}
                    name="address"
                    required
                  />
                </Box>
              </Box>
              <Divider />
              <Field
                label={t("Personal Message", {
                  ns: "OrganisationInvite",
                })}
                name="message"
                required
                component={TextField}
                multiline
                rows={8}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
