import { Box, Paper, Typography } from "@mui/material";
import { Dictionary } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Stack } from "../../commons/layout/Stack";
import { ClosingTextInput } from "./InvoiceClosingText";
import {
  InvoiceDraftPreview_InvoiceFragment,
  InvoiceDraftPreview_ProjectFragment,
  InvoiceDraftPreview_QuoteFragment,
  useModifyInvoiceTexts_InvoiceDraftPreviewMutation,
} from "./InvoiceDraftPreview.generated";
import { OpeningTextInput } from "./InvoiceOpeningText";
import { InvoiceFinalTotals } from "./preview/InvoiceFinalTotals";
import { InvoiceHeader } from "./preview/InvoiceHeader";
import { InvoicePriceTable } from "./preview/InvoicePriceTable";
import { InvoiceSum } from "./preview/InvoiceSum";

// TODO: could we reuse the InvoicePreview component?
export const InvoiceDraftPreview = ({
  project,
  invoice,
  quote,
  quoteItemsMap,
  setSelectedItemId,
  setSelectedExtraItemId,
}: {
  project: InvoiceDraftPreview_ProjectFragment;
  invoice: InvoiceDraftPreview_InvoiceFragment;
  quote: InvoiceDraftPreview_QuoteFragment;
  quoteItemsMap: Dictionary<
    InvoiceDraftPreview_InvoiceFragment["invoiceItems"][number]["item"]
  >;
  setSelectedItemId: (id: string) => void;
  setSelectedExtraItemId: (id: string) => void;
}) => {
  const [modifyInvoiceTexts] =
    useModifyInvoiceTexts_InvoiceDraftPreviewMutation();
  const { enqueueSnackbar } = useSnackbar();

  const quoteItemMapper = React.useMemo(
    () => (item: { item: { id: string } }) => quoteItemsMap[item.item.id],
    [quoteItemsMap]
  );

  async function saveQuoteText(content: any) {
    try {
      await modifyInvoiceTexts({
        variables: {
          projectId: project.id,
          docId: quote.id,
          invoiceId: invoice.id,
          values: {
            invoiceTexts: content,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const payee = invoice.payee;
  const payer = invoice.payer;
  const invoiceTexts = invoice.invoiceTexts;

  const enableNavigating =
    invoice.type === "partial_invoice" || invoice.type === "final_invoice";

  return (
    <Stack flexDirection="column">
      <InvoiceHeader
        project={project}
        payee={payee}
        payer={payer}
        invoice={invoice}
        canEdit={true}
      />

      <Paper>
        <Box padding={2}>
          <Typography variant="h2">{invoice.title}</Typography>
        </Box>
      </Paper>
      <OpeningTextInput
        content={invoiceTexts?.opening}
        onSave={saveQuoteText}
        showInfo={false}
      />

      <InvoicePriceTable
        invoice={invoice}
        quoteItemMapper={quoteItemMapper}
        onRowClick={
          enableNavigating
            ? item => {
                if (item.__typename === "InvoiceItem") {
                  const quoteItem = quoteItemMapper(item);
                  if (quoteItem.isRootItem) return;
                  setSelectedItemId(quoteItem.id);
                }
                if (item.__typename === "InvoiceExtraItem") {
                  setSelectedExtraItemId(item.id);
                }
              }
            : undefined
        }
      />

      {quote && (
        <InvoiceSum
          calculation={invoice.calculation}
          doc={quote}
          invoice={invoice}
        />
      )}
      {invoice.type === "final_invoice" && (
        <InvoiceFinalTotals
          invoiceId={invoice.id}
          calculation={invoice.calculation}
          invoiceReferences={invoice.invoiceReferences}
        />
      )}
      <ClosingTextInput
        content={invoiceTexts?.closing}
        onSave={saveQuoteText}
        showInfo={false}
      />
    </Stack>
  );
};
