import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull } from "@msys/common";
import { MenuItemWithIcon, Modal, ModalOpenButton } from "@msys/ui";
import BugReportIcon from "@mui/icons-material/BugReportOutlined";
import { Button, Stack } from "@mui/material";
import { cloneDeep, omit } from "lodash";
import omitDeep from "omit-deep-lodash";
import React, { useEffect, useState } from "react";
import { useFeature } from "../../../../common/FeatureFlags";
import {
  AttributeExpressionInput,
  DefineItemProps2Entry,
  EnterItemProps2ValueEntry,
  ItemAttributeExpression,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
  Props2,
  SetItemProps2Mapping,
  EntitySearchArrayOfFilterOperator,
} from "../../../../clients/graphqlTypes";
import { useSetItemProps2MappingsMutation } from "../../templates/quote/PropertyMappingExpressionModal.generated";
import {
  isNonComputedProp,
  isPropMissingValue,
  propToDefineInput,
} from "../properties";
import {
  Props2MappingAllFragment,
  Props2NonComputedAllFragment,
} from "../properties.generated";
import { productSearchFilterPropertyFilterExpression2Input } from "../useProductSearchFilterExpressionMutations";
import {
  PropertiesDebugModalQueryResult,
  PropertiesDebugModal_UnansweredPropsFlowDocument,
  PropertiesDebugModal_UnansweredPropsFlowQuery,
  PropertiesDebugModal_UnansweredPropsFlowQueryVariables,
  useDebugAllPropQuoteQuery,
  useDebugAllPropTemplateQuery,
  usePropertiesDebugModalQuery,
  usePropertiesDebugModal_DefineItemAttributeExpressionMutation,
  usePropertiesDebugModal_DefineItemProductSearchFilterExpressionMutation,
  usePropertiesDebugModal_DefineItemProps2Mutation,
  usePropertiesDebugModal_EnterItemProps2ValueMutation,
} from "./PropertiesDebug.generated";

interface Props {
  projectId: string | null;
  docId: string;
  itemId: string;
}

export function PropertiesDebugMenuItem({ projectId, docId, itemId }: Props) {
  return (
    <ModalOpenButton
      Modal={PropertiesDebugModal}
      modalProps={{ projectId, docId, itemId }}
    >
      <MenuItemWithIcon icon={<BugReportIcon />}>
        Debug properties
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
}

export function PropertiesDebugModal({
  projectId,
  docId,
  itemId,
  handleClose,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  handleClose: () => void;
}) {
  const client = useApolloClient();
  const query = usePropertiesDebugModalQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
    fetchPolicy: "cache-and-network",
  });
  const [defineItemProps2] = usePropertiesDebugModal_DefineItemProps2Mutation({
    client,
  });
  const [enterItemProps2] =
    usePropertiesDebugModal_EnterItemProps2ValueMutation({
      client,
    });
  const [defineItemAttributeExpression] =
    usePropertiesDebugModal_DefineItemAttributeExpressionMutation({ client });

  const [setItemProps2Mappings] = useSetItemProps2MappingsMutation({ client });

  const item = getDataOrNull(query.data?.item);

  return (
    <Modal
      title={"Properties"}
      handleClose={handleClose}
      actionButtons={[
        {
          label: "Close",
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
      ]}
      dialogProps={{ maxWidth: "lg" }}
    >
      {item && (
        <Stack spacing={1}>
          {
            <div style={{ border: "1px solid " }}>
              <h2>unansweredProps2</h2>
              <hr />
              {item.unansweredProps2.length > 0 ||
              (item.isRootItem && item.unansweredProps2Deep.length > 0) ? (
                <>
                  {/* <PrototypeHelperModalButton
                    btn={
                      <button type="button">
                        start answer props flow (
                        {item.unansweredProps2Deep.reduce(
                          (memo, x) => memo + x.props.length,
                          0
                        )}
                        )
                      </button>
                    }
                  >
                    {({ close }) => {
                      return (
                        <UnansweredPropsFlow
                          close={close}
                          projectId={projectId}
                          docId={docId}
                          itemId={item.id}
                        />
                      );
                    }}
                  </PrototypeHelperModalButton> */}

                  <ModalOpenButton
                    Modal={UnansweredPropsFlowModal}
                    modalProps={{
                      projectId,
                      docId,
                      itemId: item.id,
                    }}
                  >
                    <Button variant="contained">
                      start answer props flow (
                      {item.unansweredProps2Deep.reduce(
                        (memo, x) => memo + x.props.length,
                        0
                      )}
                      )
                    </Button>
                  </ModalOpenButton>

                  <hr />
                </>
              ) : null}
              <textarea
                readOnly
                value={JSON.stringify(item.unansweredProps2, null, 2)}
              ></textarea>
            </div>
          }

          {
            <>
              <div style={{ border: "1px solid " }}>
                <h2>attribute expressions</h2>
                <hr />
                <button
                  type="button"
                  onClick={() => {
                    doDefineItemAttributeExpressionFlow(
                      item,
                      async attributeExpressions => {
                        await defineItemAttributeExpression({
                          variables: {
                            input: {
                              projectId,
                              docId,
                              itemId,
                              attributeExpressions,
                            },
                          },
                        });

                        await client.reFetchObservableQueries();
                      }
                    );
                  }}
                >
                  add attribute expression
                </button>

                <hr />
                <ul>
                  {item.attributeExpressions.map(e => (
                    <li key={e.attribute}>
                      <div>attribute: {e.attribute}</div>
                      <div>expr: {e.expr}</div>
                      <div>result: {JSON.stringify(e.result)}</div>
                      <div>missingValue: {String(e.missingValue)}</div>
                      <button
                        type="button"
                        onClick={() => {
                          doEditAttributeExpressionFlow(
                            item,
                            async attributeExpressions => {
                              await defineItemAttributeExpression({
                                variables: {
                                  input: {
                                    projectId,
                                    docId,
                                    itemId,
                                    attributeExpressions,
                                  },
                                },
                              });

                              await client.reFetchObservableQueries();
                            },
                            e.attribute
                          );
                        }}
                      >
                        edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          doDeDeleteAttributeExpressionFlow(
                            item,
                            async attributeExpressions => {
                              await defineItemAttributeExpression({
                                variables: {
                                  input: {
                                    projectId,
                                    docId,
                                    itemId,
                                    attributeExpressions,
                                  },
                                },
                              });

                              await client.reFetchObservableQueries();
                            },
                            e.attribute
                          );
                        }}
                      >
                        delete
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              {!item.linkedQuoteTemplate && (
                <>
                  <div style={{ border: "1px solid " }}>
                    <h2>search expressions</h2>
                    {/* <PrototypeHelperModalButton
                      btn={<button type="button">edit</button>}
                    >
                      {({ close }) => (
                        <EditSearchExpressionsFlow
                          close={close}
                          projectId={projectId}
                          docId={docId}
                          item={item}
                        />
                      )}
                    </PrototypeHelperModalButton> */}
                    <ModalOpenButton
                      Modal={EditSearchExpressionsFlowModal}
                      modalProps={{
                        projectId,
                        docId,
                        item,
                      }}
                    >
                      <Button variant="contained">edit</Button>
                    </ModalOpenButton>
                    <hr />
                    <ul>
                      {item.productSearchFilterExpressions.propertyFilters.map(
                        e => {
                          switch (e.__typename) {
                            case "EntitySearchPropertyFilterBoolFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorBool}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueBoolComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterBoolInFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorBoolIn}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueBoolInComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterNumberFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorNumber}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueNumberComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterNumberInFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorNumberIn}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueNumberInComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorNumberBetween}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(
                                      e.valueNumberBetweenComputed
                                    )}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterTextFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorText}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueTextComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            case "EntitySearchPropertyFilterTextInFilterComputed": {
                              return (
                                <li key={e.key}>
                                  <div>filter: {e.key}</div>
                                  <div>operator: {e.operatorTextIn}</div>
                                  <div>expr: {e.expr}</div>
                                  <div>
                                    result:{" "}
                                    {JSON.stringify(e.valueTextInComputed)}
                                  </div>
                                  <div>
                                    missingValue: {String(e.missingValue)}
                                  </div>
                                </li>
                              );
                            }
                            default:
                              assertNever(e);
                          }
                        }
                      )}
                    </ul>
                  </div>
                  <div style={{ border: "1px solid " }}>
                    <h2>default product search filters</h2>
                    <hr />
                    <div>
                      {JSON.stringify(item.productSearchFilterDefaults)}
                    </div>
                  </div>
                  {item.linkedQuoteTemplate && (
                    <div style={{ border: "1px solid black" }}>
                      <h2>property mappings</h2>
                      <hr />
                      <ul>
                        {item.propertyMappings.map(m => {
                          return (
                            <li key={m.from.expr + m.to.key}>
                              <div>from: {m.from.expr}</div>
                              <div>to: {m.to.key}</div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  <div style={{ border: "1px solid " }}>
                    <h2>props2</h2>
                    <hr />
                    <button
                      type="button"
                      onClick={() =>
                        doStartDefineProps2Flow(
                          item,
                          { id: item.rootItemId },
                          async entries => {
                            await defineItemProps2({
                              variables: {
                                input: {
                                  projectId,
                                  docId,
                                  itemId,
                                  entries,
                                },
                              },
                            });

                            await client.reFetchObservableQueries();
                          }
                        )
                      }
                    >
                      add property
                    </button>

                    <hr />
                    <ul>
                      {item.props2.map(p => {
                        return (
                          <li
                            key={p.key}
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div>kind: {p.__typename}</div>
                            <div>key: {p.key}</div>
                            <div>label: {p.label}</div>
                            <div>group: {p.group}</div>
                            {p.__typename === "Props2Text" && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: isPropMissingValue(p)
                                      ? "#ffe800" // "#ffcccb"
                                      : undefined,
                                  }}
                                >
                                  value: {String(p.valueText)}
                                </div>
                                <div>essential: {String(p.essential)}</div>
                                <textarea
                                  value={JSON.stringify(p, null, 2)}
                                  disabled
                                />
                              </>
                            )}
                            {p.__typename === "Props2TextArray" && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: isPropMissingValue(p)
                                      ? "#ffe800" // "#ffcccb"
                                      : undefined,
                                  }}
                                >
                                  value: {JSON.stringify(p.valueTextArray)}
                                </div>
                                <div>essential: {String(p.essential)}</div>
                                <textarea
                                  value={JSON.stringify(p, null, 2)}
                                  disabled
                                />
                              </>
                            )}
                            {p.__typename === "Props2Number" && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: isPropMissingValue(p)
                                      ? "#ffe800" // "#ffcccb"
                                      : undefined,
                                  }}
                                >
                                  value: {p.valueNumber}
                                </div>
                                <div>unit: {p.unit}</div>
                                <div>essential: {String(p.essential)}</div>
                                <textarea
                                  value={JSON.stringify(p, null, 2)}
                                  disabled
                                />
                              </>
                            )}
                            {p.__typename === "Props2NumberArray" && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: isPropMissingValue(p)
                                      ? "#ffe800" // "#ffcccb"
                                      : undefined,
                                  }}
                                >
                                  value: {JSON.stringify(p.valueNumberArray)}
                                </div>
                                <div>unit: {p.unit}</div>
                                <div>essential: {String(p.essential)}</div>
                                <textarea
                                  value={JSON.stringify(p, null, 2)}
                                  disabled
                                />
                              </>
                            )}
                            {p.__typename === "Props2Bool" && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: isPropMissingValue(p)
                                      ? "#ffe800" // "#ffcccb"
                                      : undefined,
                                  }}
                                >
                                  value: {String(p.valueBool)}
                                </div>
                                <div>essential: {String(p.essential)}</div>
                                <textarea
                                  value={JSON.stringify(p, null, 2)}
                                  disabled
                                />
                              </>
                            )}
                            {p.__typename === "Props2TextComputed" && (
                              <>
                                <div>expr: {p.expr}</div>
                                <div>value: {String(p.valueTextComputed)}</div>
                                <div
                                  style={{
                                    backgroundColor: p.missingValue
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  missingValue: {String(p.missingValue)}
                                </div>
                              </>
                            )}
                            {p.__typename === "Props2TextArrayComputed" && (
                              <>
                                <div>expr: {p.expr}</div>
                                <div>
                                  value:{" "}
                                  {JSON.stringify(p.valueTextArrayComputed)}
                                </div>
                                <div
                                  style={{
                                    backgroundColor: p.missingValue
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  missingValue: {String(p.missingValue)}
                                </div>
                              </>
                            )}
                            {p.__typename === "Props2NumberComputed" && (
                              <>
                                <div>expr: {p.expr}</div>
                                <div>unit: {p.unit}</div>
                                <div>
                                  value: {String(p.valueNumberComputed)}
                                </div>
                                <div
                                  style={{
                                    backgroundColor: p.missingValue
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  missingValue: {String(p.missingValue)}
                                </div>
                              </>
                            )}
                            {p.__typename === "Props2NumberArrayComputed" && (
                              <>
                                <div>expr: {p.expr}</div>
                                <div>unit: {p.unit}</div>
                                <div>
                                  value:{" "}
                                  {JSON.stringify(p.valueNumberArrayComputed)}
                                </div>
                                <div
                                  style={{
                                    backgroundColor: p.missingValue
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  missingValue: {String(p.missingValue)}
                                </div>
                              </>
                            )}
                            {p.__typename === "Props2BoolComputed" && (
                              <>
                                <div>expr: {p.expr}</div>
                                <div>value: {String(p.valueBoolComputed)}</div>
                                <div
                                  style={{
                                    backgroundColor: p.missingValue
                                      ? "red"
                                      : undefined,
                                  }}
                                >
                                  missingValue: {String(p.missingValue)}
                                </div>
                              </>
                            )}
                            {(p.__typename === "Props2Bool" ||
                              p.__typename === "Props2Number" ||
                              p.__typename === "Props2NumberArray" ||
                              p.__typename === "Props2Text" ||
                              p.__typename === "Props2TextArray") && (
                              <>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    await doStartEnterProps2ValueFlow(
                                      "",
                                      item,
                                      p,
                                      async entries => {
                                        await enterItemProps2({
                                          variables: {
                                            input: {
                                              projectId,
                                              docId,
                                              itemId: item.id,
                                              entries,
                                            },
                                          },
                                        });

                                        await client.reFetchObservableQueries();
                                      },
                                      async () => {}
                                    );
                                  }}
                                >
                                  edit value
                                </button>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    const entry: EnterItemProps2ValueEntry =
                                      (() => {
                                        if (p.__typename === "Props2Text") {
                                          return {
                                            text: {
                                              key: p.key,
                                              valueText: null,
                                            },
                                          };
                                        } else if (
                                          p.__typename === "Props2Bool"
                                        ) {
                                          return {
                                            bool: {
                                              key: p.key,
                                              valueBool: null,
                                            },
                                          };
                                        } else if (
                                          p.__typename === "Props2Number"
                                        ) {
                                          return {
                                            number: {
                                              key: p.key,
                                              valueNumber: null,
                                            },
                                          };
                                        } else if (
                                          p.__typename === "Props2NumberArray"
                                        ) {
                                          return {
                                            numberArray: {
                                              key: p.key,
                                              valueNumberArray: null,
                                            },
                                          };
                                        } else if (
                                          p.__typename === "Props2TextArray"
                                        ) {
                                          return {
                                            textArray: {
                                              key: p.key,
                                              valueTextArray: null,
                                            },
                                          };
                                        } else {
                                          assertNever(p);
                                        }
                                      })();

                                    await enterItemProps2({
                                      variables: {
                                        input: {
                                          projectId,
                                          docId,
                                          itemId: item.id,
                                          entries: [entry],
                                        },
                                      },
                                    });

                                    await client.reFetchObservableQueries();
                                  }}
                                >
                                  reset value
                                </button>
                              </>
                            )}
                            <button
                              type="button"
                              onClick={() =>
                                doDeletePropFlow(
                                  item,
                                  async entries => {
                                    await defineItemProps2({
                                      variables: {
                                        input: {
                                          projectId,
                                          docId,
                                          itemId,
                                          entries,
                                        },
                                      },
                                    });

                                    await client.reFetchObservableQueries();
                                  },
                                  p.key
                                )
                              }
                            >
                              delete
                            </button>
                            {isNonComputedProp(p) ? (
                              <>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    await defineItemProps2({
                                      variables: {
                                        input: {
                                          projectId,
                                          docId,
                                          itemId,
                                          entries: item.props2.map(
                                            (p2): DefineItemProps2Entry => {
                                              if (p2.key !== p.key)
                                                return propToDefineInput(p2);

                                              switch (p2.__typename) {
                                                case "Props2Bool":
                                                case "Props2Number":
                                                case "Props2NumberArray":
                                                case "Props2TextArray":
                                                case "Props2Text": {
                                                  return propToDefineInput({
                                                    ...p2,
                                                    essential: true,
                                                  });
                                                }
                                                case "Props2BoolComputed":
                                                case "Props2NumberComputed":
                                                case "Props2NumberArrayComputed":
                                                case "Props2TextArrayComputed":
                                                case "Props2TextComputed": {
                                                  return propToDefineInput(p);
                                                }
                                                default: {
                                                  assertNever(p2);
                                                }
                                              }
                                            }
                                          ),
                                        },
                                      },
                                    });

                                    await client.reFetchObservableQueries();
                                  }}
                                >
                                  set essential
                                </button>

                                <button
                                  type="button"
                                  onClick={async () => {
                                    await defineItemProps2({
                                      variables: {
                                        input: {
                                          projectId,
                                          docId,
                                          itemId,
                                          entries: item.props2.map(
                                            (p2): DefineItemProps2Entry => {
                                              if (p2.key !== p.key)
                                                return propToDefineInput(p2);

                                              switch (p2.__typename) {
                                                case "Props2Bool":
                                                case "Props2Number":
                                                case "Props2NumberArray":
                                                case "Props2TextArray":
                                                case "Props2Text": {
                                                  return propToDefineInput({
                                                    ...p2,
                                                    essential: false,
                                                  });
                                                }
                                                case "Props2BoolComputed":
                                                case "Props2NumberComputed":
                                                case "Props2NumberArrayComputed":
                                                case "Props2TextArrayComputed":
                                                case "Props2TextComputed": {
                                                  return propToDefineInput(p);
                                                }
                                                default: {
                                                  assertNever(p2);
                                                }
                                              }
                                            }
                                          ),
                                        },
                                      },
                                    });

                                    await client.reFetchObservableQueries();
                                  }}
                                >
                                  unset essential
                                </button>
                              </>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </>
          }
          {item.linkedQuoteTemplate && (
            <>
              <div style={{ border: "1px solid black" }}>
                <h2>property mappings</h2>
                <hr />
                <button
                  type="button"
                  onClick={async () => {
                    await doAddPropMappingFlow(
                      item,
                      // quoteTemplate.rootItem,
                      async mappings => {
                        await setItemProps2Mappings({
                          variables: {
                            input: {
                              projectId,
                              docId,
                              itemId,
                              mappings,
                            },
                          },
                        });

                        await client.reFetchObservableQueries();
                      }
                    );
                  }}
                >
                  add property mapping
                </button>
                <hr />
                <ul>
                  {item.propertyMappings.map(pm => (
                    <li key={JSON.stringify(pm)}>
                      <div>from: {pm.from.expr}</div>
                      <div>to: {pm.to.key}</div>
                      <button
                        type="button"
                        onClick={async () => {
                          await doDeletePropMappingFlow(
                            item,
                            async mappings => {
                              await setItemProps2Mappings({
                                variables: {
                                  input: {
                                    projectId,
                                    docId,
                                    itemId,
                                    mappings,
                                  },
                                },
                              });
                              await client.reFetchObservableQueries();
                            },
                            pm.to.key
                          );
                        }}
                      >
                        delete
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              <div style={{ border: "1px solid black" }}>
                <h2>linked template properties</h2>
                <hr />
                <ul>
                  {item.linkedQuoteTemplate.rootItem.props2.map(p => (
                    <li key={JSON.stringify(p.key)}>
                      <div>key: {p.key}</div>
                      <div>kind: {p.__typename}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </Stack>
      )}
    </Modal>
  );
}

function EditSearchExpressionsFlowModal({
  handleClose,
  projectId,
  docId,
  item,
}: {
  handleClose: () => void;
} & Omit<React.ComponentProps<typeof EditSearchExpressionsFlow>, "close">) {
  return (
    <Modal handleClose={handleClose}>
      <EditSearchExpressionsFlow
        close={handleClose}
        projectId={projectId}
        docId={docId}
        item={item}
      />
    </Modal>
  );
}

type EditSearchExpressionsFlowItem = Exclude<
  Exclude<PropertiesDebugModalQueryResult["data"], null | undefined>["item"],
  null | undefined
>;

function EditSearchExpressionsFlow(props: {
  close: () => void;
  projectId: string | null;
  docId: string;
  item:
    | (EditSearchExpressionsFlowItem & { __typename: "Item" })
    | (EditSearchExpressionsFlowItem & { __typename: "Item" })
    | (EditSearchExpressionsFlowItem & { __typename: "Item" });
}) {
  const { projectId, docId, item: origItem, close } = props;

  const client = useApolloClient();

  const [item, setItem] = useState(cloneDeep(origItem));

  const [defineItemProductSearchFilterExpression] =
    usePropertiesDebugModal_DefineItemProductSearchFilterExpressionMutation({
      client,
    });

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        e.stopPropagation();

        await defineItemProductSearchFilterExpression({
          variables: {
            input: {
              projectId,
              docId,
              itemId: item.id,
              itemProductSearchFilterPropertyFilterExpressions:
                item.productSearchFilterExpressions.propertyFilters.map(
                  productSearchFilterPropertyFilterExpression2Input
                ),
              itemProductSearchFilterListPriceFilterExpression: item
                .productSearchFilterExpressions.listPriceFilter
                ? {
                    numberBetweenFilter:
                      item.productSearchFilterExpressions.listPriceFilter
                        .__typename ===
                      "EntitySearchNumberBetweenFilterComputed"
                        ? {
                            expr: item.productSearchFilterExpressions
                              .listPriceFilter.expr,
                            operatorNumberBetween:
                              item.productSearchFilterExpressions
                                .listPriceFilter.operatorNumberBetween,
                          }
                        : undefined,
                    numberFilter:
                      item.productSearchFilterExpressions.listPriceFilter
                        .__typename === "EntitySearchNumberFilterComputed"
                        ? {
                            expr: item.productSearchFilterExpressions
                              .listPriceFilter.expr,
                            operatorNumber:
                              item.productSearchFilterExpressions
                                .listPriceFilter.operatorNumber,
                          }
                        : undefined,
                  }
                : null,
            },
          },
        });

        await client.reFetchObservableQueries();

        close();
      }}
    >
      {item.productSearchFilterExpressions.propertyFilters.map((e, idx) => {
        switch (e.__typename) {
          case "EntitySearchPropertyFilterBoolFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorBool}
                    onChange={evt => {
                      e.operatorBool = evt.target
                        .value as EntitySearchBoolFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="eq">eq</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterBoolInFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorBoolIn}
                    onChange={evt => {
                      e.operatorBoolIn = evt.target
                        .value as EntitySearchBoolInFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="in">in</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterNumberFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorNumber}
                    onChange={evt => {
                      e.operatorNumber = evt.target
                        .value as EntitySearchNumberFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="eq">eq</option>
                    <option value="lt">lt</option>
                    <option value="lte">lte</option>
                    <option value="gt">gt</option>
                    <option value="gte">gte</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterNumberInFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorNumberIn}
                    onChange={evt => {
                      e.operatorNumberIn = evt.target
                        .value as EntitySearchNumberInFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="in">in</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorNumberBetween}
                    onChange={evt => {
                      e.operatorNumberBetween = evt.target
                        .value as EntitySearchNumberBetweenFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="between">between</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterTextFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorText}
                    onChange={evt => {
                      e.operatorText = evt.target
                        .value as EntitySearchTextFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="eq">eq</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          case "EntitySearchPropertyFilterTextInFilterComputed": {
            return (
              <div key={idx}>
                <label>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: 200,
                    }}
                  >
                    {e.key}
                  </span>
                  <select
                    style={{ marginLeft: "1rem" }}
                    name={"entries[" + idx + "][operator]"}
                    value={e.operatorTextIn}
                    onChange={evt => {
                      e.operatorTextIn = evt.target
                        .value as EntitySearchTextInFilterOperator;
                      setItem({ ...item });
                    }}
                  >
                    <option value="in">in</option>
                  </select>
                  <input
                    style={{ marginLeft: "1rem" }}
                    type="text"
                    value={e.expr}
                    onChange={evt => {
                      e.expr = evt.target.value;
                      setItem({ ...item });
                    }}
                  />
                </label>
              </div>
            );
          }
          default:
            assertNever(e);
        }
      })}
      <button type="submit">save</button>
    </form>
  );
}

function UnansweredPropsFlowModal({
  handleClose,
  projectId,
  docId,
  itemId,
}: { handleClose: () => void } & Omit<
  React.ComponentProps<typeof UnansweredPropsFlow>,
  "close"
>) {
  return (
    <Modal handleClose={handleClose}>
      <UnansweredPropsFlow
        close={handleClose}
        projectId={projectId}
        docId={docId}
        itemId={itemId}
      />
    </Modal>
  );
}

function UnansweredPropsFlow(props: {
  close: () => void;
  projectId: string | null;
  docId: string;
  itemId: string;
}) {
  const client = useApolloClient();
  const { projectId, docId } = props;

  const [itemId, setItemId] = useState(props.itemId);

  const [unanswered, setUnanswered] = useState<Array<Unanswered>>([]);
  const [skipItemIds, setSkipItemIds] = useState<string[]>([]);

  const [enterItemProps2] =
    usePropertiesDebugModal_EnterItemProps2ValueMutation({
      client,
    });

  const [firstUnansweredItem] = unanswered;

  useEffect(() => {
    (async () => {
      const res =
        projectId === null
          ? []
          : await fetchUnanswered(client, {
              projectId,
              docId,
              itemId,
              skipItemIds: [],
            });

      if (res.length === 0) {
        alert("no unanswered props");
        props.close();
        return;
      }

      setItemId(res[0].item.id);
      setUnanswered(res);
    })();
  }, []);

  if (!firstUnansweredItem) return null;

  return (
    <div>
      <h1>{firstUnansweredItem.item.title}</h1>
      <hr />

      <form
        onSubmit={async e => {
          e.preventDefault();
          e.stopPropagation();

          for (let p of firstUnansweredItem.props) {
            switch (p.__typename) {
              case "Props2Text":
              case "Props2Number": {
                break;
              }
              case "Props2Bool": {
                p.valueBool ??= false;
                break;
              }
              case "Props2NumberArray": {
                p.valueNumberArray ??= [];
                break;
              }
              case "Props2TextArray": {
                p.valueTextArray ??= [];
                break;
              }
              default: {
                assertNever(p);
              }
            }
          }

          await enterItemProps2({
            variables: {
              input: {
                projectId,
                docId,
                itemId,
                entries: firstUnansweredItem.props.map(p => {
                  switch (p.__typename) {
                    case "Props2Bool": {
                      return {
                        bool: { key: p.key, valueBool: p.valueBool },
                      };
                    }
                    case "Props2Text": {
                      return {
                        text: { key: p.key, valueText: p.valueText },
                      };
                    }
                    case "Props2TextArray": {
                      return {
                        textArray: {
                          key: p.key,
                          valueTextArray: p.valueTextArray,
                        },
                      };
                    }
                    case "Props2Number": {
                      return {
                        number: { key: p.key, valueNumber: p.valueNumber },
                      };
                    }
                    case "Props2NumberArray": {
                      return {
                        numberArray: {
                          key: p.key,
                          valueNumberArray: p.valueNumberArray,
                        },
                      };
                    }
                    default: {
                      assertNever(p);
                    }
                  }
                }),
              },
            },
          });

          const nextSkipItemIds = firstUnansweredItem.props.some(
            isPropMissingValue
          )
            ? [...skipItemIds, firstUnansweredItem.item.id]
            : skipItemIds;

          const nextUnanswered = await fetchUnanswered(client, {
            projectId: projectId!,
            docId,
            itemId: props.itemId,
            skipItemIds: nextSkipItemIds,
          });

          if (nextUnanswered.length === 0) {
            await client.reFetchObservableQueries();
            props.close();
            return;
          }

          setItemId(nextUnanswered[0].item.id);
          setSkipItemIds(nextSkipItemIds);
          setUnanswered(nextUnanswered);
        }}
      >
        <ul>
          {firstUnansweredItem.props.map((p, idx) => (
            <li key={p.key}>
              <div>key: {p.key}</div>
              <div>datatype: {p.__typename}</div>

              <div>
                value:
                {(() => {
                  switch (p.__typename) {
                    case "Props2Bool": {
                      return (
                        <input
                          type="checkbox"
                          checked={p.valueBool === true}
                          onChange={e => {
                            p.valueBool = e.currentTarget.checked;
                            setUnanswered([firstUnansweredItem]);
                          }}
                        />
                      );
                    }
                    case "Props2Number": {
                      return (
                        <input
                          type="text"
                          value={p.valueNumber ?? ""}
                          onChange={e => {
                            const newValue = makePropertyValueFromStrInput(
                              p,
                              p.key,
                              e.currentTarget.value
                            );

                            p.valueNumber = newValue.number?.valueNumber;
                            setUnanswered([firstUnansweredItem]);
                          }}
                        />
                      );
                    }
                    case "Props2NumberArray": {
                      return (
                        <input
                          type="text"
                          defaultValue={
                            Array.isArray(p.valueNumberArray)
                              ? JSON.stringify(p.valueNumberArray)
                              : "[]"
                          }
                          onBlur={e => {
                            try {
                              const newValue = makePropertyValueFromStrInput(
                                p,
                                p.key,
                                e.currentTarget.value
                              );

                              p.valueNumberArray =
                                newValue.numberArray?.valueNumberArray;
                              setUnanswered([firstUnansweredItem]);
                            } catch (err) {
                              //
                            }
                          }}
                        />
                      );
                    }
                    case "Props2Text": {
                      if (p.allowedValuesText.length > 0) {
                        if (p.allowedValuesText.every(a => a.media !== null)) {
                          return (
                            <>
                              <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                {p.allowedValuesText.map(a => (
                                  <li
                                    key={a.allowedText}
                                    style={{
                                      margin: "1rem",
                                      border:
                                        p.valueText === a.allowedText
                                          ? "3px solid blue"
                                          : undefined,
                                    }}
                                    onClick={() => {
                                      const newValue =
                                        makePropertyValueFromStrInput(
                                          p,
                                          p.key,
                                          a.allowedText
                                        );

                                      p.valueText = newValue.text?.valueText;
                                      setUnanswered([firstUnansweredItem]);
                                    }}
                                  >
                                    <div>{a.allowedText}</div>
                                    <img src={a.media!.url} />
                                  </li>
                                ))}
                              </ul>
                            </>
                          );
                        } else {
                          // dropdown
                          return (
                            <select
                              value={p.valueText ?? "___null___"}
                              onChange={e => {
                                if (e.target.value === "___null___") {
                                  p.valueText = null;
                                } else {
                                  const newValue =
                                    makePropertyValueFromStrInput(
                                      p,
                                      p.key,
                                      e.target.value
                                    );

                                  p.valueText = newValue.text?.valueText;
                                }

                                setUnanswered([firstUnansweredItem]);
                              }}
                            >
                              <option value="___null___">choose</option>
                              {p.allowedValuesText.map(a => (
                                <option
                                  key={a.allowedText}
                                  value={a.allowedText}
                                >
                                  {a.allowedText}
                                </option>
                              ))}
                            </select>
                          );
                        }
                      }
                      return (
                        <input
                          type="text"
                          value={p.valueText ?? ""}
                          onInput={e => {
                            const newValue = makePropertyValueFromStrInput(
                              p,
                              p.key,
                              e.currentTarget.value
                            );

                            p.valueText = newValue.text?.valueText;
                            setUnanswered([firstUnansweredItem]);
                          }}
                        />
                      );
                    }
                    case "Props2TextArray": {
                      return (
                        <input
                          type="text"
                          defaultValue={
                            Array.isArray(p.valueTextArray)
                              ? JSON.stringify(p.valueTextArray)
                              : "[]"
                          }
                          onBlur={e => {
                            try {
                              const newValue = makePropertyValueFromStrInput(
                                p,
                                p.key,
                                e.currentTarget.value
                              );

                              p.valueTextArray =
                                newValue.textArray?.valueTextArray;
                              setUnanswered([firstUnansweredItem]);
                            } catch (err) {
                              //
                            }
                          }}
                        />
                      );
                    }
                    default: {
                      assertNever(p);
                    }
                  }
                })()}
              </div>
            </li>
          ))}
        </ul>

        <button type="submit">continue</button>
      </form>
    </div>
  );
}

async function fetchUnanswered(
  client: ApolloClient<any>,
  vars: {
    projectId: string;
    docId: string;
    itemId: string;
    skipItemIds: string[];
  }
) {
  const res = await client.query<
    PropertiesDebugModal_UnansweredPropsFlowQuery,
    PropertiesDebugModal_UnansweredPropsFlowQueryVariables
  >({
    query: PropertiesDebugModal_UnansweredPropsFlowDocument,
    variables: {
      projectId: vars.projectId,
      docId: vars.docId,
      itemId: vars.itemId,
    },
    fetchPolicy: "network-only",
  });

  const unansweredProps2Deep =
    getDataOrNull(res.data?.item)?.unansweredProps2Deep ?? [];

  // TODO
  // graphql does not support $variables in fragments
  // so we fetch all from server and filter client side
  return cloneDeep(
    unansweredProps2Deep.filter(e => !vars.skipItemIds.includes(e.item.id))
  );
}

type Unanswered = Awaited<ReturnType<typeof fetchUnanswered>>[0];

// ------------------------------
// THIS IS FOR PROTOTYPING FEATURES ONLY AND NOT SUPPOSED TO EVER BE USED FOR PRODUCTION FEATURES
// ------------------------------

export function DebugAllPropsButton({
  projectId,
  docId,
}: {
  projectId: string | null;
  docId: string;
}) {
  return (
    <ModalOpenButton
      Modal={DebugAllPropsModal}
      modalProps={{ projectId, docId }}
    >
      <Button variant={"outlined"}>Debug all props</Button>
    </ModalOpenButton>
  );
}

function DebugAllPropsModal({
  handleClose,
  projectId,
  docId,
}: {
  handleClose: () => void;
  projectId: string | null;
  docId: string;
}) {
  return (
    <Modal
      handleClose={handleClose}
      actionButtons={[]}
      maxWidth="xl"
      fixedHeight
    >
      <DebugAllProp projectId={projectId} docId={docId} />
    </Modal>
  );
}

export function DebugAllProp(props: {
  projectId: string | null;
  docId: string;
}) {
  const client = useApolloClient();
  const resQuote = useDebugAllPropQuoteQuery({
    client,
    variables: {
      projectId: props.projectId!,
      quoteId: props.docId,
    },
    skip: props.projectId === null,
  });

  const resTemplate = useDebugAllPropTemplateQuery({
    client,
    variables: {
      docId: props.docId,
    },
    skip: props.projectId !== null,
  });

  type Tabs = "attributeExpressions" | "props" | "propertyMappings";

  const [tab, setTab] = useState<Tabs>("props");

  const entries =
    getDataOrNull(resQuote.data?.quote)?.quote?.debugAllProps ??
    resTemplate.data?.quoteTemplateLatest?.debugAllProps ??
    [];

  const [filterItemTitle, setFilterItemTitle] = useState("");

  const [filterAttributeName, setFilterAttributeName] = useState("");
  const [filterAttributeExpr, setFilterAttributeExpr] = useState("");
  const [filterAttributeResult, setFilterAttributeResult] = useState("");
  const [filterAttributeMissingValue, setFilterAttributeMissingValue] =
    useState("");

  // ----
  const [filterPropsKey, setFilterPropsKey] = useState("");
  const [filterPropsExpr, setFilterPropsExpr] = useState("");
  const [filterPropsDataType, setFilterPropsDataType] = useState("");
  const [filterPropsValue, setFilterPropsValue] = useState("");
  const [filterPropsMissingValue, setFilterPropsMissingValue] = useState("");

  // ----
  const [filterPropertyMappingExpr, setFilterPropertyMappingExpr] =
    useState("");
  const [filterPropertyMappingToKey, setFilterPropertyMappingToKey] =
    useState("");

  const switchTab = (newTab: Tabs) => {
    if (tab === newTab) return;

    setTab(newTab);
  };

  const buildLinkHref = (itemId: string) => {
    if (props.projectId === null) {
      return `/templates/documents/${props.docId}/edit/items/${itemId}`;
    } else {
      return `/projects/${props.projectId}/quotes/${props.docId}/edit/items/${itemId}`;
    }
  };

  const filteredEntries = [...entries].filter(e =>
    filterItemTitle.length > 0
      ? e.itemTitle.toLowerCase().indexOf(filterItemTitle.toLowerCase()) !== -1
      : true
  );

  const filteredProps = filteredEntries.flatMap(e => {
    return [...e.props]
      .filter(p =>
        filterPropsKey.length !== 0
          ? p.key.indexOf(filterPropsKey) !== -1
          : true
      )
      .sort((a, b) => a.key.localeCompare(b.key))
      .map(p => {
        const [val, expr, missingValue] = (() => {
          switch (p?.__typename) {
            case "Props2Bool": {
              return [p.valueBool, null, null];
            }
            case "Props2BoolComputed": {
              return [p.valueBoolComputed, null, null];
            }
            case "Props2Number": {
              return [p.valueNumber, null, null];
            }
            case "Props2NumberComputed": {
              return [p.valueNumberComputed, p.expr, p.missingValue];
            }
            case "Props2NumberArray": {
              return [p.valueNumberArray, null, null];
            }
            case "Props2NumberArrayComputed": {
              return [p.valueNumberArrayComputed, p.expr, p.missingValue];
            }
            case "Props2Text": {
              return [p.valueText, null, null];
            }
            case "Props2TextComputed": {
              return [p.valueTextComputed, p.expr, p.missingValue];
            }
            case "Props2TextArray": {
              return [p.valueTextArray, null, null];
            }
            case "Props2TextArrayComputed": {
              return [p.valueTextArrayComputed, p.expr, p.missingValue];
            }
            default: {
              assertNever(p);
            }
          }
        })();
        return {
          key: p.key,
          val,
          expr,
          missingValue,
          dataType: p.__typename,
        };
      })
      .filter(e =>
        filterPropsExpr.length !== 0
          ? String(e.expr).toLowerCase().indexOf(filterPropsExpr) !== -1
          : true
      )
      .filter(e =>
        filterPropsMissingValue.length !== 0
          ? JSON.stringify(e.missingValue)
              .toLowerCase()
              .indexOf(filterPropsMissingValue) !== -1
          : true
      )
      .filter(e =>
        filterPropsDataType.length !== 0
          ? e.dataType.toLowerCase().indexOf(filterPropsDataType) !== -1
          : true
      )
      .filter(e =>
        filterPropsValue.length !== 0
          ? JSON.stringify(e.val).toLowerCase().indexOf(filterPropsValue) !== -1
          : true
      )
      .map(ae => {
        return {
          ...ae,
          ...e,
        };
      });
  });

  const filteredAttributeExpressions = filteredEntries.flatMap(e => {
    return e.attributeExpressions
      .filter(ae =>
        filterAttributeName.length > 0
          ? ae.attribute.toLowerCase().indexOf(filterAttributeName) !== -1
          : true
      )
      .filter(ae =>
        filterAttributeExpr.length > 0
          ? ae.expr.toLowerCase().indexOf(filterAttributeExpr) !== -1
          : true
      )
      .filter(ae =>
        filterAttributeResult.length > 0
          ? JSON.stringify(ae.result)
              .toLowerCase()
              .indexOf(filterAttributeResult) !== -1
          : true
      )
      .filter(ae =>
        filterAttributeMissingValue.length > 0
          ? JSON.stringify(ae.missingValue)
              .toLowerCase()
              .indexOf(filterAttributeMissingValue) !== -1
          : true
      )
      .map(ae => {
        return {
          ...ae,
          ...e,
        };
      });
  });

  const filteredPropertyMappings = filteredEntries.flatMap(e => {
    return e.propertyMappings
      .filter(pm =>
        filterPropertyMappingExpr.length > 0
          ? pm.from.expr.toLowerCase().indexOf(filterPropertyMappingExpr) !== -1
          : true
      )
      .filter(pm =>
        filterPropertyMappingToKey.length > 0
          ? pm.to.key.toLowerCase().indexOf(filterPropertyMappingToKey) !== -1
          : true
      )
      .map(pm => {
        return {
          ...pm,
          ...e,
        };
      });
  });

  const propertyMappingsEl =
    tab === "propertyMappings" ? (
      <>
        <h2>property mappings ({filteredPropertyMappings.length})</h2>
        <hr />
        <table style={{ fontSize: "0.8rem" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                title{" "}
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterItemTitle}
                  onChange={e =>
                    setFilterItemTitle(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                expr{" "}
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropertyMappingExpr}
                  onChange={e =>
                    setFilterPropertyMappingExpr(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                to key{" "}
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropertyMappingToKey}
                  onChange={e =>
                    setFilterPropertyMappingToKey(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPropertyMappings.map(pm => {
              return (
                <tr key={pm.itemId + pm.to.key}>
                  <td>
                    <a
                      href={buildLinkHref(pm.itemId)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {pm.itemPath + " " + pm.itemTitle}
                    </a>
                  </td>
                  <td>
                    <textarea defaultValue={pm.from.expr} readOnly />
                  </td>
                  <td>{pm.to.key}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null;

  const attributeExpressionsEl =
    tab === "attributeExpressions" ? (
      <>
        <h2>attribute expressions ({filteredAttributeExpressions.length})</h2>
        <hr />
        <table style={{ fontSize: "0.8rem" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                title{" "}
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterItemTitle}
                  onChange={e =>
                    setFilterItemTitle(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                attribute
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterAttributeName}
                  onChange={e =>
                    setFilterAttributeName(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                result
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterAttributeResult}
                  onChange={e =>
                    setFilterAttributeResult(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                missingValue
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterAttributeMissingValue}
                  onChange={e =>
                    setFilterAttributeMissingValue(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                expr
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterAttributeExpr}
                  onChange={e =>
                    setFilterAttributeExpr(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAttributeExpressions.map(ae => {
              return (
                <tr key={ae.itemId + ae.attribute}>
                  <td style={{ textAlign: "left" }} title={"id:" + ae.itemId}>
                    <a href={buildLinkHref(ae.itemId)}>
                      {ae.itemPath + " " + ae.itemTitle}
                    </a>
                  </td>
                  <td style={{ textAlign: "left" }}>{ae.attribute}</td>
                  <td style={{ textAlign: "left" }}>
                    {JSON.stringify(ae.result)}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {JSON.stringify(ae.missingValue)}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <textarea defaultValue={ae.expr} readOnly />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null;

  const propsEl =
    tab === "props" ? (
      <>
        <h2>props ({filteredProps.length})</h2>
        <hr />
        <table style={{ fontSize: "0.8rem" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                title
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterItemTitle}
                  onChange={e =>
                    setFilterItemTitle(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                key
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropsKey}
                  onChange={e =>
                    setFilterPropsKey(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                value
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropsValue}
                  onChange={e =>
                    setFilterPropsValue(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>

              <th style={{ textAlign: "left" }}>
                expr
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropsExpr}
                  onChange={e =>
                    setFilterPropsExpr(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                dataType
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropsDataType}
                  onChange={e =>
                    setFilterPropsDataType(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
              <th style={{ textAlign: "left" }}>
                missingValue
                <input
                  style={{ display: "block" }}
                  type="text"
                  value={filterPropsMissingValue}
                  onChange={e =>
                    setFilterPropsMissingValue(e.target.value.toLowerCase())
                  }
                  placeholder="filter..."
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProps.map(
              ({
                key,
                val,
                expr,
                missingValue,
                itemId,
                itemTitle,
                itemPath,
                dataType,
              }) => {
                return (
                  <tr key={itemId + key}>
                    <td style={{ textAlign: "left" }} title={"id:" + itemId}>
                      <a href={buildLinkHref(itemId)}>
                        {itemPath + " " + itemTitle}
                      </a>
                    </td>
                    <td style={{ textAlign: "left" }}>{key}</td>
                    <td style={{ textAlign: "left" }}>{JSON.stringify(val)}</td>
                    <td style={{ textAlign: "left" }}>
                      {expr ? <textarea defaultValue={expr} readOnly /> : "n/a"}
                    </td>
                    <td style={{ textAlign: "left" }}>{dataType}</td>
                    <td style={{ textAlign: "left" }}>
                      {missingValue === null
                        ? "n/a"
                        : JSON.stringify(missingValue)}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </>
    ) : null;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            backgroundColor: "#c0c0c0",
            padding: "0.5rem 1rem",
            width: 200,
            textAlign: "center",
            border: tab === "props" ? "2px solid black" : undefined,
            cursor: "pointer",
          }}
          onClick={() => switchTab("props")}
        >
          props
        </div>
        <div
          style={{
            marginLeft: "1rem",
            backgroundColor: "#c0c0c0",
            padding: "0.5rem 1rem",
            width: 200,
            textAlign: "center",
            border:
              tab === "attributeExpressions" ? "2px solid black" : undefined,
            cursor: "pointer",
          }}
          onClick={() => switchTab("attributeExpressions")}
        >
          attribute expressions
        </div>
        <div
          style={{
            marginLeft: "1rem",
            backgroundColor: "#c0c0c0",
            padding: "0.5rem 1rem",
            width: 200,
            textAlign: "center",
            border: tab === "propertyMappings" ? "2px solid black" : undefined,
            cursor: "pointer",
          }}
          onClick={() => switchTab("propertyMappings")}
        >
          property mappings
        </div>
      </div>
      <hr />
      {attributeExpressionsEl}
      {propsEl}
      {propertyMappingsEl}
    </>
  );
}

function extractExistingPropsFromItem(item: { props2: Array<Props2> }) {
  const existingPropsEntries = item.props2.map(propToDefineInput);

  return existingPropsEntries;
}

async function doDeDeleteAttributeExpressionFlow(
  item: { attributeExpressions: Array<ItemAttributeExpression> },
  defineItemAttributeExpression: (
    attributeExpressions: Array<AttributeExpressionInput>
  ) => Promise<void>,
  attribute: string
) {
  await defineItemAttributeExpression(
    item.attributeExpressions
      .filter(e2 => e2.attribute !== attribute)
      .map(a => omit(a, ["__typename", "result", "missingValue"]))
  );
}

async function doEditAttributeExpressionFlow(
  item: { attributeExpressions: Array<ItemAttributeExpression> },
  defineItemAttributeExpression: (
    attributeExpressions: Array<AttributeExpressionInput>
  ) => Promise<void>,
  attribute: string
) {
  const ae = item.attributeExpressions.find(ae => ae.attribute === attribute);
  if (!ae) throw new Error("attribute not found: " + attribute);

  const exprStr = prompt("enter expr:", ae.expr);

  if (exprStr !== null) {
    await defineItemAttributeExpression([
      ...item.attributeExpressions
        .map(a => {
          if (a.attribute === attribute) {
            return {
              __typename: "ItemAttributeExpression",
              attribute,
              expr: exprStr,
            };
          } else {
            return a;
          }
        })
        .map(a => {
          return {
            attribute: a.attribute,
            expr: a.expr,
          };
        }),
    ]);
  }
}

async function doDefineItemAttributeExpressionFlow(
  item: { attributeExpressions: Array<ItemAttributeExpression> },
  defineItemAttributeExpression: (
    attributeExpressions: Array<AttributeExpressionInput>
  ) => Promise<void>
) {
  const attributeStr = prompt(
    `
    supported attributes:
    - decisionContingentItemPreselection (boolean|null)
    - decisionIsContingentItem (boolean)
    - estimatedQuantity (number)
    supported extra effects:
    - eliminateDecisionOptionIf (boolean)
    - preselectDecisionOptionIf: (boolean)
    - eliminateItemIf (boolean)
    
    enter attribute:
  `
      .split("\n")
      .map(line => line.trim())
      .join("\n")
  );

  if (attributeStr !== null) {
    const exprStr = prompt(
      `
    samples:
    - "some-static-value"
    - 1 + 1
    - prop(@root, "somePropKey")

    enter expr:
    `
        .split("\n")
        .map(line => line.trim())
        .join("\n")
    );

    if (exprStr !== null) {
      await defineItemAttributeExpression([
        ...item.attributeExpressions.map(a =>
          omit(a, ["__typename", "missingValue", "result"])
        ),
        {
          attribute: attributeStr,
          expr: exprStr,
        },
      ]);
    }
  }
}

async function doDeletePropFlow(
  item: { props2: Array<Props2> },
  defineProp: (entries: Array<DefineItemProps2Entry>) => Promise<void>,
  key: string
) {
  const existingPropsEntries = extractExistingPropsFromItem({
    props2: item.props2.filter(p => p.key !== key),
  });

  await defineProp([...existingPropsEntries]);
}

async function doStartDefineProps2Flow(
  item: { props2: Array<Props2> },
  rootItem: { id: string },
  defineProp: (entries: Array<DefineItemProps2Entry>) => Promise<void>
) {
  const keyStr = prompt("enter key:");

  if (keyStr) {
    const typeStr = prompt(
      `enter type:
  
  0) text
  00) text-array
  1) number
  11) numer-array
  2) bool
  3) text-computed
  33) text-array-computed
  4) number-computed
  44) number-array-computed
  5) bool-computed                      
  `,
      "0"
    );

    if (typeStr !== null) {
      const existingPropsEntries = extractExistingPropsFromItem(item);

      if (typeStr === "0") {
        await defineProp([
          ...existingPropsEntries,
          {
            text: {
              key: keyStr,
              label: "",
              allowedValuesText: [],
              essential: false,
              group: "",
              clientVisibility: true,
              prompt: "",
              askWhen: [],
              askWhom: [],
            },
          },
        ]);
      } else if (typeStr === "00") {
        await defineProp([
          ...existingPropsEntries,
          {
            textArray: {
              key: keyStr,
              label: "",
              allowedValuesText: [],
              essential: false,
              group: "",
              clientVisibility: true,
              prompt: "",
              askWhen: [],
              askWhom: [],
            },
          },
        ]);
      } else if (typeStr === "1") {
        await defineProp([
          ...existingPropsEntries,
          {
            number: {
              key: keyStr,
              label: "",
              unit: "m",
              essential: false,
              allowedValuesNumber: [],
              group: "",
              clientVisibility: true,
              prompt: "",
              askWhen: [],
              askWhom: [],
            },
          },
        ]);
      } else if (typeStr === "11") {
        await defineProp([
          ...existingPropsEntries,
          {
            numberArray: {
              key: keyStr,
              label: "",
              unit: "m",
              essential: false,
              allowedValuesNumber: [],
              group: "",
              clientVisibility: true,
              prompt: "",
              askWhen: [],
              askWhom: [],
            },
          },
        ]);
      } else if (typeStr === "2") {
        await defineProp([
          ...existingPropsEntries,
          {
            bool: {
              key: keyStr,
              label: "",
              essential: false,
              group: "",
              clientVisibility: true,
              prompt: "",
              askWhen: [],
              askWhom: [],
            },
          },
        ]);
      } else if (typeStr === "3") {
        await askComputedExpr(rootItem, async expr => {
          await defineProp([
            ...existingPropsEntries,
            {
              textComputed: {
                key: keyStr,
                label: "",
                expr,
                group: "",
                clientVisibility: true,
              },
            },
          ]);
        });
      } else if (typeStr === "33") {
        await askComputedExpr(rootItem, async expr => {
          await defineProp([
            ...existingPropsEntries,
            {
              textArrayComputed: {
                key: keyStr,
                label: "",
                expr,
                group: "",
                clientVisibility: true,
              },
            },
          ]);
        });
      } else if (typeStr === "4") {
        await askComputedExpr(rootItem, async expr => {
          await defineProp([
            ...existingPropsEntries,
            {
              numberComputed: {
                key: keyStr,
                label: "",
                expr,
                unit: "m",
                group: "",
                clientVisibility: true,
              },
            },
          ]);
        });
      } else if (typeStr === "44") {
        await askComputedExpr(rootItem, async expr => {
          await defineProp([
            ...existingPropsEntries,
            {
              numberArrayComputed: {
                key: keyStr,
                label: "",
                expr,
                unit: "m",
                group: "",
                clientVisibility: true,
              },
            },
          ]);
        });
      } else if (typeStr === "5") {
        await askComputedExpr(rootItem, async expr => {
          await defineProp([
            ...existingPropsEntries,
            {
              boolComputed: {
                key: keyStr,
                label: "",
                expr,
                group: "",
                clientVisibility: true,
              },
            },
          ]);
        });
      } else {
        alert("invalid type");
      }
    }
  }
}

async function askComputedExpr(
  rootItem: { id: string },
  fn: (expr: string) => Promise<void>
) {
  const exprStr = prompt("enter expr");

  if (exprStr !== null) {
    return await fn(exprStr);
  }
}

async function doStartEnterProps2ValueFlow(
  promptHeaderText: string,
  item: { id: string; title: string },
  m: Props2NonComputedAllFragment,
  enterItemProps2: (entries: Array<EnterItemProps2ValueEntry>) => Promise<void>,
  onSkipped: () => Promise<void>
) {
  const propKey = (() => {
    switch (m.__typename) {
      case "Props2Bool":
      case "Props2Text":
      case "Props2TextArray":
      case "Props2Number":
      case "Props2NumberArray": {
        return m.key;
      }
      default: {
        assertNever(m);
      }
    }
  })();

  const propValue = (() => {
    if (m.__typename === "Props2Text") {
      return m.valueText;
    } else if (m.__typename === "Props2Bool") {
      return m.valueBool;
    } else if (m.__typename === "Props2TextArray") {
      return m.valueTextArray ? JSON.stringify(m.valueTextArray) : "[]";
    } else if (m.__typename === "Props2Number") {
      return m.valueNumber;
    } else if (m.__typename === "Props2NumberArray") {
      return m.valueNumberArray ? JSON.stringify(m.valueNumberArray) : "[]";
    } else {
      assertNever(m);
    }
  })();

  const newPropValueStr = prompt(
    [
      ...(promptHeaderText ? [promptHeaderText, `------------------`] : []),
      `item: ${item.title}`,
      `prop: ${propKey}`,
      `current (default) value: ${String(propValue)}`,
      ``,
      `enter value`,
    ].join("\n"),
    propValue !== null && propValue !== undefined ? String(propValue) : ""
  );

  if (
    newPropValueStr !== null
    //  && newPropValueStr !== propValue
  ) {
    const prop2Value = makePropertyValueFromStrInput(
      m,
      propKey,
      newPropValueStr
    );

    await enterItemProps2([prop2Value]);
  } else {
    await onSkipped();
  }
}

function makePropertyValueFromStrInput(
  m: Props2NonComputedAllFragment,
  propKey: string,
  newPropValueStr: string
) {
  const prop2Value: EnterItemProps2ValueEntry = (() => {
    if (m.__typename === "Props2Text") {
      return {
        text: {
          key: propKey,
          valueText: newPropValueStr,
        },
      };
    } else if (m.__typename === "Props2TextArray") {
      const valueTextArray = JSON.parse(
        newPropValueStr.length === 0 ? "[]" : newPropValueStr
      );

      if (!Array.isArray(valueTextArray)) throw new Error(`not an array value`);

      if (valueTextArray.some(n => typeof n !== "string"))
        throw new Error(`contains non-text value`);

      return {
        textArray: {
          key: propKey,
          valueTextArray,
        },
      };
    } else if (m.__typename === "Props2Bool") {
      return {
        bool: {
          key: propKey,
          valueBool: newPropValueStr === "true",
        },
      };
    } else if (m.__typename === "Props2Number") {
      return {
        number: {
          key: propKey,
          valueNumber: parseFloat(newPropValueStr),
        },
      };
    } else if (m.__typename === "Props2NumberArray") {
      const valueNumberArray = JSON.parse(
        newPropValueStr.length === 0 ? "[]" : newPropValueStr
      );

      if (!Array.isArray(valueNumberArray))
        throw new Error(`not an array value`);

      if (valueNumberArray.some(n => typeof n !== "number"))
        throw new Error(`contains non-number value`);

      return {
        numberArray: {
          key: propKey,
          valueNumberArray: valueNumberArray.map(parseFloat),
        },
      };
    } else {
      assertNever(m);
    }
  })();

  return prop2Value;
}

async function doAddPropMappingFlow(
  item: { propertyMappings: Array<Props2MappingAllFragment> },
  // rootItem: { id: string },
  setMappings: (propertyMappings: Array<SetItemProps2Mapping>) => Promise<void>
) {
  const fromStr = prompt("enter 'from' expr:", 'prop(@root, "somekey")');

  if (fromStr !== null) {
    const toPropKeyStr = prompt("enter 'to' prop key:");

    if (toPropKeyStr !== null) {
      await setMappings([
        ...item.propertyMappings.map(
          pm => omitDeep(pm, ["__typename"]) as SetItemProps2Mapping
        ),
        {
          from: {
            expr: fromStr,
          },
          to: {
            key: toPropKeyStr,
          },
        },
      ]);
    }
  }
}

async function doDeletePropMappingFlow(
  item: { propertyMappings: Array<Props2MappingAllFragment> },
  setMappings: (propertyMappings: Array<SetItemProps2Mapping>) => Promise<void>,
  toKey: string
) {
  const newPropertyMappings = item.propertyMappings
    .filter(pm => pm.to.key !== toKey)
    .map(pm => omitDeep(pm, ["__typename"]) as SetItemProps2Mapping);

  await setMappings(newPropertyMappings);
}
