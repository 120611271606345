/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestoreTemplateVersionModalQueryVariables = Types.Exact<{
  docId: Types.Scalars['ID']['input'];
}>;


export type RestoreTemplateVersionModalQuery = { quoteTemplateLatest?: { __typename: 'QuoteTemplate', id: string, latestPublishedVersionNumber?: number | null, resolvedAsReadModelVersionNumber?: number | null } | null };

export type RestoreTemplateVersionMutationVariables = Types.Exact<{
  input: Types.RestoreTemplateVersionInput;
}>;


export type RestoreTemplateVersionMutation = { restoreTemplateVersion: { __typename: 'RestoreTemplateVersionResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, latestPublishedVersionNumber?: number | null, resolvedAsReadModelVersionNumber?: number | null } } };


export const RestoreTemplateVersionModalDocument = gql`
    query RestoreTemplateVersionModal($docId: ID!) {
  quoteTemplateLatest(id: $docId) {
    id
    latestPublishedVersionNumber
    resolvedAsReadModelVersionNumber
  }
}
    `;

/**
 * __useRestoreTemplateVersionModalQuery__
 *
 * To run a query within a React component, call `useRestoreTemplateVersionModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestoreTemplateVersionModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestoreTemplateVersionModalQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useRestoreTemplateVersionModalQuery(baseOptions: Apollo.QueryHookOptions<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables> & ({ variables: RestoreTemplateVersionModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>(RestoreTemplateVersionModalDocument, options);
      }
export function useRestoreTemplateVersionModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>(RestoreTemplateVersionModalDocument, options);
        }
export function useRestoreTemplateVersionModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>(RestoreTemplateVersionModalDocument, options);
        }
export type RestoreTemplateVersionModalQueryHookResult = ReturnType<typeof useRestoreTemplateVersionModalQuery>;
export type RestoreTemplateVersionModalLazyQueryHookResult = ReturnType<typeof useRestoreTemplateVersionModalLazyQuery>;
export type RestoreTemplateVersionModalSuspenseQueryHookResult = ReturnType<typeof useRestoreTemplateVersionModalSuspenseQuery>;
export type RestoreTemplateVersionModalQueryResult = Apollo.QueryResult<RestoreTemplateVersionModalQuery, RestoreTemplateVersionModalQueryVariables>;
export const RestoreTemplateVersionDocument = gql`
    mutation RestoreTemplateVersion($input: RestoreTemplateVersionInput!) {
  restoreTemplateVersion(input: $input) {
    quoteTemplate {
      id
      latestPublishedVersionNumber
      resolvedAsReadModelVersionNumber
    }
  }
}
    `;
export type RestoreTemplateVersionMutationFn = Apollo.MutationFunction<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>;

/**
 * __useRestoreTemplateVersionMutation__
 *
 * To run a mutation, you first call `useRestoreTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTemplateVersionMutation, { data, loading, error }] = useRestoreTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>(RestoreTemplateVersionDocument, options);
      }
export type RestoreTemplateVersionMutationHookResult = ReturnType<typeof useRestoreTemplateVersionMutation>;
export type RestoreTemplateVersionMutationResult = Apollo.MutationResult<RestoreTemplateVersionMutation>;
export type RestoreTemplateVersionMutationOptions = Apollo.BaseMutationOptions<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>;