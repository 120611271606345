import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete, LabeledValue, Modal, ModalOpenButton } from "@msys/ui";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, useField } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import {
  AddressInput,
  DocActorCompanyOrIndividual,
  DocActorType,
  Salutation,
} from "../../../clients/graphqlTypes";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField";
import { RadioGroupField } from "../../commons/form-fields/RadioGroupField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextField } from "../../commons/form-fields/TextField";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { AddressField } from "../addresses/AddressField";
import { CrmCompanySelectModal } from "../crm-companies/modals/CrmCompanySelectModal";
import { CrmPersonSelectModal } from "../crm-persons/CrmPersonSelectModal";
import { useCrmContactTypes } from "../crm/useCrmContactTypes";
import { useEmailAddressValidationSchema } from "../email/validateEmailAddress";
import { OrganisationMemberSelectModal } from "../organisations/OrganisationMemberSelectModal";
import { UserTitleWithNameField } from "../users/UserTitleWithNameField";
import {
  DocumentActorEditModal_CrmPersonDocument,
  DocumentActorEditModal_CrmPersonQuery,
  DocumentActorEditModal_CrmPersonQueryVariables,
  DocumentActorEditModal_OrganisationMembershipDocument,
  DocumentActorEditModal_OrganisationMembershipQuery,
  DocumentActorEditModal_OrganisationMembershipQueryVariables,
} from "./DocumentActorEditModal.generated";
import { useActorTypes } from "./useActorTypes";

type Maybe<T> = T | null | undefined;

export interface DocumentActorEditFormValues {
  companyOrIndividual: DocActorCompanyOrIndividual;
  companyName?: Maybe<string>;
  salutation?: Maybe<Salutation>;
  firstname?: Maybe<string>;
  familyname?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  address?: Maybe<AddressInput>;
  contact?: Maybe<{
    salutation: Salutation;
    firstname: string;
    familyname: string;
  }>;
}

interface Props {
  title: string;
  actor: {
    type: DocActorType;
    isMyOrganisation: boolean;
    identifier?: Maybe<string>;
    viewerCrmCompany?: Maybe<{
      id: string;
      email: string;
      phones: Array<{ number: string }>;
    }>;
    contact?: Maybe<{
      viewerCrmPerson?: Maybe<{
        email: string;
        phones: Array<{
          number: string;
        }>;
      }>;
    }>;
  } & DocumentActorEditFormValues;
  handleClose: () => void;
  handleReset: (crmCompanyId: string, handleClose: () => void) => Promise<void>;
  handleComplete: (
    actorFormValues: DocumentActorEditFormValues,
    handleClose: () => void
  ) => void;
}

export const DocumentActorEditModal = ({
  actor,
  title,
  handleClose,
  handleReset,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Global", "DocActor"]);
  const { actorTypeLabels } = useActorTypes();
  const { contactTypeLabels } = useCrmContactTypes();
  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const client = useApolloClient();

  const formId = React.useMemo(() => uniqueId(), []);

  const emailOptions: string[] = [];
  if (actor.viewerCrmCompany?.email) {
    emailOptions.push(actor.viewerCrmCompany.email);
  }
  if (actor.contact?.viewerCrmPerson?.email) {
    emailOptions.push(actor.contact.viewerCrmPerson.email);
  }

  const phoneOptions: string[] = [];
  if (actor.viewerCrmCompany?.phones) {
    phoneOptions.push(...actor.viewerCrmCompany.phones.map(p => p.number));
  }
  if (actor.contact?.viewerCrmPerson?.phones) {
    phoneOptions.push(
      ...actor.contact.viewerCrmPerson.phones.map(p => p.number)
    );
  }

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  return (
    <Formik<DocumentActorEditFormValues>
      initialValues={{
        companyOrIndividual: actor.companyOrIndividual,
        companyName: actor.companyName,
        salutation: actor.salutation,
        firstname: actor.firstname,
        familyname: actor.familyname,
        email: actor.email,
        phone: actor.phone,
        address: actor.address
          ? {
              countryCode: actor.address.countryCode,
              city: actor.address.city,
              postalCode: actor.address.postalCode,
              streetLines1: actor.address.streetLines1,
            }
          : null,
        contact: actor.contact
          ? {
              salutation: actor.contact.salutation,
              firstname: actor.contact.firstname,
              familyname: actor.contact.familyname,
            }
          : null,
      }}
      validationSchema={Yup.object().shape({
        companyOrIndividual: Yup.string()
          .oneOf(["COMPANY", "INDIVIDUAL"])
          .required(),
        companyName: Yup.string().when("companyOrIndividual", {
          is: "COMPANY",
          then: schema => schema.required(),
        }),
        salutation: Yup.string(),
        firstname: Yup.string(),
        familyname: Yup.string().when("companyOrIndividual", {
          is: "INDIVIDUAL",
          then: schema => schema.required(),
        }),
        email: createEmailAddressValidationSchema(
          t("Email", { ns: "Global" }),
          false
        ),
        phone: Yup.string().nullable(),
        address: Yup.object()
          .shape({
            countryCode: Yup.string(),
            city: Yup.string(),
            postalCode: Yup.string(),
            streetLines1: Yup.string(),
          })
          .nullable(),
        contact: Yup.object()
          .shape({
            salutation: Yup.string(),
            firstname: Yup.string(),
            familyname: Yup.string(),
          })
          .nullable(),
      })}
      validateOnChange={false}
      validateOnBlur
      onSubmit={values => {
        const actorInputValues = {
          ...values,
          ...(values.companyOrIndividual === "COMPANY"
            ? {
                companyName: values.companyName,
                firstname: "",
                familyname: "",
              }
            : {
                companyName: "",
                salutation: values.salutation,
                firstname: values.firstname,
                familyname: values.familyname,
              }),
        };
        handleComplete(actorInputValues, handleClose);
      }}
    >
      {formikProps => (
        <Form id={formId}>
          <Modal
            title={title}
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Cancel", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Save", { ns: "Global" }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled:
                    !formikProps.touched ||
                    !formikProps.isValid ||
                    formikProps.isSubmitting ||
                    formikProps.isValidating,
                  loading: formikProps.isSubmitting || formikProps.isValidating,
                },
              },
            ]}
            headerActions={
              <ModalOpenButton
                Modal={CrmCompanySelectModal}
                modalProps={{
                  title: t("Select {docActorType}", {
                    ns: "Global",
                    docActorType: actorTypeLabels[actor.type],
                  }),
                  inputLabel: t("Choose one of your contacts", {
                    ns: "Global",
                  }),
                  canCreateNew: true,
                  createNewLabel: t("Create new contact", { ns: "Global" }),
                  allowedContactTypesForCreate: ["COMPANY", "INDIVIDUAL"],
                  required: true,
                  handleComplete: async crmCompanyId => {
                    if (actor.isMyOrganisation) {
                      const result = await startConfirmProcess({
                        title: t("Removal your own organisation as actor", {
                          ns: "DocActor",
                        }),
                        text: t(
                          "You are trying to remove your own organisation as actor from this document",
                          { ns: "DocActor" }
                        ),
                      });
                      if (!result) return;
                    }
                    await handleReset(crmCompanyId, handleClose);
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {t("Change {docActorType}", {
                    ns: "Global",
                    docActorType: actorTypeLabels[actor.type],
                  })}
                </Button>
              </ModalOpenButton>
            }
          >
            <Stack spacing={1}>
              {actor.identifier && (
                <LabeledValue label={t("Client number", { ns: "Global" })}>
                  {actor.identifier}
                </LabeledValue>
              )}

              <RadioGroupField
                inline
                name="companyOrIndividual"
                options={[
                  { value: "COMPANY", label: contactTypeLabels["COMPANY"] },
                  {
                    value: "INDIVIDUAL",
                    label: contactTypeLabels["INDIVIDUAL"],
                  },
                ]}
              />
              {formikProps.values.companyOrIndividual === "COMPANY" ? (
                <TextField
                  name="companyName"
                  label={t("Company name", { ns: "Global" })}
                  required
                />
              ) : (
                <Box>
                  <UserTitleWithNameField
                    titleField="salutation"
                    firstnameField="firstname"
                    familynameField="familyname"
                  />
                </Box>
              )}

              <Typography variant="h4">
                {t("Address", { ns: "Global" })}
              </Typography>
              <AddressField
                name="address"
                label={t("Address", { ns: "Global" })}
              />

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={1}
              >
                <Typography variant="h4">
                  {t("Contact details", { ns: "Global" })}
                </Typography>

                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  {actor.isMyOrganisation ? (
                    <ModalOpenButton
                      Modal={OrganisationMemberSelectModal}
                      modalProps={{
                        title: t("Select contact person", { ns: "DocActor" }),
                        handleComplete: async (userId, handleClose) => {
                          const result = await client.query<
                            DocumentActorEditModal_OrganisationMembershipQuery,
                            DocumentActorEditModal_OrganisationMembershipQueryVariables
                          >({
                            query:
                              DocumentActorEditModal_OrganisationMembershipDocument,
                            variables: {
                              userId,
                            },
                          });

                          const user = result.data.organisationMemberships[0];
                          if (!user) throw new Error("Member not found");

                          formikProps.setFieldValue("contact", {
                            salutation: user.title,
                            firstname: user.firstname,
                            familyname: user.familyname,
                          });
                          formikProps.setFieldValue(
                            "email",
                            user.email ?? null
                          );
                          formikProps.setFieldValue(
                            "phone",
                            user.phones[0]?.number ?? null
                          );
                          handleClose();
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ whiteSpace: "nowrap", alignSelf: "flex-start" }}
                      >
                        {t("Select contact person", { ns: "DocActor" })}
                      </Button>
                    </ModalOpenButton>
                  ) : actor.viewerCrmCompany ? (
                    <ModalOpenButton
                      Modal={CrmPersonSelectModal}
                      modalProps={{
                        title: t("Select contact person", { ns: "DocActor" }),
                        crmCompanyId: actor.viewerCrmCompany.id,
                        handleComplete: async (crmPersonId, handleClose) => {
                          const result = await client.query<
                            DocumentActorEditModal_CrmPersonQuery,
                            DocumentActorEditModal_CrmPersonQueryVariables
                          >({
                            query: DocumentActorEditModal_CrmPersonDocument,
                            variables: {
                              crmPersonId,
                            },
                          });

                          const crmPerson = getDataOrNull(
                            result.data.crmPersons
                          )?.edges[0]?.node;
                          if (!crmPerson) throw new Error("Person not found");

                          formikProps.setFieldValue("contact", {
                            salutation: crmPerson.title,
                            firstname: crmPerson.firstname,
                            familyname: crmPerson.familyname,
                          });
                          formikProps.setFieldValue(
                            "email",
                            crmPerson.email ?? null
                          );
                          formikProps.setFieldValue(
                            "phone",
                            crmPerson.phones[0]?.number ?? null
                          );
                          handleClose();
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ whiteSpace: "nowrap", alignSelf: "flex-start" }}
                      >
                        {t("Select contact person", { ns: "DocActor" })}
                      </Button>
                    </ModalOpenButton>
                  ) : null}
                  {formikProps.values.contact && (
                    <Tooltip
                      title={t("Remove contact person", { ns: "DocActor" })}
                      placement="top"
                    >
                      <Box display="flex">
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={async () => {
                            const result = await startConfirmProcess({
                              title: t("Remove existing contact person?", {
                                ns: "DocActor",
                              }),
                            });
                            if (!result) return;
                            formikProps.setFieldValue("contact", null);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>

              {formikProps.values.contact && (
                <Box>
                  <UserTitleWithNameField
                    titleField="contact.salutation"
                    firstnameField="contact.firstname"
                    familynameField="contact.familyname"
                    isRequiredOption="BOTH_OR_NONE"
                  />
                </Box>
              )}

              <EmailAutocompleteField
                name="email"
                label={t("Email", { ns: "Global" })}
                options={emailOptions}
              />
              <PhoneAutocompleteField
                name="phone"
                label={t("Phone", { ns: "Global" })}
                options={phoneOptions}
              />
            </Stack>
            <ConfirmProcess ref={confirmProcessRef} />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

const EmailAutocompleteField = ({
  name,
  label,
  options,
}: {
  name: string;
  label: string;
  options: Array<string>;
}) => {
  const [{ value }, { error }, { setValue }] = useField(name);

  return (
    <Autocomplete<string, true>
      inputLabel={label}
      options={options}
      freeSolo
      renderOption={(props, option) => <li {...props}>{option}</li>}
      value={value}
      onChange={newValue => {
        setValue(newValue);
      }}
      onInputChange={(_, value) => {
        setValue(value);
      }}
      error={error}
    />
  );
};

const PhoneAutocompleteField = ({
  name,
  label,
  options,
}: {
  name: string;
  label: string;
  options: Array<string>;
}) => {
  const [{ value }, { error }, { setValue }] = useField(name);

  return (
    <AutocompleteField<string, true, true>
      name={name}
      inputLabel={label}
      options={options}
      freeSolo
      disableClearable
      renderOption={(props, option) => <li {...props}>{option}</li>}
      onChange={(newValue, event) => {
        setValue(newValue);
      }}
      onInputChange={(event, value) => {
        setValue(value);
      }}
      error={error}
    />
  );
};
