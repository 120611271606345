import { useApolloClient } from "@apollo/client";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Route, Routes, useMatch, useParams } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { PageNotFound } from "../../../commons/PageNotFound";
import { usePageWidth } from "../../../commons/hooks/usePageWidth";
import { PageTopbarItem } from "../../../commons/layout/Page";
import { SelectInfoMessageItem } from "../../../features/doc-items/items/SelectInfoMessageItem";
import { TemplatesQuoteTemplateVersionSettingsModal } from "../../../features/templates/quote/TemplatesQuoteTemplateSettingsModal";
import { TemplatesQuoteTemplateSubHeader } from "../../../features/templates/quote/TemplatesQuoteTemplateSubHeader";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils";
import { TemplateQuoteVersion } from "./TemplateQuoteVersion";
import { TemplateQuoteVersionItem } from "./TemplateQuoteVersionItem";
import { useTemplateQuoteVersionRoutesQuery } from "./TemplateQuoteVersionRoutes.generated";
import { TemplateQuoteThreeDVersion } from "./TemplateQuoteVersionThreeD";
import { TemplateQuoteVersionTroubleshoot } from "./TemplateQuoteVersionTroubleshoot";

const REFETCH_QUERIES_LIST = [namedOperations.Query.TemplateQuotes];
const REFETCH_QUERIES_PREVIEW = [
  namedOperations.Query.TemplateQuoteEditPreview,
];

type PageSubmenuItem = PageTopbarItem;

interface Props {
  submenuItems: PageSubmenuItem[];
}

export const TemplateQuoteVersionRoutes = ({ submenuItems }: Props) => {
  const { id, versionNumber: versionNumberFromParams } = useParams();
  if (!id) throw new Error("Template id is missing");

  const { t } = useTranslate("SidebarItems");
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParams
  );

  const client = useApolloClient();
  const query = useTemplateQuoteVersionRoutesQuery({
    client,
    variables: { id, versionNumber: versionNumber! },
  });

  const doc = query.data?.quoteTemplateVersion;
  const docTitle = doc?.title ?? "";
  const rootItem = doc?.rootItem;

  const pathToDocList = `/templates/documents`;
  const pathToDoc = `${pathToDocList}/${id}`;
  const pathToDocVersion = `${pathToDoc}/${versionNumber}`;

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("Templates"),
        to: pathToDocList,
      },
      { title: docTitle, to: pathToDoc },
    ],
    [docTitle, pathToDoc, pathToDocList, t]
  );

  const activeView = useActiveView(pathToDoc);

  const pageSubHeader =
    doc && rootItem ? (
      <TemplatesQuoteTemplateSubHeader
        docId={doc.id}
        doc={doc}
        rootItem={rootItem}
        rootItemId={rootItem.id}
        pathToDocList={pathToDocList}
        pathToDoc={pathToDoc}
        pathToDocVersion={pathToDocVersion}
        onDuplicateRefetchQueries={REFETCH_QUERIES_LIST}
        onUpdateDataRefetchQueries={REFETCH_QUERIES_PREVIEW}
        activeView={activeView}
        isHeaderVisible={undefined as never}
        setHeaderVisible={undefined as never}
        canEdit={false}
        SettingsModalButton={
          <ModalOpenButton
            Modal={TemplatesQuoteTemplateVersionSettingsModal}
            modalProps={{
              templateId: doc.id,
              versionNumber: versionNumber!,
              refetchQueries: [
                namedOperations.Query.TemplateQuoteEdit,
                namedOperations.Query.TemplateQuoteVersion, // TODO: settings should be read-only in version - then this is unnecessary
                namedOperations.Query.TemplateQuoteEditPreview,
                namedOperations.Query.TemplateQuoteVersionPreview, // TODO: settings should be read-only in version - then this is unnecessary
              ],
              pathToDocList,
            }}
          >
            <Tooltip
              title={t("Info", {
                ns: "Global",
              })}
            >
              <IconButton size="small" color="primary">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </ModalOpenButton>
        }
      />
    ) : null;

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <TemplateQuoteVersion
            docId={id}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            pathToDocPage={pathToDocVersion}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pageSubHeader={pageSubHeader}
          />
        }
      >
        <Route
          path={`items/:itemId`}
          element={
            <TemplateQuoteVersionItem
              docId={id}
              pathToDocList={pathToDocList}
              pathToDoc={pathToDoc}
              pathToDocPage={pathToDocVersion}
            />
          }
        />
        {isMinTablet &&
          (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
            <Route index element={<SelectInfoMessageItem />} />
          )}
      </Route>
      <Route
        path={`3d`}
        element={
          <TemplateQuoteThreeDVersion
            docId={id}
            pathToDoc={pathToDoc}
            pathToDocVersion={pathToDocVersion}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pageSubHeader={pageSubHeader}
          />
        }
      />
      <Route
        path={`troubleshoot`}
        element={
          <TemplateQuoteVersionTroubleshoot
            docId={id}
            pathToDocList={pathToDocList}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pageSubHeader={pageSubHeader}
          />
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function useActiveView(pathToDoc: string) {
  const matchIndex = useMatch(`${pathToDoc}/`);
  const matchItem = useMatch(`${pathToDoc}/items/*`);
  const match3d = useMatch(`${pathToDoc}/3d/*`);

  const activeView = matchIndex || matchItem ? "tree" : match3d ? "3d" : null;

  return activeView;
}
