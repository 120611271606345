import { useApolloClient } from "@apollo/client";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes, useMatch, useParams } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { PageNotFound } from "../../../commons/PageNotFound";
import { usePageWidth } from "../../../commons/hooks/usePageWidth";
import { PageTopbarItem } from "../../../commons/layout/Page";
import { SelectInfoMessageItem } from "../../../features/doc-items/items/SelectInfoMessageItem";
import { TemplatesQuoteTemplateEditSettingsModal } from "../../../features/templates/quote/TemplatesQuoteTemplateSettingsModal";
import { TemplatesQuoteTemplateSubHeader } from "../../../features/templates/quote/TemplatesQuoteTemplateSubHeader";
import { TemplateQuoteEdit } from "./TemplateQuoteEdit";
import { TemplateQuoteEditItem } from "./TemplateQuoteEditItem";
import { TemplateQuoteThreeDEdit } from "./TemplateQuoteEditThreeD";
import { TemplateQuoteEditTroubleshoot } from "./TemplateQuoteEditTroubleshoot";
import { useTemplateQuoteRoutesQuery } from "./TemplateQuoteRoutes.generated";
import { UnnestedTemplateQuoteEditItem } from "./UnnestedTemplateQuoteEditItem";

const REFETCH_QUERIES_LIST = [namedOperations.Query.TemplateQuotes];
const REFETCH_QUERIES_PREVIEW = [
  namedOperations.Query.TemplateQuoteEditPreview,
];
const REFETCH_QUERIES_EDIT = [namedOperations.Query.TemplateQuoteEdit];

type PageSubmenuItem = PageTopbarItem;

interface Props {
  submenuItems: PageSubmenuItem[];
}

export const TemplateQuoteRoutes = ({ submenuItems }: Props) => {
  const { id } = useParams();
  if (!id) throw new Error("Template id is missing");

  const { t } = useTranslate("SidebarItems");
  const viewer = useUserData().currentUser!;
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const client = useApolloClient();
  const query = useTemplateQuoteRoutesQuery({
    client,
    variables: { id },
  });

  const doc = query.data?.quoteTemplateLatest;
  const docTitle = doc?.title ?? "";
  const rootItem = doc?.rootItem;
  const isOrganisationOwnTemplate =
    doc?.owningSystemOrganisationId === viewer.organisation.id;
  const canEdit = isOrganisationOwnTemplate;

  const pathToDocList = `/templates/documents`;
  const pathToDoc = `${pathToDocList}/${id}`;
  const pathToDocVersion = `${pathToDoc}/latest`;

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("Templates"),
        to: pathToDocList,
      },
      { title: docTitle, to: pathToDocVersion },
    ],
    [docTitle, pathToDocList, pathToDocVersion, t]
  );

  const activeView = useActiveView(pathToDocVersion);

  const pageSubHeader =
    doc && rootItem ? (
      <TemplatesQuoteTemplateSubHeader
        docId={doc.id}
        doc={doc}
        rootItem={rootItem}
        rootItemId={rootItem.id}
        pathToDocList={pathToDocList}
        pathToDoc={pathToDoc}
        pathToDocVersion={pathToDocVersion}
        onDuplicateRefetchQueries={REFETCH_QUERIES_LIST}
        onUpdateDataRefetchQueries={[
          ...REFETCH_QUERIES_PREVIEW,
          ...REFETCH_QUERIES_EDIT,
        ]}
        activeView={activeView}
        isHeaderVisible={undefined as never}
        setHeaderVisible={undefined as never}
        canEdit={canEdit}
        SettingsModalButton={
          <ModalOpenButton
            Modal={TemplatesQuoteTemplateEditSettingsModal}
            modalProps={{
              templateId: doc.id,
              refetchQueries: [
                namedOperations.Query.TemplateQuoteEdit,
                namedOperations.Query.TemplateQuoteVersion, // TODO: settings should be read-only in version - then this is unnecessary
                namedOperations.Query.TemplateQuoteEditPreview,
                namedOperations.Query.TemplateQuoteVersionPreview, // TODO: settings should be read-only in version - then this is unnecessary
              ],
              canEdit,
              pathToDocList,
            }}
          >
            <Tooltip
              title={
                canEdit
                  ? t("Settings", {
                      ns: "Global",
                    })
                  : t("Info", {
                      ns: "Global",
                    })
              }
            >
              <IconButton size="small" color="primary">
                {canEdit ? <SettingsIcon /> : <InfoOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </ModalOpenButton>
        }
      />
    ) : null;

  return (
    <Routes>
      <Route index element={<Navigate to={`edit`} replace />} />
      <Route
        path={`edit`}
        element={
          <TemplateQuoteEdit
            docId={id}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            pathToDocPage={`${pathToDocVersion}/edit`}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pageSubHeader={pageSubHeader}
          />
        }
      >
        <Route
          path={`items/:itemId`}
          element={
            <TemplateQuoteEditItem
              docId={id}
              pathToDocList={pathToDocList}
              pathToDoc={pathToDoc}
              pathToDocPage={`${pathToDocVersion}/edit`}
            />
          }
        />
        {isMinTablet &&
          (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
            <Route index element={<SelectInfoMessageItem />} />
          )}
      </Route>
      <Route
        path={`3d`}
        element={
          <TemplateQuoteThreeDEdit
            docId={id}
            pathToDoc={pathToDoc}
            pathToDocVersion={pathToDocVersion}
            pathToDocPage={`${pathToDocVersion}/3d`}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pathToDocList={pathToDocList}
            pageSubHeader={pageSubHeader}
          />
        }
      >
        <Route
          path={`items/:itemId`}
          element={
            <UnnestedTemplateQuoteEditItem
              docId={id}
              pathToDocList={pathToDocList}
              pathToDocPage={`${pathToDocVersion}/3d`}
              isAbsolute
            />
          }
        />
      </Route>
      <Route
        path={`troubleshoot`}
        element={
          <TemplateQuoteEditTroubleshoot
            docId={id}
            submenuItems={submenuItems}
            prefixBreadcrumbs={breadcrumbs}
            pageSubHeader={pageSubHeader}
            pathToDocList={pathToDocList}
          />
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function useActiveView(pathToDoc: string) {
  const matchTree = useMatch(`${pathToDoc}/edit/*`);
  const match3d = useMatch(`${pathToDoc}/3d/*`);

  const activeView = matchTree ? "tree" : match3d ? "3d" : null;

  return activeView;
}
