import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Alert, Box, Container } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { pick } from "lodash";
import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  Agreement,
  ItemType,
  ModifyItemProductValuesInput,
  namedOperations,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { useUserData } from "../../auth/useUserData";
import { usePageWidth } from "../../commons/hooks/usePageWidth";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { QUOTE_ITEM_TYPES } from "../../constants";
import { ProfitabilityBox } from "../../features/doc-items/boxes/ProfitabilityBox";
import { isAllowedToPaste } from "../../features/doc-items/constraints";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers";
import { useCreateItem } from "../../features/doc-items/hooks/useCreateItem";
import { useDecisionProcess } from "../../features/doc-items/hooks/useDecisionProcess";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard";
import { DecisionsInBulkProcessRef } from "../../features/doc-items/modals/DecisionsInBulkProcess";
import { DecisionWizardModalWithDefaultActions } from "../../features/doc-items/modals/DecisionWizardModal";
import { ProductSearchItem__ProductSearchResultFragment } from "../../features/products/Product.generated";
import {
  createTreeItem,
  createTreeItemInput,
} from "../../features/projects/quote-trees";
import { QuoteChangeDeclinedAlert } from "../../features/quotes/alerts/QuoteChangeDeclinedAlert";
import { QuoteDeclinedAlert } from "../../features/quotes/alerts/QuoteDeclinedAlert";
import {
  allowHaveChildren,
  shouldRenderCreateInput,
} from "../../features/quotes/helpers";
import { QuoteAction } from "../../features/quotes/QuoteAction";
import { QuoteDecisionsInBulkProcess } from "../../features/quotes/QuoteDecisionsInBulkProcess";
import { QuotePreview } from "../../features/quotes/QuotePreview";
import { QuotePriceSummaryBox } from "../../features/quotes/QuotePriceSummaryBox";
import { useQuoteQuickFilters } from "../../features/quotes/QuoteQuickFilters";
import { QuoteSubHeader } from "../../features/quotes/QuoteSubHeader";
import { QuoteTimeSummaryBox } from "../../features/quotes/QuoteTimeSummaryBox";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../features/templates/quote/TemplatesQuoteSelectMultipleModal.generated";
import { VanMarckeProfitability } from "../../features/van-marcke-profitability/VanMarckeProfitability";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore";
import { TreeViewMode } from "../../trees-virtual/types";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton";
import { DocShareBox } from "../documents/DocShareBox";
import {
  ProjectQuoteEdit_ItemFragment,
  useProjectQuoteEdit_CreateItemsFromTemplatesMutation,
  useProjectQuoteEdit_CreateItemsMutation,
  useProjectQuoteEdit_PasteItemFromClipboardMutation,
  useProjectQuoteEditQuery,
} from "./ProjectQuoteEdit.generated";

const REFETCH_QUERIES = [namedOperations.Query.ProjectQuoteEdit];
const falseFn = () => false;
const nullFn = () => null;

interface Props {
  projectId: string;
  quoteId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectQuoteEdit = ({
  projectId,
  quoteId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const pathToDocList = `/projects/${projectId}/quotes`;
  const pathToDoc = `/projects/${projectId}/quotes/${quoteId}`;
  const pathToDocPage = `${pathToDoc}/edit`;
  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Global", "Quote", "ProjectOverview"]);

  const isClientVisibilityEnabled = useFeature("ClientVisibility");
  const viewerPermissionDetailsEnabled = useFeature("ViewerPermissionDetails");

  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();
  const { isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);

  // const [treeItemsCount, setTreeItemsCount] = React.useState<number>(0);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote",
    quoteId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectQuoteEditQuery({
    client,
    variables: {
      projectId,
      quoteId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.quote)?.quote;
  const docTitle = doc?.title ?? "";
  const sapS4HanaIntegration = getDataOrNull(
    query.data?.sapS4HanaOrganisationIntegration
  )?.organisationIntegration;

  const isAgreed =
    (doc?.isPublished && doc?.isBinding && doc?.agreement === "YES") ?? false;
  const isViewersQuote =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  React.useEffect(() => {
    if (!query.loading && !isViewersQuote) {
      navigate(pathToDoc);
    }
  }, [isViewersQuote, navigate, pathToDoc, query.loading]);

  const enableDragging = !isAgreed;

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<ProjectQuoteEdit_ItemFragment>(
      doc?.agreement === "YES"
    );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(
    function getFilteredItems() {
      if (!doc) return [[], undefined, []];
      const allDocItems = doc.items;
      const rootItem = allDocItems?.find(item => item.isRootItem);
      const treeItems = allDocItems
        .filter(item => item.authorOrganisationId === viewer.organisation.id)
        .filter(item => !item.isAnyParentItemEliminated);
      const filteredItems = quickFilter(treeItems);
      return [filteredItems, rootItem, allDocItems];
    },
    [doc, quickFilter, viewer.organisation.id]
  );

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [navigate, pathToDocPage]
  );

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const viewerPermissions: PermissionName[] = React.useMemo(
    () => quote?.viewerPermissions ?? [],
    [quote?.viewerPermissions]
  );
  const agreement: Agreement | undefined = doc?.agreement;

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const decisionsInBulkProcessRef =
    React.useRef<DecisionsInBulkProcessRef>(null);
  const startDecisionsInBulkProcess = React.useCallback(() => {
    decisionsInBulkProcessRef.current?.startDecisionsInBulkProcess();
  }, []);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: quoteId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
  });

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateQuoteItems({
    projectId,
    quoteId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(() => {
    if (agreement === undefined) return nullFn;
    return createTreeItem({
      pathToDocPage,
      projectId,
      quoteId,
      doc: { viewerPermissions },
      docAgreement: agreement,
      startConfirmProcess,
      navigateToItem,
      expandedItemIds: expandedStore.expandedItemIds,
      setItemExpanded: expandedStore.setItemExpanded,
      pasteItem,
      isAllowedToPasteItem,
      viewerDecisionRole,
    });
  }, [
    agreement,
    pathToDocPage,
    projectId,
    viewerPermissions,
    quoteId,
    startConfirmProcess,
    navigateToItem,
    expandedStore.expandedItemIds,
    expandedStore.setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  ]);

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        viewMode: TreeViewMode.Item,
        quoteId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      quoteId,
    ]
  );

  // redirect to edit route when selected item is filtered out
  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    query.loading,
    navigate,
  ]);

  const filterItem = React.useCallback(
    (i: ProjectQuoteEdit_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  const docSharingEnabled = useFeature("DocSharing");

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <QuoteSubHeader
            project={project}
            quote={doc}
            activeView="tree"
            canEdit={isViewersQuote}
            isReadOnly={isAgreed}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            hasSapS4HanaIntegration={Boolean(sapS4HanaIntegration)}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            expandedItemIds={expandedStore.expandedItemIds}
          />
        ) : undefined
      }
      action={
        project &&
        doc && (
          <QuoteAction
            doc={doc}
            project={project}
            onDiscardPendingChangesRefetchQueries={REFETCH_QUERIES}
            onDeclineChangesRefetchQueries={REFETCH_QUERIES}
            startDecisionWizard={decisionProcess.start}
            startDecisionsInBulkProcess={startDecisionsInBulkProcess}
          />
        )
      }
    >
      <PageColumn
        $hasRightBorder
        $hasBackground
        $display="block"
        ref={isMinTablet ? setTreeContainer : undefined}
      >
        {doc && project && rootItem && (
          <Stack flexDirection={"column"} spacing={2}>
            {doc.decliningReason && (
              <Box>
                <QuoteDeclinedAlert reason={doc.decliningReason} />
              </Box>
            )}

            {doc.changeDecliningReason && (
              <Box>
                <QuoteChangeDeclinedAlert
                  projectId={projectId}
                  quoteId={quoteId}
                  itemId={rootItem!.id}
                  reason={doc.changeDecliningReason}
                  refetchQueries={REFETCH_QUERIES}
                />
              </Box>
            )}

            {doc.incomingRequest &&
              doc.incomingRequest.status !== "PUBLISHED" &&
              doc.incomingRequest.owningSystemOrganisationId !==
                viewer.organisation.id &&
              !doc.isPublished && (
                <Box>
                  <Alert variant="outlined" severity="warning">
                    {t("This opportunity is no longer accepting new quotes", {
                      ns: "ProjectOverview",
                    })}
                  </Alert>
                </Box>
              )}

            <Stack width="100%" alignItems="center" spacing={1}>
              <TreeToggleAllExpandedButton
                areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                setAllItemsExpanded={expandedStore.setAllItemsExpanded}
              />
              <QuickFiltersTabs />
            </Stack>

            <Box>
              <VirtualBareTreeStandaloneItem
                item={rootItem}
                items={filteredTreeItems}
                allItems={allDocItems}
                depth={0}
                itemComponent={TreeItem}
                selected={isRootSelected}
                sticky={isRootSelected}
                top={0}
                clickable
                onClick={() => navigateToItem(rootItem.id)}
                to={`${pathToDocPage}/items/${rootItem.id}`}
              />

              {/* <TreeConnector $isAtTheTopOfTree={treeItemsCount > 0} /> */}
              <VirtualItemTree<
                ProjectQuoteEdit_ItemFragment,
                typeof enableDragging
              >
                docId={enableDragging ? doc.id : null}
                projectId={enableDragging ? projectId : null}
                items={filteredTreeItems}
                allItems={allDocItems}
                filterItem={
                  isClientVisibilityEnabled || !isViewersQuote
                    ? filterItem
                    : undefined
                }
                selectedItemId={selectedItemId}
                enableCreating={true}
                enableDragging={enableDragging}
                documentItemTypes={QUOTE_ITEM_TYPES}
                container={treeContainer}
                itemComponent={TreeItem}
                inputComponent={isViewersQuote ? TreeItemInput : nullFn}
                allowHaveChildren={isViewersQuote ? allowHaveChildren : falseFn}
                shouldRenderCreateInput={
                  isViewersQuote ? shouldRenderCreateInput : falseFn
                }
                expandedStore={expandedStore}
                // onItemsCountChange={setTreeItemsCount}
              />
            </Box>

            <DecisionWizardModalWithDefaultActions
              processState={decisionProcess.state}
              projectId={projectId}
              docType="QUOTE"
              docId={quoteId}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedStore.expandedItemIds}
              handleClose={decisionProcess.close}
              handleNextItem={decisionProcess.next}
              handlePreviousItem={decisionProcess.previous}
              decisionContext={decisionProcess.decisionContext}
            />

            <QuoteDecisionsInBulkProcess
              ref={decisionsInBulkProcessRef}
              quoteId={doc.id}
              projectId={projectId}
              canFinalize={true}
              externalExpandedItemIds={expandedStore.expandedItemIds}
            />

            {doc.proposedCalculation && (
              <QuoteTimeSummaryBox
                key={`quote_time_summary-${isAgreed}`}
                calculated={doc.proposedCalculation}
              />
            )}

            {doc.proposedCalculation && (
              <QuotePriceSummaryBox
                key={`quote_price_summary-${isAgreed}`}
                isManualSave={isAgreed}
                quoteId={quoteId}
                quote={doc}
                project={project}
                discountPercentage={doc.discountPercentage}
                calculated={doc.proposedCalculation}
              />
            )}

            {doc.proposedCalculation && (
              <ProfitabilityBox calculated={doc.proposedCalculation} />
            )}

            <VanMarckeProfitability projectId={projectId} docId={quoteId} />
          </Stack>
        )}
      </PageColumn>
      <ConfirmProcess ref={confirmProcessRef} />
      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />
      {project && doc && isMinOneColumnWithPreview && (
        <PageContainer
          $fullHeight
          onClick={() => {
            if (!isMinTwoColumnsWithPreview)
              navigate(pathToDocPage, { replace: true });
          }}
          ref={setPreviewContainer}
        >
          <Container maxWidth="md">
            {viewerPermissionDetailsEnabled ? (
              <div>
                {" "}
                <h2>viewer permission details</h2>
                <hr />
                <textarea
                  style={{ width: "100%", height: 50 }}
                  readOnly
                  defaultValue={JSON.stringify(doc.viewerPermissions, null, 2)}
                ></textarea>
              </div>
            ) : null}
            {docSharingEnabled ? (
              <DocShareBox
                docType="QUOTE"
                docId={doc.id}
                projectId={project.id}
              />
            ) : null}

            <QuotePreview
              navigateToItem={({ id }) => navigateToItem(id)}
              selectedItemId={selectedItemId}
              quote={doc}
              canFinalize={true}
              container={previewContainer}
              expandedStore={expandedStore}
              expandedItemIds={expandedStore.expandedItemIds}
              hasSapS4HanaIntegration={Boolean(sapS4HanaIntegration)}
            />
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

function useCreateQuoteItems({
  projectId,
  quoteId,
  expandedItemIds,
}: {
  projectId: string;
  quoteId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useProjectQuoteEdit_CreateItemsMutation({ client });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useProjectQuoteEdit_CreateItemsFromTemplatesMutation({
      client,
    });

  const createItemFromTemplate = React.useCallback(
    async (title: string, parentItemId: string, templateQuoteId: string) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: [
              {
                templateQuoteId,
              },
            ],
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsFromTemplates = React.useCallback(
    async (
      parentItemId: string,
      templates: {
        quantity: number;
        template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
      }[]
    ) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: templates.map(tpl => ({
              templateQuoteId: tpl.template.id,
              templateEstimatedQuantity: tpl.quantity,
            })),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsWithProducts = React.useCallback(
    async (
      parentItemId: string,
      products: {
        quantity: number;
        product:
          | ProductSearchItem__ProductSearchResultFragment
          | ModifyItemProductValuesInput;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItems.items;
    },
    [createItems, expandedItemIds, projectId, quoteId]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useProjectQuoteEdit_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: projectId,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, projectId, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: QUOTE_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}
