/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectSupplierAndDeliveryModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type SelectSupplierAndDeliveryModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, building?: { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null } | null }, viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } } | null };

export type SupplierPickupAddressesQueryVariables = Types.Exact<{
  supplierId: Types.Scalars['ID']['input'];
}>;


export type SupplierPickupAddressesQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, title: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null } | { __typename: 'MissingPermissions' } };


export const SelectSupplierAndDeliveryModalDocument = gql`
    query SelectSupplierAndDeliveryModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        building {
          id
          title
          buildingAddress {
            ...AddressDetails__Address
          }
        }
      }
    }
  }
  viewer {
    id
    organisation {
      id
      branchAddress {
        ...AddressDetails__Address
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;

/**
 * __useSelectSupplierAndDeliveryModalQuery__
 *
 * To run a query within a React component, call `useSelectSupplierAndDeliveryModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectSupplierAndDeliveryModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectSupplierAndDeliveryModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSelectSupplierAndDeliveryModalQuery(baseOptions: Apollo.QueryHookOptions<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables> & ({ variables: SelectSupplierAndDeliveryModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>(SelectSupplierAndDeliveryModalDocument, options);
      }
export function useSelectSupplierAndDeliveryModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>(SelectSupplierAndDeliveryModalDocument, options);
        }
export function useSelectSupplierAndDeliveryModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>(SelectSupplierAndDeliveryModalDocument, options);
        }
export type SelectSupplierAndDeliveryModalQueryHookResult = ReturnType<typeof useSelectSupplierAndDeliveryModalQuery>;
export type SelectSupplierAndDeliveryModalLazyQueryHookResult = ReturnType<typeof useSelectSupplierAndDeliveryModalLazyQuery>;
export type SelectSupplierAndDeliveryModalSuspenseQueryHookResult = ReturnType<typeof useSelectSupplierAndDeliveryModalSuspenseQuery>;
export type SelectSupplierAndDeliveryModalQueryResult = Apollo.QueryResult<SelectSupplierAndDeliveryModalQuery, SelectSupplierAndDeliveryModalQueryVariables>;
export const SupplierPickupAddressesDocument = gql`
    query SupplierPickupAddresses($supplierId: ID!) {
  crmCompany(id: $supplierId) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        title
        branchAddress {
          ...AddressDetails__Address
        }
        pickupAddress {
          ...AddressDetails__Address
        }
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;

/**
 * __useSupplierPickupAddressesQuery__
 *
 * To run a query within a React component, call `useSupplierPickupAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPickupAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPickupAddressesQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useSupplierPickupAddressesQuery(baseOptions: Apollo.QueryHookOptions<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables> & ({ variables: SupplierPickupAddressesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>(SupplierPickupAddressesDocument, options);
      }
export function useSupplierPickupAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>(SupplierPickupAddressesDocument, options);
        }
export function useSupplierPickupAddressesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>(SupplierPickupAddressesDocument, options);
        }
export type SupplierPickupAddressesQueryHookResult = ReturnType<typeof useSupplierPickupAddressesQuery>;
export type SupplierPickupAddressesLazyQueryHookResult = ReturnType<typeof useSupplierPickupAddressesLazyQuery>;
export type SupplierPickupAddressesSuspenseQueryHookResult = ReturnType<typeof useSupplierPickupAddressesSuspenseQuery>;
export type SupplierPickupAddressesQueryResult = Apollo.QueryResult<SupplierPickupAddressesQuery, SupplierPickupAddressesQueryVariables>;