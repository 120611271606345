/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../../features/addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardPersonalSchedule_PlanSessionFragment = { __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, project: { __typename: 'Project', id: string, title: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string } | null } };

export type DashboardPersonalScheduleQueryVariables = Types.Exact<{
  rangeStart?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  rangeEnd?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  userId: Types.Scalars['ID']['input'];
}>;


export type DashboardPersonalScheduleQuery = { planSessions: { __typename: 'MissingCapabilities' } | { __typename: 'PlanSessionConnection', edges: Array<{ __typename: 'PlanSessionEdge', node: { __typename: 'PlanSession', id: string, from: any, till: any, isTentative: boolean, project: { __typename: 'Project', id: string, title: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string } | null } } }> }, organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null } };

export const DashboardPersonalSchedule_PlanSessionFragmentDoc = gql`
    fragment DashboardPersonalSchedule_PlanSession on PlanSession {
  id
  from
  till
  isTentative
  project {
    id
    title
    building {
      id
      buildingAddress {
        ...AddressDetails__Address
      }
    }
    crmOrganisation {
      id
      title
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const DashboardPersonalScheduleDocument = gql`
    query DashboardPersonalSchedule($rangeStart: DateTime, $rangeEnd: DateTime, $userId: ID!) {
  planSessions(
    limit: 0
    filters: {rangeStart: $rangeStart, rangeEnd: $rangeEnd, whoIds: [$userId]}
  ) {
    ... on PlanSessionConnection {
      edges {
        node {
          id
          ...DashboardPersonalSchedule_PlanSession
        }
      }
    }
  }
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultStartWorkDay
      defaultEndWorkDay
    }
  }
}
    ${DashboardPersonalSchedule_PlanSessionFragmentDoc}`;

/**
 * __useDashboardPersonalScheduleQuery__
 *
 * To run a query within a React component, call `useDashboardPersonalScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPersonalScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPersonalScheduleQuery({
 *   variables: {
 *      rangeStart: // value for 'rangeStart'
 *      rangeEnd: // value for 'rangeEnd'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardPersonalScheduleQuery(baseOptions: Apollo.QueryHookOptions<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables> & ({ variables: DashboardPersonalScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>(DashboardPersonalScheduleDocument, options);
      }
export function useDashboardPersonalScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>(DashboardPersonalScheduleDocument, options);
        }
export function useDashboardPersonalScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>(DashboardPersonalScheduleDocument, options);
        }
export type DashboardPersonalScheduleQueryHookResult = ReturnType<typeof useDashboardPersonalScheduleQuery>;
export type DashboardPersonalScheduleLazyQueryHookResult = ReturnType<typeof useDashboardPersonalScheduleLazyQuery>;
export type DashboardPersonalScheduleSuspenseQueryHookResult = ReturnType<typeof useDashboardPersonalScheduleSuspenseQuery>;
export type DashboardPersonalScheduleQueryResult = Apollo.QueryResult<DashboardPersonalScheduleQuery, DashboardPersonalScheduleQueryVariables>;