/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationVariables = Types.Exact<{
  input: Types.CopySupplierOrganisationAsCrmInput;
}>;


export type CopySupplierOrganisationAsCrm__NewSupplierMutation = { copySupplierOrganisationAsCrm: { __typename: 'CopySupplierOrganisationAsCrmResult', crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type CrmCreateModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CrmCreateModalQuery = { sapS4HanaOrganisationIntegration: { __typename: 'MissingCapabilities' } | { __typename: 'OrganisationIntegrationResult', organisationIntegration?: { __typename: 'OrganisationIntegration', id: string } | null } };

export type SapCreateBusinessPartnerMutationVariables = Types.Exact<{
  input: Types.SapCreateBusinessPartnerInput;
}>;


export type SapCreateBusinessPartnerMutation = { sapCreateBusinessPartner: { __typename: 'SapCreateBusinessPartnerResult', fioriLaunchPadUrl: string } };


export const CopySupplierOrganisationAsCrm__NewSupplierDocument = gql`
    mutation CopySupplierOrganisationAsCrm__NewSupplier($input: CopySupplierOrganisationAsCrmInput!) {
  copySupplierOrganisationAsCrm(input: $input) {
    crmOrganisation {
      id
      title
      email
      abcCategory
      contactPerson {
        id
        fullname
      }
      billingAddress {
        ...AddressDetails__Address
      }
      logo {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationFn = Apollo.MutationFunction<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;

/**
 * __useCopySupplierOrganisationAsCrm__NewSupplierMutation__
 *
 * To run a mutation, you first call `useCopySupplierOrganisationAsCrm__NewSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySupplierOrganisationAsCrm__NewSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySupplierOrganisationAsCrmNewSupplierMutation, { data, loading, error }] = useCopySupplierOrganisationAsCrm__NewSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySupplierOrganisationAsCrm__NewSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>(CopySupplierOrganisationAsCrm__NewSupplierDocument, options);
      }
export type CopySupplierOrganisationAsCrm__NewSupplierMutationHookResult = ReturnType<typeof useCopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationResult = Apollo.MutationResult<CopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationOptions = Apollo.BaseMutationOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;
export const CrmCreateModalDocument = gql`
    query CrmCreateModal {
  sapS4HanaOrganisationIntegration: organisationIntegration(
    integrationId: sap_s4hana
  ) {
    ... on OrganisationIntegrationResult {
      organisationIntegration {
        id
      }
    }
  }
}
    `;

/**
 * __useCrmCreateModalQuery__
 *
 * To run a query within a React component, call `useCrmCreateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCreateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCreateModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrmCreateModalQuery(baseOptions?: Apollo.QueryHookOptions<CrmCreateModalQuery, CrmCreateModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCreateModalQuery, CrmCreateModalQueryVariables>(CrmCreateModalDocument, options);
      }
export function useCrmCreateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCreateModalQuery, CrmCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCreateModalQuery, CrmCreateModalQueryVariables>(CrmCreateModalDocument, options);
        }
export function useCrmCreateModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCreateModalQuery, CrmCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCreateModalQuery, CrmCreateModalQueryVariables>(CrmCreateModalDocument, options);
        }
export type CrmCreateModalQueryHookResult = ReturnType<typeof useCrmCreateModalQuery>;
export type CrmCreateModalLazyQueryHookResult = ReturnType<typeof useCrmCreateModalLazyQuery>;
export type CrmCreateModalSuspenseQueryHookResult = ReturnType<typeof useCrmCreateModalSuspenseQuery>;
export type CrmCreateModalQueryResult = Apollo.QueryResult<CrmCreateModalQuery, CrmCreateModalQueryVariables>;
export const SapCreateBusinessPartnerDocument = gql`
    mutation SapCreateBusinessPartner($input: SapCreateBusinessPartnerInput!) {
  sapCreateBusinessPartner(input: $input) {
    fioriLaunchPadUrl
  }
}
    `;
export type SapCreateBusinessPartnerMutationFn = Apollo.MutationFunction<SapCreateBusinessPartnerMutation, SapCreateBusinessPartnerMutationVariables>;

/**
 * __useSapCreateBusinessPartnerMutation__
 *
 * To run a mutation, you first call `useSapCreateBusinessPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSapCreateBusinessPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sapCreateBusinessPartnerMutation, { data, loading, error }] = useSapCreateBusinessPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapCreateBusinessPartnerMutation(baseOptions?: Apollo.MutationHookOptions<SapCreateBusinessPartnerMutation, SapCreateBusinessPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SapCreateBusinessPartnerMutation, SapCreateBusinessPartnerMutationVariables>(SapCreateBusinessPartnerDocument, options);
      }
export type SapCreateBusinessPartnerMutationHookResult = ReturnType<typeof useSapCreateBusinessPartnerMutation>;
export type SapCreateBusinessPartnerMutationResult = Apollo.MutationResult<SapCreateBusinessPartnerMutation>;
export type SapCreateBusinessPartnerMutationOptions = Apollo.BaseMutationOptions<SapCreateBusinessPartnerMutation, SapCreateBusinessPartnerMutationVariables>;