import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, {
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import SortableTree, {
  ExtendedNodeData,
  getVisibleNodeCount,
  TreeItem,
} from "react-sortable-tree";
import { useLatest, useUpdateEffect } from "react-use";
import { WindowScroller } from "react-virtualized";
import { LocalDndProvider } from "../../common/dnd";
import { useScrollZone } from "../commons/hooks/useScrollZone";
import { TREE_SCAFFOLD_PX_WIDTH } from "../constants";
import { browserHasOnlyTouch } from "../featureDetection";
import { getDefaultCreatableSubItemTypes } from "../features/doc-items/constraints";
import { color, size } from "../../common/MuiThemeProvider";
import { ItemType } from "../../clients/graphqlTypes";
import { transientOptions } from "../styles";
import { InputComponent, ItemComponent } from "../trees/types";
import { VirtualTableNodeRenderer } from "./components/VirtualTableNodeRenderer";
import {
  getLastSelectedNode,
  getNonLeafChildrenIdsRecurively,
  getSyntheticRootItem,
  getTreeFromItems,
  isNodePlacedAfterInput,
  ROOT_ITEM_ID,
} from "./helpers";
import { ALL_KEY, ExpandedStore } from "./hooks/useExpandedStore";
import { useMoveTreeItem } from "./hooks/useMoveTreeItem";
import {
  VirtualItem,
  VirtualItemTreeRef,
  VirtualTreeTableContext,
} from "./types";

const DEFAULT_STYLE = { height: "unset" };

interface Props<T extends VirtualItem, EnableDragging extends boolean> {
  minWidth?: number;

  docId: EnableDragging extends true ? string : null;
  projectId: EnableDragging extends true ? string | null : null;

  items: T[];
  allItems?: T[];
  filterItem?: (item: T) => boolean;

  // virtual synthetic root item or root item id
  rootItem?: T;
  rootItemId?: string;

  rootItemComponent?: ItemComponent<T>;
  itemComponent: ItemComponent<T>;
  inputComponent?: InputComponent<T, T>;

  selectedItemId: string | null;

  enableCreating: boolean;
  enableDragging: EnableDragging;

  allowHaveChildren?: (item: T) => boolean;

  shouldRenderCreateInput?: (item: T) => boolean;

  documentItemTypes: ItemType[];

  container?: HTMLElement | null;

  isVirtualized?: boolean;

  onItemsCountChange?: (itemsCount: number) => void;

  HeaderRow?: JSX.Element | null;
  EmptyRow?: JSX.Element | null;

  headerTitle?: string | null;

  expandedStore: ExpandedStore;
  // isItemExpanded: (itemId: string, defaultValue?: boolean) => boolean;
  // setItemExpanded: (itemId: string, expanded: boolean) => void;
  // setExpandedState: (state: { [key: string]: boolean }) => void;
}

export const VirtualTableTree = React.memo(
  React.forwardRef(VirtualTableTreeComponent)
);

function VirtualTableTreeComponent<
  T extends VirtualItem,
  EnableDragging extends boolean,
>(
  {
    docId,
    projectId,
    items,
    allItems,
    filterItem,
    rootItem: syntheticRootItem,
    rootItemId: syntheticRootItemId,
    selectedItemId,
    rootItemComponent,
    itemComponent,
    inputComponent,
    enableCreating,
    enableDragging,
    allowHaveChildren,
    shouldRenderCreateInput,
    documentItemTypes,
    container,
    isVirtualized = true,
    onItemsCountChange,
    minWidth = 1400,
    headerTitle,
    HeaderRow,
    EmptyRow,
    expandedStore,
  }: // isItemExpanded,
  // setItemExpanded,
  // setExpandedState,
  Props<T, EnableDragging>,
  forwardedRef: Ref<VirtualItemTreeRef>
) {
  const [scrollbarHeight, setScrollbarHeight] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerWidthLatest = useLatest(containerWidth);

  const selectedItem = useMemo<T | null>(
    () => items.find(i => i.id === selectedItemId) ?? null,
    [items, selectedItemId]
  );

  const [handleMoveTreeItem, moveTreeItemLoading] = useMoveTreeItem({
    projectId,
    docId,
  });

  // @ts-ignore synthetic root item support
  const rootItem: T | undefined = useMemo(() => {
    if (syntheticRootItem) return syntheticRootItem;
    if (syntheticRootItemId)
      return getSyntheticRootItem("Item", syntheticRootItemId);
    return items.find(item => item.isRootItem);
  }, [items, syntheticRootItem, syntheticRootItemId]);

  const getTree = () => {
    return getTreeFromItems(
      items,
      rootItem,
      selectedItem,
      filterItem,
      enableCreating,
      expandedStore.isItemExpanded,
      documentItemTypes,
      shouldRenderCreateInput
    );
  };

  const updateTree = () => {
    const treeData = getTree();
    setQuoteItems(treeData);
    return treeData;
  };

  const [quoteItems, setQuoteItems] = useState(getTree());

  const collapseItemAndChildrenRecursively = React.useCallback(
    (node: TreeItem) => {
      const toCollapseIds = getNonLeafChildrenIdsRecurively(node);
      const toExpandItemIds = expandedStore.areAllItemsExpanded
        ? items
            .filter(
              item => item.hasChildren && !toCollapseIds.includes(item.id)
            )
            .map(item => item.id)
        : [];
      expandedStore.setExpandedState(
        Object.fromEntries([
          [ALL_KEY, false],
          ...toExpandItemIds.map(id => [id, true]),
          ...toCollapseIds.map(id => [id, false]),
        ])
      );
    },
    [expandedStore, items]
  );

  useUpdateEffect(() => {
    updateTree();
  }, [items, filterItem, rootItem?.id, selectedItem?.id]);

  const treeItemsCount = useMemo(
    () => getVisibleNodeCount({ treeData: quoteItems }),
    [quoteItems]
  );
  const [, lastSelectedItemId] = useMemo(
    () => getLastSelectedNode(quoteItems, items, selectedItem),
    [quoteItems, items, selectedItem]
  );

  const onItemsCountChangeLatest = useLatest(onItemsCountChange);

  useUpdateEffect(() => {
    onItemsCountChangeLatest.current?.(treeItemsCount);
  }, [treeItemsCount, onItemsCountChangeLatest]);

  const windowScrollerRef = useRef<any>(null);

  useImperativeHandle(forwardedRef, () => ({
    // isItemExpanded: expandedStore.isItemExpanded,
    // setItemExpanded: expandedStore.setItemExpanded,
    // areAllItemsExpanded: expandedStore.areAllItemsExpanded,
    // setAllItemsExpanded: expandedStore.setAllItemsExpanded,
    // getAllExpandedItemIds: expandedStore.getAllExpandedItemIds,
    updateTree,
    updatePosition: () => windowScrollerRef.current?.updatePosition(),
    treeItemsCount,
  }));

  const generateNodeProps = useCallback(
    ({ node }: ExtendedNodeData): VirtualTreeTableContext<T> => {
      return {
        selectedItemId: selectedItem?.id ?? null,
        lastSelectedItemId,
        rootItem,
        items,
        allItems,
        rootItemComponent,
        itemComponent,
        inputComponent,
        EmptyRow,
        HeaderRow,
        headerTitle,
      };
    },
    [
      selectedItem?.id,
      lastSelectedItemId,
      rootItem,
      items,
      allItems,
      rootItemComponent,
      itemComponent,
      inputComponent,
      EmptyRow,
      HeaderRow,
      headerTitle,
    ]
  );

  const shouldRenderHeaderRow = !!(headerTitle || HeaderRow);

  const dragging = useRef<boolean>(false);
  const draggingHeight = useRef<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const virtualizedListRef = useRef<any>(null);

  const measureScrollContainer = () => {
    if (!scrollContainerRef.current) return;
    const { width } = scrollContainerRef.current.getBoundingClientRect() ?? {};
    if (width && containerWidth !== width) {
      setContainerWidth(width);
    }
    const currentScrollbarHeight =
      (scrollContainerRef.current.offsetHeight ?? 0) -
      (scrollContainerRef.current.clientHeight ?? 0);

    if (currentScrollbarHeight !== scrollbarHeight)
      setScrollbarHeight(currentScrollbarHeight);
  };

  const { startDrag, endDrag } = useScrollZone();

  return (
    <LocalDndProvider>
      <div>
        <ScrollSync>
          {/*@ts-ignore render return type is incompatible*/}
          <WindowScroller
            ref={windowScrollerRef}
            scrollElement={isVirtualized ? container ?? undefined : undefined}
            onResize={() => {
              measureScrollContainer();
            }}
          >
            {({ height, isScrolling, onChildScroll, scrollTop }) => {
              const showFixedHeaderRow = scrollTop > 9; // 8px top padding + 1px border
              return (
                <>
                  {shouldRenderHeaderRow && showFixedHeaderRow && (
                    <ScrollSyncPane group="horizontal">
                      <HeaderRowFixedWrapper $top={-1} $width={containerWidth}>
                        <HeaderRowWrapper $minWidth={minWidth}>
                          <Typography>{headerTitle}</Typography>
                          {HeaderRow}
                        </HeaderRowWrapper>
                      </HeaderRowFixedWrapper>
                    </ScrollSyncPane>
                  )}
                  <ScrollSyncPane group="horizontal">
                    <div>
                      <ScrollContainer
                        ref={scrollContainerRef}
                        $itemsCount={
                          treeItemsCount + (shouldRenderHeaderRow ? 1 : 0)
                        }
                        $scrollbarHeight={scrollbarHeight}
                      >
                        {shouldRenderHeaderRow && (
                          <HeaderRowWrapper $minWidth={minWidth}>
                            <Typography>{headerTitle}</Typography>
                            {HeaderRow}
                          </HeaderRowWrapper>
                        )}
                        <StyledSortableTree
                          $minWidth={minWidth}
                          style={DEFAULT_STYLE}
                          key={`tree-table-${containerWidth}`}
                          isVirtualized={isVirtualized}
                          generateNodeProps={generateNodeProps}
                          reactVirtualizedListProps={{
                            autoHeight: true,
                            height,
                            isScrolling,
                            scrollTop,
                            onScroll: onChildScroll,
                            overscanRowCount: 20,
                            overscanIndicesGetter: ({
                              cellCount,
                              overscanCellsCount,
                              startIndex,
                              stopIndex,
                            }) => {
                              return {
                                overscanStartIndex: Math.max(
                                  0,
                                  startIndex - overscanCellsCount
                                ),
                                overscanStopIndex: Math.min(
                                  cellCount - 1,
                                  stopIndex + overscanCellsCount
                                ),
                              };
                            },
                            ref: virtualizedListRef,
                          }}
                          scaffoldBlockPxWidth={TREE_SCAFFOLD_PX_WIDTH}
                          rowHeight={size.tableItemFullHeight}
                          canDrag={({ node }) =>
                            !moveTreeItemLoading &&
                            enableDragging &&
                            !node.isInput
                          }
                          canDrop={({
                            node,
                            nextParent,
                            nextPath,
                            prevPath,
                          }) => {
                            if (moveTreeItemLoading) return false;
                            if (
                              dragging.current &&
                              scrollContainerRef.current?.style
                            ) {
                              // calculate new height during dragging
                              const rowCount =
                                virtualizedListRef.current?.props?.rowCount;
                              const newHeight = getTreeHeight(rowCount);
                              if (draggingHeight.current !== newHeight) {
                                draggingHeight.current = newHeight;
                                scrollContainerRef.current.style.height = `${newHeight}px`;
                              }
                            }

                            const nextParentItem = (nextParent?.item ||
                              rootItem) as T;

                            if (
                              "linkedQuoteTemplate" in nextParentItem &&
                              Boolean(nextParentItem.linkedQuoteTemplate)
                            ) {
                              return false;
                            }

                            if (
                              !getDefaultCreatableSubItemTypes(
                                nextParentItem.type,
                                documentItemTypes
                              ).includes(node.type)
                            ) {
                              return false;
                            }
                            if (nextParent?.isInput) {
                              return false;
                            }
                            if (
                              syntheticRootItemId === ROOT_ITEM_ID &&
                              (nextParent?.id === syntheticRootItemId ||
                                nextParent?.id === syntheticRootItem?.id)
                            ) {
                              return false;
                            }
                            if (
                              isNodePlacedAfterInput(
                                quoteItems,
                                node,
                                nextPath,
                                prevPath
                              )
                            ) {
                              return false;
                            }
                            return true;
                          }}
                          canNodeHaveChildren={node =>
                            !node.isInput &&
                            allowHaveChildren?.(node.item as T) !== false
                          }
                          treeData={quoteItems}
                          onChange={setQuoteItems}
                          onMoveNode={async ({
                            node,
                            treeData,
                            nextParentNode,
                          }) => {
                            const parentId = (nextParentNode?.id ??
                              rootItem?.id) as string;
                            const childrenIds = (
                              (nextParentNode?.children as TreeItem[]) ??
                              treeData
                            )
                              .filter(node => !node.isInput)
                              .map(node => node.id);
                            const rank =
                              childrenIds.findIndex(id => id === node.id) + 1;
                            await handleMoveTreeItem(node.id, parentId, rank);
                          }}
                          onVisibilityToggle={({ node, expanded }) => {
                            if (expanded === true) {
                              expandedStore.setItemExpanded(node.id, expanded);
                            } else {
                              collapseItemAndChildrenRecursively(node);
                            }
                          }}
                          onDragStateChanged={({ isDragging }) => {
                            if (browserHasOnlyTouch) {
                              if (isDragging) {
                                startDrag();
                              } else {
                                endDrag();
                              }
                            }
                            dragging.current = isDragging;
                            draggingHeight.current = isDragging
                              ? getTreeHeight(treeItemsCount)
                              : 0;
                            if (
                              !isDragging &&
                              scrollContainerRef.current?.style
                            )
                              scrollContainerRef.current.style.height = "";
                          }}
                          // @ts-ignore
                          nodeContentRenderer={VirtualTableNodeRenderer}
                        />
                      </ScrollContainer>
                    </div>
                  </ScrollSyncPane>
                </>
              );
            }}
          </WindowScroller>
        </ScrollSync>
      </div>
    </LocalDndProvider>
  );
}

const getTreeHeight = (itemsCount: number) =>
  size.tableItemFullHeight * itemsCount;

const ScrollContainer = styled(
  "div",
  transientOptions
)<{
  $scrollbarHeight: number;
  $itemsCount: number;
}>(
  ({ $itemsCount, $scrollbarHeight }) => `
  box-sizing: content-box;
  position: relative;
  height: ${getTreeHeight($itemsCount) + $scrollbarHeight}px;

  // hiding scrollbar
  // &::-webkit-scrollbar {
  //   display: none;
  //   width: 0 !important;
  // }
  // & {
  //   scrollbar-width: none;
  //   -ms-overflow-style: none;
  // }
  
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-gutter: stable;
`
);

const HeaderRowFixedWrapper = styled(
  "div",
  transientOptions
)<{ $width: number; $top: number }>(
  ({ $width, $top }) => `
  box-sizing: border-box;
  position: sticky;
  height: ${size.tableItemFullHeight}px;
  z-index: 20;

  width: ${$width}px;
  top: ${$top}px;

  overflow-y: visible;
  overflow-x: auto;

  // hiding scrollbar
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`
);

const HeaderRowWrapper = styled(
  "div",
  transientOptions
)<{
  $minWidth: number;
  $overflow?: string;
}>(
  ({ theme, $minWidth }) => `
  height: ${size.tableItemFullHeight}px;
  box-shadow: inset 0 -1px 0 0 ${theme.palette.grey[300]},
    inset 0 1px 0 0 ${theme.palette.grey[300]};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 38px;
  padding-right: 6px;
  box-sizing: border-box;

  min-width: ${$minWidth}px;
  
  background-color: ${color.background};
`
);

const StyledSortableTree = styled(
  SortableTree,
  transientOptions
)<{
  $minWidth: number;
}>(
  ({ theme, $minWidth }) => `
  & {
    height: unset;
    margin-left: -${TREE_SCAFFOLD_PX_WIDTH}px;
    min-width: ${$minWidth + TREE_SCAFFOLD_PX_WIDTH}px;
  }

  &.rst__tree {
    flex-grow: 1;
    min-height: 0;
  }

  /**
   * Extra class applied to VirtualScroll through className prop
   */
  .rst__virtualScrollOverride {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid {
    outline: none;
  }

  .rst__node {
    min-width: 100%;
    white-space: nowrap;
    text-align: left;
    position: relative;
    // border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .rst__nodeContent {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
`
);
