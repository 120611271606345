import { Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { CollapseSection } from "../CollapseSection";

interface Props {
  label: string;
  value?: any[];
  initiallyExpanded?: boolean;
  notSetText: string;
  closedText: string;
}

export const LabeledOpeningHoursValue: FC<Props> = ({
  label,
  value,
  initiallyExpanded = true,
  notSetText,
  closedText,
}) => {
  return (
    <CollapseSection title={label} isInitiallyExpanded={initiallyExpanded}>
      {!value || value.length === 0 || !value.some(v => v?.isOpen) ? (
        <Typography color="gray">{notSetText}</Typography>
      ) : (
        Array(7)
          .fill(null)
          .map((v, index) => {
            const isoDayIndex = moment().weekday(index).isoWeekday() - 1;
            const isOpen =
              value[isoDayIndex]?.isOpen &&
              value[isoDayIndex]?.from &&
              value[isoDayIndex]?.to;
            return (
              <Stack
                key={index}
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Typography>
                  {moment().weekday(index).format("dddd")}
                </Typography>
                <Typography color={!isOpen ? "gray" : undefined}>
                  {!isOpen
                    ? closedText
                    : `${value[isoDayIndex]?.from}–${value[isoDayIndex]?.to}`}
                </Typography>
              </Stack>
            );
          })
      )}
    </CollapseSection>
  );
};
