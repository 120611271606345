import { MenuButton } from "@msys/ui";
import { Divider, IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AskWhom, ItemType } from "../../../clients/graphqlTypes";
import { ReactComponent as FileMoveIcon } from "../../assets/icons/icon-file-move.svg";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { ViewModeEditMenuItem } from "../../commons/ViewModeEditMenuItem";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import {
  itemCanBeReplacedWithTemplates,
  itemCanHaveVerticalSelection,
} from "../doc-items/app-conditions/actions";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton";
import { rootItemsAllowedConstraints } from "../doc-items/constraints";
import { PropertiesDebugMenuItem } from "../doc-items/debug/PropertiesDebug";
import { RunAttributeExpressionsMenuItem } from "../doc-items/debug/RunAttributeExpressionsMenuItem";
import { hasAnyQuestions } from "../doc-items/helpers";
import { DocItemContextMenuItems } from "../doc-items/menus/DocItemContextMenuItems";
import { ItemVerticalMenuItem } from "../doc-items/menus/ItemVerticalMenuItem";
import { OutsourceMenuItem } from "../doc-items/menus/OutsourceMenuItem";
import { ProjectBuildingPlacementMenuItem } from "../doc-items/menus/ProjectBuildingPlacementMenuItem";
import { ReplaceWithTemplatesMenuItem } from "../doc-items/menus/ReplaceWithTemplatesMenuItem";
import { ExportAsXiopdMenuItem } from "../importExport/xiopdExport/ExportAsXiopdMenuItem";
import { QuoteTemplateCreateFromItemButton } from "../templates/quote/buttons/QuoteTemplateCreateFromItemMenuItem";
import {
  QuoteItemActions_ProjectFragment,
  QuoteItemActions_QuoteFragment,
  QuoteItemActions_QuoteItemFragment,
} from "./QuoteItemActions.generated";
import { QuoteItemMenuItems } from "./QuoteItemMenuItems";
import { getSubcontractPath } from "./helpers";

interface Props {
  canEdit: boolean;
  isManualSave: boolean;
  project: QuoteItemActions_ProjectFragment;
  quote: QuoteItemActions_QuoteFragment;
  item: QuoteItemActions_QuoteItemFragment;
  pathToDocList: string;
  pathToDocPage: string;
  onSetContentRefetchQueries: string[];
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  documentItemTypes: ItemType[];
  expandedItemIds: string[] | undefined;
  pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
  viewerDecisionRole: AskWhom;
}

export const QuoteItemActions = ({
  canEdit,
  isManualSave,
  item,
  quote,
  project,
  onSetContentRefetchQueries,
  pathToDocList,
  pathToDocPage,
  viewMode,
  setViewMode,
  documentItemTypes,
  expandedItemIds,
  pasteItem,
  viewerDecisionRole,
}: Props) => {
  const { t } = useTranslate("QuoteItem");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const projectId = project.id;
  const docId = quote.id;
  const itemId = item.id;

  const subcontractPath = getSubcontractPath(item);
  const canCreateTemplate =
    !item.deletedAt &&
    rootItemsAllowedConstraints["TEMPLATE"].includes(item.type);
  const canCreateSubcontract =
    item.type === "section" &&
    !item.selfOrClosestSubcontract &&
    !subcontractPath;
  const canHaveVerticalSelection = itemCanHaveVerticalSelection(item);
  const canBeReplacedWithTemplates = itemCanBeReplacedWithTemplates(
    item,
    documentItemTypes
  );

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {hasAnyQuestions(item, "QUOTE", viewerDecisionRole) && (
          <DecisionModalButton
            projectId={projectId}
            docType="QUOTE"
            docId={docId}
            itemId={item.id}
            canFinalize={true}
            viewerDecisionRole={viewerDecisionRole}
            expandedItemIds={expandedItemIds}
            type="icon"
          />
        )}
        {subcontractPath ? (
          <Tooltip title={t("Go to subcontracting project")}>
            <IconButton
              component={Link}
              color="primary"
              size="small"
              to={subcontractPath}
              style={{ width: 30, height: 30 }}
            >
              <FileMoveIcon style={{ width: "22px", flexShrink: 0 }} />
            </IconButton>
          </Tooltip>
        ) : undefined}
        <MenuButton
          dataTestId="quote-item-secondary-dotdotdot-menu"
          onToggle={setIsOpen}
        >
          {isManualSave && canEdit && (
            <ViewModeEditMenuItem
              viewMode={viewMode}
              onViewModeChange={setViewMode}
            />
          )}
          {canCreateTemplate && (
            <RestrictedByCapabilityWithDebug capability="TEMPLATING">
              <QuoteTemplateCreateFromItemButton
                projectId={projectId}
                docId={docId}
                itemId={itemId}
                docHasAnyPublishedVersion={false}
              />
            </RestrictedByCapabilityWithDebug>
          )}

          {canCreateSubcontract && (
            <OutsourceMenuItem
              itemId={itemId}
              docId={docId}
              projectId={projectId}
              disabled={!canEdit}
              itemTitle={item.title}
              itemDescription={item.description}
            />
          )}

          {canBeReplacedWithTemplates && item.parentId && (
            <RestrictedByCapabilityWithDebug capability="TEMPLATING">
              <ReplaceWithTemplatesMenuItem
                itemId={itemId}
                itemRank={item.rank}
                parentItemId={item.parentId}
                docId={docId}
                projectId={projectId}
                disabled={!canEdit}
                onItemDeleted={() => {
                  navigate(pathToDocPage, { replace: true });
                }}
                startConfirmProcess={startConfirmProcess}
                expandedItemIds={expandedItemIds}
              />
            </RestrictedByCapabilityWithDebug>
          )}

          {item.isRootItem && (
            <ExportAsXiopdMenuItem
              projectId={projectId}
              docId={docId}
              exportDestination={"UNKNOWN"}
            />
          )}
          {item.isRootItem && (
            <ExportAsXiopdMenuItem
              projectId={projectId}
              docId={docId}
              exportDestination={"MSYS"}
            />
          )}

          {(canCreateTemplate || canCreateSubcontract || item.isRootItem) && (
            <Divider />
          )}

          <QuoteItemMenuItems
            projectId={projectId}
            docId={docId}
            item={item}
            onSetContentRefetchQueries={onSetContentRefetchQueries}
            documentItemTypes={documentItemTypes}
            expandedItemIds={expandedItemIds}
            isMenuOpen={isOpen}
          />
          <Divider />

          {canHaveVerticalSelection && (
            <ItemVerticalMenuItem
              projectId={projectId}
              docId={docId}
              itemId={item.id}
              vertical={item.vertical ?? null}
            />
          )}
          {canHaveVerticalSelection && <Divider />}

          <ProjectBuildingPlacementMenuItem
            projectId={projectId}
            docId={docId}
            itemId={item.id}
          />
          <Divider />

          <DocItemContextMenuItems
            pathToDocPage={pathToDocPage}
            docId={docId}
            docType="QUOTE"
            projectId={projectId}
            item={item}
            startConfirmProcess={startConfirmProcess}
            expandedItemIds={expandedItemIds}
            pasteItem={pasteItem}
          />

          <Divider />

          <RunAttributeExpressionsMenuItem
            projectId={projectId}
            docId={docId}
          />
          <PropertiesDebugMenuItem
            projectId={projectId}
            docId={docId}
            itemId={itemId}
          />
        </MenuButton>
      </Stack>
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  );
};
