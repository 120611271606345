/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
export type ProjectListItemFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, state: Types.ProjectStateMachineStatus, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null };

export const ProjectListItemFragmentDoc = gql`
    fragment ProjectListItem on Project {
  id
  viewerPermissions
  title
  deadline
  overdue
  state
  phase {
    id
    name
  }
  leadId
  budget {
    currency
    amount
  }
  urgent
  assignees {
    id
    firstname
    familyname
  }
  assignee {
    id
    user {
      id
      firstname
      familyname
      fullname
      avatar {
        ...AttachmentSnapshot
      }
    }
  }
  viewerPermissions
  buildingInfo {
    id
    buildingAddress {
      streetLines1
      city
      postalCode
      countryCode
      lat
      lng
    }
  }
  crmOrganisation {
    id
    title
    contactPerson {
      id
      title
      description
      website
      isMeister
      familyname
      firstname
      fullname
      locale
      skillset
    }
  }
  earliestStart
  earliestPlanSessionDate
  assigned
}
    ${AttachmentSnapshotFragmentDoc}`;