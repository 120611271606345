/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRequirementModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type CreateRequirementModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, state: Types.ProjectStateMachineStatus } | null }, projectRequirements: { __typename: 'MissingPermissions' } | { __typename: 'ProjectRequirementsConnection', totalCount: number }, organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultContractType: Types.ContractType }, quoteTemplates: { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, owningSystemOrganisationId: string } }> } };

export type CreateRequirementMutationVariables = Types.Exact<{
  input: Types.CreateRequirementInput;
}>;


export type CreateRequirementMutation = { createRequirement: { __typename: 'CreateRequirementResult', requirement: { __typename: 'Requirement', id: string } } };


export const CreateRequirementModalDocument = gql`
    query CreateRequirementModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        state
      }
    }
  }
  projectRequirements(projectId: $projectId, limit: 0) {
    ... on ProjectRequirementsConnection {
      totalCount
    }
  }
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultContractType
    }
  }
  quoteTemplates(
    limit: 1
    filters: {sources: [available], applicableFor: REQUIREMENT}
    sorting: {column: title, direction: asc}
  ) {
    edges {
      node {
        id
        resolvedAsReadModelVersionNumber
        title
        owningSystemOrganisationId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useCreateRequirementModalQuery__
 *
 * To run a query within a React component, call `useCreateRequirementModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateRequirementModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateRequirementModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateRequirementModalQuery(baseOptions: Apollo.QueryHookOptions<CreateRequirementModalQuery, CreateRequirementModalQueryVariables> & ({ variables: CreateRequirementModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>(CreateRequirementModalDocument, options);
      }
export function useCreateRequirementModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>(CreateRequirementModalDocument, options);
        }
export function useCreateRequirementModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>(CreateRequirementModalDocument, options);
        }
export type CreateRequirementModalQueryHookResult = ReturnType<typeof useCreateRequirementModalQuery>;
export type CreateRequirementModalLazyQueryHookResult = ReturnType<typeof useCreateRequirementModalLazyQuery>;
export type CreateRequirementModalSuspenseQueryHookResult = ReturnType<typeof useCreateRequirementModalSuspenseQuery>;
export type CreateRequirementModalQueryResult = Apollo.QueryResult<CreateRequirementModalQuery, CreateRequirementModalQueryVariables>;
export const CreateRequirementDocument = gql`
    mutation CreateRequirement($input: CreateRequirementInput!) {
  createRequirement(input: $input) {
    requirement {
      id
    }
  }
}
    `;
export type CreateRequirementMutationFn = Apollo.MutationFunction<CreateRequirementMutation, CreateRequirementMutationVariables>;

/**
 * __useCreateRequirementMutation__
 *
 * To run a mutation, you first call `useCreateRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequirementMutation, { data, loading, error }] = useCreateRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequirementMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequirementMutation, CreateRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequirementMutation, CreateRequirementMutationVariables>(CreateRequirementDocument, options);
      }
export type CreateRequirementMutationHookResult = ReturnType<typeof useCreateRequirementMutation>;
export type CreateRequirementMutationResult = Apollo.MutationResult<CreateRequirementMutation>;
export type CreateRequirementMutationOptions = Apollo.BaseMutationOptions<CreateRequirementMutation, CreateRequirementMutationVariables>;